<template>
  <CModal backdrop="static" size="xl" :visible="cModalVisible" @show="initData()" @close="closeAnalysisRequest()">
    <CModalHeader>
      <CModalTitle>{{ $t('newLabReport') }}</CModalTitle>
    </CModalHeader>
    <CModalBody v-if="dataLoaded"> 
      <CRow class="mb-3">
        <CCol sm="12">
          <CFormLabel for="machineName"><strong>{{ $t('machine') }}</strong></CFormLabel>
          <CFormInput type="text" id="machineName" v-model="machineName" :value="machineName" readonly />
        </CCol>       
      </CRow>
      <CRow>
        <CCol sm="12">
          <CFormLabel for="batchNumber">{{ pretifyName('labReportFields.batch_Number') }}</CFormLabel>
          <CInputGroup>
            <CInputGroupText>ST</CInputGroupText>          
            <CFormInput type="text" id="batchNumber" v-model="batchNumber" />
          </CInputGroup>
        </CCol>       
      </CRow>
      <CRow>
        <CCol sm="12">
          <CFormLabel for="reasonOfAnalysis">{{ pretifyName('labReportFields.reason_Of_Analysis') }}</CFormLabel>
          <CFormTextarea id="reasonOfAnalysis" rows="5" v-model="reasonOfAnalysis" />   
        </CCol>
      </CRow>      
      <CRow>
        <CCol sm="6">
          <CFormLabel for="smell">{{ pretifyName('labReportFields.smell') }}</CFormLabel>
          <CFormTextarea id="smell" rows="5" v-model="smell" />   
        </CCol>
        <CCol>
          <CFormLabel for="cleannessOfWorkstation">{{ pretifyName('labReportFields.cleanness_Of_Workstation') }}</CFormLabel>
          <CFormTextarea id="cleannessOfWorkstation" rows="5" v-model="cleannessOfWorkstation" />   
        </CCol>       
      </CRow>  
      <CRow>
        <CCol sm="6">
          <CFormCheck type="checkbox" id="floatingOilPhase" :label="pretifyName('labReportFields.floating_Oil_Phase')" v-model="floatingOilPhase" />
        </CCol>      
      </CRow>      
      <br> 
      <CRow class="mb-3">
        <div class="mb-3">
          <CFormCheck type="checkbox" id="notifyUser" :label="$t('notificationFlag')" v-model="notifyUser"/>
        </div>
        <div v-if="isAgentSession">
          <CFormSelect class="mb-3" aria-label="user select" id="contact" v-model="notifySpecificUser">
            <option v-for="(user, index) in orderedUserList" :key="index"
              :value="user.id">
                {{ mainContactText(user.isMainContact) }} {{ user.first_Name }} {{ user.last_Name }} {{ mainContactText(user.isMainContact) }}
            </option>
          </CFormSelect>
        </div>        
      </CRow>    
    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="closeAnalysisRequest()">
        {{ $t('close') }}
      </CButton>
      <CButton color="dark" @click="createAnalysisRequest()">{{ $t('createRequest') }}</CButton>
    </CModalFooter>      

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </CModal>
</template>

<script>
import MeasurementService from '../../services/measurements'
import AnalysisRequestService from '../../services/analysis-requests'
import StringFormatter from '../../helpers/stringFormatter'
import CustomerService from '../../services/customers'
import PermissionManager from '../../helpers/permissionManager'
import UserService from '../../services/users'

export default {
  name: 'NewLabReportRequestMobile',
  props: {
    cModalVisible: Boolean,
    idMeasure: Number
  },
  data() {
    return {
      dataLoaded: false,
      message: '',
      selectedMachineID: 0,
      machineName: '',
      notifyUser: true,
      batchNumber: '',
      reasonOfAnalysis: '',
      smell: '',
      cleannessOfWorkstation: '',
      floatingOilPhase: false,
      notifySpecificUser: 0,
      customerContactList: []          
    }
  },
  created() {
    this.initData()
  },
  computed: {
    orderedUserList() {
      let processedData = []
      let filteredUserData = [...this.customerContactList]
      if (filteredUserData.length > 0) {
        processedData.push({
          'id': 0,
          'first_Name': '',
          'last_Name': '',
          'isMainContact': false
        })
        let mainContactIndex = filteredUserData.findIndex(user => user.isMainContact)
        if (mainContactIndex >= 0) {
          processedData.push(filteredUserData[mainContactIndex])
          filteredUserData.splice(mainContactIndex, 1)
        }
        filteredUserData.forEach((user) => {
          processedData.push(user)
        })
      }
      return processedData
    },
    isAgentSession() {
      return (PermissionManager.getAgentFilteredVisibility() != null)
    }
  },
  methods: {
    getMeasurement() {
      if ((this.idMeasure) && (this.idMeasure != 0)) {
        MeasurementService.getSingleMeasurement(this.idMeasure).then(
          (response) => {
            this.selectedMachineID = response.data.idMachine
            this.machineName = response.data.machine_Name
            this.getCustomerContactList(response.data.idCustomer)
            if ((response.data.idReport) && (response.data.idReport != 0)) {
              this.message = this.$t('alreadyAssignedLabReportException')
              this.delay(2000).then(
                () => {
                  this.closeAnalysisRequest()
                }
              )
            } else {            
              this.dataLoaded = true
            }
          },
          (error)  => {
            this.message =           
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message ||
                error.toString()
          }
        )
      }
    },
    createAnalysisRequest() {
      if ((this.idMeasure) && (this.idMeasure != 0)) { 
        const userData = JSON.parse(localStorage.getItem('user'))
        let params = {
            'request_Type': 1,
            'requested_By_User': this.notifySpecificUser != 0 ? this.notifySpecificUser : userData.user.id,
            'request_Status': 0,
            'idMachine': this.selectedMachineID,
            'notify_User': this.notifyUser,
            'attached_idMeasure': this.idMeasure,
            'keep_User_Notified': this.notifyUser,
            'batch_Number': this.batchNumber,
            'reason_Of_Analysis': this.reasonOfAnalysis,
            'smell': this.smell,
            'cleannes_Of_Workstation': this.cleannessOfWorkstation,
            'floating_Oil_Phase': this.floatingOilPhase,
            'active_Forward_Process': false        
        }
        AnalysisRequestService.createAnalysisRequest(params).then(
            () => {
            this.closeAnalysisRequest()
            },
            (error)  => {
            this.message =           
                (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message ||
                error.toString()
            }
        )
      } else {
        this.message = this.$t('validMachineIDException')
      }
    },
    closeAnalysisRequest() {
      this.message = ''
      this.dataLoaded = false
      this.$emit('closeAnalysisRequest')
    },
    initData() {
      this.dataLoaded = false
      this.message = ''
      this.selectedMachineID = 0
      this.notifyUser = true
      this.machineName = ''   
      this.batchNumber = ''
      this.reasonOfAnalysis = ''
      this.smell = ''
      this.cleannessOfWorkstation = ''
      this.floatingOilPhase = false      
      this.getMeasurement()
    },
    pretifyName(name) {
      return StringFormatter.pretifyName(this.$t(name))
    },
    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time))
    },
    getCustomerContactList(customerID) {
      this.customerContactList = []
      if ((customerID) && (customerID != 0)) {
        // 1. Get Customer
        CustomerService.getSingleCustomer(customerID).then(
          (response) => {
            let contactPerson = response.data.contact_Person
            // 2. Get Users
            let userParams = {
              'company_Type': 1,
              'idCompany': customerID,
              '$limit': 5000
            }
            UserService.getUsers(userParams).then(
              (response) => {
                response.data.data.forEach((user) => {
                  var newUser = user
                  newUser.isMainContact = (newUser.first_Name + ' ' + newUser.last_Name == contactPerson)
                  this.customerContactList.push(newUser)
                })
              }
            )            
          }
        )
      }
    },
    mainContactText(isMainContact) {
      return (isMainContact ? '**' : '')
    }                  
  }
}
</script>