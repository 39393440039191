<template>
  <CCard fluid>
    <CCardBody>
      <CForm>
        <div class="mb-3">
          <CFormLabel for="newPassword"><strong>{{ $t('newPassword') }}</strong></CFormLabel>
          <CFormInput type="password" v-model="newPassword" id="newPassword" placeholder="Password"/>
        </div>
        <div class="mb-3">
          <CFormLabel for="newPasswordCheck"><strong>{{ $t('newPasswordCheck') }}</strong></CFormLabel>
          <CFormInput type="password" v-model="newPasswordCheck" id="newPasswordCheck" placeholder="Confirm Password"/>
        </div>
        <div class="mb-3">
          <CButton type="button" color="dark" shape="rounded-0" class="mb-3" @click="checkAndResetPassword()">{{ $t('resetPassword') }}</CButton>
        </div>
        <CAlert v-if="errorMessage" color="danger" class="d-flex align-items-center">
          <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
          <div>
            {{ errorMessage }}
          </div>
        </CAlert>    
        <CAlert v-if="confirmationMessage" color="success" class="d-flex align-items-center">
          <CIcon icon="cil-check" class="flex-shrink-0 me-2" width="24" height="24" />
          <div>
            {{ confirmationMessage }}
          </div>
        </CAlert>             
      </CForm>
    </CCardBody>
  </CCard>  
</template>

<script>
export default {
  name: "ResetPassword",
  data() {
      return {
        newPassword: '',
        newPasswordCheck: '',
        errorMessage: '',
        confirmationMessage: ''
      }
  },
  methods: {
    checkAndResetPassword() {
      if ((this.newPassword == '') || (this.newPasswordCheck == '')) {
        this.errorMessage = this.$t('emptyPasswordException')
        return
      }
      if (this.newPassword != this.newPasswordCheck) {
        this.errorMessage = this.$t('checkPasswordMismatchException')
        return
      }
      this.resetPassword()
    },
    resetPassword() {
      let token = this.$route.query.token
      let passwordData = {
        'token': token,
        'password': this.newPassword
      }
      this.$store.dispatch("auth/resetPwd", passwordData).then(
        () => {
          this.confirmationMessage = this.$t('passwordSuccesfullyUpdated')
          this.delay(2000).then(
            () => {
              this.goToLoginPage()
            }
          )
        },
        () => {
          this.errorMessage = this.$t('changePasswordException')
        }
      )
    },
    isMobile() {
      let mobile = localStorage.getItem('isMobile')
      return (mobile === 'true')
    },
    goToLoginPage() {
      if (this.isMobile()) {
        this.$router.push('/m-login')
      } else {
        this.$router.push('/login')
      }
    },
    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time))
    },
  },
}
</script>