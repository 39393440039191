<template>
  <CContainer fluid>
    <div class="row">
      <div class="col-12 col-8-tablet push-2-tablet text-center">
        <h4 class="description" style="color:Darkgrey">
          <CSpinner color="dark" size="sm" variant="grow"/> 
          <CSpinner color="secondary" size="sm" variant="grow"/> 
          <CSpinner color="light" size="sm" variant="grow"/>      
              {{ $t('loadingData')}}    
          <CSpinner color="light" size="sm" variant="grow"/>
          <CSpinner color="secondary" size="sm" variant="grow"/>
          <CSpinner color="dark" size="sm" variant="grow"/>
        </h4>
      </div>
    </div>
  </CContainer>
</template>

<script>
export default {
  name: 'LoadingData'
}
</script>