import axios from 'axios'
import authHeader from './auth-header'
import formatQuery from './query-helper'

const API_URL = process.env.VUE_APP_SERVER_BASE_URL + 'measurement-pictures'

class MeasurementPicturesService {
    getPictures(queryParams) {
        return axios.get(API_URL + formatQuery(queryParams), { headers: authHeader() })
    }
    assignPicture(pictureParams) {
        return axios.post(API_URL, pictureParams, { headers: authHeader() })
    }
    deletePicture(compositeKeyParams) {
        return axios.delete(API_URL + formatQuery(compositeKeyParams), {headers: authHeader() })
    }
    // Build other api calls here....   
}

export default new MeasurementPicturesService()