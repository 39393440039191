import axios from 'axios'
import authHeader from './auth-header'
import formatQuery from './query-helper'

const API_URL = process.env.VUE_APP_SERVER_BASE_URL + 'machine-assigned-filtrations'

class AssignedFiltrationsService {
    getFiltrations(queryParams) {
        return axios.get(API_URL + formatQuery(queryParams), { headers: authHeader() })
    }
    updateFiltration(compositeKeyParams, filtrationParams) {
        return axios.patch(API_URL + formatQuery(compositeKeyParams), filtrationParams, { headers: authHeader() })
    }
    assignFiltration(filtrationParams) {
        return axios.post(API_URL, filtrationParams, { headers: authHeader() })
    }
    deleteFiltration(compositeKeyParams) {
        return axios.delete(API_URL + formatQuery(compositeKeyParams), {headers: authHeader() })
    }
    // Build other api calls here....   
}

export default new AssignedFiltrationsService()