import axios from 'axios'
import authHeader from './auth-header'
import formatQuery from './query-helper'

const API_URL = process.env.VUE_APP_SERVER_BASE_URL + 'machines'

class MachineService {
    getMachines(queryParams) {
        return axios.get(API_URL + formatQuery(queryParams), { headers: authHeader() })
    }
    getSingleMachine(machineID) {
        return axios.get(API_URL + '/' + machineID, { headers: authHeader() })
    }    
    updateMachines(machineID, machineParams) {
        return axios.patch(API_URL + '/' + machineID, machineParams, { headers: authHeader() })
    }
    createMachine(machineParams) {
        return axios.post(API_URL, machineParams, { headers: authHeader() })
    }
    deleteMachine(machineID) {
        return axios.delete(API_URL + '/' + machineID, {headers: authHeader() })
    }
    // Build other api calls here....   
}

export default new MachineService()
