<template>
  <CCard fluid v-if="isDataLoaded">
    <CCardBody>
      <CCardTitle>{{ $t('lastLabReport') }} </CCardTitle>
      <CCardText v-if="currReport">
        {{ pretifyName('labReportFields.date_Of_Analysis') }}: <strong>{{ currReport.date_Of_Analysis }}</strong><br>
        {{ pretifyName('labReportFields.batch_Number') }}: <strong>{{ currReport.batch_Number }}</strong><br>
        {{ pretifyName('characteristics.Product Specific Value') }}: <strong>{{ currReport.prod_Specific_Value }}</strong><br>
        {{ pretifyName('characteristics.Product specific value alkalinity') }}: <strong>{{ currReport.prod_Specific_Alk }}</strong><br>
        {{ pretifyName('characteristics.Product specific value acid split') }}: <strong>{{ currReport.prod_Specific_Acid }}</strong>
      </CCardText>
    </CCardBody>
  </CCard>
</template>

<script>
import DateFormatter from '../helpers/dateFormatter'
import StringFormatter from '../helpers/stringFormatter'
import LabReportService from '../services/lab-reports'
import LabReportDetailService from '../services/lab-report-details'

export default {
  name: 'PrevLabReportInfobox',
  props: {
    newLabReport: Object
  },
  expose: ['dataLoaded'],
  data() {
    return {
      newLabReportID: 0,
      reportLoaded: false,
      dataLoaded: false,
      currReport: ''
    }
  },
  computed: {
    isDataLoaded() {
      if (this.newLabReportID != this.newLabReport.id) {
        this.refreshData()
      }
      if (!this.reportLoaded) {
        this.getPreviousReportData()
      } else if (!this.dataLoaded) {
        this.getReportDetails()
      }
      return this.dataLoaded
    }
  },
  created() {
    this.refreshData()
  },
  methods: {
    getPreviousReportData(){
      this.currReport = ''
      if (this.newLabReport) {
        LabReportService.getLabReports({
          'type': this.newLabReport.type,
          'idMachine': this.newLabReport.idMachine,
          'idProduct': this.newLabReport.idProduct,
          'id': '<' + this.newLabReport.id,
          '$sort[id]': -1
        }).then(
          (response) => {
            const foundReports = response.data
            if (foundReports.data[0]) {
              this.currReport = foundReports.data[0]
              this.currReport.date_Of_Analysis = DateFormatter.formatDate(this.currReport.date_Of_Analysis, true)
            }
            this.reportLoaded = true
          }
        )
      } else {
        this.reportLoaded = true
        this.dataLoaded = true
      }
    },
    getReportDetails() {
      if (this.currReport) {
        LabReportDetailService.getLabReportDetails({ 'idLabReport': this.currReport.id }).then(
          (response) => {
            this.currReport.prod_Specific_Value = '-'
            this.currReport.prod_Specific_Alk = '-'
            this.currReport.prod_Specific_Acid = '-'
            const reportDetails = response.data.data
            if (reportDetails) {
              reportDetails.forEach(detail => {
                if (detail.id == 24) {
                  this.currReport.prod_Specific_Value = detail.sample
                }
                if (detail.id == 31) {
                  this.currReport.prod_Specific_Alk = detail.sample
                }
                if (detail.id == 32) {
                  this.currReport.prod_Specific_Acid = detail.sample
                }                                
              })
            }
            this.dataLoaded = true
          }
        )
      } else {
        this.dataLoaded = true
      }
    },
    refreshData() {
      this.reportLoaded = false,
      this.dataLoaded = false,
      this.newLabReportID = (this.newLabReport ? this.newLabReport.id : 0)
    },
    pretifyName(name) {
      return StringFormatter.pretifyName(this.$t(name))
    }        
  }
}
</script>