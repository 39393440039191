<template>
  <CCard fluid>
    <CCardBody>
      <CForm>
        <div class="mb-3">
          <CFormLabel for="email"><strong>{{ $t('emailAddress') }}</strong></CFormLabel>
          <CFormInput type="email" v-model="email" id="email" placeholder="name@example.ch"/>
        </div>
        <div class="mb-3">
          <CFormLabel for="password"><strong>{{ $t('password') }}</strong></CFormLabel>
          <CFormInput type="password" v-model="password" id="password" placeholder="Password"/>
        </div>
        <div class="mb-3">
          <CButton type="button" color="dark" shape="rounded-0" class="mb-3" @click="handleRegister()">{{ $t('signup') }}</CButton>
        </div>
        <CAlert v-if="message" 
          class="d-flex align-items-center"
          :color="successful ? 'success' : 'warning'" >
          <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
          <div>
            {{ message }}
          </div>
        </CAlert>        
      </CForm>
    </CCardBody>
  </CCard>          
</template>

<script>
export default {
  name: "RegisterMobile",
  data() {
    return {
      loading: false,
      message: '',
      successful: false,
      email: '',
      password: ''        
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/m-profile")
    }
  },
  methods: {
    handleRegister() {
      let user = {
        email: this.email,
        password: this.password
      }
      this.loading = true;
      this.$store.dispatch("auth/register", user).then(
        () => {
          this.message = this.$t('confirmUserMessage')
          this.successful = true,
          this.loading = false
        },
        (error) => {
          this.loading = false
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
          this.successful = false
        }
      )
    },
  },
}
</script>