import { jsPDF } from 'jspdf'
import dateFormatter from '../helpers/dateFormatter'
import StringFormatter from '../helpers/stringFormatter'
import i18n from '../i18n'
const { t } = i18n.global

class LabReportSummary {
  print(reportContent, reportTitle, fileName) {
    const doc = new jsPDF()

    // Define Report Layout
    doc.setFont('helvetica', 'bold')
    doc.setFontSize(16)
    doc.text(reportTitle, 10, 15)
    doc.setFontSize(12)
    doc.text( t('analysisRequestFields.id') + ' #' + this.preventNullValues(reportContent.id), 10, 20)

    var img = new Image()
    img.src = require('@/assets/Logo_Swiss_Tribology_transp_grey.png')
    doc.addImage(img, 'jpeg', 150, 7, 50, 13) 

    // Main Data Part
    doc.setDrawColor(211, 211, 211)
    doc.setFillColor(211, 211, 211)
    doc.rect(10, 26, 190, 5, 'F')
    doc.text( t('details'), 12, 30)

    doc.setFont('helvetica', 'normal')
    doc.setFontSize(11)
    doc.text( t('machine') + ': ', 10, 40)
    doc.text( t('product') + ': ', 10, 45)
    doc.text( this.pretifyName('analysisRequestFields.id') + ': ', 10, 50)
    doc.text(this.preventNullValues(reportContent.machine_Name), 40, 40)
    doc.text(this.preventNullValues(reportContent.product_Name), 40, 45)
    doc.text(this.preventNullValues(String(reportContent.id)), 40, 50)

    doc.text( t('analysisRequestFields.requested_By_User') + ': ', 100, 40)
    doc.text( this.pretifyName('analysisRequestFields.creation_Date') + ': ', 100, 45)
    doc.text( this.pretifyName('analysisRequestFields.handled_By') + ': ', 100, 50)
    doc.text(this.preventNullValues(reportContent.requested_By_User_Name), 140, 40)
    doc.text(reportContent.creation_Date ? dateFormatter.formatDate(reportContent.creation_Date, true) : '-', 140, 45)
    doc.text(this.preventNullValues(reportContent.request_Handled_By_Name), 140, 50)

    doc.text( this.pretifyName('analysisRequestFields.status') + ':  ' + this.preventNullValues(reportContent.request_Status), 10, 57)
    doc.text( this.pretifyName('analysisRequestFields.request_Type') + ':  ' + this.preventNullValues(reportContent.request_Type), 10, 62)

    // Details part
    doc.setDrawColor(211, 211, 211)
    doc.setFillColor(211, 211, 211)
    doc.rect(10, 66, 190, 5, 'F')
    doc.setFont('helvetica', 'bold')
    doc.setFontSize(12)      
    doc.text( t('details'), 12, 70)

    // Print LabReport/Water Analysis Data:
    let nextLine = 80
    doc.setFontSize(11)
    doc.setFont('helvetica', 'normal')
    if (reportContent.reason_Of_Analysis) {
      doc.text( this.pretifyName('labReportFields.reason_Of_Analysis') + ': ', 15, nextLine)
      doc.text(this.preventNullValues(reportContent.reason_Of_Analysis), 85, nextLine)
      nextLine += 5
    }
    if (reportContent.batch_Number) {
      doc.text( this.pretifyName('labReportFields.batch_Number') + ': ', 15, nextLine)
      doc.text(this.preventNullValues(reportContent.batch_Number), 85, nextLine)
      nextLine += 5
    }
    if (reportContent.smell) {
      doc.text( this.pretifyName('labReportFields.smell') + ': ', 15, nextLine)
      doc.text(this.preventNullValues(reportContent.smell), 85, nextLine)
      nextLine += 5
    }
    if (reportContent.cleannes_Of_Workstation) {
        doc.text( this.pretifyName('labReportFields.cleanness_Of_Workstation') + ': ', 15, nextLine)
        doc.text(this.preventNullValues(reportContent.cleannes_Of_Workstation), 85, nextLine)
        nextLine += 5
    }
    if (reportContent.floating_Oil_Phase) {
      doc.text( this.pretifyName('labReportFields.floating_Oil_Phase') + ': ', 15, nextLine)
      doc.text(this.preventNullValues(String(reportContent.floating_Oil_Phase)), 85, nextLine)
      nextLine += 5
    }          
    
    // Footer
    doc.setFont('helvetica', 'normal')
    doc.setFontSize(10)
    doc.text('STRUB + Co.AG', 10, 285)
    doc.text('Swiss Tribology', 10, 290)
    doc.text('Mühlemattstrasse 5', 55, 285)
    doc.text('CH-6260 Reiden', 55, 290)
    doc.text('Phone +41 62 785 22 22', 100, 285)
    doc.text('Fax +41 62 785 22 33', 100, 290)
    doc.text('www.strub-lube.ch', 155, 285)
    doc.text('strub@strub-lube.ch', 155, 290)                  

    // Export File
    doc.save(fileName ? fileName + '.pdf' : 'analysisRequest.pdf')    
  }

  preventNullValues(parameter) {
    return (parameter? parameter : '-')
  }

  pretifyName(name) {
    return StringFormatter.pretifyName(t(name))
  }
}

export default new LabReportSummary()