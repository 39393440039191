<template>
  <CModal backdrop="static" size="xl" :visible="cModalVisible" @show="reloadData()" @close="closeCustomerCard()">
    <CModalHeader>
      <CModalTitle>{{ confirmationButtonText}} {{ $t('customer') }}</CModalTitle>
    </CModalHeader>
    <CModalBody v-if="dataLoaded"> 
      <CNav variant="tabs" role="tablist">
        <CNavItem>
          <CNavLink
            href="javascript:void(0);"
            :active="tabPaneActiveKey === 1"
            @click="() => {tabPaneActiveKey = 1}"
          >
            {{ $t('general') }}
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink
            href="javascript:void(0);"
            :active="tabPaneActiveKey === 2"
            @click="() => {tabPaneActiveKey = 2}"
          >
            {{ $t('customerLocation') }}
          </CNavLink>
        </CNavItem>
      </CNav> 
      <CTabContent>
        <CTabPane role="tabpanel" aria-labelledby="home-tab" :visible="tabPaneActiveKey === 1">
          <br>   
          <CRow class="mb-3">
            <CFormLabel for="customerName" :class="labelColumnsLength">{{ pretifyName('customerFields.name') }}</CFormLabel>
            <CCol :sm="fieldColumnsLength">
              <CFormInput type="text" id="customerName" v-model="currCustomer.company" />
            </CCol>
          </CRow>    
          <CRow class="mb-3">
            <CFormLabel for="customerPhone" :class="labelColumnsLength">{{ pretifyName('customerFields.phone_Number') }}</CFormLabel>
            <CCol :sm="fieldColumnsLength">
              <CFormInput type="phone" id="customerPhone" v-model="currCustomer.phone_Number" />
            </CCol>
          </CRow>
          <CRow class="mb-3">
            <CFormLabel for="navCustomerNo" :class="labelColumnsLength">{{ pretifyName('customerFields.client_No') }}</CFormLabel>
            <CCol :sm="fieldColumnsLength">
              <CFormInput type="text" id="navCustomerNo" v-model="currCustomer.client_No" />
            </CCol>
          </CRow>
          <CRow class="mb-3">
            <CFormLabel for="customerContact" :class="labelColumnsLength">{{ pretifyName('customerFields.contact_Person') }}</CFormLabel>
            <CCol :sm="fieldColumnsLength">
              <CInputGroup class="mb-3">
                <CFormInput type="text" id="customerContact" v-model="currCustomer.contact_Person" />
                <CButton type="button" color="dark" variant="outline" id="searchContactPerson" :disabled="checkExtendedUserPermissions"
                  @click="userLookupVisible = !userLookupVisible">{{ $t('search') }}</CButton>
              </CInputGroup>
            </CCol>
          </CRow>  
          <CRow class="mb-3">
            <CFormLabel for="customerDistributor" :class="labelColumnsLength">{{ $t('distributor') }}</CFormLabel>
            <CCol :sm="fieldColumnsLength">
              <CInputGroup class="mb-3">
                <CFormInput type="text" id="customerDistributor" v-model="customerDistributor" readonly :value="currDistributor"/>
                <CButton type="button" color="dark" variant="outline" id="searchDistributor" :disabled="checkExtendedUserPermissions"
                  @click="distLookupVisible = !distLookupVisible">{{ $t('search') }}</CButton>
              </CInputGroup>
            </CCol>
          </CRow>           
          <CRow class="mb-3">
            <div :class="toggleOffset">
              <CFormCheck type="checkbox" id="disabled" :label="$t('disabled')" v-model="currCustomer.disabled" />
            </div>
          </CRow>                
        </CTabPane>
        <CTabPane role="tabpanel" aria-labelledby="profile-tab" :visible="tabPaneActiveKey === 2">
          <br>   
          <CRow class="mb-3">
            <CFormLabel for="customerAddress" :class="labelColumnsLength">{{ pretifyName('customerFields.address') }}</CFormLabel>
            <CCol :sm="fieldColumnsLength">
              <CFormInput type="text" id="customerAddress" v-model="currCustomer.address" />
            </CCol>
          </CRow>
          <CRow class="mb-3">
            <CFormLabel for="customerCity" :class="labelColumnsLength">{{ pretifyName('customerFields.city') }}</CFormLabel>
            <CCol :sm="fieldColumnsLength">
              <CFormInput type="text" id="customerCity" v-model="currCustomer.city" />
            </CCol>
          </CRow>   
          <CRow class="mb-3">
            <CFormLabel for="customerZip" :class="labelColumnsLength">{{ pretifyName('customerFields.zip') }}</CFormLabel>
            <CCol :sm="fieldColumnsLength">
              <CFormInput type="text" id="customerZip" v-model="currCustomer.zipCode" />
            </CCol>
          </CRow> 
          <CRow class="mb-3">
            <CFormLabel for="customerCountry" :class="labelColumnsLength">{{ pretifyName('customerFields.country') }}</CFormLabel>
            <CCol :sm="fieldColumnsLength">
              <CFormSelect class="mb-3" aria-label="country select" id="customerCountry" v-model="currCustomer.country">
                <option v-for="(country, index) in countries" :key="index"
                  :value="country.code">{{ country.name }}</option>
              </CFormSelect>
            </CCol>
          </CRow>
          <CRow class="mb-3">
            <CFormLabel for="language" :class="labelColumnsLength">{{ pretifyName('customerFields.language') }}</CFormLabel>
            <CCol :sm="fieldColumnsLength">
              <CFormSelect class="mb-3" aria-label="language select" id="language" v-model="currCustomer.language_int">
                <option value="0">{{ $t('languageOptions.english') }}</option>
                <option value="1">{{ $t('languageOptions.german') }}</option>
                <option value="2">{{ $t('languageOptions.italian') }}</option>
                <option value="3">{{ $t('languageOptions.french') }}</option>
              </CFormSelect>
            </CCol>
          </CRow>           
        </CTabPane>
      </CTabContent>                                   
    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="closeCustomerCard()">
        {{ $t('close') }}
      </CButton>
      <CButton color="primary"
        :disabled="checkUserPermissions"
        @click="performConfirmationAction()">
        {{ confirmationButtonText }}
      </CButton>
    </CModalFooter>

    <CModal size="xl" alignment="center" :visible="distLookupVisible" @close="() => { distLookupVisible = false }">
      <CModalHeader>
        <CModalTitle>{{ $t('distributors') }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <distributors-lookup
          @itemSelected="getDistributorName($event)" />
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="() => { distLookupVisible = false }">
          {{ $t('close') }}
        </CButton>
      </CModalFooter>
    </CModal>     

    <CModal size="xl" alignment="center" :visible="userLookupVisible" @close="() => { userLookupVisible = false }">
      <CModalHeader>
        <CModalTitle>{{ $t('users') }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <users-lookup v-if="!isMobile"
          :companyType="1"
          :companyID="currCustomer.id"
          :appendButtons="appendButtons()"
          @itemSelected="getContactInfo($event)" />      
        <users-lookup-mobile v-if="isMobile"
          :companyType="1"
          :companyID="currCustomer.id"
          :appendButtons="appendButtons()"
          @itemSelected="getContactInfo($event)" />
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="() => { userLookupVisible = false }">
          {{ $t('close') }}
        </CButton>
      </CModalFooter>
    </CModal>

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </CModal>
</template>

<script>
import CustomerService from '../services/customers'
import DistributorService from '../services/distributors'
import DistributorsLookup from '../components/DistributorsLookup.vue'
import { Languages } from '../helpers/getEnumValues'
import StringFormatter from '../helpers/stringFormatter'
import PermissionManager from '../helpers/permissionManager'
import CountryService from '../services/countries'
import UsersLookupMobile from './mobile/UsersLookupMobile.vue'
import UsersLookup from './UsersLookup.vue'
import UserService from '../services/users'

export default {
  name: 'CustomerCard',
  props: {
    cModalVisible: Boolean,
    operationType: Number,
    customerID: Number
  },
  data() {
    return {
      message: '',
      currCustomer: '',
      dataLoaded: false,
      distLookupVisible: false,
      currDistributor: '',
      currDistributorID: 0,
      countriesLoaded: false,
      countries: [],
      tabPaneActiveKey: 1,
      userLookupVisible: false            
    }
  },
  computed: {
    confirmationButtonText() {
      if (this.operationType == 0) {
        return this.$t('edit')
      } else {
        return this.$t('create')
      }
    },
    checkUserPermissions() {
      return !(PermissionManager.hasAgentPermissions())
    },
    checkExtendedUserPermissions() {
      return (!(PermissionManager.hasAgentPermissions()) || (PermissionManager.getCustomerFilteredVisibility()))
    },
    labelColumnsLength() {
      if (screen.width <= 1000) {
        return 'col-sm-4 col-form-label'
      } else {
        return 'col-sm-2 col-form-label'
      }
    },
    fieldColumnsLength() {
      if (screen.width <= 1000) {
        return '8'
      } else {
        return '10'
      }      
    },
    toggleOffset() {
      if (screen.width <= 1000) {
        return 'col-sm-8 offset-sm-4'
      } else {
        return 'col-sm-10 offset-sm-2'
      }
    },
    isMobile() {
      let mobile = localStorage.getItem('isMobile')
      return (mobile === 'true')
    }                
  },
  methods: {
    getCustomer() {
      if (this.operationType == 0) {
        if ((this.customerID == 0) || (!this.customerID)) {
          this.message = this.$t('validCustomerIDException')
        } else {
          CustomerService.getSingleCustomer(this.customerID).then(
            (response) => {
              this.currCustomer = response.data
              this.currCustomer.language_int = this.getLanguage(this.currCustomer.language)
              if (!(this.currCustomer.language_int)) {
                this.currCustomer.language_int = 0
              }              
              this.currDistributorID = this.currCustomer.idDistributor
              this.currDistributor = this.currCustomer.distributor_Name
              this.dataLoaded = true
            },
            (error) => {
              this.message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString()
            }
          )
        }
      } else {
        this.currDistributorID = 0
        this.currDistributor = ''
        // Create empy customer
        this.currCustomer = {
          'company': null,
          'address': null,
          'city': null,
          'zipCode': null,
          'country': null,
          'phone_Number': null,
          'client_No': null,
          'contact_Person': null,
          'disabled': false,
          'language_int': 0      
        }
        this.dataLoaded = true
      }
    },
    getCountries() {
      this.countriesLoaded = false
      this.countries = []
      CountryService.getCountries({ '$limit': 5000 }).then(
        (response) => {
          const tempCountries = response.data.data
          tempCountries.forEach(country => {
            this.countries.push({
              'code': country.alpha2Code,
              'name': country.name
            })
          })
          this.countriesLoaded = true
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()          
        }
      )
    },    
    performConfirmationAction() {
      let customerParameters = {
        'company': this.currCustomer.company,
        'address': this.currCustomer.address,
        'city': this.currCustomer.city,
        'zipCode': this.currCustomer.zipCode,
        'country': this.currCustomer.country,
        'phone_Number': this.currCustomer.phone_Number,
        'idDistributor': this.currDistributorID,
        'client_No': this.currCustomer.client_No,
        'contact_Person': this.currCustomer.contact_Person,
        'disabled': this.currCustomer.disabled,
        'language': this.currCustomer.language_int
      }
      if (this.operationType == 0) {
        this.editCustomer(customerParameters)
      } else {
        this.createCustomer(customerParameters)
      }
    },
    createCustomer(customerParameters) {
      const userAgent = PermissionManager.getAgentFilteredVisibility()
      if (userAgent) {
        customerParameters.idAgent = userAgent.idAgent
      }
      CustomerService.createCustomer(customerParameters).then(
        () => {
          this.message = ''
          this.dataLoaded = false
          this.$emit('closeCustomerCardModal', true)
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        }
      )
    },
    editCustomer(customerParameters) {
      CustomerService.updateCustomer(this.customerID, customerParameters).then(
        () => {
          this.message = ''
          this.dataLoaded = false
          this.$emit('closeCustomerCardModal', true)
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        }
      )
    },
    closeCustomerCard() {
      this.message = ''
      this.dataLoaded = false
      this.$emit('closeCustomerCardModal', false)
    },
    reloadData() {
      this.dataLoaded = false
      this.tabPaneActiveKey = 1
      this.getCustomer()
      this.getCountries()      
    },
    getDistributorName(distributorID) {
      if (distributorID != 0) {
        DistributorService.getSingleDistributor(distributorID).then(
          (response) => {
            this.currDistributorID = distributorID
            this.currDistributor = response.data.company
            this.distLookupVisible = false
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validDistributorIDException')
      } 
    },
    getLanguage(languageString) {
      if ((languageString) && (languageString != '')) {
        return Languages.indexOf(languageString)
      }
    },
    pretifyName(name) {
      return StringFormatter.pretifyName(this.$t(name))
    },
    getContactInfo(userID) {
      if ((userID) && (userID != 0)) {
        UserService.getSpecificUser(userID).then(
          (response) => {
            let user = response.data
            if (user) {
              this.currCustomer.contact_Person = user.first_Name + ' ' + user.last_Name
            }
          },
          (error) => {
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()          
          }
        )
      }
      this.userLookupVisible = false
    },
    appendChooseButton() {
      return (
        {
          key: 'choose', 
          label: '', 
          _style: 'width:1%', 
          sorter: false, 
          filter: false
        }
      )
    },
    appendButtons() {
      let buttonsToAppend = []
      buttonsToAppend.push(this.appendChooseButton())
      return buttonsToAppend
    },                             
  },
  components: {
    DistributorsLookup,
    UsersLookupMobile,
    UsersLookup
  }
}
</script>