import axios from 'axios'
import authHeader from './auth-header'
import formatQuery from './query-helper'

const API_URL = process.env.VUE_APP_SERVER_BASE_URL + 'distributors'

class DistributorService {
    getDistributors(queryParams) {
        return axios.get(API_URL + formatQuery(queryParams), { headers: authHeader() })
    }
    getSingleDistributor(distributorID) {
        return axios.get(API_URL + '/' + distributorID, { headers: authHeader() })
    }     
    updateDistributor(distributorID, distributorParams) {
        return axios.patch(API_URL + '/' + distributorID, distributorParams, { headers: authHeader() })
    }
    createDistributor(distributorParams) {
        return axios.post(API_URL, distributorParams, { headers: authHeader() })
    }
    deleteDistributor(distributorID) {
        return axios.delete(API_URL + '/' + distributorID, {headers: authHeader() })
    }
    // Build other api calls here....   
}

export default new DistributorService()
