<template>
  <CCard fluid color="secondary">
    <CCardBody>
      <CCardTitle>
        <CRow>
          <CCol xs="auto" class="me-auto">
            <strong>{{ $t('machinesOverview') }}</strong>
          </CCol>
          <CCol xs="auto">
            <CIcon size="lg" icon="cil-list" @click="toggleView()" v-if="!listView"/>
            <CIcon size="lg" icon='cil-window-maximize' @click="toggleView()" v-if="listView"/>
          </CCol>
        </CRow>    
      </CCardTitle>
      <CCardSubtitle class="mb-2 text-muted">{{ $t('machinesOverviewDescription') }}</CCardSubtitle>   
      <CFormSelect class="mb-3" aria-label="customer select" id="customer" v-model="selectedCustomerID" v-if="!isCustomerUser">
        <option v-for="(customer, index) in orderedCustomerData" :key="index"
          :value="customer.id">{{ customer.company }}</option>
      </CFormSelect>
      <CRow :xs="{ cols: getWidgetDimension }" v-if="!listView">
        <CCol v-for="(machine, index) in machines" :key="index">
          <small><machine-widget-mobile
            :machine="machine"
          /></small>
        </CCol>
      </CRow>
      <CListGroup v-if="listView">
        <CListGroupItem>
          <CFormInput
            type="text"
            id="machineFilter"
            :placeholder="$t('filterPlaceholder')"
            v-model="machinesFilterState" />  
        </CListGroupItem>      
        <small><CListGroupItem v-for="(machine, index) in filteredMachines" :key="index" component="button" @click="navigateMachine(machine.id)">
          <machine-list-item-mobile
            :machine="machine" />
        </CListGroupItem></small>
      </CListGroup>
    </CCardBody>

    <CModal size="sm" alignment="center" :visible="actionsVisible" @close="() => { actionsVisible = false }">
      <CModalBody>
        <div class="d-grid gap-2">   
          <CButton type="button" color="dark" shape="rounded-0" id="editMachine"
            @click="editMachine()">{{ $t('edit') }}</CButton>
          <CButton type="button" color="dark" shape="rounded-0" id="measurement"
            @click="goToMeasurements()">{{ $t('measurements') }}</CButton>
          <CButton type="button" color="dark" shape="rounded-0" id="newMeasurement"
            @click="newMeasurement()">{{ $t('newMeasurement') }}</CButton>                        
        </div>        
      </CModalBody>
    </CModal>

  </CCard>
</template>

<script>
import CustomerLightService from '../../services/customers-light'
import PermissionManager from '../../helpers/permissionManager'
import MachineWidgetMobile from './MachineWidgetMobile.vue'
import MachineListItemMobile from './MachineListItemMobile.vue'
import MachineOverviewService from '../../services/machines-overview'

export default {
  name: 'MachinesStatusOverviewMobile',
  data() {
    return {
      message: '',
      machines: [],
      retrievalStatus: 'stopped',
      customerData: [],
      customersLoaded: false,
      selectedCustomerID: 0,
      dataLoaded: false,
      defaultCustomer: {
        'id': 0,
        'company': '...select Customer...'
      },
      listView: this.getInitialListViewValue(),
      machinesFilterState: '',
      machineColumns: [        
        'id',
        'name',
        'id_Machine',
        'product_Name',    
      ],
      selectedMachineID: 0,
      actionsVisible: false      
    }
  },
  watch: {
    selectedCustomerID: {
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          this.getMachinesGradually(0, this.selectedCustomerID)
          const userCustomer = PermissionManager.getCustomerFilteredVisibility() 
          if (!(userCustomer)) {
            localStorage.setItem('currentCustomer', this.selectedCustomerID)
          }
        }
      }
    }
  },
  computed: {
    orderedCustomerData() {
      let processedData = [...this.customerData]
      if (this.customerData.length > 0) {
        processedData = processedData.sort((a,b) => a.company.localeCompare(b.company))
        processedData.unshift(this.defaultCustomer)
      }
      return processedData
    },
    isCustomerUser() {
      const userCustomer = PermissionManager.getCustomerFilteredVisibility() 
      return (userCustomer != null)
    },
    getWidgetDimension() {
      if (screen.width <= 500) {
        return 'auto'
      } else {
        return 2
      }
    },
    filteredMachines() {
      let processedData = [...this.machines]
      if (this.machinesFilterState) {
        const filter = this.machinesFilterState.toLowerCase()
        const hasFilter = (item) => String(item).toLowerCase().includes(filter)
        processedData = processedData.filter(item => {
          return this.machineColumns.filter(key => hasFilter(item[key])).length
        })          
      }
      return processedData
    },    
  },
  created() {
    this.initData()
    this.getCustomersGradually()
  },
  methods: {
    initData() {
      this.message = ''
      this.customersLoaded = false
      this.machines = []
      this.customerData = []
      this.loadMachines()
    },
    loadMachines() {
      const userCustomer = PermissionManager.getCustomerFilteredVisibility() 
      if (userCustomer) {
        this.selectedCustomerID = userCustomer.idCustomer
      } else if (localStorage.getItem('currentCustomer')) {
        this.selectedCustomerID = localStorage.getItem('currentCustomer')
      }
    },
    getMachinesGradually(skipParam, customerID) {
      let processMachines = true
      if ((!skipParam) || (skipParam == 0)) {
        this.machines = []
      }   
      if ((!customerID) || (customerID == 0) || (customerID == '0')) {
        processMachines = false
      }
      if (processMachines) {
        let queryParams = {
          'idCustomer': customerID
        }
        if (!(PermissionManager.hasExtendedAgentPermissions())) {
          const userDistributor = PermissionManager.getDistributorFilteredVisibility()
          if (userDistributor) {
            queryParams.idDistributor = userDistributor.idDistributor
          }       
        }         
        // Get active only
        queryParams.status = 0
        // Get Additional Filters
        if (this.additionalFilter) {
          queryParams = { ...queryParams, ...this.additionalFilter}
        }                           
        queryParams.$limit = 50
        if (skipParam) {
          queryParams['$skip'] = skipParam
        }
        MachineOverviewService.getMachines(queryParams).then(
          (response) => {
            if (this.retrievalStatus == 'restart') {
              this.retrievalStatus = 'stopped'
              this.machines = ''
              this.dataLoaded = false
              this.getMachinesGradually()
            } else {           
              if (this.machines.length > 0) {
                this.machines = this.machines.concat(response.data.data)
              } else {
                this.machines = response.data.data
              }
              this.dataLoaded = true
              if (response.data.total > response.data.limit + response.data.skip) {
                if (skipParam) {
                  skipParam += response.data.limit
                } else {
                  skipParam = response.data.limit
                }
                this.getMachinesGradually(skipParam)
              } else {
                this.retrievalStatus = 'stopped'
              }                
            }
          },
          (error)  => {
            this.message =           
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()

            this.retrievalStatus = 'stopped'
          }        
        )
      }
    },
    getCustomersGradually(skipParam) {
      if (!skipParam) {
        this.customerData = []
      }      
      let paramQuery = {
        '$limit': 50
      }
      // Manage Permissions first
      if (!(PermissionManager.hasExtendedTechnicianPermissions())) {
        // 1. filter by distributor and agent evt.
        const userDistributor = PermissionManager.getDistributorFilteredVisibility()
        if (userDistributor) {
          paramQuery.idDistributor = userDistributor.idDistributor
        }
        const userAgent = PermissionManager.getAgentFilteredVisibility()
        if (userAgent) {
          paramQuery.idAgent = userAgent.idAgent
        }        
      }       
      // Get active only from local storage
      let activeOnly = localStorage.getItem('showOnlyActiveRecords')
      if (activeOnly === 'true') {
        paramQuery.disabled = false
      }
      if (skipParam) {
        paramQuery['$skip'] = skipParam
      }
      CustomerLightService.getCustomers(paramQuery).then(
        (response) => {
          if (this.customerData.length > 0) {
            this.customerData = this.customerData.concat(response.data.data)
          } else {
            this.customerData = response.data.data
          }
          this.customersLoaded = true
          if (response.data.total > response.data.limit + response.data.skip) {
            if (skipParam) {
              skipParam += response.data.limit
            } else {
              skipParam = response.data.limit
            }
            this.getCustomersGradually(skipParam)
          }
        },
        (error)  => {
          this.message =           
           (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }        
      )
    },
    toggleView() {
      this.listView = !this.listView
      localStorage.setItem('openMachinesOverviewInListMode', this.listView)
    },
    navigateMachine(machineID) {
      this.selectedMachineID = machineID
      if ((this.selectedMachineID) && (this.selectedMachineID != 0)) {
        this.actionsVisible = true
      }
    },
    goToMeasurements() {
      this.$router.push({ name: 'mMeasurementsMachine', params: { 'idMachine': this.selectedMachineID, 'calledByOverview': true } })
    },
    editMachine() {
      this.$router.push({ name: 'mEditMachine', params: { 'idMachine': this.selectedMachineID, 'calledByOverview': true } })
    },
    newMeasurement() {
      this.$router.push({ name: 'mMeasure', params: { 'machineID': this.selectedMachineID, 'measurementType': 0 } })
    },
    getInitialListViewValue() {
      const currListView = localStorage.getItem('openMachinesOverviewInListMode')
      if (currListView) {
        return ((currListView == true) || (currListView === 'true'))
      } else {
        // List view by default
        return true
      }
    }
  },
  components: {
    MachineWidgetMobile,
    MachineListItemMobile
  }  
}
</script>
