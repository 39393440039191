import axios from 'axios'
import authHeader from './auth-header'
import formatQuery from './query-helper'
const API_URL = process.env.VUE_APP_SERVER_BASE_URL + 'users'

class UserService {
    getUsers(queryParams) {
        return axios.get(API_URL + formatQuery(queryParams), { headers: authHeader() })
    }
    getUserData() {
        const userData = JSON.parse(localStorage.getItem('user'))
        if (userData.user.id) {
            return this.getSpecificUser(userData.user.id)
        }
    }
    getSpecificUser(userID) {
        return axios.get(API_URL + '/' + userID, { headers: authHeader() })
    }
    updateUserData(userParams) {
        const userData = JSON.parse(localStorage.getItem('user'))
        return this.updateSpecificUser(userData.user.id, userParams)
    }
    updateSpecificUser(userID, userParams) {
        return axios.patch(API_URL + '/' + userID, userParams, { headers: authHeader() })
    }    
}

export default new UserService()