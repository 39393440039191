<template>
  <div>
    <p>{{ $t('adminMobileDescription') }}</p>
    <CCard fluid>
      <CCardBody>
        <CCardTitle>{{ $t('adminCenterTitle') }}</CCardTitle>
        <CRow>
          <CCol :xs="auto">
            <CWidgetStatsF color="info" :title="$t('userPermissions')">
              <template #icon>
                <CIcon icon="cil-people" size="xl"/>
              </template>
              <template #footer>
                <a class="nav-link" @click="editUsers()"> {{ $t('edit') }} </a>
              </template>  
            </CWidgetStatsF>       
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
export default {
  name: 'AdminMainMobile',
  methods: {
    editUsers() {
      this.$router.push('/m-users')   
    }
  }
}
</script>