import axios from 'axios'
import authHeader from './auth-header'
import formatQuery from './query-helper'

const API_URL = process.env.VUE_APP_SERVER_BASE_URL + 'customers-light'

class CustomerLightService {
    getCustomers(queryParams) {
        return axios.get(API_URL + formatQuery(queryParams), { headers: authHeader() })
    }
    getSingleCustomer(customerID) {
        return axios.get(API_URL + '/' + customerID, { headers: authHeader() })
    } 
}

export default new CustomerLightService()