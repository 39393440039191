<template>
  <CCard v-if="dataLoaded">
    <CCardBody>
      <CRow class="mb-3">
        <CFormLabel for="firstName" class="col-sm-2 col-form-label">{{ pretifyName('userFields.first_Name') }}</CFormLabel>
        <CCol sm="10">
          <CFormInput type="text" id="firstName" v-model="firstName"
            :value="currUser.first_Name" />
        </CCol>
      </CRow>
      <CRow class="mb-3">
        <CFormLabel for="lastName" class="col-sm-2 col-form-label">{{ pretifyName('userFields.last_Name') }}</CFormLabel>
        <CCol sm="10">
          <CFormInput type="text" id="lastName" v-model="lastName"
          :value="currUser.last_Name" />
        </CCol>
      </CRow>  
      <CRow class="mb-3">
        <CFormLabel for="email" class="col-sm-2 col-form-label">{{ pretifyName('userFields.email') }}</CFormLabel>
        <CCol sm="10">
          <CFormInput type="email" id="email" readonly plain-text
          :value="currUser.email" />
        </CCol>
      </CRow>
      <CRow class="mb-3">
        <CFormLabel for="cellphone" class="col-sm-2 col-form-label">{{ pretifyName('userFields.phone_Number') }}</CFormLabel>
        <CCol sm="10">
          <CFormInput type="phone" id="password" v-model="phoneNumber"
          :value="currUser.phone_Number" />
        </CCol>
      </CRow>
      <CRow class="mb-3">
        <CFormLabel for="language" class="col-sm-2 col-form-label">{{ pretifyName('userFields.language') }}</CFormLabel>
        <CCol sm="10">
          <CFormSelect class="mb-3" aria-label="language select" id="language" v-model="language"
            :value="getLanguage(currUser.language)">
            <option value="0">{{ $t('languageOptions.english') }}</option>
            <option value="1">{{ $t('languageOptions.german') }}</option>
            <option value="2">{{ $t('languageOptions.italian') }}</option>
            <option value="3">{{ $t('languageOptions.french') }}</option>
          </CFormSelect>
        </CCol>
      </CRow>      
      <CRow class="mb-3" v-if="userWithAdminPermissions()">
        <CFormLabel for="companyType" class="col-sm-2 col-form-label">{{ pretifyName('userFields.linked_To') }}</CFormLabel>
        <CCol sm="10">
          <CFormSelect class="mb-3" aria-label="company type select" id="companyType" v-model="companyType"
            :value="getCompanyType(currUser.company_Type)">
            <option value="0">{{ $t('distributor') }}</option>
            <option value="1">{{ $t('customer') }}</option>
          </CFormSelect>
        </CCol>
      </CRow>
      <CRow class="mb-3" v-if="userWithAdminPermissions()">
        <CFormLabel for="companyName" class="col-sm-2 col-form-label">{{ pretifyName('userFields.company_Name') }}</CFormLabel>
        <CCol sm="10">
          <CInputGroup class="mb-3">
            <CFormInput type="text" id="companyName" v-model="companyName" readonly
              :value="currCompany.company" />
            <CButton type="button" color="dark" variant="outline" id="searchCompany"
              @click="setModalVisibility(companyType)">{{ $t('search') }}</CButton>
          </CInputGroup>
        </CCol>
      </CRow>  
      <CButton type="button" color="dark" shape="rounded-0" id="updateUserInfo"
        @click="updateUserData(firstName, lastName, phoneNumber, language)">
        {{ $t('updateUserData') }}
      </CButton>

      <CModal size="xl" alignment="center" :visible="distLookupVisible" @close="() => { distLookupVisible = false }">
        <CModalHeader>
          <CModalTitle>{{ $t('distributors') }}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <distributors-lookup
            @itemSelected="getDistributor($event)" />
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" @click="() => { distLookupVisible = false }">
            {{ $t('close') }}
          </CButton>
        </CModalFooter>
      </CModal> 

      <CModal size="xl" alignment="center" :visible="custLookupVisible" @close="() => { custLookupVisible = false }">
        <CModalHeader>
          <CModalTitle>{{ $t('customers') }}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <customers-lookup
            @itemSelected="getCustomer($event)" />
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" @click="() => { custLookupVisible = false }">
            {{ $t('close') }}
          </CButton>
        </CModalFooter>
      </CModal>   

      <CAlert v-if="message" color="danger" class="d-flex align-items-center">
        <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
        <div>
            {{ message }}
        </div>
      </CAlert> 
    </CCardBody>
  </CCard>
</template>

<script>
import UserService from '../services/users'
import DistributorService from '../services/distributors'
import CustomerService from '../services/customers'
import DistributorsLookup from './DistributorsLookup.vue'
import CustomersLookup from './CustomersLookup.vue'
import { CompanyType, Languages } from '../helpers/getEnumValues'
import PermissionManager from '../helpers/permissionManager'
import StringFormatter from '../helpers/stringFormatter'

export default {
  name: 'UserCard',
  data() {
    return {
      message: '',
      dataLoaded: false,
      currUser: '',
      currCompany: '',
      distLookupVisible: false,
      custLookupVisible: false
    }
  },
  created() {
    this.getCurrentUser()
  },
  methods: {
    getCurrentUser() {
      this.currUser = {}
      UserService.getUserData().then(
        (response) => {
          this.currUser = response.data
          this.currCompany = {}
          this.currCompany.id = this.currUser.idCompany
          this.currCompany.company = this.currUser.company_Name
          this.dataLoaded = true
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()          
        }
      )
    },
    updateUserData(firstName, lastName, phoneNumber, language) {
      if (!language) {
        language = 0
      }
      let userParams = {
        "first_Name": firstName,
        "last_Name": lastName,
        "phone_Number": phoneNumber,
        "language": language
      }
      UserService.updateUserData(userParams).then(
        () => { this.getCurrentUser() },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        }
      )
    },
    getCompanyType(companyTypeString) {
      if ((companyTypeString) && (companyTypeString != '')) {
        return CompanyType.indexOf(companyTypeString)
      }
    },
    getLanguage(languageString) {
      if ((languageString) && (languageString != '')) {
        return Languages.indexOf(languageString)
      }
    },    
    getDistributor(distributorID) {
      if ((distributorID) && (distributorID != 0)) {
        DistributorService.getSingleDistributor(distributorID).then(
          (response) => {
            this.currCompany = response.data
            this.distLookupVisible = false
          },
          (error)  => {
            this.message =           
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validDistributorIDException')
      }
    },
    getCustomer(customerID) {
      if ((customerID) && (customerID != 0)) {
        CustomerService.getSingleCustomer(customerID).then(
          (response) => {
            this.currCompany = response.data
            this.custLookupVisible = false
          },
          (error)  => {
            this.message =           
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validCustomerIDException')
      }
    },
    setModalVisibility(companyType) {
      if ((this.distLookupVisible) || (this.custLookupVisible)) {
        this.distLookupVisible = false
        this.custLookupVisible = false
      } else {
        if ((!companyType) || (companyType == 0)) {
          this.distLookupVisible = true
        } else {
          this.custLookupVisible = true
        }
      }
    },
    userWithAdminPermissions() {
      return PermissionManager.hasAdminPermissions()
    },
    pretifyName(name) {
      return StringFormatter.pretifyName(this.$t(name))
    }           
  },
  components: {
    DistributorsLookup,
    CustomersLookup
  }
}
</script>