<template>
  <CContainer fluid>
    <div class="row">
      <div class="col-12 col-8-tablet push-2-tablet text-center">
        <CIcon size="xxl" icon="cil-puzzle"/>
        <h3 class="title">{{ $t('products') }}</h3>
      </div>
    </div>

    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
      <CButton type="button" color="dark" shape="rounded-0" id="createProduct" @click="createProduct()">{{ $t('createNewProduct') }}</CButton>
    </div>

    <loading-data v-if="!dataLoaded"/>

    <general-table v-if="dataLoaded"
      :data="products"
      :columns="tableColumns"
      :rowsdetail="tableRowsDetails"
      machineLookupButton
      @editItem="editProduct($event)"
      @deleteItem="askForDeletionConfirmation($event)"
      @machineLookup="lookupMachines($event)"/>

    <product-card
      :cModalVisible="productCardModal"
      :operationType="currOperationType"
      :productID="selectedProductID"
      @closeProductCardModal="toggleProdCardModalVisibility($event)"/>  

    <confirm-action
      :cModalVisible="popupConfirmMessage"
      :title="confirmTitle"
      :message="confirmMessage"
      :yesButton="$t('confirmButton')"
      :noButton="$t('cancelButton')"
      @confirm="confirmAction()" 
      @cancel="cancelAction()" />   

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </CContainer>
</template>

<script>
import GeneralTable from '@/components/GeneralTable.vue'
import ProductService from '../services/products'
import ProductCard from '../components/ProductCard.vue'
import DateFormatter from '../helpers/dateFormatter'
import ConfirmAction from '../components/ConfirmAction.vue'
import LoadingData from '../components/LoadingData.vue'

export default {
  name: 'Products',
  data() {
    return {
      dataLoaded: false,
      message: '',
      content: [],
      tableColumns: [
        'productFields.id',
        'productFields.name',
        'productFields.fluid_Supplier',
        'productFields.refractometer_Factor',
        'productFields.critical_pH',
        'productFields.product_Number',
        'status',
        {
          key: 'show_details', 
          label: '', 
          _style: 'width:1%', 
          sorter: false, 
          filter: false
        }
      ],
      tableRowsDetails: [
        'productFields.technology',
        'productFields.created',
        'productFields.created_By'
      ],
      currOperationType: 0,
      productCardModal: false,
      selectedProductID: 0,
      popupConfirmMessage: false,
      confirmTitle: '',
      confirmMessage: ''       
    }
  },
  created() {
    this.getProductsGradually()
  },
  computed: {
    products() {
      let processedData = []
      if (this.content.length > 0) {
        this.content.forEach((product) => {
          processedData.push({
            'productFields.id': product.id,
            'productFields.name': product.product,
            'productFields.fluid_Supplier': product.fluid_Supplier_Name,
            'productFields.refractometer_Factor': product.refactormeter_Factor,
            'productFields.critical_pH': product.critical_pH,
            'productFields.technology': product.technology_Name,
            'status': product.status,
            'productFields.product_Number': product.product_Number,
            'productFields.created': DateFormatter.formatDate(product.created, true),
            'productFields.created_By': product.created_By_User                      
          })                    
        })
      }
      return processedData
    }
  },
  methods: {
    getProductsGradually(skipParam) {
      if (!skipParam) {
        this.content = []
      }      
      let paramQuery = {
        '$limit': 50
      }
      if (skipParam) {
        paramQuery['$skip'] = skipParam
      }
      ProductService.getProducts(paramQuery).then(
        (response) => {
          if (this.content.length > 0) {
            this.content = this.content.concat(response.data.data)
          } else {
            this.content = response.data.data
          }
          this.dataLoaded = true
          if (response.data.total > response.data.limit + response.data.skip) {
            if (skipParam) {
              skipParam += response.data.limit
            } else {
              skipParam = response.data.limit
            }
            this.getProductsGradually(skipParam)
          }
        },
        (error)  => {
          this.message =           
           (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }        
      )
    },
    toggleProdCardModalVisibility(refreshPage) {
      this.productCardModal = !this.productCardModal
      if (refreshPage) {
        this.dataLoaded = false
        this.getProductsGradually()
      }
    },    
    editProduct(productID) {
      if ((!productID) || (productID == 0)) {
        this.message = this.$t('validProductException')
      } else {
        this.currOperationType = 0
        this.selectedProductID = productID
        this.productCardModal = !this.productCardModal
      }
    },
    createProduct() {
      this.currOperationType = 1
      this.selectedProductID = 0
      this.productCardModal = !this.productCardModal
    },    
    askForDeletionConfirmation(productID) {
      this.selectedProductID = productID
      this.confirmTitle = this.$t('continueTitle')
      this.confirmMessage = this.$t('productDeletionWarning', {prod: productID})
      this.popupConfirmMessage = true
    },     
    deleteProduct() {
      if ((!this.selectedProductID) || (this.selectedProductID == 0)) {
        this.message = this.$t('nonExistentProductException')
      } else {
        // let continueProcessing = confirm('You are about to delete the product... Do you want to continue?')
        // if (continueProcessing) {
          ProductService.deleteProduct(this.selectedProductID).then(
            () => {
              this.dataLoaded = false
              this.getProductsGradually()
            },
            (error)  => {
              this.message =           
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()
            }
          ) 
        // }
      }            
    },
    lookupMachines(productID) {
      if (productID) {
        this.$router.push({ name: 'MachinesProduct', params: { idProduct: productID } })
      }
    },
    confirmAction() {
      this.resetConfirmMessage()
      this.deleteProduct()
    },
    cancelAction() {
      this.resetConfirmMessage()
    },
    resetConfirmMessage() {
      this.confirmTitle = ''
      this.confirmMessage = '',
      this.popupConfirmMessage = false
    }                  
  },
  components: {
    GeneralTable,
    ProductCard,
    ConfirmAction,
    LoadingData
  }
}
</script>