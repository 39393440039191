import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_SERVER_BASE_URL + 'upload'

class UploadService {
    uploadPictures(files, onUploadProgress) {
        let formData = new FormData()
        formData.append('files', files)
        return axios.post(API_URL, formData, { headers: authHeader() }, onUploadProgress)
    }
    // Build other api calls here....   
}

export default new UploadService()