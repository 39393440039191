<template>
  <div class="about">
    <h1>{{ $t('welcome') }} {{ currentUser.user.first_Name }} {{ currentUser.user.last_Name}} </h1>
  </div>
</template>

<script>
export default {
  name: 'About',
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  }
}
</script>