<template>
  <div>
    <Sidebar />
    <div class="wrapper d-flex flex-column min-vh-100 bg-light">
      <Header />
      <div class="body flex-grow-1 px-3">
        <CContainer fluid>
          <router-view />
        </CContainer>
      </div>
      <Footer />
    </div>
  </div>
</template>
<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import Sidebar from '@/components/Sidebar.vue'

export default {
  name: 'DefaultLayout',
  components: {
    Footer,
    Header,
    Sidebar
  },
}
</script>