<template>
  <CCard fluid v-if="isDataLoaded">
    <CCardBody>
      <CCardTitle>{{ $t('measurements') }} </CCardTitle>
      <CCardText>{{ $t('noOfMeasurements') }}: {{ noOfMeasurements }}</CCardText>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <CButton type="submit" color="dark" shape="rounded-0" class="mb-3"
          @click="goToMeasurements()">
          {{ $t('viewMeasurements') }}
        </CButton>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import MeasurementLightService from '../services/measurements-light'

export default {
  name: 'MeasurementInfobox',
  props: {
    machineID: Number,
    productID: Number
  },
  expose: ['dataLoaded'],
  data() {
    return {
      dataLoaded: false,
      noOfMeasurements: 0,
      lastMachineID: 0
    }
  },
  computed: {
    isDataLoaded() {
      if (this.machineID != this.lastMachineID) {
        this.refreshData()
      }
      if (!this.dataLoaded) {
        this.getMeasurements()
      }
      return this.dataLoaded
    }
  },
  methods: {
    getMeasurements() {
      if (this.machineID != 0) {
        let queryParams = {
          'idMachine': this.machineID
        }
        if ((this.productID) && (this.productID != 0)) {
          queryParams.idProduct = this.productID
        }
        queryParams.$limit = 5000
        MeasurementLightService.getMeasurements(queryParams).then(
          (response) => {
            this.noOfMeasurements = response.data.total
            this.lastMachineID = this.machineID
            this.dataLoaded = true
          }
        )
      } else {
        this.dataLoaded = true
      }
    },    
    goToMeasurements() {
      let isMobile = localStorage.getItem('isMobile')
      this.$router.replace({ name: (isMobile === 'true') ? 'mMeasurementsMachine' : 'MeasurementsMachine', params: { idMachine: this.machineID } })
    },
    refreshData() {
      this.dataLoaded = false
    }    
  }
}
</script>