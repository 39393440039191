export default {
  "quickActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick Actions"])},
  "quickActionsSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick actions can vary in relation with the user Role"])},
  "newMeasurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Measurement"])},
  "requestAnalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Analysis"])},
  "uselessButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Useless Button"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
  "loadingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Data"])},
  "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
  "adminCenterTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Center"])},
  "userPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Permissions"])},
  "productTechnologies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Technologies"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
  "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process"])},
  "analysisRequestTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis Request"])},
  "analysisRequestSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Type of request and Machine in which to perform the analysis"])},
  "requestType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Type"])},
  "requestTypeOpt0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measurements (general)"])},
  "requestTypeOpt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lab Report"])},
  "requestTypeOpt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water Analysis"])},
  "selectMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Machine"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search..."])},
  "measurementAttachmentDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach Measurement to specify why you need the analysis"])},
  "measurementAttachmentMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach Measurement"])},
  "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New"])},
  "linkExisting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Existing"])},
  "notificationFlag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notify me when the Request is Handled"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "createRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Request"])},
  "selectMeasurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Measurement"])},
  "noMeasurementLinkedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No measurments currently linked..."])},
  "measurementLinkedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measure correctly linked"])},
  "measurementConfirmTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specification Measurement missing"])},
  "measurementConfirmMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to send an analysis requests without a measure... Continue?"])},
  "noOfMeasurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. of Measurements"])},
  "viewMeasurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Measurements"])},
  "machineSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine selection"])},
  "goToMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to machine"])},
  "customize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize"])},
  "assignedFiltrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned Filtrations"])},
  "assignedMaterials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned Materials"])},
  "assignedOperations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned Operations"])},
  "assignedLabReportParam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned Lab Report Parameters"])},
  "assignedWaterAnalysisParam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned Water Analysis Parameters"])},
  "assignedPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned Permissions"])},
  "labReportParam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lab Report"])},
  "waterAnalysisParam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water Analysis"])},
  "editProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Product"])},
  "editDistributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Distributor"])},
  "editCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Customer"])},
  "distributorLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributor Location"])},
  "customerLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Location"])},
  "receivedMeasurementAsAttachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received Measurement as attachment"])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter:"])},
  "filterPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type string..."])},
  "itemsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items per page:"])},
  "pageContentInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Content Info (Lookups)"])},
  "toggleInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle Infos about the page"])},
  "verifyTokenMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for verifying your account. Go to the login page to continue"])},
  "verifyingAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifying account... please wait... "])},
  "unableToVerifyAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to verify account. Please contact support."])},
  "openAnalysisRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Analysis Requests"])},
  "openAnalysisDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming Analysis requested to distributors, click on View All button to display also the completed ones"])},
  "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["now"])},
  "distributorOverwiev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributor Overview"])},
  "analysisToPerform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming Analysis to perform requested from Customers"])},
  "analysisForwardedToMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming forwarded analysis"])},
  "analysisForwardedByMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forwarded analysis"])},
  "analysisForwardedToMeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis to perform which have been forwarded by other distributors"])},
  "analysisForwardedByMeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis forwarded to a different distributor (defined in the distributor card)"])},
  "handleAnalysisRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handle Analysis Request"])},
  "handleAnalysisRequestDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No analysis have been created yet to handle this request... To proceed with the creation, confirm/change the Analysis to perform"])},
  "handleRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handle Request"])},
  "assignedFiltrationsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This list contains all the already assigned Filtration..."])},
  "assignedFiltrationsSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's possible to see/edit the comment related to the assignment by clicking on the button and to remove the assignment by clicking the 'X'"])},
  "availableFiltrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Filtrations"])},
  "availableFiltrationsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All the remaining available filtrations are listed here"])},
  "assignedMaterialsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This list contains all the already assigned Materials..."])},
  "assignedMaterialsSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's possible to edit the fields related to the assignment by clicking on the button 'Update' and to remove the assignment by clicking the 'X'"])},
  "availableMaterials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Materials"])},
  "availableMaterialsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All the remaining available materials are listed here"])},
  "assignedOperationsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This list contains all the already assigned Operations..."])},
  "assignedOperationsSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's possible to remove the assignment by clicking the 'X'"])},
  "availableOperations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Operations"])},
  "availableOperationsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All the remaining available operations are listed here"])},
  "assignedParametersTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This list contains all the already assigned Parameters..."])},
  "assignedParametersSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's possible to edit the fields related to the assignment by clicking on the button 'Update' and to remove the assignment by clicking the 'X'"])},
  "availableParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Parameters"])},
  "availableParametersDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All the remaining available parameters are listed here"])},
  "assignedPermissionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This list contains all the already assigned Permissions..."])},
  "assignedPermissionsSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's possible to remove the assignment by clicking the 'X'"])},
  "availablePermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Permissions"])},
  "availablePermissionsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All the remaining available permissions are listed here"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "newLabReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Lab Report"])},
  "newWaterAnalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Water Analysis"])},
  "inputMeasuredValues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input the Measured Values"])},
  "confirmMeasurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Measurement"])},
  "technologyLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology Line"])},
  "technologyLineDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Type of line to create (Header or Value Line)"])},
  "lineType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line Type"])},
  "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Header"])},
  "line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line"])},
  "lineValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line Value"])},
  "calculatedField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculated Field..."])},
  "noFormulaDefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Formula defined"])},
  "formulaEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Editor"])},
  "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula"])},
  "formulaEditorInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this editor is possible to type the desired Formula... The available operations are the standard one, so (+, -, *, /) It's also possible to group operations using brackets () Use the identifiers of of the parameters to specify a parameter (A-Z)"])},
  "formulaEditorAvailableParamDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This list contains all the available parameters, refer to them via identifier (A-Z)"])},
  "productTechnologyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Define the Technology Layout"])},
  "confirmUserMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check out your Email to confirm User"])},
  "uploadPictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Pictures"])},
  "dropPicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop Them Here"])},
  "dropPicture2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to add them"])},
  "dragFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag your files here"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
  "clickHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["click here"])},
  "toSelectFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to select files"])},
  "userAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Assignment"])},
  "linkToCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to Company"])},
  "updateUserData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update User Data"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
  "createNewCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New Customer"])},
  "createNewDistributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New Distributor"])},
  "createNewProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New Product"])},
  "createNewMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New Machine"])},
  "finalizeReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalize Report"])},
  "mainData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Data"])},
  "inputParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters (Input)"])},
  "inputParameterDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert the main parameteres and than click on Calculate..."])},
  "calculatedParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters (Calculated)"])},
  "calculatedParametersDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculated Values... Editable, but also calculable"])},
  "updateReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Report"])},
  "updateReportBaseData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Main Data"])},
  "machineDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine Details"])},
  "updateMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Machine"])},
  "machineContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine Content"])},
  "emptyMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty Machine"])},
  "refillMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refill Machine"])},
  "changeProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Product"])},
  "showMachineHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Machine History"])},
  "analysisRequestedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis Requested By"])},
  "remainingQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining Quantity"])},
  "refillQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refill Quantity"])},
  "initQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Init Quantity"])},
  "insertProductRefillQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Product Refill Quantity"])},
  "insertProductInitQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Product Init Quantity"])},
  "insertMeasuredContainedQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert the measured contained quantity before the refill"])},
  "insertRemainingQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert the remaining quantity of the previous Product that will be empted"])},
  "insertQuantityToRefill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert the quantity to Refill"])},
  "insertQuantityToInit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert the Init Quantity of the new product"])},
  "downloadReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Report"])},
  "downloadRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Request"])},
  "labReportInfoboxInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The values in this list contain data related to the current machine... Single Report values could differ, open a single report to have a more specific focus"])},
  "phValuesVariation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PH Values Variation"])},
  "effConcentrationVariation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eff. Concentration Variation"])},
  "valuesFilteredByProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Values are filtered by the Product currently in the Machine"])},
  "phValues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PH Values"])},
  "effConcentration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eff. Concentration"])},
  "waitingForPermissionAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for Permission Assignment..."])},
  "activeCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Customers"])},
  "activeProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Products"])},
  "activeMachines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Machines"])},
  "currWorkingMachines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently Working Machines"])},
  "activeCustomersMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers"])},
  "activeProductsMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
  "activeMachinesMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machines"])},
  "currWorkingMachinesMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Mach."])},
  "sample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample"])},
  "parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter"])},
  "tolerance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tolerance"])},
  "valueUnitOfMeasure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value (Unit of Measure)"])},
  "recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommendations for action"])},
  "phEffConcGraphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pH and Effective Concentrations Graphs"])},
  "waterAnalysisGraphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphs (pH, Hardness, Chloride, Sulfate)"])},
  "resultIndicatorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visual result of the requested report"])},
  "customerAgentAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Agent Assignment"])},
  "updateAssignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Assignments"])},
  "removeAssigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove selected assigned"])},
  "unassignedCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unassigned Customers"])},
  "unassignedCustomersDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this list all the non assigned customers are displayed"])},
  "agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agents"])},
  "agentsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The available Agents are listed here..."])},
  "assignedCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned Customers"])},
  "assignedCustomersDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this list all the non assigned customers are displayed"])},
  "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unassigned"])},
  "lastLabReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Report"])},
  "adminMobileDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the mobile version, the only available setting is the user assignment, the other operations need to be performed in the desktop environment!"])},
  "globalSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Settings"])},
  "onlyActiveRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show only active records"])},
  "cannotRenderGraphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphs cannot be rendered on Mobile devices."])},
  "displayFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display additional filters"])},
  "machinesFilterSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machines quick search"])},
  "machinesOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machines Overview"])},
  "machinesOverviewDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select the requested customer to check the Overview"])},
  "customerSelectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUSTOMER SELECTION"])},
  "measuredMachinesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MEASURED MACHINES"])},
  "availableMachinesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AVAILABLE MACHINES"])},
  "switchToDesktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to Desktop"])},
  "switchToMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to Mobile"])},
  "customerSessionActiveDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A customer session is currently active: "])},
  "startCustomerSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start customer session"])},
  "stopCustomerSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop customer session"])},
  "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
  "specifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifications"])},
  "lastMeasured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last measured date"])},
  "forgotThePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot the password?"])},
  "sendPasswordResetTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
  "sendPasswordResetDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert your E-Mail Address to receive a password-reset link"])},
  "sendPasswordResetButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Reset-Link"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
  "newPasswordCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
  "agentSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch Agent"])},
  "sendLabReportEmailToCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Lab Report via E-Mail"])},
  "customerEmailSelectionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a user, from the customer list to whom you would like to send an E-Mail. If nothing is selected, the main Agent will be the E-Mail main contact."])},
  "analysisFloatingOilPhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oil phase in the emulsion tank"])},
  "labReportFloatingOilPhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oil phase in the emulsion sample"])},
  "__MENUS__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menus"])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
  "__BUTTONS__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buttons"])},
  "pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pictures"])},
  "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
  "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
  "showComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Comment..."])},
  "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
  "createLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Line"])},
  "editFormula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Formula"])},
  "editDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Details"])},
  "updateFormula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Formula"])},
  "createNewLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new line"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
  "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change..."])},
  "processChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process Changes"])},
  "viewAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View All"])},
  "reopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reopen"])},
  "replyAndClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reply and close"])},
  "forwardRequestBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forward Request"])},
  "confirmAndNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm and New"])},
  "updateContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update content"])},
  "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More..."])},
  "viewCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Requests"])},
  "confirmAndRequestLabReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Lab Report"])},
  "closeRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close Request"])},
  "__TABLE_NAMES__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table Names"])},
  "machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine"])},
  "machines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machines"])},
  "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
  "distributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributor"])},
  "distributors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributors"])},
  "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
  "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers"])},
  "labReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lab Report"])},
  "labReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lab Reports"])},
  "waterAnalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water Analysis"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
  "measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measurement"])},
  "measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measurements"])},
  "analysisRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis Request"])},
  "analysisRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis Requests"])},
  "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "__TABLE_FIELDS__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table Fields"])},
  "machineFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
    "machine_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["machine ID"])},
    "distributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["distributor"])},
    "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["customer"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product"])},
    "product_Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product Content"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status"])},
    "critical_pH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["critical pH"])},
    "sump_Size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sump Size"])},
    "water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["water"])},
    "min_Eff_Conc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min. Eff. Concentration"])},
    "max_Eff_Conc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. Eff. Concentration"])},
    "last_Filled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last Filled"])},
    "unknown_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unknown Date"])},
    "creation_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["creation Date"])},
    "created_By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["created By"])},
    "central_Plant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["central Plant"])},
    "central_Machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["central Machine"])},
    "min-max_Eff_Conc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min/Max Eff. Concentration"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["content (LT)"])}
  },
  "productFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
    "fluid_Supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fluid Supplier"])},
    "refractometer_Factor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["refractometer Factor"])},
    "critical_pH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["critical pH"])},
    "technology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["technology"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status"])},
    "product_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product Number"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["created"])},
    "created_By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["created By"])}
  },
  "customerFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["country"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["city"])},
    "phone_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone No."])},
    "client_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAV No."])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disabled"])},
    "contact_Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact Person"])},
    "distributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["distributor"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zip"])},
    "creation_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["creation Date"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["language"])},
    "agent_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent Name"])}
  },
  "distributorFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["country"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["city"])},
    "phone_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone No."])},
    "client_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAV No."])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disabled"])},
    "contact_Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact Person"])},
    "active_Machines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["active Machines"])},
    "inactive_Machines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inactive Machines"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zip"])},
    "creation_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["creation Date"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["language"])},
    "analysis_Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis Notification Email"])},
    "handle_Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handle Reports"])},
    "forward_To_Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forward Request to"])}
  },
  "measurementFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["machine"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product"])},
    "measured_By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meas. By"])},
    "pH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pH"])},
    "refrac_Reading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["refract."])},
    "eff_Concentration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eff. Conc."])},
    "measured_At": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meas. At"])},
    "distributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["distributor"])},
    "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["customer"])},
    "appareance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["appareance"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comment"])},
    "last_Filled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last Filled"])},
    "creation_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["creation Date"])},
    "created_By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["created By"])},
    "refrac_Factor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["refrac. Factor"])},
    "image_Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image Count"])},
    "id_Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD Report"])},
    "temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["temperature"])},
    "water_Hardness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["water hardness"])}
  },
  "labReportFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "sample_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sample Number"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product"])},
    "requested_By_Customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["requested By Customer"])},
    "date_Ordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date Ordered"])},
    "reason_Of_Analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reason of Analysis"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status"])},
    "batch_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["batch No."])},
    "date_Of_Analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date of Analysis"])},
    "completed_At": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["completed At"])},
    "appearance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["appearance"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deposit"])},
    "smell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["smell"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comment"])},
    "cleanness_Of_Workstation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cleanness of workstation"])},
    "floating_Oil_Phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["floating Oil Phase"])},
    "measured_By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["measured By"])},
    "appearance_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["appearance"])}
  },
  "characteristics": {
    "Water hardness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water hardness"])},
    "pH electrometric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pH electrometric"])},
    "Conductivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conductivity mS/cm"])},
    "Nitrite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nitrite"])},
    "Nitrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nitrate"])},
    "Chloride": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chloride"])},
    "Sulfate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulfate"])},
    "Bacteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bacteria"])},
    "Funghi / yeast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funghi / yeast"])},
    "Tramp Oil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tramp Oil"])},
    "Effective Concentration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eff. Concentration"])},
    "Refractometer Reading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refrac. Reading"])},
    "pH electrometric (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pH electrometric"])},
    "Conductivity (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conductivity"])},
    "Nitrite (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nitrite"])},
    "Nitrate (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nitrate"])},
    "Chloride (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chloride"])},
    "Sulfate (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulfate"])},
    "Bacteria (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bacteria"])},
    "Funghi / yeast (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funghi / yeast"])},
    "Total hardness emulsion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total hardness emulsion"])},
    "Deposit of wear 5 um": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit of wear 5 um"])},
    "Corrosion degree DIN 51360": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrosion degree DIN 51360"])},
    "Product Specific Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Specific Value"])},
    "Concentration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concentration"])},
    "Mixed Phase acid split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mixed Phase acid split"])},
    "Mineral Oil Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mineral Oil Content"])},
    "Titration Emulsion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titration Emulsion"])},
    "Correction Factor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction Factor"])},
    "Concentration acid split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concentration acid split"])},
    "Product specific value alkalinity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product specific value alkalinity"])},
    "Product specific value acid split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product specific value acid split"])},
    "Density": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Density"])},
    "Corrosion test on steel plate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrosion test on steel plate"])}
  },
  "filtrations": {
    "Band filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Band filter"])},
    "Centrifuge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrifuge"])},
    "Chip conveyor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chip conveyor"])},
    "Magnet filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magnet filter"])},
    "No filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No filter"])},
    "Other filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other filters"])},
    "Paper filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paper filter"])},
    "Skimmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skimmer"])}
  },
  "materials": {
    "Aluminium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aluminium"])},
    "Brass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brass"])},
    "Bronze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bronze"])},
    "Cast Iron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cast Iron"])},
    "Copper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copper"])},
    "Duplex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplex"])},
    "Hastelloy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hastelloy"])},
    "Inconel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inconel"])},
    "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
    "Stainless Steel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stainless Steel"])},
    "Steel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steel"])},
    "Super duplex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super duplex"])},
    "Titanium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titanium"])}
  },
  "materialFields": {
    "specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific."])},
    "tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tool"])},
    "part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part"])}
  },
  "operations": {
    "Broaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broaching"])},
    "Deep Hole Drilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deep hole drilling"])},
    "Drilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drilling"])},
    "Forming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formaing"])},
    "Grinding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grinding"])},
    "Milling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milling"])},
    "Reaming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reaming"])},
    "Sawing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sawing"])},
    "Tapping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapping"])},
    "Thread cutting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thread cutting"])},
    "Turning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turning"])},
    "Neat Cutting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neat Cutting"])},
    "Hobbing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hobbing"])},
    "Honing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honing"])},
    "Drawing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drawing"])},
    "Forming2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forming 2"])},
    "Rolling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolling"])},
    "Stamping Fine Blanking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stamping Fine Blanking"])},
    "Lapping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lapping"])},
    "Roughing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roughing"])},
    "Electronig Discharge Machining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronig Discharge Machining"])},
    "Deep Hole Drilling Thread Cutting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deep Hole Drilling Thread Cutting"])},
    "Quenching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quenching"])}
  },
  "machineEntryFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "entry_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entry Type"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quantity"])},
    "idProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product ID"])},
    "product_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product Name"])},
    "execution_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["execution Date"])},
    "machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["machine"])}
  },
  "analysisRequestFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "request_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["request Type"])},
    "requested_By_User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["requested By User"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status"])},
    "product_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product Name"])},
    "machine_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["machine Name"])},
    "handled_By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["handled By"])},
    "creation_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["creation Date"])},
    "forwarder_By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forwarded by"])},
    "forward_Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forward status"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comment"])}
  },
  "userFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "first_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first Name"])},
    "last_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last Name"])},
    "phone_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone Number"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
    "linked_To": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["linked to"])},
    "company_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["company Name"])},
    "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["permission"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["language"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["role"])}
  },
  "parameterFields": {
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unit"])},
    "tolerance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tolerance"])},
    "init_Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["init Value"])},
    "calc_Formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["calc. Formula"])}
  },
  "statusOptions": {
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
    "under_Mantainance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under Mantainance"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
    "analysing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysing"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])}
  },
  "languageOptions": {
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
    "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
    "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])}
  },
  "userRolesOptions": {
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent"])}
  },
  "waterOptions": {
    "soft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soft (below 5°dH)"])},
    "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium (5-15°dH)"])},
    "hard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hard (above 15°dH)"])},
    "demineralised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demineralised"])},
    "ostmotic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostmotic"])},
    "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process Water"])}
  },
  "__TABLE_COMMON_FIELDS__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table Common Fields"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabled"])},
  "forwardStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forward status"])},
  "__EXCEPTIONS__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceptions"])},
  "validMachineException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A valid machine has to be choosen to create the request"])},
  "validMachineIDException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A valid Machine ID needs to be provided to continue!"])},
  "validMachineSelectionException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a valid machine first!"])},
  "validProductException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A valid product has to be choosen to continue"])},
  "validProductIDException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A valid Product ID needs to be provided to continue!"])},
  "validProductSelectionException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a valid product first!"])},
  "validDistributorException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A valid distributor has to be choosen to continue"])},
  "validDistributorIDException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A valid Distributor ID needs to be provided to continue!"])},
  "validDistributorSelectionException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a valid distributor first!"])},
  "validCustomerException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A valid customer has to be choosen to continue"])},
  "validCustomerIDException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A valid Customer ID needs to be provided to continue!"])},
  "validCustomerSelectionException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a valid customer first!"])},
  "noInfosAvailableException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No infos available for this page..."])},
  "validMachineAndFiltrationException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid Machine ID and Filtration ID need to be provided to perform this function!"])},
  "validMachineAndMaterialException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid Machine ID and Material ID need to be provided to perform this function!"])},
  "validMachineAndOperationException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid Machine ID and Operation ID need to be provided to perform this function!"])},
  "validMachineAndParameterException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid Machine ID and Parameter ID need to be provided to perform this function!"])},
  "missingParameterException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot evaluate formulas cause Parameter is missing..."])},
  "validUserIDException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User ID not specified!! Cannot load the requested page..."])},
  "validUserAndPermissionException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid User ID and Permission ID need to be provided to perform this function!"])},
  "nonExistentCustomerException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot delete a non existent customer!!"])},
  "nonExistentDistributorException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot delete a non existent distributor!!"])},
  "nonExistentMachineException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot delete a non existent machine!!"])},
  "nonExistentProductException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot delete a non existent product!!"])},
  "labReportLoadException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot load Lab Report"])},
  "unknownMachineLabReportException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot collect Lab Reports without knowing the Machine"])},
  "unknownReportTypeException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot collect Lab Report Parameter cause Lab Report Type is missing!!"])},
  "undefinedReportException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot navigate to an undefined report"])},
  "missingParametersException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not enough Parameters specified!"])},
  "unknownUserPermissionException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot access permission of a non existent user!!"])},
  "unknownUserAssignmentsException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot access assignments of a non existent user!!"])},
  "emptyCommentException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot close a lab report without a comment! Insert a comment first!!"])},
  "validAnalysisRequestIDException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A valid Analysis Request ID needs to be provided to continue!"])},
  "limitedPermissionsException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not enough permissions to perform this action!"])},
  "emptyForwardingRequestException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot forward an empty request!"])},
  "emptyRequestReplyException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot reply to an empty request or with an empty text!"])},
  "associatedMachineException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot delete the selected record, because it's linked at least with one machine... Remove the references first!!!!"])},
  "alreadyAssignedLabReportException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A report has already been created for this Measurement!!"])},
  "emptyEmailException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail Address cannot be empty!"])},
  "emptyPasswordException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password cannot be empty!"])},
  "checkPasswordMismatchException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation password does not match the inserted password!"])},
  "changePasswordException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred when changing the password..."])},
  "noMeasurementsException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No measurements for this machine yet..."])},
  "__TOASTS__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toasts"])},
  "requestStatusNotCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status of the request is not completed!!"])},
  "cannotAccessDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot access request details..."])},
  "noAttachmentProvided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No attachments provided!"])},
  "noAttachmentProvidedDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request has not been filled, or a measurement has been requested"])},
  "dataSuccesfullyUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data succesfully Updated"])},
  "redirectingToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirecting to Home page..."])},
  "reportSuccesfullyUpdated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Report successfully ", _interpolate(_named("rep")), ". Redirecting......"])},
  "reportBaseDataSuccesfullyUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Main Data succesfully updated!"])},
  "reportClosedMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cannot Edit ", _interpolate(_named("rep")), " cause the status is already closed!!!"])},
  "reportOpenMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("rep")), " is not closed, you can edit it without any further operations!!"])},
  "redirectingToList": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Redirecting to ", _interpolate(_named("list")), "..."])},
  "measurementSuccesfullyCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measurement Succesfully created!!"])},
  "labReportSuccesfullyRequested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lab Report Succesfully requested"])},
  "waterAnalysisSuccesfullyRequested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water Analysis succesfully requested"])},
  "forwardedRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Analysis request has been forwarded"])},
  "forwardedRequestDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It will be available once the forwarding process is closed..."])},
  "__CONFIRMATION__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
  "confirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "sendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Email"])},
  "doNotSendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not send Email"])},
  "continueTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to continue?"])},
  "productTechnologyWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to delete the table and recreate all the values..."])},
  "customerDeletionWarning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'You are about to delete a customer (", _interpolate(_named("cust")), ")..."])},
  "distributorDeletionWarning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'You are about to delete a distributor (", _interpolate(_named("dist")), ")..."])},
  "machineDeletionWarning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'You are about to delete a machine (", _interpolate(_named("machine")), ")..."])},
  "productDeletionWarning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'You are about to delete a product (", _interpolate(_named("prod")), ")..."])},
  "sendEmailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send an Email to the requesting customer?"])},
  "sendEmailDetails": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A request analysis is linked to this Report... Do you want to send User ", _interpolate(_named("usr")), " a notification via EMail?"])},
  "emptyingMachineWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to empty the machine..."])},
  "customerAgentAssigmentWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following modifications will be executed: "])},
  "eccessiveProductQuantityWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The inserted Product quantity overcome the maximum allowed by the machine"])},
  "closeForwardedRequestTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close Request by replying to your Customer"])},
  "closeForwardedRequestDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert a message you want to send to your customer and click on the reply button to close the request..."])},
  "resetEmailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A reset-link has been sent to the specified E-Mail address."])},
  "passwordSuccesfullyUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password successfully updated!"])},
  "labReportDeletionWarning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are about to delete a lab report (", _interpolate(_named("labReport")), "), do you want to proceed?"])}
}