<template>
  <CContainer fluid v-if="isDataLoaded">
    <CRow>
      <CCol :sm="6">
        <CCard>
          <CCardBody>
            <CCardTitle><strong>{{ $t('assignedOperations') }}</strong></CCardTitle>
            <CCardText>{{ $t('assignedOperationsTitle') }}
                {{ $t('assignedOperationsSubtitle') }}</CCardText>
            <CRow v-for="assOperation in assignedOperations" :key="assOperation.id" class="mb-3">
              <CCol>
                <CInputGroup class="mb-3">
                  <CFormInput type="text" id="assOperationName" readonly
                    :value="getTranslatedName(assOperation.operation_Name)" />
                  <CButton type="button" color="dark" variant="outline" id="showComment"
                    @click="toggleCommentVisibility(assOperation)">
                    {{ commentText }}
                    <CIcon icon="cil-comment-square" size="sm" v-if="isMobile"/>
                  </CButton>          
                  <CButton type="button" color="danger" id="removeItem"  
                    @click="removeAssignedOperation(assOperation.id, assOperation.idMachine)">
                    <CIcon icon="cil-trash" size="lg" />
                  </CButton>                              
                </CInputGroup>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol :sm="6">
        <CCard>
          <CCardBody>
            <CCardTitle><strong>{{ $t('availableOperations') }}</strong></CCardTitle>
            <CCardText>{{ $t('availableOperationsDesc') }}</CCardText>
            <div class="d-grid gap-2 col-6 mx-auto">
              <CButton v-for="operation in availableOperations" :key="operation.id" 
                color="secondary" variant="outline" shape="rounded-0"
                @click="assignOperation(operation.id)">{{ getTranslatedName(operation.name) }}</CButton>
            </div>
          </CCardBody>
        </CCard>
      </CCol>      
    </CRow>

    <CModal alignment="center" :visible="commentVisible" @close="() => { commentVisible = false }">
      <CModalHeader>
        <CModalTitle>{{ $t('comment') }} - {{ getTranslatedName(currOperation.operation_Name) }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CFormTextarea id="assOperationComment" v-model="currOperation.comment" rows="5" />
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="() => { commentVisible = false }">
          {{ $t('close') }}
        </CButton>
        <CButton color="primary" @click="updateAssignedOperation(currOperation.id, currOperation.idMachine, currOperation.comment)">{{ $t('saveChanges') }}</CButton>
      </CModalFooter>
    </CModal>

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert>     
  </CContainer> 
</template>

<script>
import AssignedOperationsService from '../services/machine-assigned-operations'
import OperationsService from '../services/operations'

export default {
  name: 'OperationComponent',
  data() {
    return {
      assignedOperations: '',
      availableOperations: '',
      allOperations: '',
      assignedOpLoaded: false,
      allOpLoaded: false,
      dataLoaded: false,
      commentVisible: false,
      currOperation: '',      
      message: ''
    }
  },
  created() {
    this.refreshComponent()
  },
  computed: {
    isDataLoaded() {
      if ((this.assignedOpLoaded) && (this.allOpLoaded)) {
        this.getAvailableOperations()
      }
      return this.dataLoaded
    },
    isMobile() {
      let mobile = localStorage.getItem('isMobile')
      return (mobile === 'true')
    },    
    commentText() {
      if (this.isMobile) {
        return ''
      } else {
        return this.$t('showComment')
      }
    }    
  },
  methods: {
    refreshComponent() {
      this.message = ''
      this.dataLoaded = false
      this.assignedOpLoaded = false
      this.allOpLoaded = false
      this.getAssignedOperations()
      this.getAllOperations()
    },
    getAssignedOperations() {
      if (this.$route.params.idMachine) {
        AssignedOperationsService.getOperations({ 'idMachine': this.$route.params.idMachine }).then(
          (response) => {
            this.assignedOperations = response.data.data
            this.assignedOpLoaded = true
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validMachineIDException')
      }  
    },
    getAllOperations() {
      OperationsService.getOperations().then(
        (response) => {
          this.allOperations = response.data.data
          this.allOpLoaded = true
        },
        (error)  => {
          this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
        }
      )
    },    
    getAvailableOperations() {
      let calcOperations = []
      this.allOperations.forEach(operation => {
        if (!this.assignedOperations.find(assOp => assOp.id == operation.id)) {
          calcOperations.push(operation)
        }
      })
      this.availableOperations = calcOperations
      this.dataLoaded = true
    },
    removeAssignedOperation(operationID, machineID) {
      if ((machineID != 0) && (operationID != 0)) {
        AssignedOperationsService.deleteOperation({ 'id': operationID, 'idMachine': machineID }).then(
          () => {
            this.refreshComponent()
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validMachineAndOperationException')
      }
    },
    assignOperation(operationID) {
      if ((this.$route.params.idMachine) && (operationID != 0)) {
        AssignedOperationsService.assignOperation({ 'id': operationID, 'idMachine': this.$route.params.idMachine }).then(
          () => {
            this.refreshComponent()
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validMachineAndOperationException')
      }    
    },
    updateAssignedOperation(operationID, machineID, comment) {
      if ((machineID != 0) && (operationID != 0)) {
        AssignedOperationsService.updateOperations({ 'id': operationID, 'idMachine': machineID }, { 'comment': comment }).then(
          () => {
            this.commentVisible = false
            this.refreshComponent()
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validMachineAndOperationException')
      }        
    },
    toggleCommentVisibility(operation) {
      if (this.commentVisible) {
        this.currOperation = ''
      } else {
        this.currOperation = operation
      }
      this.commentVisible = !this.commentVisible
    },
    getTranslatedName(operation) {
      let result = ''
      if (operation) {
        result = this.$t('operations.' + operation)
      }
      return result
    }            
  }
}
</script>