<template>
  <CCard fluid>
    <CCardBody>
      <CCardTitle>{{ $t('quickActions') }}</CCardTitle>
      <CCardSubtitle class="mb-2 text-muted">{{ $t('quickActionsSubtitle') }}</CCardSubtitle>
      <CAlert color="warning" v-if="customerSessionActive">
        {{ $t('customerSessionActiveDescription') }}<strong>{{ customerName }}</strong>
      </CAlert>
      <div class="d-grid gap-2 d-md-flex justify-content-md-start">
        <CButton type="button" color="dark" shape="rounded-0" id="customerSession"
          @click="startStopCustomerSession()">{{ customerSessionTitle }}</CButton>        
        <CButton type="button" color="dark" shape="rounded-0" id="newMeasurement"
          @click="openMeasurementCard()">{{ $t('newMeasurement') }}</CButton>
        <CPopover placement="top" :trigger="specificBrowserTrigger" v-if="!isAgentSession">
          <template #content>
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
              <CButton type="button" color="dark" shape="rounded-0" id="labReport"
                @click="setLabReportSelection(0)">{{ $t('labReport') }}</CButton>  
              <CButton type="button" color="dark" shape="rounded-0" id="waterAnalysis"
                @click="setLabReportSelection(1)">{{ $t('waterAnalysis') }}</CButton>            
            </div>
          </template>
          <template #toggler="{ on }">
            <CButton type="button" color="dark" shape="rounded-0" id="newLabReport" v-on="on">
              {{ $t('newLabReport') }}</CButton>     
          </template>
        </CPopover> 
        <CButton type="button" color="dark" shape="rounded-0" id="requestAnalysis" v-if="isAgentSession"
          @click="toggleAnalysisRequestModalVisibility()">{{ $t('requestAnalysis') }}</CButton>        
        <CButton type="button" color="dark" shape="rounded-0" id="machinesOverview"
          @click="openMachineOverview()">{{ $t('machinesOverview') }}</CButton>         
      </div>    
    </CCardBody>
    <machine-selection-modal-mobile
      :cModalVisible="newLabReportModalVisible"
      @closeMachineSelection="onBeforeClosingNewLabReport($event)"/>   
    <customer-selection-modal-mobile
      :cModalVisible="customerSelectionVisible"
      @cancelCustomerSelection="onCancelCustomerSelection()"
      @confirmCustomerSelection="onConfirmCustomerSelection($event)"/>  
    <analysis-request-mobile
      :cModalVisible="analysisRequestModal"
      @closeAnalysisRequest="toggleAnalysisRequestModalVisibility()"/>          
  </CCard>
</template>

<script>
import MachineSelectionModalMobile from './MachineSelectionModalMobile.vue'
import CustomerSelectionModalMobile from './CustomerSelectionModalMobile.vue'
import CustomerService from '../../services/customers'
import AnalysisRequestMobile from './AnalysisRequestMobile.vue'
import PermissionManager from '../../helpers/permissionManager'

export default {
  name: 'DistributorQuickActionsMobile',
  data() {
    return {
      machineSelectionModalVisible: false,
      newLabReportModalVisible: false,
      labReportType: 0,
      customerSelectionVisible: false,
      customerName: '',
      analysisRequestModal: false
    }
  },
  computed: {
    customerSessionActive() {
      let customerSessionStarted = localStorage.getItem('customerSessionStarted')
      return ((customerSessionStarted) && (!(customerSessionStarted === 'false')))      
    },
    customerSessionTitle() {
      if (this.customerSessionActive) {
        return this.$t('stopCustomerSession')
      } else {
        return this.$t('startCustomerSession')
      }      
    },
    isAgentSession() {
      return (PermissionManager.getAgentFilteredVisibility() != null)
    },
    specificBrowserTrigger() {
      let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      return (isSafari ? 'hover' : 'focus')
    }      
  },
  created() {
    this.getCustomerName()
  },
  methods: {
    setLabReportSelection(selection) {
      this.labReportType = selection
      this.toggleLabReportModalVisibility()
    },
    toggleLabReportModalVisibility() {
      this.newLabReportModalVisible = !this.newLabReportModalVisible
    },
    onBeforeClosingNewLabReport(machineID) {
      this.toggleLabReportModalVisibility()
      if ((machineID) && (machineID != 0)) {
        let selectionParameters = {
            'idMachine': machineID,
            'labReportType': this.labReportType
        }
        this.onCloseNewLabReportModal(selectionParameters)
      } 
    },
    onCloseNewLabReportModal(selectionParameters) {
      if (this.isMobile()) {
        this.$router.push({ name: 'mMeasure', params: { 'machineID': selectionParameters.idMachine, 'measurementType': Number(selectionParameters.labReportType) + 1 } })
      } else {
        this.$router.push({ name: 'Measure', params: { 'machineID': selectionParameters.idMachine, 'measurementType': Number(selectionParameters.labReportType) + 1 } })
      }
    },    
    openMeasurementCard() {
      this.$router.push({ name: 'mMeasure', params: { 'measurementType': 3 } })  
    },
    isMobile() {
      let mobile = localStorage.getItem('isMobile')
      return (mobile === 'true')
    },
    openMachineOverview() {
      this.$router.push({ name: 'mMachinesOverview' })
    },
    startStopCustomerSession() {
      if (!this.customerSessionActive) {
        this.customerSelectionVisible = true
      } else {
        localStorage.setItem('customerSessionStarted', false)
        this.$emit('refreshRequestsLists')
      }      
    },
    onConfirmCustomerSelection(selectedCustomerID) {
      if ((selectedCustomerID) && (selectedCustomerID != 0)) {
        localStorage.setItem('customerSessionStarted', true)
        localStorage.setItem('currentCustomer', selectedCustomerID)
        this.customerSelectionVisible = false
        this.getCustomerName()
        this.$emit('refreshRequestsLists')
      }
    },
    onCancelCustomerSelection() {
      this.customerSelectionVisible = false
      this.$emit('refreshRequestsLists')
    },
    getCustomerName() {
      this.customerName = ''
      let customerID = localStorage.getItem('currentCustomer')
      if ((customerID) && (customerID != 0)) {
        CustomerService.getSingleCustomer(customerID).then(
          (response) => {
            this.customerName = response.data.company
          }
        )
      }
    },
    toggleAnalysisRequestModalVisibility() {
      this.analysisRequestModal = !this.analysisRequestModal
    }       
  },
  components: {
    MachineSelectionModalMobile,
    CustomerSelectionModalMobile,
    AnalysisRequestMobile
  }
}
</script>