import axios from 'axios'
import authHeader from './auth-header'
import formatQuery from './query-helper'

const API_URL = process.env.VUE_APP_SERVER_BASE_URL + 'machine-assigned-parameters'

class AssignedParametersService {
    getParameters(queryParams) {
        return axios.get(API_URL + formatQuery(queryParams), { headers: authHeader() })
    }
    updateParameters(compositeKeyParams, parameterParams) {
        return axios.patch(API_URL + formatQuery(compositeKeyParams), parameterParams, { headers: authHeader() })
    }
    assignParameter(parameterParams) {
        return axios.post(API_URL, parameterParams, { headers: authHeader() })
    }
    deleteParameter(compositeKeyParams) {
        return axios.delete(API_URL + formatQuery(compositeKeyParams), {headers: authHeader() })
    }
    // Build other api calls here....   
}

export default new AssignedParametersService()