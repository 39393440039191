<template>
  <div>  
    <div class="row">
      <div class="col-12 col-8-tablet push-2-tablet text-center">
        <CIcon size="xxl" icon="cil-speedometer"/>
        <h3 class="title">{{ $t('machines') }}
        <CIcon size="lg" icon="cil-filter" @click="additionalFiltersVisible = !additionalFiltersVisible"/>
        </h3>
      </div>
    </div>

    <div class="d-grid gap-2 d-md-flex justify-content-md-end">   
      <CButton type="button" color="dark" shape="rounded-0" id="createMachine" @click="createMachine()">{{ $t('createNewMachine') }}</CButton>
    </div>

    <machine-search-component 
      :filtersVisible="additionalFiltersVisible"
      @loadData="setAdditionalFilterAndSearch($event)"/>

    <loading-data v-if="!dataLoaded"/>

    <small><general-table v-if="dataLoaded"
      :data="machines"
      :columns="tableColumns"
      :rowsdetail="tableRowsDetails"
      :showItemsPerPage="false"
      :sorter="false"
      :columnFilter="false"
      :extraButtonName="$t('measurements')"
      @customAction="goToMeasurements($event)"
      @editItem="editMachine($event)"
      @deleteItem="askForDeletionConfirmation($event)"/></small>

    <confirm-action
      :cModalVisible="popupConfirmMessage"
      :title="confirmTitle"
      :message="confirmMessage"
      :yesButton="$t('confirmButton')"
      :noButton="$t('cancelButton')"
      @confirm="confirmAction()" 
      @cancel="cancelAction()" />        

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </div>
</template>

<script>
import GeneralTable from '@/components/GeneralTable.vue'
import MachineService from '../../services/machines'
import DateFormatter from '../../helpers/dateFormatter'
import ConfirmAction from '../../components/ConfirmAction.vue'
import PermissionManager from '../../helpers/permissionManager'
import LoadingData from '../../components/LoadingData.vue'
import MachineSearchComponent from '../MachineSearchComponent.vue'

export default {
  name: 'MachinesMobile',
  data() {
    return {
      dataLoaded: false,
      message: '',
      content: [],
      searchQuery: '',
      tableColumns: [
        { key: 'machineFields.id', _style: 'min-width: 50px' },
        { key: 'machineFields.name', _style: 'min-width: 200px' },
        { key: 'machineFields.machine_ID', _style: 'min-width: 120px' },
        { key: 'machineFields.customer', _style: 'min-width: 200px' },
        { key: 'machineFields.product', _style: 'min-width: 200px' },          
      {
        key: 'show_details', 
        label: '', 
        _style: 'width:1%', 
        sorter: false, 
        filter: false
      }
      ],
      tableRowsDetails: [
        'status',
        'machineFields.distributor',
        'machineFields.product_Content',
        'machineFields.critical_pH',
        'machineFields.sump_Size',
        'machineFields.water',
        'machineFields.min_Eff_Conc',
        'machineFields.max_Eff_Conc',
        'machineFields.last_Filled',
        'machineFields.unknown_Date',
        'machineFields.creation_Date',
        'machineFields.created_By',
        'machineFields.central_Plant',
        'machineFields.central_Machine'
      ],
      popupConfirmMessage: false,
      confirmTitle: '',
      confirmMessage: '',
      selectedMachineID: 0,
      additionalFilter: '',
      additionalFiltersVisible: false,
      retrievalStatus: 'stopped'                      
    }
  },
  created() {
    this.getMachinesGradually()
  },
  computed: {
    machines() {
      let processedData = []
      if (this.content.length > 0) {
        this.content.forEach((machine) => {
          processedData.push({
            'machineFields.id': machine.id,
            'machineFields.name': machine.name,
            'machineFields.machine_ID': machine.id_Machine,
            'machineFields.distributor': machine.distributor_Name,
            'machineFields.customer': machine.customer_Name,
            'machineFields.product': machine.product_Name,
            'machineFields.product_Content': machine.product_Content,
            'status': machine.status,
            'machineFields.critical_pH': machine.critical_pH,
            'machineFields.sump_Size': machine.sump_Size,
            'machineFields.water': machine.water,
            'machineFields.min_Eff_Conc': machine.min_Eff_Concentration,
            'machineFields.max_Eff_Conc': machine.max_Eff_Concentration,
            'machineFields.last_Filled': DateFormatter.formatDate(machine.last_Filled, true),
            'machineFields.unknown_Date': machine.unknown_Date,
            'machineFields.creation_Date': DateFormatter.formatDate(machine.created, true),
            'machineFields.created_By': machine.created_By_User,
            'machineFields.central_Plant': machine.central_Plant,
            'machineFields.central_Machine': machine.centralMachine_Name                     
          })                    
        })
      }
      return processedData
    }
  },
  methods: {
    getMachinesGradually(skipParam) {
      this.retrievalStatus = 'retrieving'
      if (!skipParam) {
        this.content = []
      }      
      let queryParams = {}
      let routeParams = this.$route.params
      if ((routeParams.idProduct) && (routeParams.idProduct != 0)) {
          queryParams.idProduct = routeParams.idProduct
      }  
      if ((routeParams.idDistributor) && (routeParams.idDistributor != 0)) {
          queryParams.idDistributor = routeParams.idDistributor
      }
      // Get activeSession Customer Filter
      let customerSessionStarted = localStorage.getItem('customerSessionStarted')
      let currentCustomer = localStorage.getItem('currentCustomer')
      if ((customerSessionStarted) && (!(customerSessionStarted === 'false')) &&
          (currentCustomer) && (currentCustomer != 0)) {
        queryParams.idCustomer = currentCustomer
      }      
      if ((routeParams.idCustomer) && (routeParams.idCustomer != 0)) {
          queryParams.idCustomer = routeParams.idCustomer
      }
      // Fix filter by permissions
      const userCustomer = PermissionManager.getCustomerFilteredVisibility()
      if (userCustomer) {
        queryParams.idCustomer = userCustomer.idCustomer
      }     
      if (!(PermissionManager.hasExtendedTechnicianPermissions())) {
        const userDistributor = PermissionManager.getDistributorFilteredVisibility()
        if (userDistributor) {
          queryParams.idDistributor = userDistributor.idDistributor
        }       
      }        
      // Get active only from local storage
      let activeOnly = localStorage.getItem('showOnlyActiveRecords')
      if (activeOnly === 'true') {
        queryParams.status = 0
      }        
      // Get Additional Filters
      if (this.additionalFilter) {
        queryParams = { ...queryParams, ...this.additionalFilter}
      }                 
      queryParams.$limit = 50
      if (skipParam) {
        queryParams['$skip'] = skipParam
      }
      MachineService.getMachines(queryParams).then(
        (response) => {
          if (this.retrievalStatus == 'restart') {
            this.retrievalStatus = 'stopped'
            this.content = ''
            this.dataLoaded = false
            this.getMachinesGradually()
          } else {          
            if (this.content.length > 0) {
              this.content = this.content.concat(response.data.data)
            } else {
              this.content = response.data.data
            }
            this.dataLoaded = true
            if (response.data.total > response.data.limit + response.data.skip) {
              if (skipParam) {
                skipParam += response.data.limit
              } else {
                skipParam = response.data.limit
              }
              this.getMachinesGradually(skipParam)
            } else {
              this.retrievalStatus = 'stopped'
            }              
          }
        },
        (error)  => {
          this.message =           
           (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()

          this.retrievalStatus = 'stopped'
        }        
      )
    },    
    goToMeasurements(iD) {
      this.$router.push({ name: 'mMeasurementsMachine', params: { idMachine: iD } })
    },
    editMachine(iD) {
      this.$router.push({ name: 'mEditMachine', params: { idMachine: iD } })
    },
    createMachine() {
      const param = { 'name': 'Temp Name _' + Math.floor(Math.random() * 9999).toString() + ' (Edit)' }
      // Set customer ID if created by customer....
      // First check if a customer session is active...
      let customerSessionStarted = localStorage.getItem('customerSessionStarted')
      let currentCustomer = localStorage.getItem('currentCustomer')
      if ((customerSessionStarted) && (!(customerSessionStarted === 'false')) &&
          (currentCustomer) && (currentCustomer != 0)) {
        param.idCustomer = currentCustomer
      }
      // then check evt. for user customers...
      const userCustomer = PermissionManager.getCustomerFilteredVisibility()
      if (userCustomer) {
        param.idCustomer = userCustomer.idCustomer
      }  
      const userDistributor = PermissionManager.getDistributorFilteredVisibility()
      if (userDistributor) {
        param.idDistributor = userDistributor.idDistributor
      }             
      MachineService.createMachine(param).then(
        (response) => {
          this.editMachine(response.data.id)
        },
        (error)  => {
          this.message =           
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }
      )          
    },
    askForDeletionConfirmation(machineID) {
      this.selectedMachineID = machineID
      this.confirmTitle = this.$t('continueTitle')
      this.confirmMessage = this.$t('machineDeletionWarning', {machine:  machineID})
      this.popupConfirmMessage = true
    },     
    deleteMachine() {
      if ((!this.selectedMachineID) || (this.selectedMachineID == 0)) {
        this.message = this.$t('nonExistentMachineException')
      } else {
        MachineService.deleteMachine(this.selectedMachineID).then(
          () => {
            this.dataLoaded = false
            this.getMachinesGradually()
          },
          (error)  => {
            this.message =           
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
          }
        ) 
      }            
    },
    confirmAction() {
      this.resetConfirmMessage()
      this.deleteMachine()
    },
    cancelAction() {
      this.resetConfirmMessage()
    },
    resetConfirmMessage() {
      this.confirmTitle = ''
      this.confirmMessage = '',
      this.popupConfirmMessage = false
    },
    setAdditionalFilterAndSearch(searchFilter) {
      this.additionalFilter = (searchFilter ? searchFilter : '')
      this.dataLoaded = false
      this.content = []
      if (this.retrievalStatus == 'retrieving') {
        this.retrievalStatus = 'restart'
      } else {      
        this.getMachinesGradually()
      }
    }              
  },
  components: {
    GeneralTable,
    ConfirmAction,
    LoadingData,
    MachineSearchComponent
  }
}
</script>