<template>
  <div>
    <small><general-table v-if="dataLoaded"
      :data="users"
      :columns="tableColumns"
      :rowsdetail="tableRowsDetails"
      :showItemsPerPage="false"
      :sorter="false"
      :columnFilter="false"        
      @itemSelected="$emit('itemSelected', $event)"
      @editItem="$emit('editItem', $event)" /></small>

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </div>
</template>

<script>
import GeneralTable from '@/components/GeneralTable.vue'
import UserService from '../../services/users'

export default {
  name: 'UsersLookupMobile',
  props: {
    companyType: Number,
    companyID: Number,
    excludeUsers: Object,
    appendButtons: Array
  },
  data() {
    return {
      dataLoaded: false,
      message: '',
      content: '',
      searchQuery: '',
      tableColumns: [
        { key: 'userFields.id', _style: 'min-width: 50px' },
        { key: 'userFields.first_Name', _style: 'min-width: 100px' },
        { key: 'userFields.last_Name', _style: 'min-width: 100px' },
        { key: 'userFields.phone_Number', _style: 'min-width: 130px' },
        { key: 'userFields.email', _style: 'min-width: 200px' },
        { key: 'userFields.permission', _style: 'min-width: 100px' }
      ],
      tableRowsDetails: [
      ]
    }
  },
  created() {
    if ((this.appendButtons) && (this.appendButtons.length > 0)) {
      this.appendButtons.forEach(button => {
        this.tableColumns.push(button)
      })
    }
    this.getUsers()
  },
  computed: {
    users() {
      let processedData = []
      if (this.dataLoaded) {
        if (this.content.data) {
          this.content.data.forEach((user) => {
            let processUser = true
            if (this.excludeUsers) {
              if (this.excludeUsers.findIndex(exclUser => exclUser.id == user.id) >= 0) {
                processUser = false
              }
            }
            if (processUser) {
              processedData.push({
                'userFields.id': user.id,
                'userFields.first_Name': user.first_Name,
                'userFields.last_Name': user.last_Name,
                'userFields.phone_Number': user.phone_Number,
                'userFields.email': user.email,
                'userFields.permission': user.permission_Name         
              }) 
            }                   
          })
        }
      }
      return processedData
    }
  },
  methods: {
    getUsers() {
      let userParams = {}
      if ((this.companyID) && (this.companyID != 0)) {
        userParams.company_Type = this.companyType
        userParams.idCompany = this.companyID
      }
      userParams.$limit = 5000
      UserService.getUsers(userParams).then(
        (response) => {
          this.content = response.data
          this.dataLoaded = true
        },
        (error)  => {
          this.message =           
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }
      )
    }
  },
  components: {
      GeneralTable
  }
}
</script>