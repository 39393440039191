<template>
  <CContainer>
    <CCard style="width: 37rem">
      <img
        class="logo center-item"
        src="@/assets/Strub_Grey.png"
        alt="Strub Logo"
        width="591"
        height="225"          
      />
      <CCardBody>
        <CForm>
          <div class="mb-3">
            <CFormLabel for="email">{{ $t('emailAddress') }}</CFormLabel>
            <CFormInput type="email" v-model="email" id="email" placeholder="name@example.ch"/>
          </div>
          <div class="mb-3">
            <CFormLabel for="password">{{ $t('password') }}</CFormLabel>
            <CFormInput type="password" v-model="password" id="password" placeholder="Password"/>
            <CLink style="text-align:right" @click="sendPasswordReset()">{{ $t('forgotThePassword') }}</CLink>
          </div>
          <div class="mb-3">
            <CButton type="button" color="dark" shape="rounded-0" class="mb-3" @click="handleLogin()">{{ $t('login') }}</CButton>
          </div>
          <CAlert v-if="message" color="warning" class="d-flex align-items-center">
            <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
            <div>
              {{ message }}
            </div>
          </CAlert>        
        </CForm>
      </CCardBody>
    </CCard> 

    <send-password-reset
      :cModalVisible="resetPasswordVisible"
      @closeModal="resetPasswordVisible = false" />

  </CContainer>           
</template>

<script>
import LanguageParser from '../helpers/languageParser'
import SendPasswordReset from './SendPasswordReset.vue'

export default {
  name: "Login",
  data() {
    return {
      loading: false,
      message: '',
      email: '',
      password: '',
      resetPasswordVisible: false
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/about')
    }
  },
  methods: {
    // ORG: handleLogin(user) {
    handleLogin() {
      let user = {
        email: this.email,
        password: this.password
      }
      this.loading = true;
      this.$store.dispatch("auth/login", user).then(
        () => {
          // Set user default Language
          this.setDefaultLanguage()
          this.$router.go(0)
          this.$router.push('/about')
        },
        (error) => {
          this.loading = false
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        }
      )
    },
    setDefaultLanguage() {
      let userData = this.$store.state.auth.user
      if (userData) {
        if (userData.user.language) {
          LanguageParser.setLanguage(LanguageParser.getLanguage(userData.user.language))
        }
      }
    },
    sendPasswordReset() {
      this.resetPasswordVisible = true
    }
  },
  components: {
    SendPasswordReset
  }
}
</script>