<template>
  <CModal size="xl" :visible="cModalVisible" @show="refreshComponent()" @close="$emit('closePermissionModal')">
    <CModalHeader>
      <CModalTitle>{{ $t('userPermissions') }}</CModalTitle>
    </CModalHeader>
    <CModalBody v-if="isDataLoaded">
      <CRow>
        <CCol :sm="6">
          <CCard>
            <CCardBody>
              <CCardTitle><strong>{{ $t('assignedPermissions') }}</strong></CCardTitle>
              <CCardText>{{ $t('assignedPermissionsTitle') }}
                  {{ $t('assignedPermissionsSubtitle') }}</CCardText>
              <CRow v-for="assPermission in assignedPermissions" :key="assPermission.idPermission" class="mb-3">
                <CCol sm="10">
                  <CFormInput type="text" id="assPermissionName" readonly
                    :value="assPermission.permission_Name" />
                </CCol>
                <CCol sm="2">
                  <CCloseButton @click="removeAssignedPermission(assPermission.idPermission, assPermission.idUser)" />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol :sm="6">
          <CCard>
            <CCardBody>
              <CCardTitle><strong>{{ $t('availablePermissions') }}</strong></CCardTitle>
              <CCardText>{{ $t('availablePermissionsDesc') }}</CCardText>
              <div class="d-grid gap-2 col-6 mx-auto">
                <CButton v-for="permission in availablePermissions" :key="permission.id" 
                  color="secondary" variant="outline" shape="rounded-0"
                  @click="assignPermission(permission.id)">{{ permission.name }}</CButton>
              </div>
            </CCardBody>
          </CCard>
        </CCol>      
      </CRow>

      <CAlert v-if="message" color="danger" class="d-flex align-items-center">
        <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
        <div>
          {{ message }}
        </div>
      </CAlert> 
    </CModalBody>
  </CModal>    
</template>

<script>
import UserPermissionsService from '../services/user-permissions'
import PermissionsService from '../services/permissions'

export default {
  name: 'UserPermissions',
  props: {
    cModalVisible: Boolean,
    userID: Number
  },  
  data() {
    return {
      assignedPermissions: '',
      availablePermissions: '',
      allPermissions: '',
      assignedPermLoaded: false,
      allPermLoaded: false,
      dataLoaded: false,
      message: ''
    }
  },
  created() {
    this.refreshComponent()
  },
  computed: {
    isDataLoaded() {
      if ((this.assignedPermLoaded) && (this.allPermLoaded)) {
        this.getAvailablePermissions()
      }
      return this.dataLoaded
    }
  },
  methods: {
    refreshComponent() {
      this.message = ''
      this.dataLoaded = false
      this.assignedPermLoaded = false
      this.allPermLoaded = false
      this.getAssignedPermissions()
      this.getAllPermissions()
    },
    getAssignedPermissions() {
      if (this.userID) {
        UserPermissionsService.getUserPermissions({ 'idUser': this.userID }).then(
          (response) => {
            this.assignedPermissions = response.data.data
            this.assignedPermLoaded = true
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validUserIDException')
      }  
    },
    getAllPermissions() {
      PermissionsService.getPermissions().then(
        (response) => {
          this.allPermissions = response.data.data
          this.allPermLoaded = true
        },
        (error)  => {
          this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
        }
      )
    },    
    getAvailablePermissions() {
      let calcPermissions = []
      this.allPermissions.forEach(permission => {
        if (!this.assignedPermissions.find(assPerm => assPerm.idPermission == permission.id)) {
          calcPermissions.push(permission)
        }
      })
      this.availablePermissions = calcPermissions
      this.dataLoaded = true
    },
    removeAssignedPermission(permissionID, userID) {
      if ((userID != 0) && (permissionID != 0)) {
        UserPermissionsService.deletePermission({ 'idUser': userID, 'idPermission': permissionID }).then(
          () => {
            this.refreshComponent()
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validUserAndPermissionException')
      }
    },
    assignPermission(permissionID) {
      if ((this.userID) && (permissionID != 0)) {
        UserPermissionsService.assignPermission({ 'idUser': this.userID, 'idPermission': permissionID }).then(
          () => {
            this.refreshComponent()
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validUserAndPermissionException')
      }    
    }    
  }
}
</script>