import axios from 'axios'
import authHeader from './auth-header'
import formatQuery from './query-helper'

const API_URL = process.env.VUE_APP_SERVER_BASE_URL + 'products'

class ProductService {
    getProducts(queryParams) {
        return axios.get(API_URL + formatQuery(queryParams), { headers: authHeader() })
    }
    getSingleProduct(productID) {
        return axios.get(API_URL + '/' + productID, { headers: authHeader() })
    }
    updateProduct(productID, productParams) {
        return axios.patch(API_URL + '/' + productID, productParams, { headers: authHeader() })
    }
    createProduct(productParams) {
        return axios.post(API_URL, productParams, { headers: authHeader() })
    }
    deleteProduct(productID) {
        return axios.delete(API_URL + '/' + productID, {headers: authHeader() })
    }    
    // Build other api calls here....   
}

export default new ProductService()
