export default {
  "quickActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schnelle Aktionen"])},
  "quickActionsSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schnellaktionen können je nach Benutzerrolle variieren"])},
  "newMeasurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Messung"])},
  "requestAnalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse anfordern"])},
  "uselessButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unnützer Knopf"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird geladen..."])},
  "loadingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten werden geladen"])},
  "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochgeladen"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen"])},
  "adminCenterTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin-Center"])},
  "userPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerberechtigungen"])},
  "productTechnologies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkttechnologien"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicht"])},
  "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfahren"])},
  "analysisRequestTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseanfrage"])},
  "analysisRequestSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie Art der Anforderung und Maschine aus, auf der die Analyse durchgeführt werden soll"])},
  "requestType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfragetyp"])},
  "requestTypeOpt0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messungen (allgemein)"])},
  "requestTypeOpt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laborbericht"])},
  "requestTypeOpt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasseranalyse"])},
  "selectMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie die Maschine aus"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche..."])},
  "measurementAttachmentDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messung anhängen, um anzugeben, warum Sie die Analyse benötigen"])},
  "measurementAttachmentMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messung anhängen"])},
  "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelle neu"])},
  "linkExisting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpfung vorhanden"])},
  "notificationFlag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigen Sie mich, wenn die Anfrage bearbeitet wird"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
  "createRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage erstellen"])},
  "selectMeasurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messung auswählen"])},
  "noMeasurementLinkedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derzeit sind keine Messungen verknüpft..."])},
  "measurementLinkedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messung richtig verknüpft"])},
  "measurementConfirmTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spezifikation Messung fehlt"])},
  "measurementConfirmMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind im Begriff, eine Analyseanfrage ohne Massnahme zu senden... Möchten Sie fortfahren?"])},
  "noOfMeasurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl Messungen"])},
  "viewMeasurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messungen anzeigen"])},
  "machineSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschinenauswahl"])},
  "goToMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehen Sie zur Maschine"])},
  "customize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anpassen"])},
  "assignedFiltrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugewiesene Filtrationen"])},
  "assignedMaterials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugewiesene Materialien"])},
  "assignedOperations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugewiesene Operationen"])},
  "assignedLabReportParam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugewiesene Laborberichtsparameter"])},
  "assignedWaterAnalysisParam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugewiesene Wasseranalyseparameter"])},
  "assignedPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugewiesene Berechtigungen"])},
  "labReportParam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labor Bericht"])},
  "waterAnalysisParam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasser Analyse"])},
  "editProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt bearbeiten"])},
  "editDistributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verteiler bearbeiten"])},
  "editCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde bearbeiten"])},
  "distributorLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Händlerstandort"])},
  "customerLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenstandort"])},
  "receivedMeasurementAsAttachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messung als Anhang erhalten"])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter:"])},
  "filterPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie eine Zeichenfolge ein..."])},
  "itemsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekte pro Seite:"])},
  "pageContentInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen zum Seiteninhalt (Lookups)"])},
  "toggleInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen über die Seite umschalten"])},
  "verifyTokenMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank für die Bestätigung Ihres Kontos. Gehen Sie zur Anmeldeseite, um fortzufahren"])},
  "verifyingAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto wird bestätigt... bitte warten..."])},
  "unableToVerifyAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto konnte nicht bestätigt werden. Bitte wenden Sie sich an den Support."])},
  "openAnalysisRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseanfragen öffnen"])},
  "openAnalysisDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An Distributoren angeforderte Eingangsanalysen, klicken Sie auf die Schaltfläche Alle anzeigen, um auch die abgeschlossenen Analysen anzuzeigen"])},
  "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jetzt"])},
  "distributorOverwiev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Händlerübersicht"])},
  "analysisToPerform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingangsanalyse zur Durchführung von Kundenanfragen"])},
  "analysisForwardedToMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingehende weitergeleitete Analysen"])},
  "analysisForwardedByMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitergeleitete Analysen"])},
  "analysisForwardedToMeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysen durchzuführen, die von anderen Distributoren weitergeleitet wurden"])},
  "analysisForwardedByMeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiterleitung der Analyse an einen anderen Händler (definiert in der Verteilerkarte)"])},
  "handleAnalysisRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseanforderung bearbeiten"])},
  "handleAnalysisRequestDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurde noch keine Analyse erstellt, um diese Anfrage zu bearbeiten... Um mit der Erstellung fortzufahren, bestätigen/ändern Sie die auszuführende Analyse"])},
  "handleRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage bearbeiten"])},
  "assignedFiltrationsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Liste enthält alle bereits zugewiesenen Filter..."])},
  "assignedFiltrationsSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist möglich, den Kommentar zur Zuordnung anzuzeigen/zu bearbeiten, indem Sie auf die Schaltfläche klicken, und die Zuordnung zu entfernen, indem Sie auf das „X“ klicken."])},
  "availableFiltrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbare Filtrationen"])},
  "availableFiltrationsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle verbleibenden verfügbaren Filter sind hier aufgelistet"])},
  "assignedMaterialsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Liste enthält alle bereits zugewiesenen Materialien..."])},
  "assignedMaterialsSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist möglich, die mit der Zuweisung verbundenen Felder zu bearbeiten, indem Sie auf die Schaltfläche „Aktualisieren“ klicken, und die Zuweisung entfernen, indem Sie auf das „X“ klicken."])},
  "availableMaterials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbare Materialien"])},
  "availableMaterialsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle verbleibenden verfügbaren Materialien sind hier aufgelistet"])},
  "assignedOperationsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Liste enthält alle bereits zugewiesenen Operationen..."])},
  "assignedOperationsSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist möglich, die Zuordnung zu entfernen, indem Sie auf das „X“ klicken."])},
  "availableOperations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbare Operationen"])},
  "availableOperationsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle verbleibenden verfügbaren Operationen sind hier aufgelistet"])},
  "assignedParametersTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Liste enthält alle bereits zugewiesenen Parameter..."])},
  "assignedParametersSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist möglich, die mit der Zuweisung verbundenen Felder zu bearbeiten, indem Sie auf die Schaltfläche „Aktualisieren“ klicken, und die Zuweisung entfernen, indem Sie auf das „X“ klicken."])},
  "availableParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbare Parameter"])},
  "availableParametersDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle verbleibenden verfügbaren Parameter sind hier aufgelistet"])},
  "assignedPermissionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Liste enthält alle bereits vergebenen Berechtigungen..."])},
  "assignedPermissionsSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist möglich, die Zuordnung zu entfernen, indem Sie auf das „X“ klicken."])},
  "availablePermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbare Berechtigungen"])},
  "availablePermissionsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle verbleibenden verfügbaren Berechtigungen sind hier aufgelistet"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
  "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Addresse"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "newLabReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Laborbericht"])},
  "newWaterAnalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Wasseranalyse"])},
  "inputMeasuredValues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die gemessenen Werte ein"])},
  "confirmMeasurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messung bestätigen"])},
  "technologyLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technologielinie"])},
  "technologyLineDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie den Typ der zu erstellenden Zeile aus (Kopf- oder Wertzeile)"])},
  "lineType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linientyp"])},
  "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Header"])},
  "line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linie"])},
  "lineValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeilenwert"])},
  "calculatedField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechnetes Feld..."])},
  "noFormulaDefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Formel definiert"])},
  "formulaEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formel-Editor"])},
  "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formel"])},
  "formulaEditorInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In diesem Editor ist es möglich, die gewünschte Formel einzugeben... Die verfügbaren Operationen sind die Standardoperationen, also (+, -, *, /) Es ist auch möglich, Operationen mit Klammern () zu gruppieren. Verwenden Sie die Bezeichner der zu spezifizierenden Parameter ein Parameter (A-Z)"])},
  "formulaEditorAvailableParamDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Liste enthält alle verfügbaren Parameter, beziehen Sie sich auf sie über die Kennung (A-Z)"])},
  "productTechnologyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definieren Sie das Technologie-Layout"])},
  "confirmUserMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen Sie Ihre E-Mail, um den Benutzer zu bestätigen"])},
  "uploadPictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lade Bilder hoch"])},
  "dropPicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lassen Sie sie hier fallen"])},
  "dropPicture2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["um sie hinzuzufügen"])},
  "dragFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziehen Sie Ihre Dateien hierher"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder"])},
  "clickHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicke hier"])},
  "toSelectFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["um Dateien auszuwählen"])},
  "userAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerzuweisung"])},
  "linkToCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link zum Unternehmen"])},
  "updateUserData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdaten aktualisieren"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herzlich willkommen"])},
  "createNewCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Kunden anlegen"])},
  "createNewDistributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Verteiler erstellen"])},
  "createNewProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Produkt erstellen"])},
  "createNewMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Maschine erstellen"])},
  "finalizeReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht abschließen"])},
  "mainData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptinformationen"])},
  "inputParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter (Eingabe)"])},
  "inputParameterDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die Hauptparameter ein und klicken Sie dann auf Berechnen..."])},
  "calculatedParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter (berechnet)"])},
  "calculatedParametersDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechnete Werte... Bearbeitbar, aber auch berechenbar"])},
  "updateReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht aktualisieren"])},
  "updateReportBaseData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptinformationen aktualisieren"])},
  "machineDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschinendetails"])},
  "updateMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschine aktualisieren"])},
  "machineContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschineninhalt"])},
  "emptyMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt herausnehmen"])},
  "refillMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachfüllen/Neubefüllung"])},
  "changeProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt wechseln"])},
  "showMachineHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historie anzeigen"])},
  "analysisRequestedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehört dem Kunden"])},
  "remainingQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbleibende Menge"])},
  "refillQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachfüllmenge"])},
  "initQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initialisierungsmenge"])},
  "insertProductRefillQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die Nachfüllmenge des Produkts ein"])},
  "insertProductInitQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die Produkt-Init-Menge ein"])},
  "insertMeasuredContainedQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die abgemessene enthaltene Menge vor dem Nachfüllen ein"])},
  "insertRemainingQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die Restmenge des vorherigen Produkts ein, die geleert werden soll"])},
  "insertQuantityToRefill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die Menge zum Nachfüllen ein"])},
  "insertQuantityToInit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die Anfangsmenge des neuen Produkts ein"])},
  "downloadReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht herunterladen"])},
  "downloadRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseanfrage herunterladen"])},
  "labReportInfoboxInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Werte in dieser Liste enthalten Daten, die sich auf die aktuelle Maschine beziehen... Einzelne Berichtswerte können unterschiedlich sein, öffnen Sie einen einzelnen Bericht, um einen spezifischeren Fokus zu haben"])},
  "phValuesVariation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variation der PH-Werte"])},
  "effConcentrationVariation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eff. Konzentrationsvariation"])},
  "valuesFilteredByProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Werte werden nach dem Produkt gefiltert, das sich derzeit in der Maschine befindet"])},
  "phValues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PH-Werte"])},
  "effConcentration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eff. Konzentration"])},
  "waitingForPermissionAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warten auf Berechtigungszuweisung..."])},
  "activeCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Kunden"])},
  "activeProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Produkte"])},
  "activeMachines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Maschinen"])},
  "currWorkingMachines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derzeit funktionierende Maschinen"])},
  "activeCustomersMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden"])},
  "activeProductsMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkte"])},
  "activeMachinesMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschinen"])},
  "currWorkingMachinesMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akt. Masch."])},
  "sample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probe"])},
  "parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter"])},
  "tolerance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toleranz"])},
  "valueUnitOfMeasure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wert (Einheit)"])},
  "recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handlungsempfehlungen"])},
  "phEffConcGraphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pH und Effektiv Konzentrationsgrafik"])},
  "waterAnalysisGraphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafiken (pH, Wasserhärte, Chlorid, Sulfat)"])},
  "resultIndicatorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visuelles Ergebnis des angeforderten Berichts"])},
  "customerAgentAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden-AD Zuweisung"])},
  "updateAssignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisungen bestätigen"])},
  "removeAssigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugewiesene Kunden entfernen"])},
  "unassignedCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht zugewiesene Kunden"])},
  "unassignedCustomersDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier werden die nicht zugewiesene Kunden angezeigt"])},
  "agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AD"])},
  "agentsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die verfügbaren Verkäufer sind unten aufgeführt..."])},
  "assignedCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugewiesene Kunden"])},
  "assignedCustomersDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier werden die zugewiesene Kunden des augewähltes Verkäufers angezeigt"])},
  "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht zugewiesen"])},
  "lastLabReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt erstellter Bericht"])},
  "adminMobileDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In der mobilen Version ist die einzige verfügbare Einstellung die Benutzerzuweisung. Die anderen Operationen müssen in der Desktop-Umgebung durchgeführt werden!"])},
  "globalSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Globale Einstellungen"])},
  "onlyActiveRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Aktive Datensätze anzeigen"])},
  "cannotRenderGraphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagramme können nicht auf Mobilgeräten gerendert werden."])},
  "displayFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Filtern anzeigen"])},
  "machinesFilterSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschinen-Schnellsuche"])},
  "machinesOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschinen Übersicht"])},
  "machinesOverviewDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie die Kunde aus, um die Maschinen zu übersicht"])},
  "customerSelectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KUNDENAUSWAHL"])},
  "measuredMachinesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GEMESSENE MASCHINEN"])},
  "availableMachinesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERFÜGBARE MASCHINEN"])},
  "switchToDesktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf Desktop wechseln"])},
  "switchToMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf Mobile wechseln"])},
  "customerSessionActiveDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Kundensitzung ist gerade aktiv: "])},
  "startCustomerSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundensitzung starten"])},
  "stopCustomerSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundensitzung beenden"])},
  "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemein"])},
  "specifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spezifikationen"])},
  "lastMeasured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt gemessen"])},
  "forgotThePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen?"])},
  "sendPasswordResetTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
  "sendPasswordResetDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihre E-Mail-Adresse ein, um einen Link zum Zurücksetzen des Passworts zu erhalten"])},
  "sendPasswordResetButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset-Link senden"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu Passwort"])},
  "newPasswordCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password bestätigen"])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Zurücksetzen"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht"])},
  "agentSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AD schalten"])},
  "sendLabReportEmailToCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laborbericht via E-Mail senden"])},
  "customerEmailSelectionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie bitte ein Benutzer von der Benutzerliste. Wenn kein Benutzer ausgewählt ist, wird der AD ausgewählt."])},
  "analysisFloatingOilPhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ölphase im Emulsionsbecken"])},
  "labReportFloatingOilPhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ölphase in der Emulsionsprobe"])},
  "__MENUS__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menus"])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einloggen"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausloggen"])},
  "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])},
  "__BUTTONS__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buttons"])},
  "pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilder"])},
  "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausblenden"])},
  "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeigen"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzelheiten"])},
  "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigungen"])},
  "showComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar anzeigen..."])},
  "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen speichern"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
  "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage"])},
  "createLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linie erstellen"])},
  "editFormula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formel bearbeiten"])},
  "editDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details bearbeiten"])},
  "updateFormula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formel aktualisieren"])},
  "createNewLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Zeile erstellen"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen"])},
  "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechnung"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern..."])},
  "processChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prozessänderungen"])},
  "viewAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle ansehen"])},
  "reopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wieder Öffnen"])},
  "replyAndClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antworten und schließen"])},
  "forwardRequestBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage weiterleiten"])},
  "confirmAndNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigt und Neu"])},
  "updateContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhalt Aktualisieren"])},
  "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr..."])},
  "viewCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenanfragen"])},
  "confirmAndRequestLabReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laborbericht anfragen"])},
  "closeRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage schliessen"])},
  "__TABLE_NAMES__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table Names"])},
  "machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschine"])},
  "machines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschinen"])},
  "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkte"])},
  "distributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verteiler"])},
  "distributors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verteiler"])},
  "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])},
  "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden"])},
  "labReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laborbericht"])},
  "labReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laborberichte"])},
  "waterAnalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasseranalyse"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
  "measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messung"])},
  "measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messungen"])},
  "analysisRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseanfrage"])},
  "analysisRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseanfragen"])},
  "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menschen"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
  "__TABLE_FIELDS__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table Fields"])},
  "machineFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
    "machine_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maschinen ID"])},
    "distributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verteiler"])},
    "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt"])},
    "product_Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktinhalt"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "critical_pH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kritischer pH"])},
    "sump_Size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tankgröße (LT)"])},
    "water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasser"])},
    "min_Eff_Conc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindest. Eff. Konzentration"])},
    "max_Eff_Conc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. Eff. Konzentration"])},
    "last_Filled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Befüllung"])},
    "unknown_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unbekanntes Datum"])},
    "creation_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellungsdatum"])},
    "created_By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erstellt von"])},
    "central_Plant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zentralsystem"])},
    "central_Machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zentrale Maschine"])},
    "min-max_Eff_Conc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min/max eff. Konzentration"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhalt (LT)"])}
  },
  "productFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "fluid_Supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schmierstofflieferant"])},
    "refractometer_Factor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refraktometer Faktor"])},
    "critical_pH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kritischer pH"])},
    "technology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technologie"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "product_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelnr."])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erstellt"])},
    "created_By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erstellt von"])}
  },
  "customerFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
    "phone_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon-Nr."])},
    "client_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAV Nr."])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesperrt"])},
    "contact_Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktperson"])},
    "distributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verteiler"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
    "creation_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellungsdatum"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
    "agent_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AD Name"])}
  },
  "distributorFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
    "phone_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon-Nr."])},
    "client_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAV Nr."])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesperrt"])},
    "contact_Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktperson"])},
    "active_Machines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktive Maschinen"])},
    "inactive_Machines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inaktive Maschinen"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
    "creation_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellungsdatum"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
    "analysis_Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse-Benachrichtigungs Email"])},
    "handle_Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichte verwalten"])},
    "forward_To_Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage weiterleiten an"])}
  },
  "measurementFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschine"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt"])},
    "measured_By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem. durch"])},
    "pH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pH"])},
    "refrac_Reading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refrakt. Ablesewert"])},
    "eff_Concentration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eff. Konz."])},
    "measured_At": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "distributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verteiler"])},
    "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])},
    "appareance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zustand"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
    "last_Filled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Befüllung"])},
    "creation_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellungsdatum"])},
    "created_By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erstellt von"])},
    "refrac_Factor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refrakt. Faktor"])},
    "image_Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl Bilder"])},
    "id_Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD-Bericht"])},
    "temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperatur"])},
    "water_Hardness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasserhärte"])}
  },
  "labReportFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "sample_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probennummer"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt"])},
    "requested_By_Customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vom Kunden angefordert"])},
    "date_Ordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelldatum"])},
    "reason_Of_Analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund der Analyse"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "batch_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batchnummer"])},
    "date_Of_Analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysedatum"])},
    "completed_At": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abgeschlossen am"])},
    "appearance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zustand"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrieb"])},
    "smell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geruch"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
    "cleanness_Of_Workstation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauberkeit der Anlage"])},
    "floating_Oil_Phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ölphase"])},
    "measured_By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemessen durch"])},
    "appearance_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aussehen"])}
  },
  "characteristics": {
    "Water hardness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasserhärte"])},
    "pH electrometric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pH elektronisch"])},
    "Conductivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leitfähigkeit mS/cm"])},
    "Nitrite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nitrit"])},
    "Nitrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nitrat"])},
    "Chloride": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chlorid"])},
    "Sulfate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulfat"])},
    "Bacteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bakterien"])},
    "Funghi / yeast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilze / Hefe"])},
    "Tramp Oil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fremdöl"])},
    "Effective Concentration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effektive Konzentration"])},
    "Refractometer Reading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refraktometer-Ablesewert"])},
    "pH electrometric (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pH elektronisch"])},
    "Conductivity (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leitfähigkeit"])},
    "Nitrite (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nitrit"])},
    "Nitrate (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nitrat"])},
    "Chloride (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chlorid"])},
    "Sulfate (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulfat"])},
    "Bacteria (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bakterien"])},
    "Funghi / yeast (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilz / Hefe"])},
    "Total hardness emulsion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamthärte"])},
    "Deposit of wear 5 um": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückstände von Abrieb 5 um"])},
    "Corrosion degree DIN 51360": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrosionsgrad DIN 51360/T2"])},
    "Product Specific Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktspezifischer Wert"])},
    "Concentration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konzentration"])},
    "Mixed Phase acid split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mischphase Säurespaltung"])},
    "Mineral Oil Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mineralölgehalt"])},
    "Titration Emulsion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalinität"])},
    "Correction Factor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrekturfaktor"])},
    "Concentration acid split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oelphase Säurespaltung"])},
    "Product specific value alkalinity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktspezifischer-Wert Alkalität"])},
    "Product specific value acid split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktspezifischer-Wert Säurespaltung"])},
    "Density": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dichte"])},
    "Corrosion test on steel plate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrosionstest auf Stahlplatte"])}
  },
  "filtrations": {
    "Band filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bandfilter"])},
    "Centrifuge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zentrifuge"])},
    "Chip conveyor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Späneförderer"])},
    "Magnet filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magnetfilter"])},
    "No filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Filter"])},
    "Other filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anderer Filter"])},
    "Paper filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papierfilter"])},
    "Skimmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skimmer"])}
  },
  "materials": {
    "Aluminium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aluminium"])},
    "Brass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messing"])},
    "Bronze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bronze"])},
    "Cast Iron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guss"])},
    "Copper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupfer"])},
    "Duplex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplex"])},
    "Hastelloy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hastelloy"])},
    "Inconel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inconel"])},
    "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])},
    "Stainless Steel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chromstahl"])},
    "Steel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stahl"])},
    "Super duplex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super-Duplex"])},
    "Titanium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titan"])}
  },
  "materialFields": {
    "specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mat. spec."])},
    "tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkzeug"])},
    "part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teile"])}
  },
  "operations": {
    "Broaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Räumen"])},
    "Deep Hole Drilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tieflochbohren"])},
    "Drilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bohren"])},
    "Forming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formen"])},
    "Grinding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schleifen"])},
    "Milling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fräsen"])},
    "Reaming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reiben"])},
    "Sawing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sägen"])},
    "Tapping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapping"])},
    "Thread cutting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewindeschneiden"])},
    "Turning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drehen"])},
    "Neat Cutting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neat Cutting"])},
    "Hobbing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hobbing"])},
    "Honing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honing"])},
    "Drawing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drawing"])},
    "Forming2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewindeformen"])},
    "Rolling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolling"])},
    "Stamping Fine Blanking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stamping Fine Blanking"])},
    "Lapping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lapping"])},
    "Roughing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roughing"])},
    "Electronig Discharge Machining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronig Discharge Machining"])},
    "Deep Hole Drilling Thread Cutting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deep Hole Drilling Thread Cutting"])},
    "Quenching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quenching"])}
  },
  "machineEntryFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "entry_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eintrag Typ"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
    "idProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt ID"])},
    "product_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktname"])},
    "execution_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausführungsdatum"])},
    "machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschine"])}
  },
  "analysisRequestFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "request_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfragetyp"])},
    "requested_By_User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vom Benutzer angefordert"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "product_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktname"])},
    "machine_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschinenname"])},
    "handled_By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bearbeitet von"])},
    "creation_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellungsdatum"])},
    "forwarder_By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weitergeleitet von"])},
    "forward_Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorwärtsstatus"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemerkung"])}
  },
  "userFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "first_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "last_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "phone_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "linked_To": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verbunden mit"])},
    "company_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Firma"])},
    "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlaubnis"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle"])}
  },
  "parameterFields": {
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einheit"])},
    "tolerance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toleranz"])},
    "init_Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["init-Wert"])},
    "calc_Formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kalk. Formel"])}
  },
  "statusOptions": {
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
    "under_Mantainance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unter Wartung"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu"])},
    "analysing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysieren"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschlossen"])}
  },
  "languageOptions": {
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch"])},
    "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
    "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italienisch"])},
    "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Französisch"])}
  },
  "userRolesOptions": {
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
    "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkäufer"])}
  },
  "waterOptions": {
    "soft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soft (unter 5°dH)"])},
    "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittel (5-15°dH)"])},
    "hard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hart (oben 15°dH)"])},
    "demineralised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demineralisiert"])},
    "ostmotic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostmotisch"])},
    "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prozesswasser"])}
  },
  "__TABLE_COMMON_FIELDS__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table Common Fields"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesperrt"])},
  "forwardStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorwärtsstatus"])},
  "__EXCEPTIONS__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceptions"])},
  "validMachineException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Erstellen der Anfrage muss eine gültige Maschine ausgewählt werden"])},
  "validMachineIDException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine gültige Maschinen-ID muss angegeben werden, um fortzufahren!"])},
  "validMachineSelectionException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie zuerst eine gültige Maschine aus!"])},
  "validProductException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um fortzufahren, muss ein gültiges Produkt ausgewählt werden"])},
  "validProductIDException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine gültige Produkt-ID muss angegeben werden, um fortzufahren!"])},
  "validProductSelectionException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie zuerst ein gültiges Produkt aus!"])},
  "validDistributorException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um fortzufahren, muss ein gültiger Distributor ausgewählt werden"])},
  "validDistributorIDException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine gültige Vertriebspartner-ID muss angegeben werden, um fortzufahren!"])},
  "validDistributorSelectionException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie zuerst einen gültigen Distributor aus!"])},
  "validCustomerException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um fortzufahren, muss ein gültiger Kunde ausgewählt werden"])},
  "validCustomerIDException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine gültige Kunden-ID muss angegeben werden, um fortzufahren!"])},
  "validCustomerSelectionException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie zuerst einen gültigen Kunden aus!"])},
  "noInfosAvailableException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Informationen für diese Seite verfügbar..."])},
  "validMachineAndFiltrationException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine gültige Maschinen-ID und Filtrations-ID müssen angegeben werden, um diese Funktion auszuführen!"])},
  "validMachineAndMaterialException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine gültige Maschinen-ID und Material-ID müssen angegeben werden, um diese Funktion auszuführen!"])},
  "validMachineAndOperationException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um diese Funktion auszuführen, müssen eine gültige Maschinen-ID und eine Betriebs-ID angegeben werden!"])},
  "validMachineAndParameterException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine gültige Maschinen-ID und Parameter-ID müssen angegeben werden, um diese Funktion auszuführen!"])},
  "missingParameterException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formeln können nicht ausgewertet werden, weil Parameter fehlt..."])},
  "validUserIDException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerkennung nicht angegeben!! Die angeforderte Seite kann nicht geladen werden..."])},
  "validUserAndPermissionException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um diese Funktion auszuführen, müssen eine gültige Benutzer-ID und eine Berechtigungs-ID angegeben werden!"])},
  "nonExistentCustomerException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein nicht vorhandener Kunde kann nicht gelöscht werden!!"])},
  "nonExistentDistributorException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein nicht vorhandener Verteiler kann nicht gelöscht werden!!"])},
  "nonExistentMachineException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine nicht existierende Maschine kann nicht gelöscht werden!!"])},
  "nonExistentProductException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein nicht vorhandenes Produkt kann nicht gelöscht werden!!"])},
  "labReportLoadException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laborbericht kann nicht geladen werden"])},
  "unknownMachineLabReportException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kann keine Laborberichte sammeln, ohne die Maschine zu kennen"])},
  "unknownReportTypeException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Laborberichtsparameter kann nicht erfasst werden, weil der Laborberichtstyp fehlt!!"])},
  "undefinedReportException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigieren zu einem nicht definierten Bericht nicht möglich"])},
  "missingParametersException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht genügend Parameter angegeben!"])},
  "unknownUserPermissionException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kann nicht auf die Berechtigung eines nicht existierenden Benutzers zugreifen!!"])},
  "unknownUserAssignmentsException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kann nicht auf Zuweisungen eines nicht existierenden Benutzers zugreifen!!"])},
  "emptyCommentException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemerkung ist leer... Fügen Sie bitte eine Bemerkung ein um den Bericht zu bestätigen!!"])},
  "validAnalysisRequestIDException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine gültige AnalyseanfragenID muss angegeben werden, um fortzufahren!"])},
  "limitedPermissionsException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Berechtigungen um dieser Aktion zu spielen!"])},
  "emptyForwardingRequestException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine leere Anfrage kann nicht weitergeleitet werden!"])},
  "emptyRequestReplyException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf eine leere Anfrage oder mit einem leeren Text kann nicht geantwortet werden!"])},
  "associatedMachineException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist nicht gültig, den ausgewählten Datensatz, zu löschen. Es ist mit einmal oder mehrere Maschinen verlinked... Löschen Sie erst die verbindungen mit den Maschinen um den Datensatz zu löschen!!!"])},
  "alreadyAssignedLabReportException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Laborbericht wurde schon von dieser Messung erstellt!!"])},
  "emptyEmailException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die E-Mail Adresse kann nicht leer sein!"])},
  "emptyPasswordException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort kann nicht leer sein!"])},
  "checkPasswordMismatchException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Bestätigungspasswort stimmt nicht mit dem eingefügte Passwort!"])},
  "changePasswordException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interne-Fehler: es wurde nicht möglich das Passwort zu aktualisieren..."])},
  "noMeasurementsException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch keine Messungen für diese Maschine..."])},
  "__TOASTS__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toasts"])},
  "requestStatusNotCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status der Anfrage ist nicht abgeschlossen!!"])},
  "cannotAccessDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugriff auf Anforderungsdetails nicht möglich..."])},
  "noAttachmentProvided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Anhänge vorhanden!"])},
  "noAttachmentProvidedDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage wurde nicht ausgefüllt oder eine Messung wurde angefordert"])},
  "dataSuccesfullyUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten erfolgreich aktualisiert"])},
  "redirectingToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiterleitung zur Startseite..."])},
  "reportSuccesfullyUpdated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erfolgreich melden ", _interpolate(_named("rep")), ". Umleitung......"])},
  "reportBaseDataSuccesfullyUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichts-Hauptinformationen wurden aktualisiert"])},
  "reportClosedMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("rep")), " kann nicht bearbeitet werden, da der Status bereits geschlossen ist!!!"])},
  "reportOpenMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("rep")), " ist nicht geschlossen, Sie können es, ohne weitere Aktionen, bearbeiten!!"])},
  "redirectingToList": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weiterleitung an ", _interpolate(_named("list")), "..."])},
  "measurementSuccesfullyCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messung erfolgreich erstellt!!"])},
  "labReportSuccesfullyRequested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laborbericht erfolgreich angefordert"])},
  "waterAnalysisSuccesfullyRequested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasseranalyse erfolgreich angefordert"])},
  "forwardedRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Analyseanfrage wurde weitergeleitet"])},
  "forwardedRequestDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wird verfügbar sein, sobald der Weiterleitungsprozess abgeschlossen ist..."])},
  "__CONFIRMATION__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
  "confirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
  "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "sendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail senden"])},
  "doNotSendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden Sie keine E-Mail"])},
  "continueTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du fortfahren?"])},
  "productTechnologyWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind dabei, die Tabelle zu löschen und alle Werte neu zu erstellen..."])},
  "customerDeletionWarning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie sind dabei, einen Kunden (", _interpolate(_named("cust")), ") zu löschen…"])},
  "distributorDeletionWarning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie sind dabei, einen Distributor (", _interpolate(_named("dist")), ") zu löschen…"])},
  "machineDeletionWarning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie sind dabei, eine Maschine (", _interpolate(_named("machine")), ") zu löschen…"])},
  "productDeletionWarning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie sind dabei, ein Produkt (", _interpolate(_named("prod")), ") zu löschen…"])},
  "sendEmailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine E-Mail an den anfragenden Kunden senden?"])},
  "sendEmailDetails": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Eine Anforderungsanalyse ist mit diesem Bericht verknüpft... Möchten Sie dem Benutzer ", _interpolate(_named("usr")), " eine Benachrichtigung per E-Mail senden?"])},
  "emptyingMachineWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind dabei, die Maschine zu leeren..."])},
  "customerAgentAssigmentWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die folgenden Änderungen werden ausgeführt: "])},
  "eccessiveProductQuantityWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die eingegebene Menge übersteingen die Höchstgrenze der Maschine"])},
  "closeForwardedRequestTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Anfrage beim der sendung eine Bemerkung, schliessen"])},
  "closeForwardedRequestDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie eine Bemerkung ein, um den Anfrage zu schliessen..."])},
  "resetEmailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein reset-Link wurde an der angegebene E-Mail Adresse gesendet."])},
  "passwordSuccesfullyUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort erfolgreich aktualisiert!"])},
  "labReportDeletionWarning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie sind dabei, ein Laborbericht (", _interpolate(_named("labReport")), ") zu löschen... Möchten Sie trotzdem fortfahren?"])}
}