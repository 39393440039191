<template>
  <CModal size="lg" :visible="cModalVisible" @show="initData()" @close="closeModal()">
    <CModalHeader>
      <CModalTitle>{{ $t('sendPasswordResetTitle') }}</CModalTitle>
    </CModalHeader>
    <CModalBody> 
      <p>{{ $t('sendPasswordResetDescription') }}</p>    
      <div class="mb-3">
        <CFormLabel for="email">{{ $t('emailAddress') }}</CFormLabel>
        <CFormInput type="email" v-model="email" id="email" placeholder="name@example.ch"/>
      </div>                                   
    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="closeModal()">
        {{ $t('close') }}
      </CButton>
      <CButton color="dark"
        @click="sendPasswordReset()">
        {{ $t('sendPasswordResetButton') }}
      </CButton>
    </CModalFooter>    

    <CAlert v-if="errorMessage" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ errorMessage }}
      </div>
    </CAlert> 
    <CAlert v-if="confirmationMessage" color="success" class="d-flex align-items-center">
      <CIcon icon="cil-check" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ confirmationMessage }}
      </div>
    </CAlert>     
  </CModal>
</template>

<script>
export default {
  name: 'SendPasswordReset',
  props: {
    cModalVisible: Boolean
  },  
  data() {
    return {
      email: '',
      errorMessage: '',
      confirmationMessage: ''
    }
  },
  methods: {
    initData() {
      this.email = ''
      this.errorMessage = ''
      this.confirmationMessage = ''
    },
    closeModal() {
      this.message = ''
      this.$emit('closeModal')
    }, 
    sendPasswordReset() {
      if (this.email == '') {
        this.errorMessage = this.$t('emptyEmailException')
        return
      }

      this.$store.dispatch("auth/sendResetPwd", { 'email': this.email }).then(
        () => {
          this.confirmationMessage = this.$t('resetEmailSent')
        },
        (error)  => {
          this.errorMessage =           
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }
      )    
    }   
  }
}
</script>