import { CompanyType, UserRoles } from '../helpers/getEnumValues'

class PermissionManager {
  hasGuestPermissions() {
    const userData = JSON.parse(localStorage.getItem('user'))
    if (!userData) {
      return false
    } 
    return (userData.user.last_Level_Permission >= 1)
  }
  hasUserPermissions() {
    const userData = JSON.parse(localStorage.getItem('user'))
    if (!userData) {
      return false
    }     
    return (userData.user.last_Level_Permission >= 2)
  }
  hasAgentPermissions() {
    const userData = JSON.parse(localStorage.getItem('user'))
    if (!userData) {
      return false
    }    
    return (userData.user.last_Level_Permission >= 3)    
  }
  hasExtendedAgentPermissions() {
    const userData = JSON.parse(localStorage.getItem('user'))
    if (!userData) {
      return false
    }    
    return (userData.user.last_Level_Permission >= 4)     
  }
  hasTechnicianPermissions() {
    const userData = JSON.parse(localStorage.getItem('user'))
    if (!userData) {
      return false
    }    
    return (userData.user.last_Level_Permission >= 5)
  }
  hasExtendedTechnicianPermissions() {
    const userData = JSON.parse(localStorage.getItem('user'))
    if (!userData) {
      return false
    }    
    return (userData.user.last_Level_Permission >= 6)
  }  
  hasAdminPermissions() {
    const userData = JSON.parse(localStorage.getItem('user'))
    if (!userData) {
      return false
    }     
    return (userData.user.last_Level_Permission == 7)
  }
  getCustomerFilteredVisibility() {
    const userData = JSON.parse(localStorage.getItem('user'))
    if (!userData) {
      return null
    } 
    return (this.getCompanyType(userData.user.company_Type) == 1 ? { 'idCustomer': userData.user.idCompany} : null)
  }
  getDistributorFilteredVisibility() {
    const userData = JSON.parse(localStorage.getItem('user'))
    if (!userData) {
      return null
    } 
    return (this.getCompanyType(userData.user.company_Type) == 0 ? { 'idDistributor': userData.user.idCompany} : null)
  }  
  getAgentFilteredVisibility(retrieveUserData) {
    const userData = JSON.parse(localStorage.getItem('user'))
    if (!userData) {
      return null
    } 
    return (this.getUserRole(userData.user.role) == 1 ? { 'idAgent': this.getSpecificAgent(userData.user.id, retrieveUserData)} : null)    
  }  
  getCompanyType(companyTypeString) {
    if ((companyTypeString) && (companyTypeString != '')) {
      return CompanyType.indexOf(companyTypeString)
    }
  }
  getUserRole(roleString) {
    if ((roleString) && (roleString != '')) {
      return UserRoles.indexOf(roleString)
    }
  }  
  userIsAssigned() {
    const userData = JSON.parse(localStorage.getItem('user'))
    if (!userData) {
      return false
    } else {
      return ((userData.user.idCompany) && (userData.user.idCompany != 0))
    }
  }
  getSpecificAgent(defaultAgent, retrieveUserData) {
    if (retrieveUserData) {
      return defaultAgent
    }
    let differentAgent = localStorage.getItem('differentAgentSelected')
    if ((differentAgent) && (differentAgent != 0)) {
      return differentAgent
    } else {
      return defaultAgent
    }
  }          
}

export default new PermissionManager()