import axios from 'axios'
import authHeader from './auth-header'
import formatQuery from './query-helper'

const API_URL = process.env.VUE_APP_SERVER_BASE_URL + 'countries'

class CountryService {
    getCountries(queryParams) {
        return axios.get(API_URL + formatQuery(queryParams), { headers: authHeader() })
    }
    getSingleCountry(countryCode) {
        return axios.get(API_URL + '/' + countryCode, { headers: authHeader() })
    }    
    updateCountry(countryCode, countryParam) {
        return axios.patch(API_URL + '/' + countryCode, countryParam, { headers: authHeader() })
    }
    createCountry(countryParam) {
        return axios.post(API_URL, countryParam, { headers: authHeader() })
    }
    deleteCountry(countryCode) {
        return axios.delete(API_URL + '/' + countryCode, {headers: authHeader() })
    }
    // Build other api calls here....   
}

export default new CountryService()