<template>
  <div>
    <CContainer fluid v-if="dataLoaded">
      <distributor-admin-widget v-if="distrAdminWidgetVisible" :distributorID="currUser.idCompany"/><br>
      <customer-quick-actions v-if="custQuickActionsVisible" @refreshRequestsLists="forceRerenderCust" />
      <br v-if="custQuickActionsVisible">
      <distributor-quick-actions v-if="distrQuickActionsVisible" :key="distrQuickActionsKey" @refreshRequestsLists="rerenderQuickActions" />
      <br v-if="distrQuickActionsVisible">
      <Row class="mb-3" v-if="distrAnalysisRequestVisible">
        <distributor-analysis-request v-if="distrAnalysisRequestVisible" :key="distrAnalysisReqKey" @refreshRequestsLists="forceRerender" /><br>
      </Row>
      <Row class="mb-3" v-if="distrAnalysisRequestForwardedToVisible">        
        <distributor-analysis-request v-if="distrAnalysisRequestForwardedToVisible" :analysisRequestType="1" :key="distrAnalysisReqKey" @refreshRequestsLists="forceRerender"/><br>
      </Row>
      <Row class="mb-3" v-if="distrAnalysisRequestForwardedByVisible">
        <distributor-analysis-request v-if="distrAnalysisRequestForwardedByVisible" :analysisRequestType="2" :key="distrAnalysisReqKey" @refreshRequestsLists="forceRerender"/>
      </Row>    
      <Row class="mb-3" v-if="custAnalysisRequestVisible">
        <customer-analysis-request v-if="custAnalysisRequestVisible" :key="custAnalysisReqKey" />
      </Row>
    </CContainer>

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </div>
</template>

<script>
import UserService from '../services/users'
import DistributorAdminWidget from './DistributorAdminWidgets.vue'
import DistributorAnalysisRequest from './DistributorAnalysisRequest.vue'
import CustomerQuickActions from './CustomerQuickActions.vue'
import CustomerAnalysisRequest from './CustomerAnalysisRequests.vue'
import DistributorQuickActions from './DistributorQuickActions.vue'
import { CompanyType } from '../helpers/getEnumValues'
import DistributorService from '../services/distributors'
import PermissionManager from '../helpers/permissionManager'

export default {
  name: 'Dashboard',
  data() {
    return {
      currUser: '',
      message: '',
      dataLoaded: false,
      distrAdminWidgetVisible: false,
      distrQuickActionsVisible: false,
      distrAnalysisRequestVisible: false,
      custQuickActionsVisible: false,
      custAnalysisRequestVisible: false,
      distrAnalysisRequestForwardedToVisible: false,
      distrAnalysisRequestForwardedByVisible: false,
      distrAnalysisReqKey: 0,
      distrQuickActionsKey: 0,
      custAnalysisReqKey: 0  
    }
  },
  created() {
    this.getUserProfile()
  },
  methods: {
    getUserProfile() {
      // Check if user is logged in, otherwise redirect to the login page
      if (!localStorage.getItem('user')) {
        this.$router.push({ name: 'Login' })
        return
      }
      UserService.getUserData().then(
        (response) => {
          this.currUser = response.data
          this.distrAdminWidgetVisible = ((this.getCompanyType(this.currUser.company_Type) == 0) && (this.currUser.idCompany != 0))
          this.distrAnalysisRequestVisible = this.distrAdminWidgetVisible
          this.distrQuickActionsVisible = this.distrAdminWidgetVisible
          if (this.distrAdminWidgetVisible) {
            let agentUser = PermissionManager.getAgentFilteredVisibility()
            if (agentUser) {
              this.distrAdminWidgetVisible = false
            }
          }          
          this.custAnalysisRequestVisible = ((this.getCompanyType(this.currUser.company_Type) == 1) && (this.currUser.idCompany != 0))
          this.custQuickActionsVisible = this.custAnalysisRequestVisible
          if (this.distrAnalysisRequestVisible) {
            DistributorService.getSingleDistributor(this.currUser.idCompany).then(
              (response) => {
                this.distrAnalysisRequestForwardedByVisible = response.data.forward_To_Company ? (response.data.forward_To_Company != 0) : false
              }
            )
            DistributorService.getDistributors({ 'forward_To_Company': this.currUser.idCompany }).then(
              (response) => {
                this.distrAnalysisRequestForwardedToVisible = response.data.data[0]
              }              
            )
          }
          this.dataLoaded = true
        },
        (error) => {
          if (error.response.status == 401) {
            this.$store.dispatch('auth/logout')
            this.$router.push({ name: 'Login' })
            return
          }
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()          
        }
      )      
    },
    getCompanyType(companyTypeString) {
      if ((companyTypeString) && (companyTypeString != '')) {
        return CompanyType.indexOf(companyTypeString)
      }
    },
    forceRerender() {
      this.distrAnalysisReqKey += 1
    },
    rerenderQuickActions() {
      this.distrQuickActionsKey += 1
      this.forceRerender()
    },
    forceRerenderCust() {
      this.custAnalysisReqKey += 1
    }          
  },
  components: {
    DistributorAdminWidget,
    DistributorAnalysisRequest,
    CustomerQuickActions,
    CustomerAnalysisRequest,
    DistributorQuickActions
  }
}
</script>