<template>
  <CFooter>
    <div>
      <a href="https://www.strub-lube.ch/" target="_blank">Strub A.G.</a>
      <span class="ms-1"
        >&copy; {{ new Date().getFullYear() }} creativeLabs. (v2.0.5)</span
      >
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'FooterMobile',
}
</script>