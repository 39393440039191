<template>
  <CCard :class="resizeRequired ? 'w-50' : 'w-100'">
    <CCardHeader>
      {{ $t('newMeasurement') }}
    </CCardHeader>
    <CCardBody>
      <CCardTitle>{{ $t('inputMeasuredValues') }}</CCardTitle>
      <CRow class="mb-3">
        <CCol sm="6">
          <CFormLabel for="machineName"><strong>{{ $t('machine') }}</strong></CFormLabel>
          <CInputGroup class="mb-3">
            <CFormInput type="text" id="machineName" v-model="machineName" :value="selectedMachine.name" readonly />
            <CInputGroupText v-if="isMobile()">
              <CIcon size="lg" icon="cil-settings" @click="checkMachine()" />
            </CInputGroupText>
          </CInputGroup>
        </CCol>
        <CCol>
          <CFormLabel for="customerName"><strong>{{ $t('customer') }}</strong></CFormLabel>
          <CFormInput type="text" id="customerName" v-model="customerName" :value="selectedMachine.customer_Name" readonly/>
        </CCol>        
      </CRow>
      <CRow class="mb-3">
        <CCol sm="6">
          <CFormLabel for="refracReading"><strong>{{ pretifyName('measurementFields.refrac_Reading') }}</strong></CFormLabel>
          <CFormInput type="number" id="refracReading" v-model="refracReading" />
        </CCol>
        <CCol sm="6">
          <CFormLabel for="pH"><strong>{{ pretifyName('measurementFields.pH') }}</strong></CFormLabel>
          <CFormInput type="number" id="pH" v-model="pH" />
        </CCol>
      </CRow>
      <CRow class="mb-3">       
        <CCol sm="6">
          <CFormLabel for="temperature"><strong>{{ pretifyName('measurementFields.temperature') }}</strong></CFormLabel>
          <CFormInput type="number" id="temperature" v-model="temperature" />
        </CCol> 
        <CCol sm="6">
          <CFormLabel for="water_Hardness"><strong>{{ pretifyName('measurementFields.water_Hardness') }}</strong></CFormLabel>
          <CFormInput type="number" id="water_Hardness" v-model="water_Hardness" />
        </CCol>                
      </CRow>
      <CRow>
        <CCol sm="6">
          <CFormLabel for="date"><strong>{{ pretifyName('measurementFields.creation_Date') }}</strong></CFormLabel>
          <CFormInput type="date" id="date" v-model="date" />    
        </CCol>
      </CRow>   
      <CRow>
        <CCol sm="12">
          <CFormLabel for="appearance"><strong>{{ pretifyName('measurementFields.appareance') }}</strong></CFormLabel>
          <CFormInput type="text" id="appearance" v-model="appearance" />    
        </CCol>
      </CRow>  
      <CRow>
        <CCol sm="12">
          <CFormLabel for="comment"><strong>{{ pretifyName('measurementFields.comment') }}</strong></CFormLabel>
          <CFormTextarea id="comment" rows="5" v-model="comment" />   
        </CCol>
      </CRow>
      <br>
      <CRow>
        <CCol sm="4">
          <CButton color="dark" variant="outline" id="attachPictures" @click="() => { picturesModalVisible = !picturesModalVisible }">
            <CIcon size="lg" icon="cil-camera"/>
          </CButton>
        </CCol>
      </CRow>
      <br>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <CButton color="secondary" @click="closeMeasurementCreation()">
          {{ $t('close') }}
        </CButton>
        <CButton color="dark"
          @click="createMeasurement(refracReading, pH, temperature, water_Hardness, date, appearance, comment, 'closeMeasurementCreation')">
          {{ $t('confirmMeasurement') }}
        </CButton>
        <CButton color="dark" v-if="createAndNewVisible"
          @click="createMeasurement(refracReading, pH, temperature, water_Hardness, date, appearance, comment, 'closeAndNewMeasurement')">
          {{ $t('confirmAndNew') }}
        </CButton>    
        <CButton color="dark" v-if="!isLabReportDistributor"
          @click="createMeasurement(refracReading, pH, temperature, water_Hardness, date, appearance, comment, 'closeAndNewLabReport')">
          {{ $t('confirmAndRequestLabReport') }}
        </CButton>            
      </div>                     
    </CCardBody> 

    <upload-image
      :picturesModalVisible = "picturesModalVisible"
      @closeImageUploadComp="closeImageUploadComp($event)" />

    <machine-content-check-mobile
      :machineID="selectedMachine.id"
      :cModalVisible="machineContentCheckVisible"
      @closeMachineCheck="onCloseMachineCheck($event)" />

  </CCard>
</template>

<script>
import MeasurementService from '../services/measurements'
import UploadImage from './UploadImage.vue'
import MeasurementPicturesService from '../services/measurement-pictures'
import StringFormatter from '../helpers/stringFormatter'
import MachineContentCheckMobile from './mobile/MachineContentCheckMobile.vue'
import PerformMachineOperationsService from '../services/perform-machine-operations'
import PermissionManager from '../helpers/permissionManager'
import MachineLightService from '../services/machines-light'

export default {
  name: 'NewMeasurement',
  props: {
    selectedMachine: Object,
    resize: Boolean
  },
  data() {
    return {
      picturesModalVisible: false,
      uploadedPictures: [],
      date: new Date().toISOString().substring(0, 10),
      machineContentCheckVisible: false      
    }
  },
  computed: {
    resizeRequired() {
      let mobile = localStorage.getItem('isMobile')
      return ((this.resize) && (!(mobile === 'true')))
    },
    isLabReportDistributor() {
      const agentUser = PermissionManager.getAgentFilteredVisibility()
      const customerUser = PermissionManager.getCustomerFilteredVisibility()
      return ((agentUser == null) && (customerUser == null))
    },
    createAndNewVisible() {
      return ((this.isMobile()) && (!(this.$route.params.calledByMeasurement)))
    }
  },
  methods: {
    closeMeasurementCreation() {
      this.$emit('closeMeasurementCreation')
    },
    createMeasurement(refracReading, ph, temperature, water_Hardness, date, appearance, comment, eventToCall) {
      const userData = JSON.parse(localStorage.getItem('user'))
      // Get Machine first to publish the updated evt data
      MachineLightService.getSingleMachine(this.selectedMachine.id).then(
        (response) => {
          const queryParams = {
            idMachine: response.data.id,
            idDistributor: response.data.idDistributor,
            idCustomer: response.data.idCustomer,
            idProduct: response.data.idProduct,
            measuredBy: userData.user.id,
            pH: ph,
            refrac_Reading: refracReading,
            eff_Concentration: 0, // Auto calculated
            appearance: appearance,
            comment: comment,
            last_Filled: response.data.last_Filled,
            refactormeter_Factor: 0,
            image_Count: 0,
            idReport: 0,
            measured_At: date,
            temperature: temperature ? temperature : null,
            measuredBy_Name: userData.user.first_Name ? userData.user.first_Name + ' ' + userData.user.last_Name : '',
            water_Hardness: water_Hardness ? water_Hardness : null
          }
          MeasurementService.createMeasurement(queryParams).then(
            (response) => {
              // Send images...
              if (this.uploadedPictures.length > 0) {
                const measurementID = response.data.id
                let index = 0
                this.uploadedPictures.forEach(picture => {
                  index++
                  MeasurementPicturesService.assignPicture({
                    'idMeasure': measurementID,
                    // Get picture name
                    'name': picture.split('\\').pop().split('/').pop(),
                    'path': picture
                  }).then(
                    () => {
                      if (this.uploadedPictures.length == index) {
                        this.$emit(eventToCall, measurementID)
                      }
                    },
                    (error) => {
                      let message = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString()
                      this.$emit('sendError', message)                  
                    }
                  )
                })
              } else {     
                this.$emit(eventToCall, response.data.id)
              }
            },
            (error) => {
              let message = (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message ||
                error.toString()
              this.$emit('sendError', message)
            }
          )
        },
        (error) => {
          let message = (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          this.$emit('sendError', message)                  
        }
      )       
    },
    closeImageUploadComp(pictures) {
      // const uploadedPictures = ref.uploadImageComp.getUploadedFiles()
      this.uploadedPictures = pictures
      this.picturesModalVisible = false
    },
    pretifyName(name) {
      return StringFormatter.pretifyName(this.$t(name))
    },  
    isMobile() {
      let mobile = localStorage.getItem('isMobile')
      return (mobile === 'true')
    },
    checkMachine() {
      if (this.isMobile) {
        this.machineContentCheckVisible = true
      }
    },
    onCloseMachineCheck(modifiedParameters) {
      if (modifiedParameters) {
        let operationParams = {
          'action': 'refillMachine',
          'phase': modifiedParameters.idProduct ? 1 : 0,
          'idMachine': this.selectedMachine.id,
          'remainingQuantity': '0',
          'refillQuantity': modifiedParameters.sump_Size,
          'executionDate': modifiedParameters.last_Filled
        }
        if (modifiedParameters.idProduct) {
          operationParams.idProduct = modifiedParameters.idProduct
        }
        this.refillMachine(operationParams)
      }
      this.machineContentCheckVisible = false
    },
    refillMachine(operationParams) {
      if (operationParams) {
        PerformMachineOperationsService.performOperation(operationParams).then(
          () => { },
          (error)  => {
            let message =           
              (error.response &&
              error.response.data &&
              error.response.data.message) ||
              error.message ||
              error.toString()
            this.$emit('sendError', message)
          }
        )    
      }  
    }                   
  },
  components: {
    UploadImage,
    MachineContentCheckMobile
  }
}
</script>