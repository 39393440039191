<template>
  <CModal backdrop="static" size="xl" :visible="cModalVisible" @show="initData()" @close="closeAnalysisRequest()">
    <CModalHeader>
      <CModalTitle>{{ $t('analysisRequestTitle') }}</CModalTitle>
    </CModalHeader>
    <CModalBody v-if="dataLoaded">
      <CCardText>{{ $t('analysisRequestSub')}}</CCardText>
      <CRow class="mb-3">
        <CFormLabel for="requestType" class="col-sm-2 col-form-label">{{ $t('requestType') }}</CFormLabel>
        <CCol sm="10">
          <CFormSelect class="mb-3" aria-label="status select" id="requestType" v-model="requestType">
            <option value="0">{{ $t('requestTypeOpt0') }}</option>
            <option value="1">{{ $t('requestTypeOpt1') }}</option>
            <option value="2">{{ $t('requestTypeOpt2') }}</option>
          </CFormSelect>
        </CCol>
      </CRow>    
      <CRow class="mb-3">
        <CFormLabel for="selectedMachine" class="col-sm-2 col-form-label">{{ $t('selectMachine') }}</CFormLabel>
        <CCol sm="10">
          <CInputGroup class="mb-3">
            <CFormInput type="text" id="selectedMachine" v-model="selectedMachine" readonly :value="selectedMachineName"/>
            <CButton type="button" color="dark" variant="outline" id="searchMachine"
              @click="machineLookupVisible = !machineLookupVisible">{{ $t('search') }}</CButton>
          </CInputGroup>
        </CCol>
      </CRow>
      <CCollapse :visible="reportVisible">
        <CCard class="mt-3" color="light">
          <CCardBody>
            <CRow v-if="labReportVisible">
              <CCol sm="12">
                <CFormLabel for="batchNumber">{{ pretifyName('labReportFields.batch_Number') }}</CFormLabel>
                <CInputGroup>
                  <CInputGroupText>ST</CInputGroupText>                 
                  <CFormInput type="text" id="batchNumber" v-model="batchNumber" />
                </CInputGroup>
              </CCol>       
            </CRow>
            <CRow>
              <CCol sm="12">
                <CFormLabel for="reasonOfAnalysis">{{ pretifyName('labReportFields.reason_Of_Analysis') }}</CFormLabel>
                <CFormTextarea id="reasonOfAnalysis" rows="5" v-model="reasonOfAnalysis" />   
              </CCol>
            </CRow>      
            <CRow v-if="labReportVisible">
              <CCol sm="6">
                <CFormLabel for="smell">{{ pretifyName('labReportFields.smell') }}</CFormLabel>
                <CFormTextarea id="smell" rows="5" v-model="smell" />   
              </CCol>
              <CCol>
                <CFormLabel for="cleannessOfWorkstation">{{ pretifyName('labReportFields.cleanness_Of_Workstation') }}</CFormLabel>
                <CFormTextarea id="cleannessOfWorkstation" rows="5" v-model="cleannessOfWorkstation" />   
              </CCol>       
            </CRow>  
            <CRow v-if="labReportVisible">
              <CCol sm="6">
                <CFormCheck type="checkbox" id="floatingOilPhase" :label="pretifyName('labReportFields.floating_Oil_Phase')" v-model="floatingOilPhase" />
              </CCol>      
            </CRow> 
          </CCardBody>
        </CCard>
      </CCollapse><br>      
      <CRow>
        <CDropdown alignment="end">
          <CDropdownToggle color="dark">{{ $t('measurementAttachmentDesc') }}</CDropdownToggle>
          <CDropdownMenu>
            <CDropdownItem @click="createNewMeasurement()">{{ $t('createNew') }}</CDropdownItem>
            <CDropdownItem @click="retrieveMeasurement()">{{ $t('linkExisting') }}</CDropdownItem>
          </CDropdownMenu>
        </CDropdown>          
      </CRow>
      <br>
      <CRow>
        <CFormLabel>{{ measureLinkedText }}</CFormLabel>
      </CRow>
      <br> 
      <CRow class="mb-3">
        <div class="mb-3">
          <CFormCheck type="checkbox" id="notifyUser" :label="$t('notificationFlag')" v-model="notifyUser"/>
        </div>
        <div v-if="isAgentSession">
          <CFormSelect class="mb-3" aria-label="user select" id="contact" v-model="notifySpecificUser">
            <option v-for="(user, index) in orderedUserList" :key="index"
              :value="user.id">
                {{ mainContactText(user.isMainContact) }} {{ user.first_Name }} {{ user.last_Name }} {{ mainContactText(user.isMainContact) }}
            </option>
          </CFormSelect>
        </div>        
      </CRow>    
    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="closeAnalysisRequest()">
        {{ $t('close') }}
      </CButton>
      <CButton color="dark" @click="tryCreateAnalysisRequest()">{{ $t('createRequest') }}</CButton>
    </CModalFooter>    

    <CModal size="xl" alignment="center" :visible="machineLookupVisible" @close="() => { machineLookupVisible = false }">
      <CModalHeader>
        <CModalTitle>{{ $t('machines') }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <machines-lookup-mobile v-if="isMobile"
          @itemSelected="getMachineName($event)" />
        <machines-lookup v-if="!isMobile"
          @itemSelected="getMachineName($event)" />          
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="() => { machineLookupVisible = false }">
          {{ $t('close') }}
        </CButton>
      </CModalFooter>
    </CModal> 

    <CModal backdrop="static" size="lg" alignment="center" :visible="newMeasurementVisible" @close="() => { newMeasurementVisible = false }">
      <CModalBody>
        <new-measurement
          :selectedMachine="selectedMachine"
          @closeMeasurementCreation="onFinishedCreation($event)"
          @sendError="() => { this.message = $event }" />
      </CModalBody>
    </CModal>

    <CModal fullscreen alignment="center" :visible="measurementsLookupVisible" @close="() => { measurementsLookupVisible = false }">
      <CModalHeader>
        <CModalTitle>{{ $t('selectMeasurement') }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <measurements-lookup-mobile v-if="isMobile"
          :machineID="selectedMachineID"
          @itemSelected="saveSelectedMeasurement($event)" />
        <measurements-lookup v-if="!isMobile"
          :machineID="selectedMachineID"
          @itemSelected="saveSelectedMeasurement($event)" />          
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="() => { measurementsLookupVisible = false }">
          {{ $t('close') }}
        </CButton>
      </CModalFooter>
    </CModal>     

    <confirm-action
      :cModalVisible="popupConfirmMessage"
      :title="confirmTitle"
      :message="confirmMessage"
      :yesButton="$t('confirmButton')"
      :noButton="$t('cancelButton')"
      @confirm="confirmAction()" 
      @cancel="cancelAction()" /> 

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </CModal>
</template>

<script>
import UserService from '../services/users'
import AnalysisRequestService from '../services/analysis-requests'
import MachinesLookup from './MachinesLookup.vue'
import MeasurementsLookup from './MeasurementsLookup.vue'
import MachinesLookupMobile from './mobile/MachinesLookupMobile.vue'
import MeasurementsLookupMobile from './mobile/MeasurementsLookupMobile.vue'
import NewMeasurement from './NewMeasurement.vue'
import MachineService from '../services/machines'
import ConfirmAction from './ConfirmAction.vue'
import StringFormatter from '../helpers/stringFormatter'
import CustomerService from '../services/customers'
import PermissionManager from '../helpers/permissionManager'

export default {
  name: 'AnalysisRequest',
  props: {
    cModalVisible: Boolean
  },
  data() {
    return {
      currUser: '',
      dataLoaded: false,
      message: '',
      machineLookupVisible: false,
      selectedMachineID: 0,
      selectedMachineName: '',
      selectedMachine: '',
      notifyUser: true,
      requestType: 0,
      linkedMeasurementID: 0,
      measurementsLookupVisible: false,
      newMeasurementVisible: false,
      popupConfirmMessage: false,
      confirmTitle: '',
      confirmMessage: '',
      batchNumber: '',
      reasonOfAnalysis: '',
      smell: '',
      cleannessOfWorkstation: '',
      floatingOilPhase: false,
      notifySpecificUser: 0,
      customerContactList: []             
    }
  },
  computed: {
    measureLinkedText() {
      return (this.linkedMeasurementID == 0) ? this.$t('noMeasurementLinkedText') : this.$t('measurementLinkedText')
    },
    reportVisible() {
      return (Number(this.requestType) > 0)
    },
    labReportVisible() {
      return (Number(this.requestType) == 1)
    },
    isMobile() {
      let mobile = localStorage.getItem('isMobile')
      return (mobile === 'true')
    },
    orderedUserList() {
      let processedData = []
      let filteredUserData = [...this.customerContactList]
      if (filteredUserData.length > 0) {
        processedData.push({
          'id': 0,
          'first_Name': '',
          'last_Name': '',
          'isMainContact': false
        })
        let mainContactIndex = filteredUserData.findIndex(user => user.isMainContact)
        if (mainContactIndex >= 0) {
          processedData.push(filteredUserData[mainContactIndex])
          filteredUserData.splice(mainContactIndex, 1)
        }
        filteredUserData.forEach((user) => {
          processedData.push(user)
        })
      }
      return processedData
    },
    isAgentSession() {
      return (PermissionManager.getAgentFilteredVisibility() != null)
    }    
  },
  created() {
    this.getCurrentUser()
  },
  methods: {
    getCurrentUser() {
      this.currUser = {}
      UserService.getUserData().then(
        (response) => {
          this.currUser = response.data
          this.dataLoaded = true
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()          
        }
      )
    },
    tryCreateAnalysisRequest() {
      if (!this.requestType) {
        this.requestType = 0
      }
      if (this.selectedMachineID == 0) {
        this.message = this.$t('validMachineException')
        return
      }
      if ((this.requestType > 0) && (this.linkedMeasurementID == 0)) {
        this.confirmTitle = this.$t('measurementConfirmTitle')
        this.confirmMessage = this.$t('measurementConfirmMessage')
        this.popupConfirmMessage = true
      } else {
        this.createAnalysisRequest()
      }
    },
    createAnalysisRequest() {
      let params = {
        'request_Type': this.requestType,
        'requested_By_User': this.notifySpecificUser != 0 ? this.notifySpecificUser : this.currUser.id,
        'request_Status': 0,
        'idMachine': this.selectedMachineID,
        'notify_User': this.notifyUser,
        'attached_idMeasure': this.linkedMeasurementID,
        'keep_User_Notified': this.notifyUser,
        'batch_Number': this.batchNumber,
        'reason_Of_Analysis': this.reasonOfAnalysis,
        'smell': this.smell,
        'cleannes_Of_Workstation': this.cleannessOfWorkstation,
        'floating_Oil_Phase': this.floatingOilPhase,
        'active_Forward_Process': false        
      }
      AnalysisRequestService.createAnalysisRequest(params).then(
        () => {
          this.closeAnalysisRequest()
        },
        (error)  => {
          this.message =           
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        }
      )
    },
    closeAnalysisRequest() {
      this.message = ''
      this.dataLoaded = false
      this.$emit('closeAnalysisRequest')
    },
    initData() {
      this.dataLoaded = false
      this.message = ''
      this.machineLookupVisible = false
      this.selectedMachineID = 0
      this.notifyUser = true
      this.requestType = 0
      this.selectedMachineName = ''
      this.selectedMachine = ''
      this.linkedMeasurementID = 0
      this.measurementsLookupVisible = false
      this.newMeasurementVisible = false      
      this.batchNumber = ''
      this.reasonOfAnalysis = ''
      this.smell = ''
      this.cleannessOfWorkstation = ''
      this.floatingOilPhase = false      
      this.getCurrentUser()
    },
    getMachineName(machineID) {
      this.selectedMachineID = machineID
      if (machineID != 0) {
        MachineService.getSingleMachine(machineID).then(
          (response) => {
            this.selectedMachine = response.data
            this.selectedMachineID = machineID
            this.selectedMachineName = response.data.name
            this.machineLookupVisible = false
            this.getCustomerContactList()
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validMachineIDException')
      } 
    },
    createNewMeasurement() {
      this.message = ''
      if (!this.newMeasurementVisible) {
        if ((!this.selectedMachineID) || (this.selectedMachineID == 0)) {
          this.message = this.$t('validMachineSelectionException')
          return
        }
      }
      this.newMeasurementVisible = !this.newMeasurementVisible
    },
    saveSelectedMeasurement(selectedMeasurementID) {
      this.linkedMeasurementID = selectedMeasurementID
      this.measurementsLookupVisible = false
    },
    retrieveMeasurement() {
      this.message = ''
      if (!this.measurementsLookupVisible) {
        if ((!this.selectedMachineID) || (this.selectedMachineID == 0)) {
          this.message = this.$t('validMachineSelectionException')
          return
        }
      }
      this.measurementsLookupVisible = !this.measurementsLookupVisible
    },
    onFinishedCreation(selectedMeasurementID) {
      if (selectedMeasurementID) {
        this.linkedMeasurementID = selectedMeasurementID
      }
      this.newMeasurementVisible = false
    },
    confirmAction() {
      this.resetConfirmMessage()
      this.createAnalysisRequest()
    },
    cancelAction() {
      this.resetConfirmMessage()
    },
    resetConfirmMessage() {
      this.confirmTitle = ''
      this.confirmMessage = '',
      this.popupConfirmMessage = false
    },
    pretifyName(name) {
      return StringFormatter.pretifyName(this.$t(name))
    },
    getCustomerContactList() {
      this.customerContactList = []
      if ((this.selectedMachine) && (this.selectedMachine.idCustomer) && (this.selectedMachine.idCustomer != 0)) {
        // 1. Get Customer
        CustomerService.getSingleCustomer(this.selectedMachine.idCustomer).then(
          (response) => {
            let contactPerson = response.data.contact_Person
            // 2. Get Users
            let userParams = {
              'company_Type': 1,
              'idCompany': this.selectedMachine.idCustomer,
              '$limit': 5000
            }
            UserService.getUsers(userParams).then(
              (response) => {
                response.data.data.forEach((user) => {
                  var newUser = user
                  newUser.isMainContact = (newUser.first_Name + ' ' + newUser.last_Name == contactPerson)
                  this.customerContactList.push(newUser)
                })
              }
            )            
          }
        )
      }
    },
    mainContactText(isMainContact) {
      return (isMainContact ? '**' : '')
    }                
  },
  components: {
    MachinesLookup,
    MeasurementsLookup,
    NewMeasurement,
    ConfirmAction,
    MachinesLookupMobile,
    MeasurementsLookupMobile
  }
}
</script>