<template>
  <CChart
    type="line"
    :data="userDataset"
    :options="options"
  />
</template>

<script>
import { CChart } from '@coreui/vue-chartjs'
//import { getStyle, hexToRgba } from '@coreui/utils/src'

export default {
  name: 'GeneralLineChart',
  props: {
      userDataset: Array
  },
  components: {
    CChart,
  },
  setup() {
    const options = {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            drawOnChartArea: false,
          },
        },
        y: {
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 5,
            stepSize: Math.ceil(250 / 5),
            max: 250,
          },
        },
      },
      elements: {
        /*
        line: {
          tension: 0.4,
        },*/
        point: {
          radius: 1,
          hitRadius: 10,
          hoverRadius: 4,
          hoverBorderWidth: 3,
        },
      },
    }

    return {
      options,
    }
  }
}
</script>
