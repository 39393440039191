<template>
  <CModal size="lg" :visible="cModalVisible" @show="reloadData()" @close="closePictureModal()">
    <CModalBody v-if="dataLoaded">
      <CCarousel controls indicators>
        <CCarouselItem v-if="pictureAvailable(0)">
          <img class="d-block w-100" :src="getNextPicture(0)"/>
        </CCarouselItem>  
        <CCarouselItem v-if="pictureAvailable(1)">
          <img class="d-block w-100" :src="getNextPicture(1)"/>
        </CCarouselItem> 
        <CCarouselItem v-if="pictureAvailable(2)">
          <img class="d-block w-100" :src="getNextPicture(2)"/>
        </CCarouselItem> 
        <CCarouselItem v-if="pictureAvailable(3)">
          <img class="d-block w-100" :src="getNextPicture(3)"/>
        </CCarouselItem> 
        <CCarouselItem v-if="pictureAvailable(4)">
          <img class="d-block w-100" :src="getNextPicture(4)"/>
        </CCarouselItem> 
        <CCarouselItem v-if="pictureAvailable(5)">
          <img class="d-block w-100" :src="getNextPicture(5)"/>
        </CCarouselItem> 
        <CCarouselItem v-if="pictureAvailable(6)">
          <img class="d-block w-100" :src="getNextPicture(6)"/>
        </CCarouselItem> 
        <CCarouselItem v-if="pictureAvailable(7)">
          <img class="d-block w-100" :src="getNextPicture(7)"/>
        </CCarouselItem> 
        <CCarouselItem v-if="pictureAvailable(8)">
          <img class="d-block w-100" :src="getNextPicture(8)"/>
        </CCarouselItem> 
        <CCarouselItem v-if="pictureAvailable(9)">
          <img class="d-block w-100" :src="getNextPicture(9)"/>
        </CCarouselItem>                                                                                                         
      </CCarousel>

      <CAlert v-if="message" color="danger" class="d-flex align-items-center">
        <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
        <div>
          {{ message }}
        </div>
      </CAlert>       
    </CModalBody>
  </CModal>
</template>

<script>
import MeasurementPicturesService from '../services/measurement-pictures'

export default {
  name: 'PicturesView',
  props: {
    idMeasurement: Number,
    cModalVisible: Boolean
  },
  data() {
    return {
      measurePictures: [],
      dataLoaded: false,
      message: ''
    }
  },
  methods: {
    getPictures() {
      if ((this.idMeasurement) && (this.idMeasurement != 0)) {
        MeasurementPicturesService.getPictures({ 'idMeasure': this.idMeasurement }).then(
          (response) => {
            this.measurePictures = response.data.data
            this.dataLoaded = true
          },
          (error) => {
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()
          }
        )
      }
    },
    reloadData() {
      this.dataLoaded = false
      this.getPictures()
    },
    closePictureModal() {
      this.$emit('closePictureModal')
    },
    getPicturePath(fromPicture) {
      if (fromPicture) {
        return require('C:/Users/r.dellantonio/Documents/RDE/Test/pictures/' + fromPicture)
        //to change with: return require(process.env.VUE_APP_PICTURES_FOLDER_PATH + fromPicture)
      } else {
        return ''
      }
    },
    getNextPicture(index) {
      if (this.measurePictures[index].name) {
        // Use the following to load local resources:
        // DEV: return this.getPicturePath(this.measurePictures[index].name)
        return this.measurePictures[index].path
      } else {
        return ''
      }
    },
    pictureAvailable(index) {
      if (!(this.measurePictures)) {
        return false
      } else if (!(this.measurePictures[index])) {
        return false
      } else {
        return true
      }
    }
  }
}
</script>