<template>
  <CModal size="xl" :visible="cModalVisible" @show="reloadData()" @close="closeParameterFormulaEditor()">
    <CModalHeader>
      <CModalTitle>{{ $t('formulaEditor') }}</CModalTitle>
    </CModalHeader>
    <CModalBody v-if="dataLoaded">  
      <CRow>
        <CCol :sm="6">
          <CCard>
            <CCardBody>
              <CCardTitle><strong>{{ $t('formula') }}</strong></CCardTitle>
              <CCardText>{{ $t('formulaEditorInstructions') }}
              </CCardText>
              <br><br>
              <CFormInput type="text" size="lg" id="formula" v-model="currFormula"/>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol>   
          <CCard>
            <CCardBody>
              <CCardTitle><strong>{{ $t('availableParameters') }}</strong></CCardTitle>
              <CCardText>{{ $t('formulaEditorAvailableParamDesc') }}</CCardText>
              <CInputGroup class="flex-nowrap" v-for="parameter in availableParameters" :key="parameter.id">
                <CInputGroupText id="addon-wrapping"><strong>{{ parameter.identifier }}</strong></CInputGroupText>
                <CFormInput aria-label="parameter" aria-describedby="addon-wrapping" :value="getTranslatedName(parameter.characteristic_Name)" readonly />
              </CInputGroup>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>                 
    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="closeParameterFormulaEditor()">
        {{ $t('close') }}
      </CButton>
      <CButton color="primary"
        @click="updateParameterFormula()">
        {{ $t('updateFormula') }}
      </CButton>
    </CModalFooter>
    

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </CModal>
</template>

<script>
import AssignedParametersService from '../services/machine-assigned-parameters'

export default {
  name: 'ParameterFormulaEditor',
  props: {
    cModalVisible: Boolean,
    currAssParameter: Object
  },
  data() {
    return {
      dataLoaded: false,
      message: '',
      availableParameters: [],
      currFormula: ''
    }
  },
  methods: {
    getAssignedParameters() {
      if ((this.currAssParameter) && 
        (this.currAssParameter.idMachine) &&
        (this.currAssParameter.idMachine != 0)) {
        AssignedParametersService.getParameters({ 
          'idMachine': this.currAssParameter.idMachine,
          'type': this.currAssParameter.type }).then(
          (response) => {
            let assignedParameters = response.data.data
            this.collectAvailableParam(assignedParameters)
            this.simplifyFormula(this.currAssParameter.calc_formula)
            this.dataLoaded = true
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )        
      } else {
        this.message = this.$t('missingParameterException')
      }
    },
    collectAvailableParam(retrievedParameters) {
      // Clear Array First
      this.availableParameters = []
      let charToAssign = 'A'
      if (retrievedParameters) {
        retrievedParameters.forEach(parameter => {
          if (!(parameter.id == this.currAssParameter.id)) {
            let modifiedParam = parameter
            modifiedParam.identifier = charToAssign
            this.availableParameters.push(parameter)
            charToAssign = this.nextChar(charToAssign)
          }
        })
      }
    },
    nextChar(c) {
      return String.fromCharCode(c.charCodeAt(0) + 1)
    },
    simplifyFormula(fromFormula) {
      this.currFormula = ''
      if (!((!fromFormula) || (fromFormula == ''))) {
        // Substitute ids with letters
        while (fromFormula.indexOf('{') >= 0) {
          const startPos = fromFormula.indexOf('{')
          const endPos = fromFormula.indexOf('}')
          const currParameterID = Number(fromFormula.substring(startPos + 1, endPos))
          const inputParameter = this.availableParameters.find(parameter => parameter.id == currParameterID)
          const stringToReplace = '{' + currParameterID + '}'
          if (inputParameter.identifier) {
            fromFormula = fromFormula.replace(stringToReplace, inputParameter.identifier)
          } else {
            fromFormula = fromFormula.replace(stringToReplace, 'NA')
          }
        }
        this.currFormula = fromFormula
      }
    },
    encodeFormula() {
      if (this.currFormula == '') {
        this.currFormula = null
      } else {
        let currPosition = 0
        while (currPosition < this.currFormula.length) {
          let characterToEncode = this.currFormula.substring(currPosition, currPosition + 1)
          if (characterToEncode.match(/[A-Z]/i)) {
            let exchangeParam = this.availableParameters.find(parameter => parameter.identifier == characterToEncode)
            if (exchangeParam) {
              const replacingString = '{' + exchangeParam.id + '}'
              this.currFormula = this.currFormula.replace(characterToEncode, replacingString)
            }
          }
          currPosition ++
        }
      }
    },
    reloadData() {
      this.getAssignedParameters()
    },
    closeParameterFormulaEditor() {
      this.message = ''
      this.dataLoaded = false
      this.$emit('closeParameterFormulaEditorModal', false)      
    },
    updateParameterFormula() {
      if (this.currAssParameter) {
        this.encodeFormula()
        AssignedParametersService.updateParameters({ 'id': this.currAssParameter.id, 'idMachine': this.currAssParameter.idMachine },
          {
            'unit_of_measure': this.currAssParameter.unit_of_measure,
            'tolerance': this.currAssParameter.tolerance,
            'init_value': this.currAssParameter.init_value,            
            'calc_formula': this.currFormula
          }).then(
          () => {
            this.$emit('closeParameterFormulaEditorModal', true) 
          },
          (error)  => {
            this.message =           
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      }
    },
    getTranslatedName(parameter) {
      let result = ''
      if (parameter) {
        result = this.$t('characteristics.' + parameter)
      }
      return result
    }     
  }
}
</script>