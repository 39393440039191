export default {
  "quickActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions rapides"])},
  "quickActionsSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les actions rapides peuvent varier en fonction du rôle de l'utilisateur"])},
  "newMeasurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle mesure"])},
  "requestAnalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande d'analyse"])},
  "uselessButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bouton inutile"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement..."])},
  "loadingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement"])},
  "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargé"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éliminer"])},
  "adminCenterTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centre d'administration"])},
  "userPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisations utilisateur"])},
  "productTechnologies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technologie des produits"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir"])},
  "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processus"])},
  "analysisRequestTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande d'analyse"])},
  "analysisRequestSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le type de demande et la machine sur laquelle effectuer l'analyse"])},
  "requestType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type de demande"])},
  "requestTypeOpt0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesures (général)"])},
  "requestTypeOpt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport de laboratoire"])},
  "requestTypeOpt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse de l'eau"])},
  "selectMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez l'appareil"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche..."])},
  "measurementAttachmentDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joindre une mesure pour spécifier pourquoi vous avez besoin de l'analyse"])},
  "measurementAttachmentMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joindre une mesure"])},
  "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau"])},
  "linkExisting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien existant"])},
  "notificationFlag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M'avertir lorsque la demande est traitée"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["proche"])},
  "createRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une demande"])},
  "selectMeasurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la mesure"])},
  "noMeasurementLinkedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune mesure actuellement liée..."])},
  "measurementLinkedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesure correctement liée"])},
  "measurementConfirmTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécification Mesure manquante"])},
  "measurementConfirmMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point d'envoyer une demande d'analyse sans mesure... Continuer?"])},
  "noOfMeasurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de mesures"])},
  "viewMeasurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les mesures"])},
  "machineSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélection de machines"])},
  "goToMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller à l'ordinateur"])},
  "customize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnaliser"])},
  "assignedFiltrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrations attribuées"])},
  "assignedMaterials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matériaux assignés"])},
  "assignedOperations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérations assignées"])},
  "assignedLabReportParam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres de rapport de laboratoire attribués"])},
  "assignedWaterAnalysisParam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres d'analyse de l'eau attribués"])},
  "assignedPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisations attribuées"])},
  "labReportParam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre de rapport de labo."])},
  "waterAnalysisParam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres d'analyse de l'eau."])},
  "editProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le produit"])},
  "editDistributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le distributeur"])},
  "editCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le client"])},
  "distributorLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emplacement du distributeur"])},
  "customerLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localisation du client"])},
  "receivedMeasurementAsAttachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesure reçue en pièce jointe"])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre:"])},
  "filterPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tapez la chaîne..."])},
  "itemsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objets par page:"])},
  "pageContentInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le contenu de la page (recherches)"])},
  "toggleInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basculer les informations sur la page"])},
  "verifyTokenMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci d'avoir vérifié votre compte. Accédez à la page de connexion pour continuer"])},
  "verifyingAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification du compte... veuillez patienter..."])},
  "unableToVerifyAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de vérifier le compte. Veuillez contacter l'assistance."])},
  "openAnalysisRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demandes d'analyse ouvertes"])},
  "openAnalysisDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse entrante demandée aux distributeurs, cliquez sur le bouton Afficher tout pour afficher également celles terminées"])},
  "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à présent"])},
  "distributorOverwiev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présentation du distributeur"])},
  "analysisToPerform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse entrante à effectuer demandée par les clients"])},
  "analysisForwardedToMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse transmise entrante"])},
  "analysisForwardedByMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse transmise"])},
  "analysisForwardedToMeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses à effectuer qui ont été transmises par d'autres distributeurs"])},
  "analysisForwardedByMeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse transmise à un autre distributeur (défini dans la fiche distributeur)"])},
  "handleAnalysisRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traiter la demande d'analyse"])},
  "handleAnalysisRequestDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune analyse n'a encore été créée pour traiter cette demande... Pour poursuivre la création, confirmez/modifiez l'analyse à effectuer"])},
  "handleRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traiter la demande"])},
  "assignedFiltrationsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette liste contient tous les filtres déjà attribués..."])},
  "assignedFiltrationsSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible de voir/modifier le commentaire lié au devoir en cliquant sur le bouton et de supprimer le devoir en cliquant sur le 'X'"])},
  "availableFiltrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres disponibles"])},
  "availableFiltrationsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les filtrations disponibles restantes sont listées ici"])},
  "assignedMaterialsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette liste contient tous les matériaux déjà attribués..."])},
  "assignedMaterialsSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible de modifier les champs liés à l'affectation en cliquant sur le bouton 'Mettre à jour' et de supprimer l'affectation en cliquant sur le 'X'"])},
  "availableMaterials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matériaux disponibles"])},
  "availableMaterialsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les matériaux disponibles restants sont listés ici"])},
  "assignedOperationsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette liste contient toutes les Opérations déjà attribuées..."])},
  "assignedOperationsSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible de supprimer l'affectation en cliquant sur le 'X'"])},
  "availableOperations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérations disponibles"])},
  "availableOperationsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les opérations disponibles restantes sont listées ici"])},
  "assignedParametersTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette liste contient tous les paramètres déjà assignés..."])},
  "assignedParametersSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible de modifier les champs liés à l'affectation en cliquant sur le bouton 'Mettre à jour' et de supprimer l'affectation en cliquant sur le 'X'"])},
  "availableParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres disponibles"])},
  "availableParametersDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les paramètres disponibles restants sont listés ici"])},
  "assignedPermissionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette liste contient toutes les autorisations déjà attribuées..."])},
  "assignedPermissionsSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible de supprimer l'affectation en cliquant sur le 'X'"])},
  "availablePermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisations disponibles"])},
  "availablePermissionsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les autorisations disponibles restantes sont répertoriées ici"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commenter"])},
  "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "newLabReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau rapport de laboratoire"])},
  "newWaterAnalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle analyse de l'eau"])},
  "inputMeasuredValues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez les valeurs mesurées"])},
  "confirmMeasurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer la mesure"])},
  "technologyLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligne technologique"])},
  "technologyLineDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le type de ligne à créer (en-tête ou ligne de valeur)"])},
  "lineType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de ligne"])},
  "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entête"])},
  "line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doubler"])},
  "lineValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur de ligne"])},
  "calculatedField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ calculé..."])},
  "noFormulaDefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune formule définie"])},
  "formulaEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditeur de formule"])},
  "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formule"])},
  "formulaEditorInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans cet éditeur il est possible de taper la Formule désirée... Les opérations disponibles sont les opérations standard, donc (+, -, *, /) Il est également possible de regrouper les opérations à l'aide de parenthèses () Utilisez les identifiants des paramètres pour spécifier un paramètre (A-Z)"])},
  "formulaEditorAvailableParamDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette liste contient tous les paramètres disponibles, référez-vous à eux via l'identifiant (A-Z)"])},
  "productTechnologyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définir la disposition de la technologie"])},
  "confirmUserMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultez votre e-mail pour confirmer l'utilisateur"])},
  "uploadPictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger des photos"])},
  "dropPicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déposez-les ici"])},
  "dropPicture2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour les ajouter"])},
  "dragFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faites glisser vos fichiers ici"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou alors"])},
  "clickHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ici"])},
  "toSelectFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour sélectionner des fichiers"])},
  "userAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affectation d'utilisateur"])},
  "linkToCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien vers l'entreprise"])},
  "updateUserData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour les données utilisateur"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue"])},
  "createNewCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau client"])},
  "createNewDistributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau distributeur"])},
  "createNewProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau produit"])},
  "createNewMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une nouvelle machine"])},
  "finalizeReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finaliser le rapport"])},
  "mainData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnée principale"])},
  "inputParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres (Entrée)"])},
  "inputParameterDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insérez les paramètres principaux puis cliquez sur Calculer..."])},
  "calculatedParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres (calculés)"])},
  "calculatedParametersDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeurs calculées... Modifiables, mais aussi calculables"])},
  "updateReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le rapport"])},
  "updateReportBaseData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour les données principales"])},
  "machineDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'appareil"])},
  "updateMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour l'ordinateur"])},
  "machineContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenu machine"])},
  "emptyMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine vide"])},
  "refillMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine de recharge"])},
  "changeProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer de produit"])},
  "showMachineHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher l'historique de la machine"])},
  "analysisRequestedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse demandée par"])},
  "remainingQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité restante"])},
  "refillQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité de recharge"])},
  "initQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité initiale"])},
  "insertProductRefillQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insérer la quantité de recharge de produit"])},
  "insertProductInitQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insérer la quantité initiale du produit"])},
  "insertMeasuredContainedQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insérez la quantité contenue mesurée avant la recharge"])},
  "insertRemainingQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insérez la quantité restante du Produit précédent qui sera vidé"])},
  "insertQuantityToRefill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insérez la quantité à recharger"])},
  "insertQuantityToInit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insérez la quantité initiale du nouveau produit"])},
  "downloadReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le rapport"])},
  "downloadRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de téléchargement"])},
  "labReportInfoboxInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les valeurs de cette liste contiennent des données relatives à la machine actuelle... Les valeurs de rapport unique peuvent différer, ouvrez un seul rapport pour avoir un focus plus spécifique"])},
  "phValuesVariation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variation des valeurs de PH"])},
  "effConcentrationVariation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eff. Variation de concentration"])},
  "valuesFilteredByProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les valeurs sont filtrées par le produit actuellement dans la machine"])},
  "phValues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeurs PH"])},
  "effConcentration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eff. Concentration"])},
  "waitingForPermissionAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente d'attribution d'autorisation..."])},
  "activeCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients actifs"])},
  "activeProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produits actifs"])},
  "activeMachines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machines actives"])},
  "currWorkingMachines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machines en cours de fonctionnement"])},
  "activeCustomersMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])},
  "activeProductsMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produits"])},
  "activeMachinesMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machines"])},
  "currWorkingMachinesMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funct. Mach."])},
  "sample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échantillon"])},
  "parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre"])},
  "tolerance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tolérance"])},
  "valueUnitOfMeasure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur (unité de mesure)"])},
  "recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommandations d'action"])},
  "phEffConcGraphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphiques de pH et de concentrations efficaces"])},
  "waterAnalysisGraphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphs"])},
  "resultIndicatorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat visuel du rapport demandé"])},
  "customerAgentAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affectation de l'agent client"])},
  "updateAssignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour les affectations"])},
  "removeAssigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la sélection attribuée"])},
  "unassignedCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients non attribués"])},
  "unassignedCustomersDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans cette liste tous les clients non affectés sont affichés"])},
  "agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agents"])},
  "agentsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les Agents disponibles sont listés ici..."])},
  "assignedCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients assignés"])},
  "assignedCustomersDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans cette liste tous les clients non affectés sont affichés"])},
  "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non attribué"])},
  "lastLabReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernier rapport"])},
  "adminMobileDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the mobile version, the only available setting is the user assignment, the other operations need to be performed in the desktop environment!"])},
  "globalSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres globaux"])},
  "onlyActiveRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher uniquement les enregistrements actifs"])},
  "cannotRenderGraphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les graphiques ne peuvent pas être rendus sur les appareils mobiles."])},
  "displayFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher des filtres supplémentaires"])},
  "machinesFilterSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche rapide de machines"])},
  "machinesOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu des machines"])},
  "machinesOverviewDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le client demandé pour vérifier l'aperçu"])},
  "customerSelectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SÉLECTION CLIENT"])},
  "measuredMachinesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MACHINES MESURÉES"])},
  "availableMachinesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MACHINES DISPONIBLES"])},
  "switchToDesktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer au Desktop"])},
  "switchToMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer au Mobile"])},
  "customerSessionActiveDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une session client est actuellement active: "])},
  "startCustomerSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrer la session client"])},
  "stopCustomerSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrêter la session client"])},
  "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Général"])},
  "specifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caractéristiques"])},
  "lastMeasured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière mesure"])},
  "forgotThePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié?"])},
  "sendPasswordResetTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
  "sendPasswordResetDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insérez votre adresse e-mail pour recevoir un lien de réinitialisation du mot de passe"])},
  "sendPasswordResetButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer le lien de réinitialisation"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nouveau mot de passe"])},
  "newPasswordCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez le mot de passe"])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
  "agentSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer d'agent"])},
  "sendLabReportEmailToCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer le rapport de laboratoire par e-mail"])},
  "customerEmailSelectionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un utilisateur, dans la liste des clients, à qui vous souhaitez envoyer un e-mail. Si rien n'est sélectionné, l'agent principal sera le contact principal du courrier électronique."])},
  "analysisFloatingOilPhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phase huileuse dans le couvercle d'émulsion"])},
  "labReportFloatingOilPhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phase huileuse dans l'échantillon d'émulsion"])},
  "__MENUS__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menus"])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
  "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])},
  "__BUTTONS__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buttons"])},
  "pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des photos"])},
  "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cacher"])},
  "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spectacle"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des détails"])},
  "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
  "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisations"])},
  "showComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le commentaire..."])},
  "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder les modifications"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour"])},
  "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande"])},
  "createLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une ligne"])},
  "editFormula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la formule"])},
  "editDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les détails"])},
  "updateFormula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour la formule"])},
  "createNewLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une nouvelle ligne"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
  "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculer"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer..."])},
  "processChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changements de processus"])},
  "viewAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tout"])},
  "reopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rouvrir"])},
  "replyAndClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répondre et fermer"])},
  "forwardRequestBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de transfert"])},
  "confirmAndNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer et nouveau"])},
  "updateContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réviser contenu"])},
  "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus..."])},
  "viewCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demandes client"])},
  "confirmAndRequestLabReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander un rapport de laboratoire"])},
  "closeRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer la demande"])},
  "__TABLE_NAMES__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table Names"])},
  "machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine"])},
  "machines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machines"])},
  "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des produits"])},
  "distributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributeur"])},
  "distributors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributeurs"])},
  "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
  "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les clients"])},
  "labReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport de laboratoire"])},
  "labReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapports de laboratoire"])},
  "waterAnalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse de l'eau"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
  "measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La mesure"])},
  "measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des mesures"])},
  "analysisRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande d'analyse"])},
  "analysisRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demandes d'analyse"])},
  "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnes"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglages"])},
  "__TABLE_FIELDS__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table Fields"])},
  "machineFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nom"])},
    "machine_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de la machine"])},
    "distributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["distributeur"])},
    "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["client"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["produit"])},
    "product_Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenu du produit"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statut"])},
    "critical_pH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pH critique"])},
    "sump_Size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille du puisard"])},
    "water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["l'eau"])},
    "min_Eff_Conc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min. Eff. Concentration"])},
    "max_Eff_Conc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. Eff. Concentration"])},
    "last_Filled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dernier rempli"])},
    "unknown_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date inconnue"])},
    "creation_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date de création"])},
    "created_By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["créé par"])},
    "central_Plant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usine centrale"])},
    "central_Machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["machine centrale"])},
    "min-max_Eff_Conc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eff. min/max. Concentration"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contenu (LT)"])}
  },
  "productFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "fluid_Supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur de fluides"])},
    "refractometer_Factor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facteur de réfractomètre"])},
    "critical_pH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pH critique"])},
    "technology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La technologie"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statut"])},
    "product_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numéro de produit"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["créé"])},
    "created_By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["créé par"])}
  },
  "customerFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adresse"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pays"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ville"])},
    "phone_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pas de téléphone."])},
    "client_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAV n°"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["désactivé"])},
    "contact_Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["personne de contact"])},
    "distributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["distributeur"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip *: français"])},
    "creation_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date de création"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
    "agent_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'agent"])}
  },
  "distributorFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adresse"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pays"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ville"])},
    "phone_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pas de téléphone."])},
    "client_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAV n°"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["désactivé"])},
    "contact_Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["personne de contact"])},
    "active_Machines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machines actives"])},
    "inactive_Machines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machines inactives"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip *: français"])},
    "creation_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date de création"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
    "analysis_Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail de notification d'analyse"])},
    "handle_Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gérer les rapports"])},
    "forward_To_Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transférer la demande à"])}
  },
  "measurementFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["machine"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["produit"])},
    "measured_By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mesuré par"])},
    "pH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pH"])},
    "refrac_Reading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["réfracter. Lecture"])},
    "eff_Concentration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["effConcentration"])},
    "measured_At": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mesuré à"])},
    "distributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["distributeur"])},
    "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["client"])},
    "appareance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apparence"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commenter"])},
    "last_Filled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dernier rempli"])},
    "creation_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date de création"])},
    "created_By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["créé par"])},
    "refrac_Factor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["réfrac. Facteur"])},
    "image_Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'images"])},
    "id_Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport d'identification"])},
    "temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["température"])},
    "water_Hardness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dureté de l'eau"])}
  },
  "labReportFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "sample_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numéro d'échantillon"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["produit"])},
    "requested_By_Customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["demandé par le client"])},
    "date_Ordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de commande"])},
    "reason_Of_Analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["raison de l'analyse"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statut"])},
    "batch_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n ° de lot."])},
    "date_Of_Analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date d'analyse"])},
    "completed_At": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terminé à"])},
    "appearance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apparence"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verser"])},
    "smell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sentir"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commenter"])},
    "cleanness_Of_Workstation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["propreté du poste de travail"])},
    "floating_Oil_Phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phase huileuse flottante"])},
    "measured_By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mesuré par"])},
    "appearance_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apparence"])}
  },
  "characteristics": {
    "Water hardness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dureté de l'eau"])},
    "pH electrometric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pH électrométrique"])},
    "Conductivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conductivité mS/cm"])},
    "Nitrite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nitrite"])},
    "Nitrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nitrate"])},
    "Chloride": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chlorure"])},
    "Sulfate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulfate"])},
    "Bacteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bactéries"])},
    "Funghi / yeast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champignons / levure"])},
    "Tramp Oil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huile de clochard"])},
    "Effective Concentration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eff. Concentration"])},
    "Refractometer Reading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réfrac. Lecture"])},
    "pH electrometric (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pH électrométrique"])},
    "Conductivity (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conductivité"])},
    "Nitrite (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nitrite"])},
    "Nitrate (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nitrate"])},
    "Chloride (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chlorure"])},
    "Sulfate (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulfate"])},
    "Bacteria (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bactéries"])},
    "Funghi / yeast (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champignons / levure"])},
    "Total hardness emulsion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émulsion de dureté totale"])},
    "Deposit of wear 5 um": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépôt d'usure 5 um"])},
    "Corrosion degree DIN 51360": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Degré de corrosion DIN 51360"])},
    "Product Specific Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur spécifique du produit"])},
    "Concentration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concentration"])},
    "Mixed Phase acid split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séparation acide en phase mixte"])},
    "Mineral Oil Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teneur en huile minérale"])},
    "Titration Emulsion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émulsion de titrage"])},
    "Correction Factor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facteur de correction"])},
    "Concentration acid split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concentration d'acide fractionné"])},
    "Product specific value alkalinity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alcalinité de la valeur spécifique du produit"])},
    "Product specific value acid split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répartition de l'acide de la valeur spécifique au produit"])},
    "Density": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Densité"])},
    "Corrosion test on steel plate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essai de corrosion sur plaque d'acier"])}
  },
  "filtrations": {
    "Band filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre à bande"])},
    "Centrifuge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrifuger"])},
    "Chip conveyor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convoyeur à copeaux"])},
    "Magnet filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre magnétique"])},
    "No filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de filtre"])},
    "Other filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres filtres"])},
    "Paper filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre papier"])},
    "Skimmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écumoire"])}
  },
  "materials": {
    "Aluminium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aluminium"])},
    "Brass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laiton"])},
    "Bronze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bronze"])},
    "Cast Iron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonte"])},
    "Copper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuivre"])},
    "Duplex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplex"])},
    "Hastelloy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hastelloy"])},
    "Inconel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inconel"])},
    "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
    "Stainless Steel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acier inoxydable"])},
    "Steel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acier"])},
    "Super duplex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super-duplex"])},
    "Titanium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titane"])}
  },
  "materialFields": {
    "specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécifique."])},
    "tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outil"])},
    "part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partie"])}
  },
  "operations": {
    "Broaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brochage"])},
    "Deep Hole Drilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forage de trous profonds"])},
    "Drilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forage"])},
    "Forming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formation"])},
    "Grinding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affûtage"])},
    "Milling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraisage"])},
    "Reaming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alésage"])},
    "Sawing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sciage"])},
    "Tapping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapotement"])},
    "Thread cutting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupe-fil"])},
    "Turning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tournant"])},
    "Neat Cutting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupe soignée"])},
    "Hobbing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taillage"])},
    "Honing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honing"])},
    "Drawing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dessin"])},
    "Forming2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formation 2"])},
    "Rolling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roulant"])},
    "Stamping Fine Blanking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estampage Découpage fin"])},
    "Lapping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clapotis"])},
    "Roughing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ebauche"])},
    "Electronig Discharge Machining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usinage par décharge électronique"])},
    "Deep Hole Drilling Thread Cutting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forage de trous profonds Filetage"])},
    "Quenching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trempe"])}
  },
  "machineEntryFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "entry_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'entrée"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quantité"])},
    "idProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["identifiant du produit"])},
    "product_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du produit"])},
    "execution_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date d'éxecution"])},
    "machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["machine"])}
  },
  "analysisRequestFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "request_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type de demande"])},
    "requested_By_User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["demandé par l'utilisateur"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statut"])},
    "product_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du produit"])},
    "machine_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nom de la machine"])},
    "handled_By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["manipulé par"])},
    "creation_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date de création"])},
    "forwarder_By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en avant par"])},
    "forward_Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statut de transfert"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commentaire"])}
  },
  "userFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "first_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prénom"])},
    "last_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nom de famille"])},
    "phone_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numéro de téléphone"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail"])},
    "linked_To": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lié à"])},
    "company_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'entreprise"])},
    "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["autorisation"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rôle"])}
  },
  "parameterFields": {
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unité"])},
    "tolerance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tolérance"])},
    "init_Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur d'initialisation"])},
    "calc_Formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["calc. Formule"])}
  },
  "statusOptions": {
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactif"])},
    "under_Mantainance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En maintenance"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau"])},
    "analysing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours d'analyse"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermé"])}
  },
  "languageOptions": {
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais"])},
    "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allemand"])},
    "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["italien"])},
    "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])}
  },
  "userRolesOptions": {
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])},
    "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent"])}
  },
  "waterOptions": {
    "soft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soft (sous 5°dH)"])},
    "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium (5-15°dH)"])},
    "hard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hard (au dessus 15°dH)"])},
    "demineralised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déminéralisé"])},
    "ostmotic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostmotique"])},
    "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eau de process"])}
  },
  "__TABLE_COMMON_FIELDS__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table Common Fields"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
  "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["désactivé"])},
  "forwardStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statut de transfert"])},
  "__EXCEPTIONS__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceptions"])},
  "validMachineException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une machine valide doit être choisie pour créer la demande"])},
  "validMachineIDException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un ID de machine valide doit être fourni pour continuer!"])},
  "validMachineSelectionException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez d'abord une machine valide!"])},
  "validProductException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un produit valide doit être choisi pour continuer"])},
  "validProductIDException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un ID de produit valide doit être fourni pour continuer!"])},
  "validProductSelectionException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez d'abord un produit valide!"])},
  "validDistributorException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un distributeur valide doit être choisi pour continuer"])},
  "validDistributorIDException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un identifiant de distributeur valide doit être fourni pour continuer!"])},
  "validDistributorSelectionException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez d'abord un distributeur valide!"])},
  "validCustomerException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un client valide doit être choisi pour continuer"])},
  "validCustomerIDException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un numéro client valide doit être fourni pour continuer!"])},
  "validCustomerSelectionException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez d'abord un client valide!"])},
  "noInfosAvailableException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune info disponible pour cette page..."])},
  "validMachineAndFiltrationException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un ID de machine et un ID de filtration valides doivent être fournis pour exécuter cette fonction!"])},
  "validMachineAndMaterialException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un ID de machine et un ID de matériau valides doivent être fournis pour exécuter cette fonction!"])},
  "validMachineAndOperationException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un ID de machine et un ID d'opération valides doivent être fournis pour exécuter cette fonction!"])},
  "validMachineAndParameterException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un ID de machine et un ID de paramètre valides doivent être fournis pour exécuter cette fonction!"])},
  "missingParameterException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'évaluer les formules car le paramètre est manquant..."])},
  "validUserIDException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID utilisateur non spécifié !! Impossible de charger la page demandée..."])},
  "validUserAndPermissionException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un ID utilisateur et un ID d'autorisation valides doivent être fournis pour exécuter cette fonction!"])},
  "nonExistentCustomerException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de supprimer un client inexistant !!"])},
  "nonExistentDistributorException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de supprimer un distributeur inexistant !!"])},
  "nonExistentMachineException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de supprimer une machine inexistante !!"])},
  "nonExistentProductException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de supprimer un produit inexistant !!"])},
  "labReportLoadException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de charger le rapport de laboratoire"])},
  "unknownMachineLabReportException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de collecter des rapports de laboratoire sans connaître la machine"])},
  "unknownReportTypeException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de collecter le paramètre de rapport de laboratoire car le type de rapport de laboratoire est manquant!"])},
  "undefinedReportException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'accéder à un rapport non défini"])},
  "missingParametersException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas assez de paramètres spécifiés!"])},
  "unknownUserPermissionException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'accéder à l'autorisation d'un utilisateur inexistant !!"])},
  "unknownUserAssignmentsException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'accéder aux devoirs d'un utilisateur inexistant !!"])},
  "emptyCommentException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de fermer un rapport de laboratoire sans commentaire! Insérez d'abord un commentaire !!"])},
  "validAnalysisRequestIDException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un ID de demande d'analyse valide doit être fourni pour continuer!"])},
  "limitedPermissionsException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisations insuffisantes pour effectuer cette action!"])},
  "emptyForwardingRequestException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de transférer une requête vide!"])},
  "emptyRequestReplyException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de répondre à une requête vide ou avec un texte vide!"])},
  "associatedMachineException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de supprimer l'enregistrement sélectionné, car il est lié à au moins une machine... Supprimez d'abord les références!!!!"])},
  "alreadyAssignedLabReportException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un rapport a déjà été créé pour cette mesure!!"])},
  "emptyEmailException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'adresse e-mail ne peut pas être vide!"])},
  "emptyPasswordException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe ne peut pas être vide!"])},
  "checkPasswordMismatchException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe de confirmation ne correspond pas au mot de passe saisi!"])},
  "changePasswordException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors de la modification du mot de passe..."])},
  "noMeasurementsException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore de mesures pour cette machine..."])},
  "__TOASTS__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toasts"])},
  "requestStatusNotCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le statut de la demande n'est pas terminé !!"])},
  "cannotAccessDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'accéder aux détails de la demande..."])},
  "noAttachmentProvided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune pièce jointe fournie!"])},
  "noAttachmentProvidedDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La demande n'a pas été satisfaite ou une mesure a été demandée"])},
  "dataSuccesfullyUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données mises à jour avec succès"])},
  "redirectingToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirection vers la page d'accueil..."])},
  "reportSuccesfullyUpdated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Signaler avec succès ", _interpolate(_named("rep")), ". Redirection......"])},
  "reportBaseDataSuccesfullyUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les données principales du rapport ont été mises à jour avec succès!"])},
  "reportClosedMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Impossible de modifier ", _interpolate(_named("rep")), " car le statut est déjà fermé !!!"])},
  "reportOpenMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("rep")), " n'est pas fermé, vous pouvez le modifier sans aucune autre opération !!"])},
  "redirectingToList": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Redirection vers ", _interpolate(_named("liste")), "..."])},
  "measurementSuccesfullyCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesure créée avec succès !!"])},
  "labReportSuccesfullyRequested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport de laboratoire demandé avec succès"])},
  "waterAnalysisSuccesfullyRequested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse de l'eau demandée avec succès"])},
  "forwardedRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette demande d'analyse a été transmise"])},
  "forwardedRequestDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il sera disponible une fois le processus de transfert terminé..."])},
  "__CONFIRMATION__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
  "confirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
  "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "sendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un e-mail"])},
  "doNotSendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne pas envoyer d'e-mail"])},
  "continueTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous continuer?"])},
  "productTechnologyWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point de supprimer la table et de recréer toutes les valeurs..."])},
  "customerDeletionWarning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'Vous êtes sur le point de supprimer un client (", _interpolate(_named("client")), ")..."])},
  "distributorDeletionWarning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'Vous êtes sur le point de supprimer un distributeur (", _interpolate(_named("dist")), ")..."])},
  "machineDeletionWarning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'Vous êtes sur le point de supprimer une machine (", _interpolate(_named("machine")), ")..."])},
  "productDeletionWarning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'Vous êtes sur le point de supprimer un produit (", _interpolate(_named("prod")), ")..."])},
  "sendEmailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un e-mail au client demandeur?"])},
  "sendEmailDetails": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une analyse de demande est liée à ce rapport... Voulez-vous envoyer une notification à l'utilisateur ", _interpolate(_named("usr")), " par e-mail?"])},
  "emptyingMachineWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point de vider la machine..."])},
  "customerAgentAssigmentWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les modifications suivantes seront exécutées:"])},
  "eccessiveProductQuantityWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La quantité de produit inséré dépasse le maximum autorisé par la machine"])},
  "closeForwardedRequestTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer la demande en répondant à votre client"])},
  "closeForwardedRequestDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insérez un message que vous souhaitez envoyer à votre client et cliquez sur le bouton de réponse pour fermer la demande..."])},
  "resetEmailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un lien de réinitialisation a été envoyé à l'adresse e-mail spécifiée."])},
  "passwordSuccesfullyUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe mis à jour avec succès!"])},
  "labReportDeletionWarning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous êtes sur le point de supprimer un rapport de laboratoire (", _interpolate(_named("labReport")), "), voulez-vous poursuivre?"])}
}