<template>
  <div>
    <CCard fluid>
      <loading-data v-if="!isDataLoaded"/>
      <CTable bordered responsive v-if="isDataLoaded">
        <CTableBody>
          <CTableRow v-for="(column, index) in labReportColumns" :key="index">
            <CTableHeaderCell scope="row">{{column.columnName}}{{column.tolerance}}</CTableHeaderCell>
            <CTableDataCell v-for="(reportValue, index2) in labReportDetailsArray" :key="index2" :color="getCellColor(reportValue.id)">
              {{reportValue[column.name]}}
            </CTableDataCell>
          </CTableRow> 
          <CTableRow>
            <CTableHeaderCell scope="row"></CTableHeaderCell>
            <CTableDataCell v-for="(reportValue, index) in labReportDetailsArray" :key="index">
              <CButton type="button" color="dark" variant="outline" id="choose"
                @click="selectReport(reportValue['id'])">{{ $t('choose') }}</CButton>  
            </CTableDataCell>
          </CTableRow>                            
        </CTableBody>
      </CTable>
    </CCard> 

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert>
  </div>
</template>

<script>
import LabReportService from '../services/lab-reports'
import LabReportDetailService from '../services/lab-report-details'
import AssignedParametersService from '../services/machine-assigned-parameters'
import { ReportStatus } from '../helpers/getEnumValues'
import DateFormatter from '../helpers/dateFormatter'
import PermissionManager from '../helpers/permissionManager'
import StringFormatter from '../helpers/stringFormatter'
import LoadingData from './LoadingData.vue'

export default {
  name: 'LabReportDetailsLookup',
  props: {
    currMachineID: Number,
    currReportID: Number,
    currProductID: Number,
    labReportType: Number
  },
  data() {
    return {
      machineParameterList: '',
      machineParameterLoaded: false,
      labReportColumns: '',
      labReportArray: '',
      message: '',
      dataLoaded: false,
      labReportsArrayFilled: false,
      labReportDetailsArray: '',
    }
  },
  created() {
    this.getMachineParameters()
    this.getLabReports()
  },
  computed: {      
    isDataLoaded() {
      if ((this.labReportsArrayFilled) && (this.machineParameterLoaded) && (!this.dataLoaded)) {
        this.getLabReportDetails()
      }
      return this.dataLoaded
    },
    checkUserPermissions() {
      return (!(PermissionManager.hasTechnicianPermissions()) || (PermissionManager.getCustomerFilteredVisibility()))
    }         
  },
  methods: {
    getMachineParameters() {
      let queryParams = {}
      if ((this.currMachineID) && (this.currMachineID != 0)) {
        queryParams.idMachine = this.currMachineID
      } else {
        this.message = this.$t('unknownMachineLabReportException')
        return
      }
      queryParams.type = this.labReportType ? this.labReportType : 0 
      AssignedParametersService.getParameters(queryParams).then(
        (response) => {
          this.machineParameterList = response.data.data
          this.getColumnsArray()
          this.machineParameterLoaded = true
        },
        (error)  => {
          this.message =           
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        }
      )        
    },
    getLabReports() {
      // Empty Array first...
      this.labReportArray = []
      this.labReportDetailsArray = []
      this.labReportsArrayFilled = false
      this.dataLoaded = false
      let queryParams = {}
      if ((this.currMachineID) && (this.currMachineID != 0)) {
        queryParams.idMachine = this.currMachineID
      } else {
        this.message = this.$t('unknownMachineLabReportException')
        return
      }
      if ((this.currProductID) && (this.currProductID != 0)) {
        queryParams.idProduct = this.currProductID
      }        
      queryParams.type = this.labReportType ? this.labReportType : 0 
      if (this.checkUserPermissions) {
        queryParams.status = 2
      }
      queryParams.$limit = 50
      queryParams['$sort[id]'] = -1
      LabReportService.getLabReports(queryParams).then(
        (response) => {
          this.labReportArray = response.data.data
          this.labReportsArrayFilled = true
        },
        (error) => {
          this.message =           
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        }
      )
    },
    getLabReportDetails() {
      this.labReportArray.forEach(element => {
        let labReport = element
        // Format Date Fields
        if (labReport.date_Ordered) {
          labReport.date_Ordered = DateFormatter.formatDate(labReport.date_Ordered, true)
        }
        if (labReport.date_Of_Analysis) {
          labReport.date_Of_Analysis = DateFormatter.formatDate(labReport.date_Of_Analysis, true)
        }
        if (labReport.completed_At) {
          labReport.completed_At = DateFormatter.formatDate(labReport.completed_At, true)
        }                
        LabReportDetailService.getLabReportDetails({ 'idLabReport': labReport.id }).then(
          (response) => {
            const reportDetails = response.data.data
            this.machineParameterList.forEach(parameter => {
              const reportParam = reportDetails.find(detail => detail.id == parameter.id)
              if (reportParam) {
                labReport[parameter.characteristic_Name] =
                    ((reportParam.unit != '') ? (reportParam.sample + ' (' + reportParam.unit + ')') : (reportParam.sample))
              } else {
                labReport[parameter.characteristic_Name] = ''
              }
            })
            this.labReportDetailsArray.push(labReport)
            if (this.labReportArray.length == this.labReportDetailsArray.length) {
              if (this.labReportDetailsArray.length > 1) {
                this.labReportDetailsArray.sort((a, b) => {return b.id - a.id})
              }
              this.dataLoaded = true
            }
          }
        )
      })
    },
    getColumnsArray() {
      this.labReportColumns = [
        { 'name': 'status', 'columnName': this.$t('status'), 'tolerance': ''},
        { 'name': 'product_Name', 'columnName': this.pretifyName('labReportFields.product'), 'tolerance': ''},
        { 'name': 'batch_Number', 'columnName': this.pretifyName('labReportFields.batch_Number'), 'tolerance': ''},
        { 'name': 'reason_Of_Analysis', 'columnName': this.pretifyName('labReportFields.reason_Of_Analysis'), 'tolerance': ''},
        { 'name': 'date_Ordered', 'columnName': this.pretifyName('labReportFields.date_Ordered'), 'tolerance': ''},
        { 'name': 'date_Of_Analysis', 'columnName': this.pretifyName('labReportFields.date_Of_Analysis'), 'tolerance': ''},
        { 'name': 'completed_At', 'columnName': this.pretifyName('labReportFields.completed_At'), 'tolerance': ''},
        { 'name': 'appearance', 'columnName': this.pretifyName('labReportFields.appearance'), 'tolerance': ''},
        { 'name': 'deposit', 'columnName': this.pretifyName('labReportFields.deposit'), 'tolerance': ''},
        { 'name': 'smell', 'columnName': this.pretifyName('labReportFields.smell'), 'tolerance': ''}
      ]
      this.machineParameterList.forEach(parameter => {
        let characteristicTag = 'characteristics.' + parameter.characteristic_Name
        this.labReportColumns.push({ 'name': parameter.characteristic_Name, 'columnName': this.$t(characteristicTag), 'tolerance': (parameter.tolerance ? ' - (' + parameter.tolerance + ')' : '') })
      })
    },
    getLabReportStatus(statusString) {
      if ((statusString) && (statusString != '')) {
        return ReportStatus.indexOf(statusString)
      }
    }, 
    getCellColor(reportID) {
      if ((reportID) && (this.currReportID) && (this.currReportID == reportID)) {
        return 'info'
      }
      return 'default'
    },
    pretifyName(name) {
      return StringFormatter.pretifyName(this.$t(name))
    },
    selectReport(reportID) {
      this.$emit('selectReport', reportID)
    }                             
  },
  components: {
    LoadingData
  }
}
</script>

<style scoped>
.chart-wrapper {
  visibility: hidden;
}
</style>