class FormulaParser {
  split(expression, operator) {
    const result = []
    let braces = 0
    let currentChunk = ''
    for (let i = 0; i < expression.length; ++i) {
      const curCh = expression[i]
      if (curCh === '(') {
        braces++
      } else if (curCh === ')') {
        braces--
      }
      if ((braces === 0) && (operator === curCh)) {
        result.push(currentChunk)
        currentChunk = ''
      } else currentChunk += curCh
    }
    if (currentChunk !== '') {
      result.push(currentChunk)
    }
    return result
  }

  // this will add the division to the available operations
  parseDivisionSeparatedExpression(expression) {
    const numbersString = this.split(expression, '/')
    const numbers = numbersString.map(noStr => {
      if (noStr[0] === '(') {
        const expr = noStr.substr(1, noStr.length - 2)
        // recursive call to the main function
        return this.parsePlusSeparatedExpression(expr)
      }
      return +noStr
    })
    const result = numbers.reduce((acc, no) => acc / no)
    return result
  }
  
  // this will only take strings containing * operator [ no + ]
  parseMultiplicationSeparatedExpression(expression) {
    const numbersString = this.split(expression, '*')
    const numbers = numbersString.map(noStr => this.parseDivisionSeparatedExpression(noStr))
    const initialValue = 1.0
    const result = numbers.reduce((acc, no) => acc * no, initialValue)
    return result
  }

  // both * -
  parseMinusSeparatedExpression(expression) {
    const numbersString = this.split(expression, '-')
    const numbers = numbersString.map(noStr => this.parseMultiplicationSeparatedExpression(noStr))
    const initialValue = numbers[0]
    const result = numbers.slice(1).reduce((acc, no) => acc - no, initialValue)
    return result
  }

  // * - +
  parsePlusSeparatedExpression(expression) {
    const numbersString = this.split(expression, '+')
    const numbers = numbersString.map(noStr => this.parseMinusSeparatedExpression(noStr))
    const initialValue = 0.0
    const result = numbers.reduce((acc, no) => acc + no, initialValue)
    return result
  }
  
  parse(expression) {
    const result = this.parsePlusSeparatedExpression(expression, '+')
    return (Math.round(result * 100) / 100)
  }
}

export default new FormulaParser()