<template>
  <CCard :class="resizeRequired ? 'w-50' : 'w-100'" v-if="isDataLoaded">
    <CCardHeader>
      {{ labReportTitle }}
    </CCardHeader>
    <CCardBody>
      <CCardTitle>{{ $t('inputMeasuredValues') }}</CCardTitle>
      <CRow class="mb-3">
        <CCol sm="6">
          <CFormLabel for="machineName"><strong>{{ $t('machine') }}</strong></CFormLabel>
          <CFormInput type="text" id="machineName" v-model="machineName" :value="selectedMachine.name" readonly/>
        </CCol>
        <CCol>
          <CFormLabel for="customerName"><strong>{{ $t('customer') }}</strong></CFormLabel>
          <CFormInput type="text" id="customerName" v-model="customerName" :value="selectedMachine.customer_Name" readonly/>
        </CCol>        
      </CRow>
      <CRow class="mb-3">
        <CCol sm="6">
          <CFormLabel for="samplingDate"><strong>{{ pretifyName('labReportFields.date_Of_Analysis') }}</strong></CFormLabel>
          <CFormInput type="date" id="samplingDate" v-model="samplingDate" />
        </CCol>
        <CCol v-if="labReportType == 0">
          <CFormLabel for="batchNumber"><strong>{{ pretifyName('labReportFields.batch_Number') }}</strong></CFormLabel>
          <CInputGroup>
            <CInputGroupText>ST</CInputGroupText>
            <CFormInput type="text" id="batchNumber" v-model="analysisRequest.batch_Number" />
          </CInputGroup>
        </CCol>       
      </CRow>
      <CRow class="mb-3">
        <CCol sm="12">
          <CFormLabel for="sampleNumber"><strong>{{ pretifyName('labReportFields.sample_Number') }}</strong></CFormLabel>
          <CFormInput type="text" id="sampleNumber" v-model="sampleNumber" />   
        </CCol>
      </CRow>      
      <CRow>
        <CCol sm="12">
          <CFormLabel for="reasonOfAnalysis"><strong>{{ pretifyName('labReportFields.reason_Of_Analysis') }}</strong></CFormLabel>
          <CFormTextarea id="reasonOfAnalysis" rows="5" v-model="analysisRequest.reason_Of_Analysis" />   
        </CCol>
      </CRow>      
      <CRow v-if="labReportType == 0">
        <CCol sm="6">
          <CFormLabel for="smell"><strong>{{ pretifyName('labReportFields.smell') }}</strong></CFormLabel>
          <CFormTextarea id="smell" rows="5" v-model="analysisRequest.smell" />   
        </CCol>
        <CCol>
          <CFormLabel for="cleannessOfWorkstation"><strong>{{ pretifyName('labReportFields.cleanness_Of_Workstation') }}</strong></CFormLabel>
          <CFormTextarea id="cleannessOfWorkstation" rows="5" v-model="analysisRequest.cleannes_Of_Workstation" />   
        </CCol>       
      </CRow>  
      <CRow v-if="labReportType == 0">
        <CCol sm="6">
          <CFormCheck type="checkbox" id="floatingOilPhase" :label="pretifyName('labReportFields.floating_Oil_Phase')" v-model="analysisRequest.floating_Oil_Phase" />
        </CCol>      
      </CRow>          
      <br>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <CButton color="secondary" @click="closeLabReportCreation()">
          {{ $t('close') }}
        </CButton>
        <CButton color="dark" v-if="canPerformLabReport"
          @click="createLabReport(samplingDate)">
          {{ $t('create') }} {{ labReportTitle }}
        </CButton>
        <CButton color="info" v-if="canForwardRequests"
          @click="forwardRequest()">
          {{ $t('forwardRequestBtn') }}
        </CButton> 
        <CButton color="warning"
          @click="() => { replyMessageVisible = true }">
          {{ $t('replyAndClose') }}
        </CButton>               
      </div>                     
    </CCardBody>

    <CModal alignment="center" :visible="replyMessageVisible" @close="resetReplyMessage()">
      <CModalHeader>
        <CModalTitle>{{ $t('message') }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CFormTextarea id="replyMessage" rows="5" v-model="replyMessage" /><br>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="resetReplyMessage()">
          {{ $t('close') }}
        </CButton>
        <CButton color="dark" v-if="!viewAllItems"
          @click="replyAndClose()">
          {{ $t('replyAndClose') }}
        </CButton>        
      </CModalFooter>
    </CModal>     
  </CCard>
</template>

<script>
import LabReportService from '../services/lab-reports'
import DateFormatter from '../helpers/dateFormatter'
import StringFormatter from '../helpers/stringFormatter'
import DistributorService from '../services/distributors'
import PermissionManager from '../helpers/permissionManager'
import AnalysisRequestService from '../services/analysis-requests'

export default {
  name: 'NewLabReport',
  props: {
    selectedMachine: Object,
    orderDate: Date,
    requestDetails: Object,
    labReportType: Number
  },
  data() {
    return {
      analysisRequest: '',
      dataLoaded: false,
      canPerformLabReport: false,
      forwardToCompanyID: 0,
      replyMessage: '',
      replyMessageVisible: false,
      samplingDate: new Date().toISOString().substring(0, 10),
      sampleNumber: ''
    }
  },
  computed: {
    labReportTitle() {
      if (!this.labReportType) {
        return this.$t('newLabReport')
      } else {
        return (this.labReportType == 0) ? this.$t('newLabReport') : this.$t('newWaterAnalysis')
      }
    },
    emptyAnalysis() {
      let emptyObj = {
        'batch_Number': '',
        'reason_Of_Analysis': '',
        'smell': '',
        'cleannes_Of_Workstation': '',
        'floating_Oil_Phase': false        
      }
      return emptyObj
    },
    isDataLoaded() {
      if (!this.dataLoaded) {
        this.getAnalysisRequest()
        this.getLabReportCreationRights()
      }
      return this.dataLoaded
    },
    resizeRequired() {
      let mobile = localStorage.getItem('isMobile')
      return (!(mobile === 'true'))
    },
    canForwardRequests() {
      return ((this.forwardToCompanyID) && (this.forwardToCompanyID != 0))
    }
  },
  methods: {
    closeLabReportCreation() {
      this.$emit('closeLabReportCreation')
    },
    createLabReport(samplingDate) {
      const userData = JSON.parse(localStorage.getItem('user'))
      let queryParams = {
        idMachine: this.selectedMachine.id,
        type: this.labReportType,
        idCustomer: this.selectedMachine.idCustomer,
        idProduct: this.selectedMachine.idProduct,
        status: 1,
        date_Ordered: DateFormatter.formatDateNumbers(this.orderDate),
        date_Of_Analysis: samplingDate,
        reason_Of_Analysis: this.analysisRequest.reason_Of_Analysis,
        measured_By: userData.user.id ? userData.user.id : '',
        measuredBy_Name: userData.user.first_Name ? userData.user.first_Name + ' ' + userData.user.last_Name : '',
        sample_Number: this.sampleNumber
      }
      if (this.labReportType == 0) {
        queryParams.smell = this.analysisRequest.smell,
        queryParams.batch_Number = this.analysisRequest.batch_Number ? 'ST' + this.analysisRequest.batch_Number : this.analysisRequest.batch_Number,
        queryParams.cleannes_Of_Workstation = this.analysisRequest.cleannes_Of_Workstation,
        queryParams.floating_Oil_Phase = this.analysisRequest.floating_Oil_Phase
      }
      LabReportService.createLabReport(queryParams).then(
        (response) => {
          this.$emit('closeLabReportCreation', response.data.id)
        },
        (error) => {
          let message = (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          this.$emit('sendError', message)
        }
      )
    },
    pretifyName(name) {
      return StringFormatter.pretifyName(this.$t(name))
    },
    getAnalysisRequest() {
      this.analysisRequest = this.requestDetails ? this.requestDetails : this.emptyAnalysis
      this.dataLoaded = true
    },
    getLabReportCreationRights() {
      const currDistributor = PermissionManager.getDistributorFilteredVisibility()
      this.canPerformLabReport = false
      this.canForwardRequests = false
      if ((PermissionManager.hasTechnicianPermissions()) && (currDistributor.idDistributor)) {    
        DistributorService.getSingleDistributor(currDistributor.idDistributor).then(
          (response) => {
            this.canPerformLabReport = response.data.handle_Reports
            this.forwardToCompanyID = response.data.forward_To_Company
          }
        )
      }
      if ((this.analysisRequest.id) && (this.analysisRequest.id != 0)) {
        AnalysisRequestService.getSingleAnalysisRequest(this.analysisRequest.id).then(
          (response) => {
            this.canPerformLabReport = (this.canPerformLabReport && !((response.data.idMeasurement) && (response.data.idMeasurement != 0)))
          }
        )
      }      
    },
    forwardRequest() {
      if ((this.analysisRequest.id) && (this.analysisRequest.id != 0)) {
        AnalysisRequestService.getSingleAnalysisRequest(this.analysisRequest.id).then(
          (response) => {
            const queryParams = {
              'forwarded_By': response.data.idDistributor,
              'idDistributor': this.forwardToCompanyID,
              'forward_Status': 0,
              'active_Forward_Process': true,
              'notify_User': false,
              'start_Forward_Process': true
            }
            AnalysisRequestService.updateAnalysisRequest(this.analysisRequest.id, queryParams).then(
              () => {
                this.closeLabReportCreation()
              },
              (error) => {
                let message = (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                  error.message ||
                  error.toString()
                this.$emit('sendError', message)                
              }
            )
          },
          (error) => {
            let message = (error.response &&
              error.response.data &&
              error.response.data.message) ||
              error.message ||
              error.toString()
            this.$emit('sendError', message)
          }
        )          
      } else {
        let message = this.$t('emptyForwardingRequestException')
        this.$emit('sendError', message)        
      }
    },
    resetReplyMessage() {
      this.replyMessage = ''
      this.replyMessageVisible = false
    },
    replyAndClose() {
      if ((this.analysisRequest.id) && (this.analysisRequest.id != 0) && (this.replyMessage)) {
        AnalysisRequestService.getSingleAnalysisRequest(this.analysisRequest.id).then(
          (response) => {
            let newAnalysisRequest = response.data
            const currDistributor = PermissionManager.getDistributorFilteredVisibility()
            this.isForwardedRequest = ((newAnalysisRequest.active_Forward_Process) &&
                                      (newAnalysisRequest.idDistributor == currDistributor.idDistributor)) 
            const userData = JSON.parse(localStorage.getItem('user'))
            const queryParams = {
              'request_Handled_By': userData.user.id,
              'notify_User': true,
              'notification_Message': this.replyMessage,
              'comment': this.replyMessage,
              'idProduct': newAnalysisRequest.idProduct,
              'idMachine': newAnalysisRequest.idMachine,
              'request_Type': newAnalysisRequest.request_Type,
              'request_Status': newAnalysisRequest.request_Status
            }
            // Manage forwarded requests
            if (this.isForwardedRequest) {
              queryParams['forward_Status'] = 2
              queryParams['active_Forward_Process'] = true
              queryParams['forwarded_By'] = newAnalysisRequest.forwarded_By
            } else {
              queryParams['request_Status'] = 2
              queryParams['requested_By_User'] = newAnalysisRequest.requested_By_User
            }                                                 

            AnalysisRequestService.updateAnalysisRequest(newAnalysisRequest.id, queryParams).then(
              () => {
                this.closeLabReportCreation()
              },
              (error) => {
                let message = (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                  error.message ||
                  error.toString()
                this.$emit('sendError', message)                
              }
            )
          },
          (error) => {
            let message = (error.response &&
              error.response.data &&
              error.response.data.message) ||
              error.message ||
              error.toString()
            this.$emit('sendError', message)
          }
        )          
      } else {
        let message = this.$t('emptyRequestReplyException')
        this.$emit('sendError', message)        
      }      
    }             
  }
}
</script>