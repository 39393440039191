<template>
  <CContainer fluid v-if="isDataLoaded">
    <CRow>
      <CCol :sm="6">
        <CCard>
          <CCardBody>
            <CCardTitle><strong>{{ $t('assignedFiltrations') }}</strong></CCardTitle>
            <CCardText>{{ $t('assignedFiltrationsTitle') }}
                {{ $t('assignedFiltrationsSubtitle') }}</CCardText>
            <CRow v-for="assFiltration in assignedFiltrations" :key="assFiltration.id" class="mb-3">
              <CCol>
                <CInputGroup class="mb-3">
                  <CFormInput type="text" id="assFiltrationName" readonly
                    :value="getTranslatedName(assFiltration.filtration_Name)" />
                  <CButton type="button" color="dark" variant="outline" id="showComment"
                    @click="toggleCommentVisibility(assFiltration)">
                    {{ commentText }}
                    <CIcon icon="cil-comment-square" size="sm" v-if="isMobile"/>
                  </CButton>
                  <CButton type="button" color="danger" id="removeItem"  
                    @click="removeAssignedFiltration(assFiltration.id, assFiltration.idMachine)">
                    <CIcon icon="cil-trash" size="lg" />
                  </CButton>                  
                </CInputGroup>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol :sm="6">
        <CCard>
          <CCardBody>
            <CCardTitle><strong>{{ $t('availableFiltrations') }}</strong></CCardTitle>
            <CCardText>{{ $t('availableFiltrationsDesc') }}</CCardText>
            <div class="d-grid gap-2 col-6 mx-auto">
              <CButton v-for="filtration in availableFiltrations" :key="filtration.id" 
                color="secondary" variant="outline" shape="rounded-0"
                @click="assignFiltration(filtration.id)">{{ getTranslatedName(filtration.name) }}</CButton>
            </div>
          </CCardBody>
        </CCard>
      </CCol>      
    </CRow>

    <CModal alignment="center" :visible="commentVisible" @close="() => { commentVisible = false }">
      <CModalHeader>
        <CModalTitle>{{ $t('comment') }} - {{ getTranslatedName(currFiltration.filtration_Name) }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CFormTextarea id="assFiltrationComment" v-model="assFiltrationComment" rows="5" :value="currFiltration.comment" />
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="() => { commentVisible = false }">
          {{ $t('close') }}
        </CButton>
        <CButton color="primary" @click="updateAssignedFiltration(currFiltration.id, currFiltration.idMachine, assFiltrationComment)">{{ $t('saveChanges') }}</CButton>
      </CModalFooter>
    </CModal>

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert>     
  </CContainer> 
</template>

<script>
import AssignedFiltrationsService from '../services/machine-assigned-filtrations'
import FiltrationsService from '../services/filtrations'

export default {
  name: 'FiltrationComponent',
  data() {
    return {
      assignedFiltrations: '',
      availableFiltrations: '',
      allFiltrations: '',
      assignedFiltLoaded: false,
      allFiltrLoaded: false,
      dataLoaded: false,
      commentVisible: false,
      currFiltration: '',
      message: ''
    }
  },
  created() {
    this.refreshComponent()
  },
  computed: {
    isDataLoaded() {
      if ((this.assignedFiltLoaded) && (this.allFiltrLoaded)) {
        this.getAvailableFiltrations()
      }
      return this.dataLoaded
    },
    isMobile() {
      let mobile = localStorage.getItem('isMobile')
      return (mobile === 'true')
    },    
    commentText() {
      if (this.isMobile) {
        return ''
      } else {
        return this.$t('showComment')
      }
    }
  },
  methods: {
    refreshComponent() {
      this.message = ''
      this.dataLoaded = false
      this.assignedFiltLoaded = false
      this.allFiltrLoaded = false
      this.getAssignedFiltrations()
      this.getAllFiltrations()
    },
    getAssignedFiltrations() {
      if (this.$route.params.idMachine) {
        AssignedFiltrationsService.getFiltrations({ 'idMachine': this.$route.params.idMachine }).then(
          (response) => {
            this.assignedFiltrations = response.data.data
            this.assignedFiltLoaded = true
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validMachineIDException')
      }  
    },
    getAllFiltrations() {
      FiltrationsService.getFiltrations().then(
        (response) => {
          this.allFiltrations = response.data.data
          this.allFiltrLoaded = true
        },
        (error)  => {
          this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
        }
      )
    },    
    getAvailableFiltrations() {
      let calcFiltrations = []
      this.allFiltrations.forEach(filtration => {
        if (!this.assignedFiltrations.find(assFilt => assFilt.id == filtration.id)) {
          calcFiltrations.push(filtration)
        }
      })
      this.availableFiltrations = calcFiltrations
      this.dataLoaded = true
    },
    removeAssignedFiltration(filtrationID, machineID) {
      if ((machineID != 0) && (filtrationID != 0)) {
        AssignedFiltrationsService.deleteFiltration({ 'id': filtrationID, 'idMachine': machineID }).then(
          () => {
            this.refreshComponent()
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validMachineAndFiltrationException')
      }
    },
    assignFiltration(filtrationID) {
      if ((this.$route.params.idMachine) && (filtrationID != 0)) {
        AssignedFiltrationsService.assignFiltration({ 'id': filtrationID, 'idMachine': this.$route.params.idMachine }).then(
          () => {
            this.refreshComponent()
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validMachineAndFiltrationException')
      }    
    },
    updateAssignedFiltration(filtrationID, machineID, comment) {
      if ((machineID != 0) && (filtrationID != 0)) {
        AssignedFiltrationsService.updateFiltration({ 'id': filtrationID, 'idMachine': machineID }, { 'comment': comment }).then(
          () => {
            this.commentVisible = false
            this.refreshComponent()
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validMachineAndFiltrationException')
      }        
    },
    toggleCommentVisibility(filtration) {
      if (this.commentVisible) {
        this.currFiltration = ''
      } else {
        this.currFiltration = filtration
      }
      this.commentVisible = !this.commentVisible
    },
    getTranslatedName(filtration) {
      let result = ''
      if (filtration) {
        result = this.$t('filtrations.' + filtration)
      }
      return result
    }    
  }
}
</script>