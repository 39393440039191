<template>
  <CContainer fluid>
    <div class="row">
      <div class="col-12 col-8-tablet push-2-tablet text-center">
        <CIcon size="xxl" icon="cil-user-plus"/>
        <h3 class="title">
          {{ $t('customerAgentAssignment') }}
        </h3>
      </div>
    </div>

    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
      <CButton type="button" color="dark" shape="rounded-0" id="confirmAssignments" @click="updateAssignments()">{{ $t('updateAssignments') }}</CButton>
      <CButton type="button" color="danger" shape="rounded-0" id="removeUnassigned" @click="removeSelectedCustomersFromAssigned()">{{ $t('removeAssigned') }}</CButton>
    </div><br>

    <CRow v-if="dataLoaded">
      <CCol>
        <CCard>
          <CCardBody>
            <CCardTitle>{{ $t('unassignedCustomers') }}</CCardTitle>
            <CCardText>{{ $t('unassignedCustomersDesc') }}</CCardText>
            <CRow class="mb-3">      
              <CCol sm="1">
                <CIcon v-if="unassignStatus" icon="cil-ban" size="xxl" @click="toggleUnassignStatus()"/>
                <CIcon v-if="!unassignStatus" icon="cil-check-alt" size="xxl" @click="toggleUnassignStatus()"/>
              </CCol>
              <CCol sm="11"> 
                <CFormInput
                  type="text"
                  id="assignedFilter"
                  :placeholder="$t('filterPlaceholder')"
                  v-model="unassignedFilterState" />             
              </CCol>
            </CRow>
            <draggable
              :key="paginatedUnassignedItems.name"
              :list="paginatedUnassignedItems"
              :id="key"
              group="unassignedCustomers"
              animation="150"
              :multi-drag="true"
              selected-class="multi-drag"
              ghost-class="ghost"
              @end="moveSelectedCustomersFromUnassigned()"
              :force-fallback="true"
            >

              <template #item="{ element }">
                <CListGroupItem class="draggable-element">
                  <CRow>
                    <CCol sm="1">
                      <CFormCheck v-model="element.checked"/>
                    </CCol>
                    <CCol sm="11">
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">{{ element.client_No }} - {{ element.name }}</h5>
                      </div>
                      <p class="mb-1">{{ element.address }}</p>
                    </CCol>
                  </CRow>
                </CListGroupItem>              
              </template>            
            </draggable><br>
            <SmartPagination
              v-show="totalUnassignedPages > 1"
              :activePage="unassignedActivePage"
              :pages="totalUnassignedPages"
              @update:activePage="unassignedActivePage = $event"
            />              
          </CCardBody>
        </CCard>
      </CCol>
      <CCol sm="3">
        <CCard>
          <CCardBody>
            <CCardTitle>{{ $t('agents') }}</CCardTitle>
            <CCardText>{{ $t('agentsDesc') }}</CCardText> 
            <CListGroup>
              <CListGroupItem v-for="agent in agents" :key="agent.id" component="button" @click="getSelectedAgent(agent.id)">
                <div class="d-flex w-100 justify-content-between" @mouseover="buttonEntering(agent.id)" @mouseleave="buttonLeaving()">
                  <h5 class="mb-1">{{ agent.first_Name }} {{ agent.last_Name }}</h5>
                </div>
                <p class="mb-1">{{ agent.email }}</p>
              </CListGroupItem>
            </CListGroup>                       
          </CCardBody>
        </CCard>
      </CCol>
      <CCol>
        <CCard>
          <CCardBody>
            <CCardTitle>{{ $t('assignedCustomers') }}</CCardTitle>
            <CCardText>{{ $t('assignedCustomersDesc') }}</CCardText>  
            <CRow class="mb-3">      
              <CCol sm="1">
                <CIcon v-if="assignStatus" icon="cil-ban" size="xxl" @click="toggleAssignStatus()"/>
                <CIcon v-if="!assignStatus" icon="cil-check-alt" size="xxl" @click="toggleAssignStatus()"/>
              </CCol>
              <CCol sm="11"> 
                <CFormInput
                  type="text"
                  id="assignedFilter"
                  :placeholder="$t('filterPlaceholder')"
                  v-model="assignedFilterState" />             
              </CCol>
            </CRow>            
            <draggable
              :key="paginatedAssignedItems.name"
              :list="paginatedAssignedItems"
              :id="key"
              group="assignedCustomers"
              animation="150"
              :multi-drag="true"
              selected-class="multi-drag"
              ghost-class="ghost"
              @end="moveSelectedCustomersFromAssigned()"
              :force-fallback="true"
            >

              <template #item="{ element }">
                <CListGroupItem class="draggable-element">
                  <CRow>
                    <CCol sm="1">
                      <CFormCheck v-model="element.checked"/>
                    </CCol>
                    <CCol sm="11">
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">{{ element.client_No }} - {{ element.name }}</h5>
                      </div>
                      <p class="mb-1">{{ element.address }}</p>
                    </CCol>
                  </CRow>
                </CListGroupItem>              
              </template>
            </draggable><br>
            <SmartPagination
              v-show="totalAssignedPages > 1"
              :activePage="assignedActivePage"
              :pages="totalAssignedPages"
              @update:activePage="assignedActivePage = $event"
            />                                           
          </CCardBody>
        </CCard>  
      </CCol>
    </CRow>          

    <CToaster placement="top-end">
      <CToast color="dark" class="text-white" v-for="(toast, index) in toasts" :key="index">
        <CToastBody>
          {{ toast.content }}
        </CToastBody>  
      </CToast>
    </CToaster>

    <confirm-action
      :cModalVisible="popupConfirmMessage"
      :title="confirmTitle"
      :message="confirmMessage"
      :yesButton="$t('confirmButton')"
      :noButton="$t('cancelButton')"
      @confirm="confirmAction()" 
      @cancel="cancelAction()" />      

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert>     
  </CContainer>
</template>

<script>
import UserService from '../services/users'
import CustomerService from '../services/customers'
import CustomerLightService from '../services/customers-light'
import ConfirmAction from '../components/ConfirmAction.vue'
import StringFormatter from '../helpers/stringFormatter'
import Draggable from 'vuedraggable'
import SmartPagination from '../components/imported/pagination/SmartPagination'

export default {
  name: 'CustomerAgentAssignment',
  data() {
    return {
      agentsRetrieved: false,
      customersRetrieved: false,
      dataLoaded: false,
      message: '',
      agents: '',
      customers: '',
      assignedCustomers: [],
      unassignedCustomers: [],
      selectedAgentID: 0,
      hoverAgentID: 0,
      toasts: [],
      popupConfirmMessage: false,
      confirmTitle: '',
      confirmMessage: '',
      customerColumns: [        
        'customerID',
        'name',
        'address',
        'client_No',    
      ],
      dragging: false,
      modifiedAssignment: [],
      unassignedActivePage: 1,
      assignedActivePage: 1,
      itemsPerPage: 10,
      unassignedFilterState: '',
      assignedFilterState: '',
      unassignStatus: false,
      assignStatus: false
    }
  },
  created() {
    this.initData()
  },
  computed: {
    filteredUnassignedCustomers() {
      let processedData = this.unassignedCustomers
      if (this.unassignedFilterState) {
        const filter = this.unassignedFilterState.toLowerCase()
        const hasFilter = (item) => String(item).toLowerCase().includes(filter)
        processedData = processedData.filter(item => {
          return this.customerColumns.filter(key => hasFilter(item[key])).length
        })          
      }
      return processedData
    },    
    filteredAssignedCustomers() {
      let processedData = []
      if (this.assignedCustomers.length > 0) {
        this.assignedCustomers.forEach(customer => {
          if (customer.idAgent == this.selectedAgentID) {
            let newCustomer = customer
            newCustomer.checked = false
            processedData.push(newCustomer)
          }
        })
        if (this.assignedFilterState) {
          const filter = this.assignedFilterState.toLowerCase()
          const hasFilter = (item) => String(item).toLowerCase().includes(filter)
          processedData = processedData.filter(item => {
            return this.customerColumns.filter(key => hasFilter(item[key])).length
          })          
        }
      }
      return processedData
    },
    totalUnassignedPages() {
      let noOfPages = 1
      if (this.unassignedCustomers.length > 0) {
        noOfPages += Math.floor(this.unassignedCustomers.length / this.itemsPerPage)
      }
      return noOfPages
    },
    totalAssignedPages() {
      let noOfPages = 1
      let noOfFilteredAssignedCustomers = this.filteredAssignedCustomers.length
      if (noOfFilteredAssignedCustomers > 0) {
        noOfPages += Math.floor(noOfFilteredAssignedCustomers / this.itemsPerPage)
      }
      return noOfPages
    },    
    paginatedUnassignedItems() {
      let firstItemIndex = (this.unassignedActivePage - 1) * this.itemsPerPage
      return this.filteredUnassignedCustomers.slice(
        firstItemIndex,
        firstItemIndex + this.itemsPerPage
      )
    },
    paginatedAssignedItems() {
      let firstItemIndex = (this.assignedActivePage - 1) * this.itemsPerPage
      return this.filteredAssignedCustomers.slice(
        firstItemIndex,
        firstItemIndex + this.itemsPerPage
      )
    }       
  },
  methods: {
    initData() {
      this.getAgents()
      this.getCustomers()
    },
    getAgents() {
      let queryParams = {
        'role': 1,
        '$limit': 5000
      }
      UserService.getUsers(queryParams).then(
        (response) => {
          this.agents = response.data.data
          this.agentsRetrieved = true
          this.dataLoaded = this.customersRetrieved && this.agentsRetrieved
        },
        (error)  => {
          this.message =           
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }
      )  
    },
    getCustomers() {
      CustomerLightService.getCustomers({ $limit: 5000 }).then(
        (response) => {
          this.customers = response.data.data
          this.splitCustomers()
          this.customersRetrieved = true
          this.dataLoaded = this.customersRetrieved && this.agentsRetrieved
        },
        (error)  => {
          this.message =           
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }          
      )
    },
    splitCustomers() {
      this.assignedCustomers = []
      this.unassignedCustomers = []
      if (this.customers) {
        this.customers.forEach(customer => {
          // Create Customer Entity:
          let compactCustomer = {
            'customerID': customer.id,
            'name': customer.company,
            'address': customer.zipCode + ' ' + customer.address + ', ' + customer.city + ' (' + customer.country_Name + ')',
            'client_No': customer.client_No,
            'checked': false
          }
          if ((customer.idAgent) && (customer.idAgent != 0)) {
            compactCustomer.idAgent = customer.idAgent
            this.assignedCustomers.push(compactCustomer)
          } else {
            this.unassignedCustomers.push(compactCustomer)
          }
        })
      }
    },
    confirmAction() {
      this.resetConfirmMessage()
      this.updateCustomers()
    },
    cancelAction() {
      this.resetConfirmMessage()
      //this.closeAnalysisRequest(this.refRequestAnalysis, false)
    },
    resetConfirmMessage() {
      this.confirmTitle = ''
      this.confirmMessage = '',
      this.popupConfirmMessage = false
    },     
    createToast(content) {
      this.toasts.push({
        content: content
      })
    },    
    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time))
    },
    pretifyName(name) {
      return StringFormatter.pretifyName(this.$t(name))
    },
    updateAssignments() {
      let modifiedCustString = this.$t('customerAgentAssigmentWarning') + "\n"
      if (this.modifiedAssignment.length > 0) {
        this.modifiedAssignment.forEach(cust => {
          const agentIndex = this.agents.findIndex(agent => agent.id == cust.idAgent)
          let agentName = (agentIndex >= 0 ? this.agents[agentIndex].first_Name + ' ' + this.agents[agentIndex].last_Name : this.$t('unassigned'))
          modifiedCustString += cust.id + ' - ' + cust.name + ' --> ' + agentName + "\r"
        })
      }
      this.confirmTitle = this.$t('continueTitle')
      this.confirmMessage = modifiedCustString
      this.popupConfirmMessage = true
    },
    updateCustomers() {
      if (this.modifiedAssignment.length > 0) {
        let counter = 0
        this.modifiedAssignment.forEach(cust => {
          CustomerService.updateCustomer(cust.id, { 'idAgent': cust.idAgent }).then(
            () => {
              counter += 1
              if (counter == this.modifiedAssignment.length) {
                this.createToast(this.$t('dataSuccesfullyUpdated') + ' ' + this.$t('redirectingToHome'))
                this.modifiedAssignment = []
                this.delay(2000).then(
                  () => this.$router.push('/'))
              }
            },
            (error)  => {
              this.message =           
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()
              return
            }            
          )
        })
      }
    },
    getSelectedAgent(agentID) {
      this.selectedAgentID = agentID
    },
    buttonEntering(agentID) {
      this.hoverAgentID = agentID
    },
    buttonLeaving() {
      this.hoverAgentID = 0
    },
    moveSelectedCustomersFromUnassigned() {
      if (this.hoverAgentID != 0) {
        const selectedCustomers = this.unassignedCustomers.filter(customer => customer.checked)
        if (selectedCustomers) {
          selectedCustomers.forEach(custToMove => {
            let newCustomer = custToMove
            newCustomer.idAgent = this.hoverAgentID
            newCustomer.checked = false
            this.storeModifications(newCustomer)
            this.assignedCustomers.push(newCustomer)
            const unassignedIndex = this.unassignedCustomers.findIndex(unassignedCust => unassignedCust.customerID == custToMove.customerID )
            if (unassignedIndex >= 0) {
              this.unassignedCustomers.splice(unassignedIndex, 1)
            }
          })
        }
      }
    }, 
    moveSelectedCustomersFromAssigned() {
      if (this.hoverAgentID != 0) {
        const selectedCustomers = this.assignedCustomers.filter(customer => { ((customer.checked) && (customer.idAgent != this.hoverAgentID)) })
        if (selectedCustomers) {
          selectedCustomers.forEach(custToMove => {
            let currCustomerIndex = this.assignedCustomers.findIndex(assCust => assCust.customerID == custToMove.customerID)
            if (currCustomerIndex >= 0) {
              this.assignedCustomers[currCustomerIndex].idAgent = this.hoverAgentID
              this.assignedCustomers[currCustomerIndex].checked = false
              this.storeModifications(this.assignedCustomers[currCustomerIndex])
            }
          })
        }
      }
    },
    removeSelectedCustomersFromAssigned() {
      const selectedCustomers = this.assignedCustomers.filter(customer => customer.checked)
      if (selectedCustomers) {
        selectedCustomers.forEach(custToRemove => {
          let newCustomer = custToRemove
          newCustomer.checked = false
          delete newCustomer.idAgent
          this.storeModifications(newCustomer)
          this.unassignedCustomers.push(newCustomer)
          const assignedIndex = this.assignedCustomers.findIndex(assignedCust => assignedCust.customerID == custToRemove.customerID )
          if (assignedIndex >= 0) {
            this.assignedCustomers.splice(assignedIndex, 1)
          }
        })
      }
    },
    storeModifications(updatedCustomer) {
      if (updatedCustomer.customerID) {
        const currIndex = this.modifiedAssignment.findIndex(cust => cust.id == updatedCustomer.customerID)
        if (currIndex >= 0) {
          this.modifiedAssignment[currIndex].idAgent = (updatedCustomer.idAgent ? updatedCustomer.idAgent : 0)
        } else {
          // Create new customer
          let newCustomerAss = {
            'id': updatedCustomer.customerID,
            'name': updatedCustomer.name
          }
          newCustomerAss.idAgent = (updatedCustomer.idAgent ? updatedCustomer.idAgent : 0)
          this.modifiedAssignment.push(newCustomerAss)
        }
      }
    },
    toggleUnassignStatus() {
      this.unassignStatus = !this.unassignStatus
      this.updateUnassignedDataSelection()
    },
    toggleAssignStatus() {
      this.assignStatus = !this.assignStatus
      this.updateAssignedDataSelection()
    },
    updateUnassignedDataSelection() {
      let processedData = this.paginatedUnassignedItems
      if (processedData.length > 0) {
        processedData.forEach(item => {
          const customerIndex = this.unassignedCustomers.findIndex(assCust => assCust.customerID == item.customerID)
          if (customerIndex >= 0) {
            this.unassignedCustomers[customerIndex].checked = this.unassignStatus
          }
        })
      }
    },
    updateAssignedDataSelection() {
      let processedData = this.paginatedAssignedItems
      if (processedData.length > 0) {
        processedData.forEach(item => {
          const customerIndex = this.assignedCustomers.findIndex(assCust => assCust.customerID == item.customerID)
          if (customerIndex >= 0) {
            this.assignedCustomers[customerIndex].checked = this.assignStatus
          }
        })
      }
    }                      
  },
  components: {
    ConfirmAction,
    Draggable,
    SmartPagination
  }
}
</script>