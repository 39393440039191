<template>
  <CModal backdrop="static" size="xl" :visible="cModalVisible" @show="reloadData()" @close="closeProductCard()">
    <CModalHeader>
      <CModalTitle>{{ confirmationButtonText}} {{ $t('product') }}</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <CNav variant="tabs" role="tablist">
        <CNavItem>
          <CNavLink
            href="javascript:void(0);"
            :active="tabPaneActiveKey === 1"
            @click="() => {tabPaneActiveKey = 1}"
          >
            {{ $t('general') }}
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink
            href="javascript:void(0);"
            :active="tabPaneActiveKey === 2"
            @click="() => {tabPaneActiveKey = 2}"
          >
            {{ $t('specifications') }}
          </CNavLink>
        </CNavItem>
      </CNav>      
      <CTabContent>
        <CTabPane role="tabpanel" aria-labelledby="home-tab" :visible="tabPaneActiveKey === 1">
          <br>   
          <CRow class="mb-3">
            <CFormLabel for="productName" :class="labelColumnsLength">{{ pretifyName('productFields.name') }}</CFormLabel>
            <CCol :sm="fieldColumnsLength">
              <CFormInput type="text" id="productName" v-model="currProduct.product" />
            </CCol>
          </CRow>
          <CRow class="mb-3">
            <CFormLabel for="fluidSupplier" :class="labelColumnsLength">{{ pretifyName('productFields.fluid_Supplier') }}</CFormLabel>
            <CCol :sm="fieldColumnsLength">
              <CInputGroup class="mb-3">
                <CFormInput type="text" id="fluidSupplier" v-model="fluidSupplier" readonly :value="currDistributor"/>
                <CButton type="button" color="dark" variant="outline" id="searchDistributor" :disabled="checkUserPermissions"
                  @click="distLookupVisible = !distLookupVisible">{{ $t('search') }}</CButton>
              </CInputGroup>
            </CCol>
          </CRow> 
          <CRow class="mb-3" v-if="productTechnologiesLoaded">
            <CFormLabel for="technology" :class="labelColumnsLength">{{ pretifyName('productFields.technology') }}</CFormLabel>
            <CCol :sm="fieldColumnsLength">
              <CFormSelect class="mb-3" aria-label="technology select" id="technology" v-model="currProduct.technology" >
                <optgroup v-for="(technologyGroup, index) in productTechnologies" :key="index"
                  :label="technologyGroup.group_Name"> 
                  <option v-for="(technologySpec, index2) in technologyGroup.items" :key="index2"
                    :value="technologySpec.id">{{ technologySpec.value }}</option>
                </optgroup>
              </CFormSelect>
            </CCol>
          </CRow> 
          <CRow class="mb-3">
            <CFormLabel for="status" :class="labelColumnsLength">{{ pretifyName('productFields.status') }}</CFormLabel>
            <CCol :sm="fieldColumnsLength">
              <CFormSelect class="mb-3" aria-label="status select" id="status" v-model="currProduct.status_int" >
                <option value="0">{{ $t('statusOptions.active') }}</option>
                <option value="1">{{ $t('statusOptions.inactive') }}</option>
                <option value="2">{{ $t('statusOptions.under_Mantainance') }}</option>
              </CFormSelect>
            </CCol>
          </CRow>
          <CRow class="mb-3">
            <CFormLabel for="productNumber" :class="labelColumnsLength">{{ pretifyName('productFields.product_Number') }}</CFormLabel>
            <CCol :sm="fieldColumnsLength">
              <CFormInput type="text" id="productNumber" v-model="currProduct.product_Number" />
            </CCol>
          </CRow>                       
        </CTabPane>
        <CTabPane role="tabpanel" aria-labelledby="profile-tab" :visible="tabPaneActiveKey === 2">
          <br> 
          <CRow class="mb-3">
            <CFormLabel for="refracFactor" :class="labelColumnsLength">{{ pretifyName('productFields.refractometer_Factor') }}</CFormLabel>
            <CCol :sm="fieldColumnsLength">
              <CFormInput type="number" id="refracFactor" v-model="currProduct.refactormeter_Factor" />
            </CCol>
          </CRow>   
          <CRow class="mb-3">
            <CFormLabel for="criticalPh" :class="labelColumnsLength">{{ pretifyName('productFields.critical_pH') }}</CFormLabel>
            <CCol :sm="fieldColumnsLength">
              <CFormInput type="number" id="criticalPh" v-model="currProduct.critical_pH" />
            </CCol>
          </CRow>           
        </CTabPane>
      </CTabContent>                                 
    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="closeProductCard()">
        {{ $t('close') }}
      </CButton>
      <CButton color="primary"
        :disabled="checkUserPermissions"
        @click="performConfirmationAction()">
        {{ confirmationButtonText }}
      </CButton>
    </CModalFooter>

    <CModal size="xl" alignment="center" :visible="distLookupVisible" @close="() => { distLookupVisible = false }">
      <CModalHeader>
        <CModalTitle>{{ $t('distributors') }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <distributors-lookup
          @itemSelected="getDistributorName($event)" />
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="() => { distLookupVisible = false }">
          {{ $t('close') }}
        </CButton>
      </CModalFooter>
    </CModal> 

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </CModal>
</template>

<script>
import DistributorsLookup from '../components/DistributorsLookup.vue'
import DistributorService from '../services/distributors'
import ProductService from '../services/products'
import { ProductStatus } from '../helpers/getEnumValues'
import ProductTechnologiesService from '../services/product-technologies'
import StringFormatter from '../helpers/stringFormatter'
import PermissionManager from '../helpers/permissionManager'

export default {
  name: 'ProductCard',
  props: {
    cModalVisible: Boolean,
    operationType: Number,
    productID: Number
  },
  data() {
    return {
      message: '',
      currProduct: '',
      dataLoaded: false,
      distLookupVisible: false,
      currDistributor: '',
      currDistributorID: 0,
      productTechnologies: [],
      productTechnologiesLoaded: false,
      tabPaneActiveKey: 1
    }
  },
  computed: {
    confirmationButtonText() {
      if (this.operationType == 0) {
        return this.$t('edit')
      } else {
        return this.$t('create')
      }
    },
    checkUserPermissions() {
      return (!(PermissionManager.hasTechnicianPermissions()) || (PermissionManager.getCustomerFilteredVisibility()))
    },
    labelColumnsLength() {
      if (screen.width <= 1100) {
        return 'col-sm-4 col-form-label'
      } else {
        return 'col-sm-2 col-form-label'
      }
    },
    fieldColumnsLength() {
      if (screen.width <= 1100) {
        return '8'
      } else {
        return '10'
      }      
    }    
  },  
  methods: {
    getProduct() {
      if (this.operationType == 0) {
        if ((this.productID == 0) || (!this.productID)) {
          this.message = this.$t('validProductIDException')
        } else {
          ProductService.getSingleProduct(this.productID).then(
            (response) => {
              this.currProduct = response.data
              this.currProduct.status_int = this.getProductStatus(this.currProduct.status)
              if (!(this.currProduct.status_int)) {
                this.currProduct.status_int = 0
              }              
              this.currDistributorID = this.currProduct.idFluid_Supplier
              this.currDistributor = this.currProduct.fluid_Supplier_Name
              this.dataLoaded = true
            },
            (error) => {
              this.message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString()
            }
          )
        }
      } else {
        this.currDistributorID = 0
        this.currDistributor = ''
        // Create an empty product
        this.currProduct = {
          'product': null,
          'idFluid_Supplier': null,
          'refactormeter_Factor': null,
          'critical_pH': null,
          'technology': 0,
          'status': null,
          'product_Number': null,
          'status_int': 0
        }
        this.dataLoaded = true
      }
    },      
    getProductStatus(statusString) {
      if ((statusString) && (statusString != '')) {
        return ProductStatus.indexOf(statusString)
      }
    },
    performConfirmationAction() {
      let productParams = {
        'product': this.currProduct.product,
        'idFluid_Supplier': this.currDistributorID,
        'refactormeter_Factor': this.currProduct.refactormeter_Factor,
        'critical_pH': this.currProduct.critical_pH,
        'technology': this.currProduct.technology,
        'status': this.currProduct.status_int,
        'product_Number': this.currProduct.product_Number
      }
      if (this.operationType == 0) {
        this.editProduct(productParams)
      } else {
        this.createProduct(productParams)
      }
    },
    createProduct(productParams) {
      ProductService.createProduct(productParams).then(
        () => {
          this.message = ''
          this.dataLoaded = false
          this.$emit('closeProductCardModal', true)
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        }
      )
    },
    editProduct(productParams) {
      ProductService.updateProduct(this.productID, productParams).then(
        () => {
          this.message = ''
          this.dataLoaded = false
          this.$emit('closeProductCardModal', true)
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        }
      )
    },
    getDistributorName(distributorID) {
      if (distributorID != 0) {
        DistributorService.getSingleDistributor(distributorID).then(
          (response) => {
            this.currDistributorID = distributorID
            this.currDistributor = response.data.company
            this.distLookupVisible = false
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validDistributorIDException')
      } 
    },
    closeProductCard() {
      this.message = ''
      this.dataLoaded = false
      this.$emit('closeProductCardModal', false)
    },
    reloadData() {
      this.dataLoaded = false
      this.productTechnologiesLoaded = false
      this.tabPaneActiveKey = 1
      this.getProduct()
      this.buildProductTechnologiesBlocks()
    },
    buildProductTechnologiesBlocks() {
      this.productTechnologies = []
      ProductTechnologiesService.getTechnologies().then(
        (response) => {
          let combinedTechnologies = response.data.data
          combinedTechnologies.sort((a, b) => a.sort_Index - b.sort_Index)
          let technologyGroup = []
          let technologyGroupName = ''
          combinedTechnologies.forEach(technology => {
            if (technology.line_Type == 0) {
              if (technologyGroup.length > 0) {
                this.productTechnologies.push({
                  'group_Name': technologyGroupName,
                  'items': technologyGroup
                })
                technologyGroup = []
                technologyGroupName = ''
              }
              technologyGroupName = technology.value
            } else {
              technologyGroup.push(technology)
            }
          })
          // Add Last Group
          if (technologyGroup.length > 0) {
            this.productTechnologies.push({
              'group_Name': technologyGroupName,
              'items': technologyGroup
            })            
          }
          this.productTechnologiesLoaded = true
        },
        (error)  => {
          this.message =           
          (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString()
        }        
      )
    },
    pretifyName(name) {
      return StringFormatter.pretifyName(this.$t(name))
    }      
  },
  components: {
    DistributorsLookup
  }
}
</script>