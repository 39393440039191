class ParameterFormatter {
  getParameterValueSelection(parameterID, valuesArrayObj) {
    valuesArrayObj.customValues = []
    switch (parameterID) {
      case 8:
      case 9:
      case 19:
      case 20:
        valuesArrayObj.customValues = ['< 10', '10', '10²', '10³', '10⁴', '10⁵', '10⁶', '10⁷']
        return true
      case 23:
        valuesArrayObj.customValues = ['KG 0/4', 'KG 1/4', 'KG 2/4', 'KG 3/4', 'KG 4/4']
        return true
      default:
        return false
    }
  }

  getParameterDecimalValue(parameterID, index) {
    switch(parameterID) {
      case 8:
      case 9:
      case 19:
      case 20:
        switch(index) {
          case 1:
            return 10
          case 2:
            return 100
          case 3:
            return 1000
          case 4:
            return 10000
          case 5:
            return 100000
          case 6:
            return 999999.99
          case 7:
            return 9999999.99
          default:
            return '0'
        }
      case 23:
        return index ? index : '0'
      default:
        return index
    }
  }

  getParameterOptionValueFromIndex(parameterID, decimalValue, ) {
    let stringValues = ['< 10', '10', '10²', '10³', '10⁴', '10⁵', '10⁶', '10⁷']
    switch(parameterID) {
      case 8:
      case 9:
      case 19:
      case 20:
        return (stringValues[decimalValue])
      case 23:
        stringValues = ['KG 0/4', 'KG 1/4', 'KG 2/4', 'KG 3/4', 'KG 4/4']
        return (stringValues[decimalValue])
      default:
        return decimalValue
    }
  }  

  getParameterOptionValue(parameterID, decimalValue, returnIndex) {
    let stringValues = ['KG 0/4', 'KG 1/4', 'KG 2/4', 'KG 3/4', 'KG 4/4']
    switch(parameterID) {
      case 8:
      case 9:
      case 19:
      case 20:
        switch(decimalValue) {
          case 10:
            return returnIndex ? 1 : '10'
          case 100:
            return returnIndex ? 2 : '10²'
          case 1000:
            return returnIndex ? 3 : '10³'
          case 10000:
            return returnIndex ? 4 : '10⁴'
          case 100000:
            return returnIndex ? 5 : '10⁵'
          case 999999.99:
            return returnIndex ? 6 : '10⁶'
          case 9999999.99:
            return returnIndex ? 7 : '10⁷'
          default:
            return returnIndex ? '0' : '< 10'
        }
      case 23:
        return decimalValue ? (returnIndex ? decimalValue : stringValues[decimalValue]) : (returnIndex ? '0' : 'KG 0/4')
      default:
        return decimalValue
    }
  }  

  getOptionParameterDefaultValue(parameterID) {
    switch(parameterID) {
      case 8:
      case 9:
      case 19:
      case 20:
      case 23:
        return '0'
      default:
        return null
    }
  } 
  
  splitSuperscriptText(fromValue) {
    let resultingString = {
      'main': String(fromValue),
      'superscript': null,
      'remainingText': null
    }
    switch(String(fromValue).substring(0,3)) {
      case '10²':
        resultingString.main = '10'
        resultingString.superscript = '2'
        resultingString.remainingText = String(fromValue).substring(3)
        break
      case '10³':
        resultingString.main = '10'
        resultingString.superscript = '3'
        resultingString.remainingText = String(fromValue).substring(3)
        break        
      case '10⁴':
        resultingString.main = '10'
        resultingString.superscript = '4'
        resultingString.remainingText = String(fromValue).substring(3)
        break        
      case '10⁵':
        resultingString.main = '10'
        resultingString.superscript = '5'
        resultingString.remainingText = String(fromValue).substring(3)
        break        
      case '10⁶':
        resultingString.main = '10'
        resultingString.superscript = '6'
        resultingString.remainingText = String(fromValue).substring(3)
        break        
      case '10⁷':
        resultingString.main = '10'
        resultingString.superscript = '7'
        resultingString.remainingText = String(fromValue).substring(3)
        break    
      default:
        break  
    }
    return resultingString
  }
}

export default new ParameterFormatter()