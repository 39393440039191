import axios from 'axios'
import authHeader from './auth-header'
import formatQuery from './query-helper'

const API_URL = process.env.VUE_APP_SERVER_BASE_URL + 'measurements-light'

class MeasurementLightService {
    getMeasurements(queryParams) {
        return axios.get(API_URL + formatQuery(queryParams), { headers: authHeader() })
    }
    getSingleMeasurement(measurementID) {
        return axios.get(API_URL + '/' + measurementID, { headers: authHeader() })
    }
    // Build other api calls here....   
}

export default new MeasurementLightService()