import axios from 'axios'
import authHeader from './auth-header'
import formatQuery from './query-helper'

const API_URL = process.env.VUE_APP_SERVER_BASE_URL + 'products-light'

class ProductLightService {
    getProducts(queryParams) {
        return axios.get(API_URL + formatQuery(queryParams), { headers: authHeader() })
    }
    getSingleProduct(productID) {
        return axios.get(API_URL + '/' + productID, { headers: authHeader() })
    }  
}

export default new ProductLightService()