<template>
  <admin-setup-quick-actions />
  <br>
  <CCard fluid>
    <CCardBody>
      <CCardTitle>Migration Tool</CCardTitle>
      <a class="nav-link" @click="goToMigrationTool">Go to the migration Tool</a>
    </CCardBody>
  </CCard>
</template>

<script>
import AdminSetupQuickActions from '../components/AdminSetupQuickActions.vue'

export default {
  name: 'AdminMain',
  methods: {
    goToMigrationTool() {
      this.$router.push('/migrationtool')
    }
  },
  components: {
    AdminSetupQuickActions
  }
}
</script>