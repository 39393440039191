<template>
  <CModal size="lg" :visible="cModalVisible" @show="reloadData()" @close="closeEmailSelectionModal()">
    <CModalHeader>
      <CModalTitle>{{ $t('sendLabReportEmailToCustomer') }}</CModalTitle>
    </CModalHeader>
    <CModalBody v-if="dataLoaded"> 
      <p>
      {{ $t('customerEmailSelectionText') }}
      </p>    
      <CRow class="mb-3">
        <div class="mb-3">
          <CFormCheck type="checkbox" id="notifyUser" :label="$t('notificationFlag')" v-model="notifyUser"/>
        </div>
        <div>
          <CFormSelect class="mb-3" aria-label="user select" id="contact" v-model="notifySpecificUser">
            <option v-for="(user, index) in orderedUserList" :key="index"
              :value="user.id">
                {{ mainContactText(user.isMainContact) }} {{ user.first_Name }} {{ user.last_Name }} {{ mainContactText(user.isMainContact) }}
            </option>
          </CFormSelect>
        </div>        
      </CRow>     
    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="closeEmailSelectionModal()">
        {{ $t('close') }}
      </CButton>
      <CButton color="dark" @click="tryCreateAnalysisRequest()">{{ $t('createRequest') }}</CButton>
    </CModalFooter>     
    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert>    
  </CModal>
</template>

<script>
import MachineService from '../services/machines'
import UserService from '../services/users'
import CustomerService from '../services/customers'
import LabReportService from '../services/lab-reports'

export default {
  name: 'CustomerEmailSelection',
  props: {
    cModalVisible: Boolean,
    machineID: Number,
    labReportData: Object
  },
  data() {
    return {
      dataLoaded: false,
      currMachine: '',
      message: '',
      customerContactList: [],
      notifyUser: false,
      notifySpecificUser: 0,
      refAgent: 0,
      refAgentName: ''
    }
  },
  computed: {
    orderedUserList() {
      let processedData = []
      let filteredUserData = [...this.customerContactList]
      if (filteredUserData.length > 0) {
        processedData.push({
          'id': 0,
          'first_Name': this.refAgentName,
          'last_Name': '',
          'isMainContact': false
        })
        let mainContactIndex = filteredUserData.findIndex(user => user.isMainContact)
        if (mainContactIndex >= 0) {
          processedData.push(filteredUserData[mainContactIndex])
          filteredUserData.splice(mainContactIndex, 1)
        }
        filteredUserData.forEach((user) => {
          processedData.push(user)
        })
      }
      return processedData
    }
  },
  methods: {
    getMachineData() {
      if ((this.machineID) && (this.machineID != 0)) {
        MachineService.getSingleMachine(this.machineID).then(
          (response) => {
            this.currMachine = response.data
            this.getCustomerContactList()
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validMachineIDException')
      }        
    },
    reloadData() {
      this.cleanVariables()
      this.getMachineData()
    },
    cleanVariables() {
      this.currMachine = ''
      this.message = ''
      this.dataLoaded = false
    },
    closeEmailSelectionModal() {
      this.cleanVariables()
      this.$emit('closeEmailSelectionModal')
    },
    getCustomerContactList() {
      this.customerContactList = []
      if ((this.currMachine) && (this.currMachine.idCustomer) && (this.currMachine.idCustomer != 0)) {
        // 1. Get Customer
        CustomerService.getSingleCustomer(this.currMachine.idCustomer).then(
          (response) => {
            let contactPerson = response.data.contact_Person
            this.refAgent = response.data.idAgent ? response.data.idAgent : 0
            this.refAgentName = response.data.agent_Name ? response.data.agent_Name : ''
            // 2. Get Users
            let userParams = {
              'company_Type': 1,
              'idCompany': this.currMachine.idCustomer,
              '$limit': 5000
            }
            UserService.getUsers(userParams).then(
              (response) => {
                response.data.data.forEach((user) => {
                  var newUser = user
                  newUser.isMainContact = (newUser.first_Name + ' ' + newUser.last_Name == contactPerson)
                  this.customerContactList.push(newUser)
                })
                this.dataLoaded = true
              }
            )            
          }
        )
      }
    },
    tryCreateAnalysisRequest() {
      if (this.labReportData) {
        if ((this.labReportData.idLabReport) && (this.labReportData.idLabReport != 0)) {
          LabReportService.getSingleLabReport(this.labReportData.idLabReport).then(
            (response) => {
              let currLabReport = response.data
              const userData = JSON.parse(localStorage.getItem('user'))
              let analysisParams = {
                'request_Type': this.labReportData.requestType,
                'requested_By_User': this.notifySpecificUser != 0 ? this.notifySpecificUser : this.refAgent,
                'request_Status': 1,
                'request_Handled_By': userData.user.id,
                'idMeasurement': this.labReportData.idLabReport,                
                'idMachine': this.machineID,
                'notify_User': this.notifyUser,
                'keep_User_Notified': this.notifyUser,
                'batch_Number': currLabReport.batch_Number,
                'reason_Of_Analysis': currLabReport.reason_Of_Analysis,
                'smell': currLabReport.smell,
                'cleannes_Of_Workstation': currLabReport.cleannes_Of_Workstation,
                'floating_Oil_Phase': false,
                'active_Forward_Process': false        
              }
              this.$emit('createAnalysisRequest', analysisParams)
            },
            (error) => {
              this.message =           
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString()              
            }
          )
        } else {
          this.message = this.$t('labReportLoadException')
        }
      }
    },
    mainContactText(isMainContact) {
      return (isMainContact ? '**' : '')
    }
  }
}
</script>