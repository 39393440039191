import axios from 'axios'
import authHeader from './auth-header'
import formatQuery from './query-helper'

const API_URL = process.env.VUE_APP_SERVER_BASE_URL + 'lab-report-details'

class LabReportDetailService {
    getLabReportDetails(queryParams) {
        return axios.get(API_URL + formatQuery(queryParams), { headers: authHeader() })
    }
    updateLabReportDetail(compositeKeyParams, labReportParams) {
        return axios.patch(API_URL + formatQuery(compositeKeyParams), labReportParams, { headers: authHeader() })
    }
    createLabReportDetail(labReportParams) {
        return axios.post(API_URL, labReportParams, { headers: authHeader() })
    }
    deleteLabReportDetail(compositeKeyParams) {
        return axios.delete(API_URL + formatQuery(compositeKeyParams), {headers: authHeader() })
    }
    // Build other api calls here....   
}

export default new LabReportDetailService()