<template>
  <CModal backdrop="static" size="xl" :visible="cModalVisible" @show="loadMachines()" @close="closeMachineSelection()">
    <CModalHeader>
      <CModalTitle>{{ $t('machineSelection') }}</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <small><CListGroup v-if="!isCustomerUser">
        <CListGroupItem color="secondary">
          <div class="d-flex w-100 justify-content-between">
            <h6 class="mb-1"><strong>{{ $t('customerSelectionTitle') }}</strong></h6>
          </div>
        </CListGroupItem>
        <CListGroupItem>
          <CFormSelect class="mb-3" aria-label="customer select" id="customer" v-model="selectedCustomerID">
            <option v-for="(customer, index) in orderedCustomerData" :key="index"
              :value="customer.id">{{ customer.company }}</option>
          </CFormSelect>
        </CListGroupItem>
      </CListGroup>     
      <CListGroup>
        <div v-if="filteredMeasuredMachines.length > 0">
          <CListGroupItem color="secondary">
            <div class="d-flex w-100 justify-content-between">
              <h6 class="mb-1"><strong>{{ $t('measuredMachinesTitle') }}</strong></h6>
            </div>
          </CListGroupItem>
          <CListGroupItem v-for="machine in filteredMeasuredMachines" :key="machine.id">
            <div class="d-flex w-100 justify-content-between">
              <h6 class="mb-1"><strong>{{ machine.id }} - {{ machine.name }}</strong></h6>
            </div>
            <p class="mb-1" v-if="validMachineID(machine.id_Machine)"><italic>{{ machine.id_Machine }}</italic></p>
          </CListGroupItem>      
        </div>
        <CListGroupItem color="secondary">
          <div class="d-flex w-100 justify-content-between">
            <CCol sm="11">
              <h6 class="mb-1"><strong>{{ $t('availableMachinesTitle') }}</strong></h6>
            </CCol>
            <CCol sm="1">
              <CIcon v-if="showAllMachines" icon="cil-filter" size="sm" @click="toggleShowMachines()"/>
              <CIcon v-if="!showAllMachines" icon="cil-filter-x" size="sm" @click="toggleShowMachines()"/>
            </CCol>
          </div>
        </CListGroupItem>
        <CListGroupItem>
          <CFormInput
            type="text"
            id="machineFilter"
            :placeholder="$t('filterPlaceholder')"
            v-model="machinesFilterState" />  
        </CListGroupItem>
        <CListGroupItem v-for="machine in filteredMachines" :key="machine.id" component="button" @click="confirmAction(machine.id)">
          <div class="d-flex w-100 justify-content-between">
            <h6 class="mb-1"><strong>{{ machine.id }} - {{ machine.name }}</strong></h6>
          </div>
          <p class="mb-1" v-if="validMachineID(machine.id_Machine)"><italic>{{ machine.id_Machine }}</italic></p>
          <p class="mb-1">{{ getCustomerName(machine) }}</p>
        </CListGroupItem>
      </CListGroup></small> 
    </CModalBody>   

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </CModal>
</template>

<script>
//import MachineService from '../../services/machines'
import CustomerLightService from '../../services/customers-light'
//import MeasurementService from '../../services/measurements'
import PermissionManager from '../../helpers/permissionManager'
import MachineOverviewService from '../../services/machines-overview'
import DateFormatter from '../../helpers/dateFormatter'

export default {
  name: 'MachineSelectionModalMobile',
  props: {
    cModalVisible: Boolean
  },
  data() {
    return {
      message: '',
      machines: [],
      retrievalStatus: 'stopped',
      customerData: [],
      customersLoaded: false,
      selectedCustomerID: 0,
      dataLoaded: false,
      machinesFilterState: '',
      machineColumns: [        
        'machineID',
        'name',
        'id_Machine',
        'customer_Name',    
      ],  
      defaultCustomer: {
        'id': 0,
        'company': 'Alle Kunden'
      },
      measuredMachines: [],
      showAllMachines: false    
    }
  },
  watch: {
    selectedCustomerID: {
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          this.getMachinesGradually(0, this.selectedCustomerID)
          const userCustomer = PermissionManager.getCustomerFilteredVisibility() 
          if (!(userCustomer)) {
            localStorage.setItem('currentCustomer', this.selectedCustomerID)
          }
        }
      }
    }
  },  
  computed: {
    orderedCustomerData() {
      let processedData = [...this.customerData]
      if (this.customerData.length > 0) {
        processedData = processedData.sort((a,b) => a.company.localeCompare(b.company))
        processedData.unshift(this.defaultCustomer)
      }
      return processedData
    },
    filteredMachines() {
      let processedData = [...this.machines]
      if ((this.selectedCustomerID) && (!this.showAllMachines)) {
        if (Number(this.selectedCustomerID) > 0) {
          let filtMeasuredMachines = this.filteredMeasuredMachines
          if (filtMeasuredMachines.length > 0) {
            filtMeasuredMachines.forEach(machine => {
              let currIndex = processedData.findIndex(item => item.id == machine.id)
              if (currIndex >= 0) {
                processedData.splice(currIndex, 1)
              }
            })
          }
        }
      }
      if (this.machinesFilterState) {
        const filter = this.machinesFilterState.toLowerCase()
        const hasFilter = (item) => String(item).toLowerCase().includes(filter)
        processedData = processedData.filter(item => {
          return this.machineColumns.filter(key => hasFilter(item[key])).length
        })          
      }
      return processedData
    },
    isCustomerUser() {
      const userCustomer = PermissionManager.getCustomerFilteredVisibility() 
      return (userCustomer != null)
    },
    filteredMeasuredMachines() {
      let processedData = []
      if (this.selectedCustomerID) {
        if (Number(this.selectedCustomerID) > 0) {
          // ORG: processedData = this.measuredMachines.filter(item => item.idCustomer == this.selectedCustomerID)
          processedData = this.machines.filter(item => this.alreadyMeasuredMachine(item))
        }
      }
      return processedData
    } 
  },  
  created() {
    this.initData()
    this.getCustomersGradually()
  },
  methods: {
    closeMachineSelection() {
      this.message = ''
      this.$emit('closeMachineSelection')
    },
    initData() {
      this.message = ''
      this.customersLoaded = false
      this.machines = []
      this.customerData = []
    },
    loadMachines() {
      const userCustomer = PermissionManager.getCustomerFilteredVisibility() 
      if (userCustomer) {
        this.selectedCustomerID = userCustomer.idCustomer
      } else if (localStorage.getItem('currentCustomer')) {
        this.selectedCustomerID = localStorage.getItem('currentCustomer')
      }
      // this.getMeasurementsGradually()
    },
    getMachinesGradually(skipParam, customerID) {
      this.retrievalStatus = 'retrieving'
      if ((!skipParam) || (skipParam == 0)) {
        this.machines = []
      }   
      let queryParams = {}
      if (!(customerID == 0)) {   
        queryParams.idCustomer = customerID
      }
      if (!(PermissionManager.hasExtendedTechnicianPermissions())) {
        const userDistributor = PermissionManager.getDistributorFilteredVisibility()
        if (userDistributor) {
          queryParams.idDistributor = userDistributor.idDistributor
        }       
      }         
      // Get active only from local storage
      let activeOnly = localStorage.getItem('showOnlyActiveRecords')
      if (activeOnly === 'true') {
        queryParams.status = 0
      }     
      // Get Additional Filters
      if (this.additionalFilter) {
        queryParams = { ...queryParams, ...this.additionalFilter}
      }                           
      queryParams.$limit = 50
      if (skipParam) {
        queryParams['$skip'] = skipParam
      }
      // ORG: MachineService.getMachines(queryParams).then(
      MachineOverviewService.getMachines(queryParams).then(
        (response) => {
          if (this.retrievalStatus == 'restart') {
            this.retrievalStatus = 'stopped'
            this.machines = ''
            this.dataLoaded = false
            this.getMachinesGradually()
          } else {           
            if (this.machines.length > 0) {
              this.machines = this.machines.concat(response.data.data)
            } else {
              this.machines = response.data.data
            }
            this.dataLoaded = true
            if (response.data.total > response.data.limit + response.data.skip) {
              if (skipParam) {
                skipParam += response.data.limit
              } else {
                skipParam = response.data.limit
              }
              this.getMachinesGradually(skipParam)
            } else {
              this.retrievalStatus = 'stopped'
            }                
          }
        },
        (error)  => {
          this.message =           
           (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()

          this.retrievalStatus = 'stopped'
        }        
      )
    },
    getCustomersGradually(skipParam) {
      if (!skipParam) {
        this.customerData = []
      }      
      let paramQuery = {
        '$limit': 50
      }
      // Manage Permissions first
      if (!(PermissionManager.hasExtendedTechnicianPermissions())) {
        // 1. filter by distributor and agent evt.
        const userDistributor = PermissionManager.getDistributorFilteredVisibility()
        if (userDistributor) {
          paramQuery.idDistributor = userDistributor.idDistributor
        }
        const userAgent = PermissionManager.getAgentFilteredVisibility()
        if (userAgent) {
          paramQuery.idAgent = userAgent.idAgent
        }        
      }       
      // Get active only from local storage
      let activeOnly = localStorage.getItem('showOnlyActiveRecords')
      if (activeOnly === 'true') {
        paramQuery.disabled = false
      }
      if (skipParam) {
        paramQuery['$skip'] = skipParam
      }
      CustomerLightService.getCustomers(paramQuery).then(
        (response) => {
          if (this.customerData.length > 0) {
            this.customerData = this.customerData.concat(response.data.data)
          } else {
            this.customerData = response.data.data
          }
          this.customersLoaded = true
          if (response.data.total > response.data.limit + response.data.skip) {
            if (skipParam) {
              skipParam += response.data.limit
            } else {
              skipParam = response.data.limit
            }
            this.getCustomersGradually(skipParam)
          }
        },
        (error)  => {
          this.message =           
           (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }        
      )
    },
    /*
    getMeasurementsGradually(skipParam) {
      if (!skipParam) {
        this.measuredMachines = []
      }
      let queryParms = {
        'measured_At': new Date().toISOString().substring(0, 10)
      }     
      const userCustomer = PermissionManager.getCustomerFilteredVisibility() 
      if (userCustomer) {
        queryParms.idCustomer = userCustomer.idCustomer
      }
      queryParms.$limit = 50
      if (skipParam) {
        queryParms['$skip'] = skipParam
      }
      MeasurementService.getMeasurements(queryParms).then(
        (response) => {
          response.data.data.forEach(measure => {
            if (this.measuredMachines.findIndex(element => element.id == measure.idMachine) < 0) {
              this.measuredMachines.push({
                'id': measure.idMachine,
                'name': measure.machine_Name,
                'id_Machine': measure.machine_External_ID,
                'idCustomer': measure.idCustomer,
                'customer_Name': measure.customer_Name
              })
            }
          })
          if (response.data.total > response.data.limit + response.data.skip) {
            if (skipParam) {
              skipParam += response.data.limit
            } else {
              skipParam = response.data.limit
            }
            this.getMeasurementsGradually(skipParam)
          }
        },
        (error)  => {
          this.message =           
           (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }        
      )
    },    
    */
    confirmAction(selectedMachineID) {
      if ((!selectedMachineID) || (selectedMachineID == 0)) {
        this.message = this.$t('validMachineSelectionException')
      } else {
        this.$emit('closeMachineSelection', selectedMachineID)
      }
    },
    validMachineID(fromMachineID) {
      if (!fromMachineID) {
        return false
      }
      return (
        (fromMachineID != 0) && (fromMachineID != '') && (fromMachineID != '0')
      )
    },
    toggleShowMachines() {
      this.showAllMachines = !this.showAllMachines
    },
    alreadyMeasuredMachine(machine) {
      if (machine.last_Measurement) {
        return (DateFormatter.formatDate(machine.last_Measurement.measured_At, true) == DateFormatter.formatDate(new Date(), true))
      } else {
        return false
      }
    },
    getCustomerName(machine) {
      if (machine.idCustomer) {
        const currIndex = this.customerData.findIndex(item => item.id == machine.idCustomer)
        return (currIndex >= 0 ? this.customerData[currIndex].company : '')
      } else {
        return ''
      }
    }
  }
}
</script>