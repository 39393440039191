<template>
  <div>
    <div class="row">
      <div class="col-12 col-8-tablet push-2-tablet text-center">
        <CIcon size="xxl" icon="cil-user"/>
        <h3 class="title">{{ $t('distributors') }}</h3>
      </div>
    </div>

    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
      <CButton type="button" color="dark" shape="rounded-0" id="createProduct" :disabled="limitedPermissions" @click="createDistributor()">{{ $t('createNewDistributor') }}</CButton>
    </div>

    <loading-data v-if="!dataLoaded"/>

    <small><general-table v-if="dataLoaded"
      :data="distributors"
      :columns="tableColumns"
      :rowsdetail="tableRowsDetails"
      :extraButtonName="$t('users')"
      machineLookupButton
      :showItemsPerPage="false"
      :sorter="false"
      :columnFilter="false"       
      @customAction="goToUsers($event)"
      @editItem="editDistributor($event)"
      @deleteItem="askForDeletionConfirmation($event)"
      @machineLookup="lookupMachines($event)"/></small>

    <distributor-card
      :cModalVisible="distrCardModal"
      :operationType="currOperationType"
      :distributorID="selectedDistributorID"
      @closeDistributorCardModal="toggleDistrCardModalVisibility($event)"/>      

    <CModal size="xl" alignment="center" :visible="userListVisible" @close="() => { userListVisible = false }">
      <CModalHeader>
        <CModalTitle>{{ $t('users') }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <users-lookup-mobile
          :companyType="0"
          :companyID="selectedDistributorID" />
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="() => { userListVisible = false }">
          {{ $t('close') }}
        </CButton>
      </CModalFooter>
    </CModal>

    <confirm-action
      :cModalVisible="popupConfirmMessage"
      :title="confirmTitle"
      :message="confirmMessage"
      :yesButton="$t('confirmButton')"
      :noButton="$t('cancelButton')"
      @confirm="confirmAction()" 
      @cancel="cancelAction()" />  

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </div>
</template>

<script>
import GeneralTable from '@/components/GeneralTable.vue'
import DistributorService from '../../services/distributors'
import UsersLookupMobile from './UsersLookupMobile.vue'
import DistributorCard from '../../components/DistributorCard.vue'
import DateFormatter from '../../helpers/dateFormatter'
import ConfirmAction from '../../components/ConfirmAction.vue'
import LoadingData from '../../components/LoadingData.vue'
import PermissionManager from '../../helpers/permissionManager'
import MachineService from '../../services/machines'

export default {
  name: 'DistributorsMobile',
  data() {
    return {
      dataLoaded: false,
      message: '',
      content: [],
      searchQuery: '',
      tableColumns: [
        { key: 'distributorFields.id', _style: 'min-width: 50px' },
        { key: 'distributorFields.name', _style: 'min-width: 180px' },
        { key: 'distributorFields.address', _style: 'min-width: 200px' },
        { key: 'distributorFields.phone_Number', _style: 'min-width: 130px' },
        { key: 'distributorFields.client_No', _style: 'min-width: 70px' },
        { 
          key: 'show_details', 
          label: '', 
          _style: 'width:1%', 
          sorter: false, 
          filter: false
        }
      ],
      tableRowsDetails: [
        'disabled',
        'distributorFields.contact_Person',
        'distributorFields.zip',
        'distributorFields.creation_Date'
      ],
      selectedDistributorID: 0,
      userListVisible: false,
      distrCardModal: false,
      currOperationType: 0,
      popupConfirmMessage: false,
      confirmTitle: '',
      confirmMessage: ''      
    }
  },
  created() {
    this.getDistributorsGradually()
  },
  computed: {
    distributors() {
      let processedData = []
      if (this.content.length > 0) {
        this.content.forEach((distributor) => {
          processedData.push({
            'distributorFields.id': distributor.id,
            'distributorFields.name': distributor.company,
            'distributorFields.address': distributor.address + ', ' + distributor.city + ' (' + distributor.country_Name + ')',
            'distributorFields.phone_Number': distributor.phone_Number,
            'distributorFields.client_No': distributor.client_No,
            'disabled': distributor.disabled,
            'distributorFields.contact_Person': distributor.contact_Person,
            'distributorFields.zip': distributor.zipCode,
            'distributorFields.creation_Date': DateFormatter.formatDate(distributor.created, true)                   
          })                    
        })
      }
      return processedData
    },
    limitedPermissions() {
      return !(PermissionManager.hasAdminPermissions())
    }    
  },
  methods: {
    getDistributorsGradually(skipParam) {
      if (!skipParam) {
        this.content = []
      }      
      let paramQuery = {
        '$limit': 50
      }
      // Manage Permissions first
      if (!(PermissionManager.hasExtendedTechnicianPermissions())) {
        // 1. filter by distributor and agent evt.
        const userDistributor = PermissionManager.getDistributorFilteredVisibility()
        if (userDistributor) {
          paramQuery.id = userDistributor.idDistributor
        }      
      }       
      // Get active only from local storage
      let activeOnly = localStorage.getItem('showOnlyActiveRecords')
      if (activeOnly === 'true') {
        paramQuery.disabled = false
      }      
      if (skipParam) {
        paramQuery['$skip'] = skipParam
      }
      DistributorService.getDistributors(paramQuery).then(
        (response) => {
          if (this.content.length > 0) {
            this.content = this.content.concat(response.data.data)
          } else {
            this.content = response.data.data
          }
          this.dataLoaded = true
          if (response.data.total > response.data.limit + response.data.skip) {
            if (skipParam) {
              skipParam += response.data.limit
            } else {
              skipParam = response.data.limit
            }
            this.getDistributorsGradually(skipParam)
          }
        },
        (error)  => {
          this.message =           
           (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }        
      )
    },    
    editDistributor(distributorID) {
      if ((!distributorID) || (distributorID == 0)) {
        this.message = this.$t('validDistributorException')
      } else {
        this.currOperationType = 0
        this.selectedDistributorID = distributorID
        this.distrCardModal = !this.distrCardModal
      }
    },
    createDistributor() {
      this.currOperationType = 1
      this.selectedDistributorID = 0
      this.distrCardModal = !this.distrCardModal
    },    
    askForDeletionConfirmation(distributorID) {
      if (!(PermissionManager.hasAdminPermissions())) {
        this.message = this.$t('limitedPermissionsException')
      } else {      
        MachineService.getMachines({'idDistributor': distributorID}).then(
          (response) => {
            if (response.data.total > 0) {
              this.message = this.$t('associatedMachineException')
            } else {        
              this.selectedDistributorID = distributorID
              this.confirmTitle = this.$t('continueTitle')
              this.confirmMessage = this.$t('distributorDeletionWarning', {dist: distributorID})
              this.popupConfirmMessage = true
            }
          }
        )
      }
    },    
    deleteDistributor() {
      if ((!this.selectedDistributorID) || (this.selectedDistributorID == 0)) {
        this.message = this.$t('nonExistentDistributorException')
      } else {
        // let continueProcessing = confirm('You are about to delete the distributor... Do you want to continue?')
        // if (continueProcessing) {
          DistributorService.deleteDistributor(this.selectedDistributorID).then(
            () => {
              this.dataLoaded = false
              this.getDistributorsGradually()
            },
            (error)  => {
              this.message =           
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()
            }
          ) 
        // }
      }            
    },
    goToUsers(distributorID) {
      if (this.userListVisible) {
        this.userListVisible = false
      } else {
        this.selectedDistributorID = distributorID
        this.userListVisible = true
      }
    },
    toggleDistrCardModalVisibility(refreshPage) {
      this.distrCardModal = !this.distrCardModal
      if (refreshPage) {
        this.dataLoaded = false
        this.getDistributorsGradually()
      }
    },
    lookupMachines(distributorID) {
      if (distributorID) {
        this.$router.push({ name: 'mMachinesDistributor', params: { idDistributor: distributorID } })
      }
    },
    confirmAction() {
      this.resetConfirmMessage()
      this.deleteDistributor()
    },
    cancelAction() {
      this.resetConfirmMessage()
    },
    resetConfirmMessage() {
      this.confirmTitle = ''
      this.confirmMessage = '',
      this.popupConfirmMessage = false
    }             
  },
  components: {
    GeneralTable,
    UsersLookupMobile,
    DistributorCard,
    ConfirmAction,
    LoadingData
  }
}
</script>