<template>
  <CCard fluid v-if="isDataLoaded">
    <CCardBody>
      <CCardTitle>{{ $t('analysisRequest') }} </CCardTitle>
      <CCardText>{{ pretifyName('analysisRequestFields.requested_By_User') }}: {{ currRequest.requested_By_User_Name }} <br> 
       {{ $t('customer') }}: {{ currRequest.customer_Name }}<br> {{ pretifyName('status') }}: {{ currRequest.request_Status }}<br>
       {{ pretifyName('analysisRequestFields.creation_Date') }}: {{ currRequest.creation_Date }}<br></CCardText>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <CButton type="button" color="dark" shape="rounded-0" class="mb-3"
          @click="showAnalysisCard()">
          {{ $t('view') }}
        </CButton>
      </div>       
      <CCardText v-if="currMeasurement">
        <strong>{{ $t('receivedMeasurementAsAttachment') }}:</strong><br>
        {{ pretifyName('measurementFields.measured_At') }}: {{ currMeasurement.measured_At }}<br>
        {{ pretifyName('measurementFields.pH') }}: {{ currMeasurement.pH }}<br>
        {{ pretifyName('measurementFields.refrac_Reading') }}: {{ currMeasurement.refrac_Reading }}<br>
        {{ pretifyName('measurementFields.eff_Concentration') }}: {{ currMeasurement.eff_Concentration }}<br>
        {{ pretifyName('measurementFields.appareance') }}: {{ currMeasurement.appearance }}<br>
        {{ pretifyName('measurementFields.comment') }}: {{ currMeasurement.comment }}
      </CCardText>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <CButton type="submit" color="dark" shape="rounded-0" class="mb-3"
          v-if="currMeasurement"
          @click="showPictures()">
          {{ $t('pictures') }}
        </CButton>
      </div>
    </CCardBody>

    <analysis-request-card
      :cModalVisible="analysisCardVisible"
      :requestID="requestID"
      @closeRequestCardModal="() => {analysisCardVisible = false}" />

    <pictures-view
      :idMeasurement="currMeasurement.id"
      :cModalVisible="picturesModalVisible"
      @closePictureModal="() => {picturesModalVisible = false}" />

  </CCard>
</template>

<script>
import AnalysisRequestService from '../services/analysis-requests'
import MeasurementService from '../services/measurements'
import DateFormatter from '../helpers/dateFormatter'
import PicturesView from './PicturesView.vue'
import StringFormatter from '../helpers/stringFormatter'
import AnalysisRequestCard from './AnalysisRequestCard.vue'

export default {
  name: 'AnalysisRequestInfobox',
  props: {
    requestID: Number
  },
  expose: ['dataLoaded'],
  data() {
    return {
      requestLoaded: false,
      dataLoaded: false,
      currRequest: '',
      currMeasurement: '',
      picturesModalVisible: false,
      analysisCardVisible: false
    }
  },
  computed: {
    isDataLoaded() {
      if (this.requestID != this.requestID) {
        this.refreshData()
      }
      if (!this.requestLoaded) {
        this.getRequestData()
      } else if (!this.dataLoaded) {
        this.getMeasurement()
      }
      return this.dataLoaded
    }
  },
  methods: {
    getRequestData() {
      if (this.requestID != 0) {
        AnalysisRequestService.getSingleAnalysisRequest(this.requestID).then(
          (response) => {
            this.currRequest = response.data
            if (this.currRequest.creation_Date) {
              this.currRequest.creation_Date = DateFormatter.formatDate(this.currRequest.creation_Date, true)
            }
            this.requestLoaded = true
          }
        )
      } else {
        this.requestLoaded = true
        this.dataLoaded = true
      }
    },
    getMeasurement() {
      if (this.currRequest.attached_idMeasure != 0) {
        MeasurementService.getSingleMeasurement(this.currRequest.attached_idMeasure).then(
          (response) => {
            this.currMeasurement = response.data
            if (this.currMeasurement.measured_At) {
              this.currMeasurement.measured_At = DateFormatter.formatDate(this.currMeasurement.measured_At, true)  
            }
            this.dataLoaded = true
          }
        )
      } else {
        this.dataLoaded = true
      }
    },
    showPictures() {
      this.picturesModalVisible = !this.picturesModalVisible
    },
    refreshData() {
      this.requestLoaded = false
      this.dataLoaded = false
      this.currRequest = ''
      this.currMeasurement = ''
    },
    pretifyName(name) {
      return StringFormatter.pretifyName(this.$t(name))
    },
    showAnalysisCard() {
      this.analysisCardVisible = !this.analysisCardVisible
    }        
  },
  components: {
    PicturesView,
    AnalysisRequestCard
  }
}
</script>