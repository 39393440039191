import axios from 'axios'
import authHeader from './auth-header'
import formatQuery from './query-helper'

const API_URL = process.env.VUE_APP_SERVER_BASE_URL + 'machine-entries'

class MachineEntriesService {
    getMachineEntries(queryParams) {
        return axios.get(API_URL + formatQuery(queryParams), { headers: authHeader() })
    }
    // Build other api calls here....   
}

export default new MachineEntriesService()