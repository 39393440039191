<template>
  <div>
  </div>
</template>

<script>
export default {
  name: 'InitLayout',
  created() {
    if (this.followMobilePath()) {
      this.$router.push('/mainmobile')
    } else {
      this.$router.push('/main')
    }
  },
  methods: {
    isMobile() {
      return (screen.width <= 760)
    },
    followMobilePath() {
      let mobile = this.isMobile()
      let previousChoice = localStorage.getItem('isMobile')
      if ((previousChoice) && (!(previousChoice === 'false'))) {
        mobile = previousChoice
      }
      localStorage.setItem('isMobile', mobile)
      return mobile
    }
  }
}
</script>