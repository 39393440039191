import axios from 'axios'
const API_URL = process.env.VUE_APP_SERVER_BASE_URL
class AuthService {
    login(user) {
        return axios
            .post(API_URL + 'authentication', {
                strategy: 'local',
                email: user.email,
                password: user.password
            })
            .then(response => {
                if (response.data.accessToken) {
                    localStorage.setItem('user', JSON.stringify(response.data))
                }
                return response.data
            })
    }
    logout() {
        localStorage.removeItem('user')
    }
    register(user) {
        return axios.post(API_URL + 'users', {
            email: user.email,
            password: user.password
        })
    }
    confirmUser(validationToken) {
        return axios.post(API_URL + 'auth-management', {
            action: 'verifySignupLong',
            value: validationToken
        })
    }
    resetPwd(passwordData) {
        return axios.post(API_URL + 'auth-management', {
            action: 'resetPwdLong',
            value: passwordData
        })
    }
    sendResetPwd(userData) {
        return axios.post(API_URL + 'auth-management', {
            action: 'sendResetPwd',
            value: userData
        })
    }
}

export default new AuthService()