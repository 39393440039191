<template>
  <CModal size="xl" :visible="cModalVisible" @show="reloadData()" @close="closeDistrCard()">
    <CModalHeader>
      <CModalTitle>{{ confirmationButtonText}} {{ $t('distributor') }}</CModalTitle>
    </CModalHeader>
    <CModalBody v-if="dataLoaded">  
      <CNav variant="tabs" role="tablist">
        <CNavItem>
          <CNavLink
            href="javascript:void(0);"
            :active="tabPaneActiveKey === 1"
            @click="() => {tabPaneActiveKey = 1}"
          >
            {{ $t('general') }}
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink
            href="javascript:void(0);"
            :active="tabPaneActiveKey === 2"
            @click="() => {tabPaneActiveKey = 2}"
          >
            {{ $t('distributorLocation') }}
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink
            href="javascript:void(0);"
            :active="tabPaneActiveKey === 3"
            @click="() => {tabPaneActiveKey = 3}"
          >
            {{ $t('analysisRequests') }}
          </CNavLink>
        </CNavItem>
      </CNav>     
      <CTabContent>
        <CTabPane role="tabpanel" aria-labelledby="home-tab" :visible="tabPaneActiveKey === 1">
          <br>
          <CRow class="mb-3">
            <CFormLabel for="distributorName" :class="labelColumnsLength">{{ pretifyName('distributorFields.name') }}</CFormLabel>
            <CCol :sm="fieldColumnsLength">
              <CFormInput type="text" id="distributorName" v-model="currDistributor.company" />
            </CCol>
          </CRow> 
          <CRow class="mb-3">
            <CFormLabel for="distributorPhone" :class="labelColumnsLength">{{ pretifyName('distributorFields.phone_Number') }}</CFormLabel>
            <CCol :sm="fieldColumnsLength">
              <CFormInput type="phone" id="distributorPhone" v-model="currDistributor.phone_Number" />
            </CCol>
          </CRow>
          <CRow class="mb-3">
            <CFormLabel for="navCustomerNo" :class="labelColumnsLength">{{ pretifyName('distributorFields.client_No') }}</CFormLabel>
            <CCol :sm="fieldColumnsLength">
              <CFormInput type="text" id="navCustomerNo" v-model="currDistributor.client_No" />
            </CCol>
          </CRow>
          <CRow class="mb-3">
            <CFormLabel for="distributorContact" :class="labelColumnsLength">{{ pretifyName('distributorFields.contact_Person') }}</CFormLabel>
            <CCol :sm="fieldColumnsLength">
              <CFormInput type="text" id="distributorContact" v-model="currDistributor.contact_Person" />
            </CCol>
          </CRow> 
          <CRow class="mb-3">
            <div :class="toggleOffset">
              <CFormCheck type="checkbox" id="disabled" :label="$t('disabled')" v-model="currDistributor.disabled" />
            </div>
          </CRow>  
          <CRow v-if="!checkUserPermissions">
            <CFormLabel><strong>{{ pretifyName('distributorFields.active_Machines')}}: {{ currDistributor.active_Machines }}</strong></CFormLabel>
          </CRow> 
          <CRow v-if="!checkUserPermissions">
            <CFormLabel><strong>{{ pretifyName('distributorFields.inactive_Machines')}}: {{ currDistributor.inactive_Machines }}</strong></CFormLabel>
          </CRow>                          
        </CTabPane>
        <CTabPane role="tabpanel" aria-labelledby="profile-tab" :visible="tabPaneActiveKey === 2">
          <br>
          <CRow class="mb-3">
            <CFormLabel for="distributorAddress" :class="labelColumnsLength">{{ pretifyName('distributorFields.address') }}</CFormLabel>
            <CCol :sm="fieldColumnsLength">
              <CFormInput type="text" id="distributorAddress" v-model="currDistributor.address" />
            </CCol>
          </CRow>
          <CRow class="mb-3">
            <CFormLabel for="distributorCity" :class="labelColumnsLength">{{ pretifyName('distributorFields.city') }}</CFormLabel>
            <CCol :sm="fieldColumnsLength">
              <CFormInput type="text" id="distributorCity" v-model="currDistributor.city" />
            </CCol>
          </CRow>   
          <CRow class="mb-3">
            <CFormLabel for="distributorZip" :class="labelColumnsLength">{{ pretifyName('distributorFields.zip') }}</CFormLabel>
            <CCol :sm="fieldColumnsLength">
              <CFormInput type="text" id="distributorZip" v-model="currDistributor.zipCode" />
            </CCol>
          </CRow> 
          <CRow class="mb-3">
            <CFormLabel for="distributorCountry" :class="labelColumnsLength">{{ pretifyName('distributorFields.country') }}</CFormLabel>
            <CCol :sm="fieldColumnsLength">
              <CFormSelect class="mb-3" aria-label="country select" id="distributorCountry" v-model="currDistributor.country">
                <option v-for="(country, index) in countries" :key="index"
                  :value="country.code">{{ country.name }}</option>
              </CFormSelect>
            </CCol>
          </CRow>
          <CRow class="mb-3">
            <CFormLabel for="language" :class="labelColumnsLength">{{ pretifyName('distributorFields.language') }}</CFormLabel>
            <CCol :sm="fieldColumnsLength">
              <CFormSelect class="mb-3" aria-label="language select" id="language" v-model="currDistributor.language_int">
                <option value="0">{{ $t('languageOptions.english') }}</option>
                <option value="1">{{ $t('languageOptions.german') }}</option>
                <option value="2">{{ $t('languageOptions.italian') }}</option>
                <option value="3">{{ $t('languageOptions.french') }}</option>
              </CFormSelect>
            </CCol>
          </CRow>         
        </CTabPane>
        <CTabPane role="tabpanel" aria-labelledby="contact-tab" :visible="tabPaneActiveKey === 3">
          <br>
          <CRow class="mb-3">
            <CFormLabel for="analysisEmail" class="col-sm-4 col-form-label">{{ pretifyName('distributorFields.analysis_Email') }}</CFormLabel>
            <CCol sm="8">
              <CFormInput type="text" id="analysisEmail" v-model="currDistributor.analysis_Email" />
            </CCol>
          </CRow> 
          <CRow class="mb-3">
            <div class="col-sm-8 offset-sm-4">
              <CFormCheck type="checkbox" id="handleReports" :label="pretifyName('distributorFields.handle_Reports')" v-model="currDistributor.handle_Reports" />
            </div>
          </CRow> 
          <CRow class="mb-3">
            <CFormLabel for="forwardToCompany" class="col-sm-4 col-form-label">{{ pretifyName('distributorFields.forward_To_Company') }}</CFormLabel>
            <CCol sm="8">
              <CInputGroup class="mb-3">
                <CFormInput type="text" id="forwardToCompany" v-model="forwardToCompany" readonly />
                <CButton type="button" color="dark" variant="outline" id="searchDistributor" :disabled="checkAdminPermissions"
                  @click="distLookupVisible = !distLookupVisible">{{ $t('search') }}</CButton>
              </CInputGroup>
            </CCol>
          </CRow>         
        </CTabPane>
      </CTabContent>                  
    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="closeDistrCard()">
        {{ $t('close') }}
      </CButton>
      <CButton color="primary"
        :disabled="checkUserPermissions"
        @click="performConfirmationAction()">
        {{ confirmationButtonText }}
      </CButton>
    </CModalFooter>

    <CModal size="xl" alignment="center" :visible="distLookupVisible" @close="() => { distLookupVisible = false }">
      <CModalHeader>
        <CModalTitle>{{ $t('distributors') }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <distributors-lookup
          @itemSelected="getForwardDistributorName($event)" />
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="() => { distLookupVisible = false }">
          {{ $t('close') }}
        </CButton>
      </CModalFooter>
    </CModal>    

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </CModal>
</template>

<script>
import DistributorService from '../services/distributors'
import { Languages } from '../helpers/getEnumValues'
import StringFormatter from '../helpers/stringFormatter'
import PermissionManager from '../helpers/permissionManager'
import CountryService from '../services/countries'
import MachineService from '../services/machines'
import DistributorsLookup from './DistributorsLookup.vue'

export default {
  name: 'DistributorCard',
  props: {
    cModalVisible: Boolean,
    operationType: Number,
    distributorID: Number
  },
  data() {
    return {
      message: '',
      currDistributor: '',
      dataLoaded: false,
      countriesLoaded: false,
      countries: [],
      distLookupVisible: false,
      forwardToCompany: '',
      forwardToCompanyID: 0,
      tabPaneActiveKey: 1
    }
  },
  computed: {
    confirmationButtonText() {
      if (this.operationType == 0) {
        return this.$t('edit')
      } else {
        return this.$t('create')
      }
    },
    checkUserPermissions() {
      return (!(PermissionManager.hasExtendedAgentPermissions()) || (PermissionManager.getCustomerFilteredVisibility()))
    },
    checkAdminPermissions() {
      return (!(PermissionManager.hasAdminPermissions()))
    },
    labelColumnsLength() {
      if (screen.width <= 1000) {
        return 'col-sm-4 col-form-label'
      } else {
        return 'col-sm-2 col-form-label'
      }
    },
    fieldColumnsLength() {
      if (screen.width <= 1000) {
        return '8'
      } else {
        return '10'
      }      
    },
    toggleOffset() {
      if (screen.width <= 1000) {
        return 'col-sm-8 offset-sm-4'
      } else {
        return 'col-sm-10 offset-sm-2'
      }
    }           
  },
  methods: {
    getDistributor() {
      if (this.operationType == 0) {
        if ((this.distributorID == 0) || (!this.distributorID)) {
          this.message = this.$t('validDistributorIDException')
        } else {
          DistributorService.getSingleDistributor(this.distributorID).then(
            (response) => {
              this.currDistributor = response.data
              this.currDistributor.language_int = this.getLanguage(this.currDistributor.language)
              if (!(this.currDistributor.language_int)) {
                this.currDistributor.language_int = 0
              }
              this.forwardToCompanyID = this.currDistributor.forward_To_Company
              this.forwardToCompany = this.currDistributor.forward_To_Company_Name
              // Get Active/Inactive machines
              MachineService.getMachines({ 'idDistributor': this.currDistributor.id, 'status': 0, '$limit': 10 }).then(
                (response) => {
                  this.currDistributor.active_Machines = response.data.total
                }
              )       
              MachineService.getMachines({ 'idDistributor': this.currDistributor.id, 'status': 1, '$limit': 10 }).then(
                (response) => {
                  this.currDistributor.inactive_Machines = response.data.total
                }
              )                      
              this.dataLoaded = true
            },
            (error) => {
              this.message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString()
            }
          )
        }
      } else {
        this.forwardToCompanyID = 0
        this.forwardToCompany = ''       
        // Create empty distributor
        this.currDistributor = {
          'company': null,
          'address': null,
          'city': null,
          'zipCode': null,
          'country': null,
          'phone_Number': null,
          'client_No': null,
          'contact_Person': null,
          'analysis_Email': null,
          'disabled': false,
          'language_int': 0,
          'active_Machines': 0,
          'inactive_Machines': 0         
        }
        this.dataLoaded = true
      }
    },
    getCountries() {
      this.countriesLoaded = false
      this.countries = []
      CountryService.getCountries({ '$limit': 5000 }).then(
        (response) => {
          const tempCountries = response.data.data
          tempCountries.forEach(country => {
            this.countries.push({
              'code': country.alpha2Code,
              'name': country.name
            })
          })
          this.countriesLoaded = true
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()          
        }
      )
    },
    performConfirmationAction() {
      let distributorParameters = {
        'company': this.currDistributor.company,
        'address': this.currDistributor.address,
        'city': this.currDistributor.city,
        'zipCode': this.currDistributor.zipCode,
        'country': this.currDistributor.country,
        'phone_Number': this.currDistributor.phone_Number,
        'client_No': this.currDistributor.client_No,
        'contact_Person': this.currDistributor.contact_Person,
        'analysis_Email': this.currDistributor.analysis_Email,
        'disabled': this.currDistributor.disabled,
        'language': this.currDistributor.language_int,
        'handle_Reports': this.currDistributor.handle_Reports,
        'forward_To_Company': this.forwardToCompanyID
      }
      if (this.operationType == 0) {
        this.editDistributor(distributorParameters)
      } else {
        this.createDistributor(distributorParameters)
      }
    },
    createDistributor(distributorParameters) {
      DistributorService.createDistributor(distributorParameters).then(
        () => {
          this.message = ''
          this.dataLoaded = false
          this.$emit('closeDistributorCardModal', true)
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        }
      )
    },
    editDistributor(distributorParameters) {
      DistributorService.updateDistributor(this.distributorID, distributorParameters).then(
        () => {
          this.message = ''
          this.dataLoaded = false
          this.$emit('closeDistributorCardModal', true)
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        }
      )
    },
    closeDistrCard() {
      this.message = ''
      this.dataLoaded = false
      this.$emit('closeDistributorCardModal', false)
    },
    reloadData() {
      this.dataLoaded = false
      this.tabPaneActiveKey = 1
      this.getDistributor()
      this.getCountries()
    },
    getLanguage(languageString) {
      if ((languageString) && (languageString != '')) {
        return Languages.indexOf(languageString)
      }
    },
    pretifyName(name) {
      return StringFormatter.pretifyName(this.$t(name))
    },
    getForwardDistributorName(forwardDistributorID) {
      if (forwardDistributorID != 0) {
        DistributorService.getSingleDistributor(forwardDistributorID).then(
          (response) => {
            this.forwardToCompanyID = forwardDistributorID
            this.forwardToCompany = response.data.company
            this.distLookupVisible = false
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validDistributorIDException')
      } 
    },                  
  },
  components: {
    DistributorsLookup
  }
}
</script>