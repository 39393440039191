<template>
  <CContainer fluid v-if="isDataLoaded">

    <new-measurement v-if="measurementVisible"
      :key="measurementDataKey"
      :selectedMachine="currMachine"
      resize
      @closeMeasurementCreation="onFinishedCreation($event)"
      @closeAndNewMeasurement="onFinishedCreationAndRefresh($event)"
      @closeAndNewLabReport="onFinishedCreationAndNewLabReport($event)"
      @sendError="logError($event)" />

    <new-lab-report v-if="labReportVisible"
      :selectedMachine="currMachine"
      :orderDate="reqOrderDate"
      :requestDetails="requestDetails"
      labReportType=0
      @closeLabReportCreation="onLabReportCreated($event)"
      @sendError="logError($event)" />

    <new-lab-report v-if="waterAnalysisVisible"
      :selectedMachine="currMachine"
      :orderDate="reqOrderDate"
      :requestDetails="requestDetails"
      labReportType=1
      @closeLabReportCreation="onWaterAnalysisCreated($event)"
      @sendError="logError($event)" />      

    <machine-selection-modal-mobile
      :cModalVisible="machineSelectionModalVisible"
      @closeMachineSelection="onCloseMachineSelection($event)"/> 

    <new-lab-report-request-mobile
      :cModalVisible="labReportRequestMobileVisible"
      :idMeasure="createdMeasureID"
      @closeAnalysisRequest="onCloseAnalysisRequest()" />

    <new-lab-report-request
      :cModalVisible="labReportRequestVisible"
      :idMeasure="createdMeasureID"
      @closeAnalysisRequest="onCloseAnalysisRequest()" />      

    <customer-email-selection
      :cModalVisible="customerEMailSelectionVisible"
      :machineID="machineID"
      :labReportData="labReportData"
      @closeEmailSelectionModal="onCloseCustomerEmailSelection()"
      @createAnalysisRequest="createAnalysisRequest($event)" />

    <CToaster placement="bottom-end">
      <CToast color="dark" class="text-white" v-for="(toast, index) in toasts" :key="index">
        <CToastHeader closeButton>
        <span class="me-auto fw-bold">{{ toast.title }}</span>
        <small>{{ $t('now') }}</small>
        </CToastHeader>
        <CToastBody>
          {{ toast.content }}
        </CToastBody>  
      </CToast>
    </CToaster>

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </CContainer>
</template>

<script>
import NewMeasurement from '../components/NewMeasurement.vue'
import AnalysisRequestService from '../services/analysis-requests'
import MachineService from '../services/machines'
import NewLabReport from '../components/NewLabReport.vue'
import MachineSelectionModalMobile from '../components/mobile/MachineSelectionModalMobile.vue'
import NewLabReportRequestMobile from '../components/mobile/NewLabReportRequestMobile.vue'
import NewLabReportRequest from '../components/NewLabReportRequest.vue'
import CustomerEmailSelection from '../components/CustomerEmailSelection.vue'

export default {
  name: 'MeasurementCard',
  data() {
    return {
      dataLoaded: false,
      requestDataRetrieved: false,
      machineName: '',
      productName: '',
      machineID: 0,
      reqOrderDate: null,
      currMachine: '',
      message: '',
      measurementVisible: false,
      labReportVisible: false,
      waterAnalysisVisible: false,
      toasts: [],
      requestDetails: '',
      machineSelectionModalVisible: false,
      measurementDataKey: 0,
      labReportRequestMobileVisible: false,
      labReportRequestVisible: false,
      createdMeasureID: 0,
      customerEMailSelectionVisible: false,
      labReportData: ''  
    }
  },
  computed: {
    isDataLoaded() {
      if (!this.dataLoaded) {
        if (!this.requestDataRetrieved) {
          this.getRequestData()
        } else {
          this.getMachineData()
        }
      }
      return this.dataLoaded
    }
  },
  created() {
    this.refreshData()
  },
  mounted() {
    if (this.$route.params.measurementType == '3') {
      this.popupMachineSelection()
    }
  },
  methods: {
    getRequestData() {
      if ((this.$route.params.requestID) && (this.$route.params.requestID != 0)) {
        AnalysisRequestService.getSingleAnalysisRequest(this.$route.params.requestID).then(
          (response) => {
            this.machineID = response.data.idMachine
            this.reqOrderDate = response.data.creation_Date
            this.requestDetails = response.data
            this.requestDataRetrieved = true
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else if ((this.$route.params.machineID) && (this.$route.params.machineID != 0)) {
        this.machineID = this.$route.params.machineID
        this.requestDataRetrieved = true
      } else {
        this.message = this.$t('missingParametersException')
      }
    },
    getMachineData() {
      if (this.machineID != 0) {
        MachineService.getSingleMachine(this.machineID).then(
          (response) => {
            this.dataLoaded = true
            this.currMachine = response.data
            this.setRouteInfoInLocalStorage()
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      }
    },
    refreshData() {
      switch(this.$route.params.measurementType) {
        case '0':
          this.measurementVisible = true
          break
        case '1':
          this.labReportVisible = true
          break
        case '2':
          this.waterAnalysisVisible = true
          break
        case '3':
          this.dataLoaded = true
          break
        default:
          break
      }
      if (!(this.$route.params.measurementType == '3')) {
        this.getRequestData()
      }
    },
    setRouteInfoInLocalStorage() {
      if (this.currMachine) {
        const params = {
          'idMachine': this.currMachine.id,
          'idProduct': this.currMachine.idProduct,
          'idCustomer': this.currMachine.idCustomer,
          'idDistributor': this.currMachine.idDistributor
        }
        if ((this.$route.params.requestID) && (this.$route.params.requestID != 0)) {
          params.idRequest = Number(this.$route.params.requestID)
        }
        localStorage.setItem('routeInfo', JSON.stringify(params))
      }
    },
    onFinishedCreation(measurementID) {
      if ((measurementID) && (measurementID != 0)) {
        // Update Request First
        this.updateAnalysisRequest(measurementID, 2, 0)
        this.createToast(this.$t('measurementSuccesfullyCreated'), this.$t('redirectingToList', {list: this.$t('measurement')}))
        this.delay(2000).then(
          () => {
            this.$router.replace({ name: this.isMobile() ? 'mMeasurementsMachine' : 'MeasurementsMachine', params: { idMachine: this.currMachine.id } })
          }
        )
      } else {
        if ((this.$route.params.calledByMeasurement) && ((this.currMachine.id) && (this.currMachine.id != 0))) {
          this.$router.replace({ name: this.isMobile() ? 'mMeasurementsMachine' : 'MeasurementsMachine', params: { idMachine: this.currMachine.id } })        
        } else {
          this.$router.back()
        }
      }
    },
    onLabReportCreated(labReportID) {
      if ((labReportID) && (labReportID != 0)) {
        // Update Request First
        if ((this.$route.params.requestID) && (this.$route.params.requestID != 0)) {
          this.updateAnalysisRequest(labReportID, 1, 1)
          this.createToast(this.$t('labReportSuccesfullyRequested'),
                          this.$t('redirectingToList', {list: this.$t('labReport')}))
          this.delay(2000).then(
            () => {
              let queryParams = {
                'idMachine': this.currMachine.id,
                'labReportType': Number(this.$route.params.measurementType) - 1,
                'idLabReport': labReportID
              }
              this.$router.replace({ name: this.isMobile() ? 'mLabReportDetails' : 'LabReportDetails', params: queryParams })
            }
          )
        } else {
          this.startAnalysisRequestProcess(labReportID, 1)
        }
      } else {
        if ((this.$route.params.calledByMeasurement) && ((this.currMachine.id) && (this.currMachine.id != 0))) {
          this.$router.replace({ name: this.isMobile() ? 'mMeasurementsMachine' : 'MeasurementsMachine', params: { idMachine: this.currMachine.id } })        
        } else {        
          this.$router.back()
        }
      }
    },
    onWaterAnalysisCreated(labReportID) {
      if ((labReportID) && (labReportID != 0)) {
        // Update Request First
        if ((this.$route.params.requestID) && (this.$route.params.requestID != 0)) {
          this.updateAnalysisRequest(labReportID, 1, 2)
          this.createToast(this.$t('waterAnalysisSuccesfullyRequested'),
                          this.$t('redirectingToList', {list: this.$t('waterAnalysis')}))
          this.delay(2000).then(
            () => {
              let queryParams = {
                'idMachine': this.currMachine.id,
                'labReportType': Number(this.$route.params.measurementType) - 1,
                'idLabReport': labReportID
              }
              this.$router.replace({ name: this.isMobile() ? 'mLabReportDetails' : 'LabReportDetails', params: queryParams })
            }
          )
        } else {
          this.startAnalysisRequestProcess(labReportID, 2)
        }
      } else {
        if ((this.$route.params.calledByMeasurement) && ((this.currMachine.id) && (this.currMachine.id != 0))) {
          this.$router.replace({ name: this.isMobile() ? 'mMeasurementsMachine' : 'MeasurementsMachine', params: { idMachine: this.currMachine.id } })     
        } else {        
          this.$router.back()
        }
      }
    },    
    createToast(title, content) {
      this.toasts.push({
        title: title,
        content: content
      })
    },
    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time))
    },
    updateAnalysisRequest(measureID, newStatus, newMeasurementType) {
      if ((this.$route.params.requestID) && (this.$route.params.requestID != 0)) {
        AnalysisRequestService.getSingleAnalysisRequest(this.$route.params.requestID).then(
          (response) => {
            let newAnalysisRequest = response.data
            const userData = JSON.parse(localStorage.getItem('user'))
            let queryParams = {
              'request_Type': newMeasurementType,
              'request_Handled_By': userData.user.id,
              'idMeasurement': measureID
              //'notify_User': (newStatus == 2)
            }            
            if (newAnalysisRequest.active_Forward_Process) {
              queryParams['forward_Status'] = newStatus
            } else {
              queryParams['request_Status'] = newStatus
            }
            AnalysisRequestService.updateAnalysisRequest(this.$route.params.requestID, queryParams).then(
              () => { },
              (error) => {
                this.message =           
                (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message ||
                error.toString()
              }
            )
          },
          (error) => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          } 
        )         
      }
    },
    startAnalysisRequestProcess(labReportID, newMeasurementType) {
      // Ask to create a new Analysis request to manage it like the customer requested it.
      this.labReportData = {
        'idLabReport': labReportID,
        'requestType': newMeasurementType
      }
      this.customerEMailSelectionVisible = true
    },
    createAnalysisRequest(params) {
      if (params) {
        AnalysisRequestService.createAnalysisRequest(params).then(
          () => {
            let requestType = params.request_Type
            this.createToast(requestType == 1 ? this.$t('labReportSuccesfullyRequested') : this.$t('waterAnalysisSuccesfullyRequested'),
                            this.$t('redirectingToList', {list: requestType == 1 ? this.$t('labReport') : this.$t('waterAnalysis')}))
            this.delay(2000).then(
              () => {
                let queryParams = {
                  'idMachine': this.currMachine.id,
                  'labReportType': Number(requestType) - 1,
                  'idLabReport': params.idMeasurement
                }
                this.$router.replace({ name: this.isMobile() ? 'mLabReportDetails' : 'LabReportDetails', params: queryParams })
              }
            )
          },
          (error)  => {
            this.message =           
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()
          }
        )
      }
    }, 
    onCloseCustomerEmailSelection() {
      this.customerEMailSelectionVisible = false
    }, 
    isMobile() {
      let mobile = localStorage.getItem('isMobile')
      return (mobile === 'true')
    },
    popupMachineSelection() {
      this.machineSelectionModalVisible = true
      this.dataLoaded = true
    },
    onCloseMachineSelection(machineID) {
      if ((machineID) && (machineID != 0)) {     
        this.machineID = machineID
        this.requestDataRetrieved = true
        this.dataLoaded = false
        this.machineSelectionModalVisible = false
        this.forceMeausermentRefresh()
        this.measurementVisible = true
        // Clear toaster
        this.toasts = []
      }          
    },
    onFinishedCreationAndRefresh(measurementID) {
      if ((measurementID) && (measurementID != 0)) {
        // Update Request First
        this.updateAnalysisRequest(measurementID, 2, 0)
        this.createToast(this.$t('measurementSuccesfullyCreated'), '')
        this.delay(1000).then(
          () => {
            this.machineID = 0
            this.dataLoaded = true
            this.measurementVisible = false
            this.machineSelectionModalVisible = true
          }
        )
      } else {
        if ((this.$route.params.calledByMeasurement) && ((this.currMachine.id) && (this.currMachine.id != 0))) {
          this.$router.replace({ name: this.isMobile() ? 'mMeasurementsMachine' : 'MeasurementsMachine', params: { idMachine: this.currMachine.id } })       
        } else {
          this.$router.back()
        }
      }
    },
    forceMeausermentRefresh() {
      this.measurementDataKey += 1
    },
    onFinishedCreationAndNewLabReport(measurementID) {
      if ((measurementID) && (measurementID != 0)) {
        this.createdMeasureID = measurementID
        if (this.isMobile()) {
          this.labReportRequestMobileVisible = true
        } else {
          this.labReportRequestVisible = true
        }
      } else {
        if ((this.$route.params.calledByMeasurement) && ((this.currMachine.id) && (this.currMachine.id != 0))) {
          this.$router.replace({ name: this.isMobile() ? 'mMeasurementsMachine' : 'MeasurementsMachine', params: { idMachine: this.currMachine.id } })       
        } else {        
          this.$router.back()
        }
      }
    },
    onCloseAnalysisRequest() {
      this.labReportRequestMobileVisible = false
      this.labReportRequestVisible = false
      if ((this.$route.params.calledByMeasurement) && ((this.currMachine.id) && (this.currMachine.id != 0))) {
        this.$router.replace({ name: this.isMobile() ? 'mMeasurementsMachine' : 'MeasurementsMachine', params: { idMachine: this.currMachine.id } })        
      } else {      
        this.$router.back()
      }
    },
    logError(error) {
      this.message = error
    }            
  },
  components: {
    NewMeasurement,
    NewLabReport,
    MachineSelectionModalMobile,
    NewLabReportRequestMobile,
    NewLabReportRequest,
    CustomerEmailSelection
  }
}
</script>