import axios from 'axios'
import authHeader from './auth-header'
import formatQuery from './query-helper'

const API_URL = process.env.VUE_APP_SERVER_BASE_URL + 'machine-assigned-materials'

class AssignedMaterialsService {
    getMaterials(queryParams) {
        return axios.get(API_URL + formatQuery(queryParams), { headers: authHeader() })
    }
    updateMaterials(compositeKeyParams, materialParams) {
        return axios.patch(API_URL + formatQuery(compositeKeyParams), materialParams, { headers: authHeader() })
    }
    assignMaterial(materialParams) {
        return axios.post(API_URL, materialParams, { headers: authHeader() })
    }
    deleteMaterial(compositeKeyParams) {
        return axios.delete(API_URL + formatQuery(compositeKeyParams), {headers: authHeader() })
    }
    // Build other api calls here....   
}

export default new AssignedMaterialsService()