<template>
  <CCard fluid>
    <CCardBody>
      <CCardTitle>{{ $t('openAnalysisRequests') }}</CCardTitle>
      <CCardSubtitle class="mb-2 text-muted">{{ $t('openAnalysisDescription') }}</CCardSubtitle> 
      <analysis-requests-lookup :key="analysisLookupKey"
        :excludeClosed="excludeClosedAnalysis"
        :initialFilter="returnAnalysisRequestFilter"
        @itemSelected="processRequest($event)"
        @showAttachment="viewAttachments($event)"
        @showComment="showComment($event)"/>
        
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <CButton type="button" color="info" shape="rounded-0" id="viewCustomer"
          @click="toggleCustomerView()">{{ $t('viewCustomer') }}</CButton>      
        <CButton type="button" color="dark" shape="rounded-0" id="viewAll"
          @click="() => { excludeClosedAnalysis = !excludeClosedAnalysis }">{{ $t('viewAll') }}</CButton>
      </div>            
   </CCardBody>

    <CToaster placement="top-end">
      <CToast color="dark" class="text-white" v-for="(toast, index) in toasts" :key="index">
        <CToastHeader closeButton>
        <span class="me-auto fw-bold">{{ toast.title }}</span>
        <small>{{ $t('now') }}</small>
        </CToastHeader>
        <CToastBody>
          {{ toast.content }}
        </CToastBody>  
      </CToast>
    </CToaster>

    <CModal alignment="center" :visible="commentVisible" @close="() => { commentVisible = false }">
      <CModalHeader>
        <CModalTitle>{{ $t('comment') }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CFormTextarea id="commentToDisplay" rows="5" :value="selectedRequest.comment" readonly /><br>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="() => { commentVisible = false }">
          {{ $t('close') }}
        </CButton>        
      </CModalFooter>
    </CModal>    

  </CCard>
</template>

<script>
import AnalysisRequestsLookup from './AnalysisRequestsLookupMobile.vue'
import AnalysisRequestService from '../../services/analysis-requests'
import { RequestType, RequestStatus } from '../../helpers/getEnumValues'
import PermissionManager from '../../helpers/permissionManager'

export default {
  name: 'CustomerAnalysisRequestMobile',
  data() {
    return {
      selectedRequest: '',
      selectedRequestID: 0,
      excludeClosedAnalysis: true,
      toasts: [],
      commentVisible: false,
      showCustomerRequests: false,
      analysisLookupKey: 0      
    }
  },
  computed: {
    returnAnalysisRequestFilter() {
      const userCustomer = PermissionManager.getCustomerFilteredVisibility()
      if ((userCustomer) && (this.showCustomerRequests)) {
        return {
          'idCustomer': userCustomer.idCustomer
        }
      } else {
        return null
      }
    },
  },
  methods: {
    processRequest(requestID) {
      if (requestID) {
        // 1. Get Analysis Request to process it later...
        this.selectedRequestID = requestID
        AnalysisRequestService.getSingleAnalysisRequest(requestID).then(
          (response) => {
            this.selectedRequest = response.data
            if (this.getRequestStatus() < 2) {
              this.createToast(this.$t('requestStatusNotCompleted'), this.$t('cannotAccessDetails'))
            } else {
              if ((this.selectedRequest.idMeasurement) && (this.selectedRequest.idMeasurement != 0)) {
                switch(this.selectedRequest.request_Type) {
                  case 'Measurement':
                    // To Update....
                    this.$router.push({ name: 'mMeasurementsMachine', params: { 'idMachine': this.selectedRequest.idMachine, 'idMeasure':  this.selectedRequest.idMeasurement} })
                    break
                  case 'Lab Report':
                    this.$router.push({ name: 'mLabReportDetails', params: { 'idMachine': this.selectedRequest.idMachine, 'labReportType': 0, 'idLabReport': this.selectedRequest.idMeasurement }})  
                    break                
                  case 'Water Analysis':
                    this.$router.push({ name: 'mLabReportDetails', params: { 'idMachine': this.selectedRequest.idMachine, 'labReportType': 1, 'idLabReport': this.selectedRequest.idMeasurement }})  
                    break                  
                  default:
                    break
                }
              } else {
                this.commentVisible = true
              }
            }
          }
        )        
      }
    },
    getRequestType() {
      if ((this.selectedRequest.request_Type) && (this.selectedRequest.request_Type != '')) {
        return RequestType.indexOf(this.selectedRequest.request_Type)
      }
    },
    getRequestStatus() {
      if ((this.selectedRequest.request_Status) && (this.selectedRequest.request_Status != '')) {
        return RequestStatus.indexOf(this.selectedRequest.request_Status)
      }
    },    
    handleRequest(requestType) {
      if (!requestType) {
        requestType = this.getRequestType()
      }
      this.handleRequestVisible = false
      this.$router.push({ name: 'mMeasure', params: { requestID: this.selectedRequestID, measurementType: requestType } })
    },
    viewAttachments(chosenRequest) {
      if ((chosenRequest.attached_idMeasure) && (chosenRequest.attached_idMeasure != 0)) {
        this.$router.push({ name: 'mMeasurementsMachine', params: { 'idMachine': chosenRequest.idMachine, 'idMeasure': chosenRequest.attached_idMeasure } })
      } else {
        this.createToast(this.$t('noAttachmentProvided'), this.$t('noAttachmentProvidedDetails'))
      }
    },
    createToast(title, content) {
      this.toasts.push({
        title: title,
        content: content
      })
    },
    showComment(requestID) {
      if (requestID) {
        this.selectedRequestID = requestID
        AnalysisRequestService.getSingleAnalysisRequest(requestID).then(
          (response) => {
            this.selectedRequest = response.data
            if (this.getRequestStatus() < 2) {
              this.createToast(this.$t('requestStatusNotCompleted'), this.$t('cannotAccessDetails'))
            } else {
              this.commentVisible = true
            }
          }
        )        
      }      
    },
    toggleCustomerView() {
      this.showCustomerRequests = !this.showCustomerRequests
      this.analysisLookupKey += 1
    }     
  },
  components: {
    AnalysisRequestsLookup
  }
}
</script>