<template>
  <CModal size="xl" :visible="cModalVisible" @show="reloadData()" @close="closeRequestCard()">
    <CModalHeader>
      <CModalTitle>{{ $t('analysisRequestTitle') }} ({{requestID}})</CModalTitle>
    </CModalHeader>
    <CModalBody v-if="dataLoaded">
      <CRow class="mb-3">
        <CFormLabel for="requestedByUser" class="col-sm-2 col-form-label">{{ pretifyName('analysisRequestFields.requested_By_User') }}</CFormLabel>
        <CCol sm="10">
          <CFormInput type="text" id="requestedByUser" v-model="currRequest.requested_By_User_Name" readonly />
        </CCol>
      </CRow>
      <CRow class="mb-3">
        <CFormLabel for="handledByUser" class="col-sm-2 col-form-label">{{ pretifyName('analysisRequestFields.handled_By') }}</CFormLabel>
        <CCol sm="10">
          <CFormInput type="text" id="handledByUser" v-model="currRequest.request_Handled_By_Name" readonly/>
        </CCol>
      </CRow>      
      <CRow class="mb-3">
        <CFormLabel for="creationDate" class="col-sm-2 col-form-label">{{ pretifyName('analysisRequestFields.creation_Date') }}</CFormLabel>
        <CCol sm="10">
          <CFormInput type="text" id="creationDate" :value="analysisDateFormatted" readonly />
        </CCol>
      </CRow>
      <CRow>
        <CFormLabel for="requestStatus" class="col-sm-2 col-form-label">{{ pretifyName('analysisRequestFields.status') }}</CFormLabel>
        <CCol sm="10">
          <CFormSelect class="mb-3" aria-label="status select" id="requestStatus" :value="getRequestStatus(currRequest.request_Status)" disabled >
            <option value="0">{{ $t('statusOptions.new') }}</option>
            <option value="1">{{ $t('statusOptions.analysing') }}</option>
            <option value="2">{{ $t('statusOptions.closed') }}</option>
          </CFormSelect>
        </CCol>      
      </CRow>      

      <CCard class="mt-3" color="light" v-if="currRequest.type > 0">
        <CCardBody>
          <CCardTitle>{{ additionalDataTitle }}</CCardTitle>
          <CRow class="mb-3">
            <CCol sm="12">
              <CFormLabel for="reasonOfAnalysis"><strong>{{ pretifyName('labReportFields.reason_Of_Analysis') }}</strong></CFormLabel>
              <CFormTextarea id="reasonOfAnalysis" rows="5" v-model="currRequest.reason_Of_Analysis" readonly/>   
            </CCol>
          </CRow>
          <CRow v-if="currRequest.type == 1">
            <CCol sm="6">
              <CFormLabel for="smell"><strong>{{ pretifyName('labReportFields.smell') }}</strong></CFormLabel>
              <CFormTextarea id="smell" rows="5" v-model="currRequest.smell" readonly/>   
            </CCol>
            <CCol>
              <CFormLabel for="cleannessOfWorkstation"><strong>{{ pretifyName('labReportFields.cleanness_Of_Workstation') }}</strong></CFormLabel>
              <CFormTextarea id="cleannessOfWorkstation" rows="5" v-model="currRequest.cleannes_Of_Workstation" readonly/>   
            </CCol>       
          </CRow>  
          <CRow v-if="currRequest.type == 1">
            <CCol sm="6">
              <CFormLabel for="batchNumber"><strong>{{ pretifyName('labReportFields.batch_Number') }}</strong></CFormLabel>
              <CInputGroup>
                <CInputGroupText>ST</CInputGroupText>               
                <CFormInput type="text" id="batchNumber" v-model="currRequest.batch_Number" readonly />
              </CInputGroup>
            </CCol>           
            <CCol sm="6">
              <br>
              <CFormCheck type="checkbox" id="floatingOilPhase" :label="pretifyName('labReportFields.floating_Oil_Phase')" v-model="currRequest.floating_Oil_Phase" disabled/>
            </CCol>      
          </CRow>          
        </CCardBody>
      </CCard>
    </CModalBody>
    <CModalFooter>
      <CButton type="button" color="dark" variant="outline" id="downloadRequest"
        @click="downloadRequest()">
        <CIcon size="lg" icon="cil-cloud-download"/>
        {{ $t('downloadRequest') }}
      </CButton>    
      <CButton color="secondary" @click="closeRequestCard()">
        {{ $t('close') }}
      </CButton>
    </CModalFooter>   

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </CModal>
</template>

<script>
import AnalysisRequestService from '../services/analysis-requests'
import StringFormatter from '../helpers/stringFormatter'
import { ReportStatus, RequestType } from '../helpers/getEnumValues'
import DateFormatter from '../helpers/dateFormatter'
import AnalysisRequestSummary from '../reports/AnalysisRequestSummary'

export default {
  name: 'AnalysisRequestCard',
  props: {
    cModalVisible: Boolean,
    requestID: Number
  },
  data() {
    return {
      message: '',
      currRequest: '',
      dataLoaded: false          
    }
  },
  computed: {
    additionalDataTitle() {
      if (!this.currRequest) {
        return ''
      }
      return (this.currRequest.type == 1 ? this.$t('labReport') : this.$t('waterAnalysis')) 
    },
    analysisDateFormatted() {
      return (DateFormatter.formatDate(this.currRequest.creation_Date))
    }    
  },
  methods: {
    getAnalysisRequest() {
      this.dataLoaded = false
      if ((this.requestID) && (this.requestID != 0)) {
        AnalysisRequestService.getSingleAnalysisRequest(this.requestID).then(
          (response) => {
            this.currRequest = response.data
            this.currRequest.type = this.getRequestType()
            this.dataLoaded = true
          },
          (error)  => {
            this.message =           
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validAnalysisRequestIDException')
      }
    },
    closeRequestCard() {
      this.message = ''
      this.dataLoaded = false
      this.$emit('closeRequestCardModal')
    },
    reloadData() {
      this.dataLoaded = false
      this.currRequest = ''
      this.getAnalysisRequest()     
    },
    pretifyName(name) {
      return StringFormatter.pretifyName(this.$t(name))
    },
    getRequestStatus(statusString) {
      if ((statusString) && (statusString != '')) {
        return ReportStatus.indexOf(statusString)
      }
    },
    getRequestType() {
      if ((this.currRequest.request_Type) && (this.currRequest.request_Type != '')) {
        return RequestType.indexOf(this.currRequest.request_Type)
      } else {
        return 0
      }
    },
    downloadRequest(fileName = '') {
      let reportTarget = JSON.parse(JSON.stringify(this.currRequest)) 
      AnalysisRequestSummary.print(reportTarget, this.$t('analysisRequest'), fileName)
    },                                      
  }
}
</script>