<template>
  <CCard fluid v-if="isDataLoaded">
    <CCardBody>
      <CCardTitle>{{ $t('product') }} </CCardTitle>
      <CCardText>{{pretifyName('productFields.id')}}: {{ currProduct.id }} <br> {{pretifyName('productFields.name')}}: {{ currProduct.product }}<br>
       {{pretifyName('productFields.status')}}: {{ currProduct.status }}<br> {{pretifyName('productFields.fluid_Supplier')}}: {{ currProduct.fluid_Supplier_Name }}<br>
       {{pretifyName('productFields.technology')}}: {{ currProduct.technology_Name }}<br> {{pretifyName('productFields.critical_pH')}}: {{ currProduct.critical_pH }}<br> 
       {{pretifyName('productFields.refractometer_Factor')}}: {{ currProduct.refactormeter_Factor }}<br> 
       {{pretifyName('productFields.product_Number')}}: {{ currProduct.product_Number }}</CCardText>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <CButton type="submit" color="dark" shape="rounded-0" class="mb-3"
          @click="() => { productCardModal = !productCardModal }">
          {{ $t('editProduct') }}
        </CButton>
      </div>
    </CCardBody>

    <product-card
      :cModalVisible="productCardModal"
      operationType="0"
      :productID="productID"
      @closeProductCardModal="toggleProdCardModalVisibility($event)"/>

  </CCard>
</template>

<script>
import ProductService from '../services/products'
import ProductCard from './ProductCard.vue'
import StringFormatter from '../helpers/stringFormatter'

export default {
  name: 'ProductInfobox',
  props: {
    productID: Number
  },
  expose: ['dataLoaded'],
  data() {
    return {
      dataLoaded: false,
      currProduct: '',
      lastProductID: 0,
      productCardModal: false
    }
  },
  computed: {
    isDataLoaded() {
      if (this.productID != this.lastProductID) {
        this.refreshData()
      }
      if (!this.dataLoaded) {
        this.getProductData()
      }
      return this.dataLoaded
    }
  },
  methods: {
    getProductData() {
      if (this.productID != 0) {
        ProductService.getSingleProduct(this.productID).then(
          (response) => {
            this.currProduct = response.data
            this.lastProductID = this.currProduct.id
            this.dataLoaded = true
          }
        )
      } else {
        this.dataLoaded = true
      }
    },
    refreshData() {
      this.dataLoaded = false
    },
    toggleProdCardModalVisibility(refreshPage) {
      this.productCardModal = !this.productCardModal
      if (refreshPage) {
        this.dataLoaded = false
      }
    },
    pretifyName(name) {
      return StringFormatter.pretifyName(this.$t(name))
    }    
  },
  components: {
    ProductCard
  }
}
</script>