<template>
  <CModal size="lg" :visible="cModalVisible" @show="loadMachine()" @close="closeMachineCheck()">
    <CModalBody v-if="dataLoaded">
      <CRow>
        <CCol>
          <CFormLabel for="product">
            <strong>{{ pretifyName('machineFields.product') }}</strong>
             - <small>({{machineData.product_Name}}) </small>
            <CSpinner color="dark" size="sm" v-if="!productsLoaded"/> 
          </CFormLabel>
          <CFormSelect class="mb-3" aria-label="product select" id="product" v-model="machineData.idProduct">
            <option v-for="(product, index) in orderedProductData" :key="index"
              :value="product.id">{{ product.product }}</option>
          </CFormSelect>        
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CFormLabel for="lastFilled"><strong>{{ pretifyName('machineFields.last_Filled') }}</strong></CFormLabel>
          <CFormInput type="date" id="lastFilled" v-model="machineData.dateFormatted" />    
        </CCol>
      </CRow>     
    </CModalBody>
  </CModal>
</template>

<script>
import MachineService from '../../services/machines'
import StringFormatter from '../../helpers/stringFormatter'
import DateFormatter from '../../helpers/dateFormatter'
import ProductLightService from '../../services/products-light'

export default {
  name: "MachineContentCheckMobile",
  props: {
    machineID: Number,
    cModalVisible: Boolean
  },
  data() {
    return {
      machineData: [],
      initialData: [],
      dataLoaded: false,
      productData: [],
      productsLoaded: false
    }
  },
  computed: {
    orderedProductData() {
      let processedData = [...this.productData]
      if (this.productData.length > 0) {
        processedData = processedData.sort((a,b) => a.product.localeCompare(b.product))
      }
      return processedData
    },
  },
  created() {
    this.getProductsGradually()
  }, 
  methods: {
    loadMachine() {
      this.dataLoaded = false
      this.machineData = []
      this.initialData = []
      MachineService.getSingleMachine(this.machineID).then(
        (response) => {
          this.machineData = response.data
          this.machineData.dateFormatted = DateFormatter.formatDateNumbers(this.machineData.last_Filled)
          this.initialData = { ...this.machineData }
          this.dataLoaded = true
        },
        () => {
          this.closeMachineCheck()
        }
      )  
    },
    getProductsGradually(skipParam) {
      if (!skipParam) {
        this.productsLoaded = false
        this.productData = []
      }      
      let paramQuery = {
        'status': 0, // Active only
        '$limit': 50
      }      
      if (skipParam) {
        paramQuery['$skip'] = skipParam
      }
      ProductLightService.getProducts(paramQuery).then(
        (response) => {
          if (this.productData.length > 0) {
            this.productData = this.productData.concat(response.data.data)
          } else {
            this.productData = response.data.data
          }
          // this.dataLoaded = true
          if (response.data.total > response.data.limit + response.data.skip) {
            if (skipParam) {
              skipParam += response.data.limit
            } else {
              skipParam = response.data.limit
            }
            this.getProductsGradually(skipParam)
          } else {
            this.productsLoaded = true
          }
        },
        () => {
          this.closeMachineCheck()
        }        
      )      
    },
    closeMachineCheck() {
      let modifiedParameters = {}
      if (this.initialData.idProduct) {
        if (this.initialData.idProduct != this.machineData.idProduct) {
          modifiedParameters.idProduct = this.machineData.idProduct
          modifiedParameters.last_Filled = this.machineData.dateFormatted
        }
        if (this.initialData.dateFormatted != this.machineData.dateFormatted) {
          modifiedParameters.last_Filled = this.machineData.dateFormatted
        }
      } else if (this.machineData.idProduct) {
        modifiedParameters.idProduct = this.machineData.idProduct
        modifiedParameters.last_Filled = this.machineData.dateFormatted        
      }
      if ((modifiedParameters.idProduct) || (modifiedParameters.last_Filled)) {
        modifiedParameters.sump_Size = this.machineData.sump_Size
        this.$emit('closeMachineCheck', modifiedParameters)  
      } else {
        this.$emit('closeMachineCheck')
      }
    },
    pretifyName(name) {
      return StringFormatter.pretifyName(this.$t(name))
    },
  }

}
</script>