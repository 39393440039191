class DataMapper {
  getTechnologyId(currID) {
    if (!currID) {
      return null
    }
    switch(Number(currID)) {
      case 4:
        return 6   
      case 5:
        return 7                          
      case 6:
        return 8
      case 7:
        return 9        
      case 8:
        return 11
      case 9:
        return 11  
      case 10:
        return 12
      case 11:
        return 13
      case 12:
        return 14       
      case 13:
        return 15
      case 14:
        return 16  
      case 15:
        return 17
      case 16:
        return 4
      case 17:
        return 5        
      default:
        return Number(currID)                                      
    }
  }
  getWaterOptionValue(currID) {
    if (!currID) {
      return 0
    }
    return (Number(currID) - 1)
  }
  getMachineTolerances(fromToleranceName, fromToleranceValue, reportType) {
    if ((!fromToleranceName) || (!fromToleranceValue)) {
      return []
    }
    if (fromToleranceValue == 1.7976931348623157e308) {
      return []
    }
    let resultingArray = []
    switch(fromToleranceName) {
      case 'concentration':
        resultingArray.push({'id': 25, 'tolerance': fromToleranceValue })
        break
      case 'trampOil':
        resultingArray.push({'id': 10, 'tolerance': fromToleranceValue })
        break
      case 'effectiveConcentration':
        resultingArray.push({'id': 11, 'tolerance': fromToleranceValue })
        break
      case 'titrationEmulsion':
        resultingArray.push({'id': 28, 'tolerance': fromToleranceValue })
        break
      case 'productSpecificValue':
        resultingArray.push({'id': 24, 'tolerance': fromToleranceValue })
        break
      case 'refractometerReading':
        resultingArray.push({'id': 12, 'tolerance': fromToleranceValue })
        break
      case 'correctionFactor':
        resultingArray.push({'id': 29, 'tolerance': fromToleranceValue })
        break
      case 'phElectrometric':
        if ((reportType == 1) || (!reportType)) {
          resultingArray.push({'id': 2, 'tolerance': fromToleranceValue })
        }
        if ((reportType == 0) || (!reportType)) {
          resultingArray.push({'id': 13, 'tolerance': fromToleranceValue })
        }
        break
      case 'conductivity':
        if ((reportType == 1) || (!reportType)) {
          resultingArray.push({'id': 3, 'tolerance': fromToleranceValue })
        }
        if ((reportType == 0) || (!reportType)) {          
          resultingArray.push({'id': 14, 'tolerance': fromToleranceValue })
        }
        break
      case 'cunductivity':
        if ((reportType == 1) || (!reportType)) {
          resultingArray.push({'id': 3, 'tolerance': fromToleranceValue })
        }
        if ((reportType == 0) || (!reportType)) {          
          resultingArray.push({'id': 14, 'tolerance': fromToleranceValue })
        }
        break        
      case 'bacterias':
        if ((reportType == 1) || (!reportType)) {        
          resultingArray.push({'id': 8, 'tolerance': fromToleranceValue })
        }
        if ((reportType == 0) || (!reportType)) {            
          resultingArray.push({'id': 19, 'tolerance': fromToleranceValue })
        }
        break 
      case 'fungiYeast':
        if ((reportType == 1) || (!reportType)) {          
          resultingArray.push({'id': 9, 'tolerance': fromToleranceValue })
        }
        if ((reportType == 0) || (!reportType)) {             
          resultingArray.push({'id': 20, 'tolerance': fromToleranceValue })
        }
        break
      case 'finghiYeast':
        if ((reportType == 1) || (!reportType)) {          
          resultingArray.push({'id': 9, 'tolerance': fromToleranceValue })
        }
        if ((reportType == 0) || (!reportType)) {             
          resultingArray.push({'id': 20, 'tolerance': fromToleranceValue })
        }
        break        
      case 'concentrationAcidSplit':
        resultingArray.push({'id': 30, 'tolerance': fromToleranceValue })
        break
      case 'totalHardnessEmulsion':
        resultingArray.push({'id': 21, 'tolerance': fromToleranceValue })
        break
      case 'nitrite':
        // Evaluate value first...
        if (isNaN(Number(fromToleranceValue))) {
          return []
        }
        if ((reportType == 1) || (!reportType)) {             
          resultingArray.push({'id': 4, 'tolerance': Number(fromToleranceValue) })
        }
        if ((reportType == 0) || (!reportType)) {            
          resultingArray.push({'id': 15, 'tolerance': Number(fromToleranceValue) })
        }
        break
      case 'nitrate':
        // Evaluate value first...
        if (isNaN(Number(fromToleranceValue))) {
          return []
        }        
        if ((reportType == 1) || (!reportType)) {                  
          resultingArray.push({'id': 5, 'tolerance': Number(fromToleranceValue) })
        }
        if ((reportType == 0) || (!reportType)) {              
          resultingArray.push({'id': 16, 'tolerance': Number(fromToleranceValue) })
        }
        break
      case 'depositOfWear':
        // Evaluate value first...
        if (isNaN(Number(fromToleranceValue))) {
          return []
        }          
        resultingArray.push({'id': 22, 'tolerance': Number(fromToleranceValue) })
        break
      case 'corrosionDegree':
        // Evaluate value first...
        if (isNaN(Number(fromToleranceValue))) {
          return []
        }          
        resultingArray.push({'id': 23, 'tolerance': Number(fromToleranceValue) })
        break
      case 'productSpecificValueAlkalinity':
        resultingArray.push({'id': 31, 'tolerance': fromToleranceValue })
        break
      case 'productSpecificValueAcidSplit':
        resultingArray.push({'id': 32, 'tolerance': fromToleranceValue })
        break
      case 'chloride':
        // Evaluate value first...
        if (isNaN(Number(fromToleranceValue))) {
          return []
        }        
        if ((reportType == 1) || (!reportType)) {          
          resultingArray.push({'id': 6, 'tolerance': Number(fromToleranceValue) })
        }
        if ((reportType == 0) || (!reportType)) {            
          resultingArray.push({'id': 17, 'tolerance': Number(fromToleranceValue) })
        }
        break 
      case 'sulfate':
        // Evaluate value first...
        if (isNaN(Number(fromToleranceValue))) {
          return []
        }        
        if ((reportType == 1) || (!reportType)) {           
          resultingArray.push({'id': 7, 'tolerance': Number(fromToleranceValue) })
        }
        if ((reportType == 0) || (!reportType)) {            
          resultingArray.push({'id': 18, 'tolerance': Number(fromToleranceValue) })
        }
        break
      case 'waterHardness':
        resultingArray.push({'id': 1, 'tolerance': fromToleranceValue })
        break 
      case 'mixedPhaseAcidSplit':
        // Evaluate value first...
        if (isNaN(Number(fromToleranceValue))) {
          return []
        }          
        resultingArray.push({'id': 26, 'tolerance': Number(fromToleranceValue) })
        break     
      case 'mineralOilContent':
        // Evaluate value first...
        if (isNaN(Number(fromToleranceValue))) {
          return []
        }          
        resultingArray.push({'id': 27, 'tolerance': Number(fromToleranceValue) })
        break                   
      default:
        break                                                                  
    }
    return resultingArray
  }
  getResultIndicator(fromIndicator) {
    //if (!fromIndicator) {
    //  return 0
    //}
    switch(fromIndicator) {
      case 0:
        return 3
      case 1:
        return 2
      case 2:
        return 1
      default:
        return 0
    }
  }
}

export default new DataMapper()