import { Languages } from './getEnumValues' 

class LanguageParser {
  getLanguage(fromLanguageString) {
    // Set User Language...
    let computedLanguage = 'en'
    if (fromLanguageString) {
      let currLanguage = Languages.indexOf(fromLanguageString)
      switch (currLanguage) {
      case 1:
        computedLanguage = 'de'
        break
      case 2:
        computedLanguage = 'it'
        break
      case 3:
        computedLanguage = 'fr'
        break        
      default:
        break
      }
    }
    return computedLanguage
  }
  setLanguage(language) {
    if (language) {
      localStorage.setItem('selectedLanguage', language)        
    } else {
      localStorage.removeItem('selectedLanguage')
    }
  }
  getStoredLanguage() {
    let currLanguage = localStorage.getItem('selectedLanguage')
    return (currLanguage ? currLanguage : 'en')
  }
}

export default new LanguageParser()