<template>
  <CModal backdrop="static" size="xl" :visible="cModalVisible" @show="initData()" @close="closeMachineSelection()">
    <CModalHeader>
      <CModalTitle>{{ $t('machineSelection') }}</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <CRow class="mb-3">
        <CFormLabel for="requestType" class="col-sm-2 col-form-label">{{ $t('requestType') }}</CFormLabel>
        <CCol sm="10">
          <CFormSelect class="mb-3" aria-label="request type select" id="requestType" v-model="requestType">
            <option value="0">{{ $t('requestTypeOpt1') }}</option>
            <option value="1">{{ $t('requestTypeOpt2') }}</option>
          </CFormSelect>
        </CCol>
      </CRow>     
      <CRow class="mb-3">
        <CFormLabel for="selectedMachine" class="col-sm-2 col-form-label">{{ $t('selectMachine') }}</CFormLabel>
        <CCol sm="10">
          <CInputGroup class="mb-3">
            <CFormInput type="text" id="selectedMachine" v-model="selectedMachine.name" readonly/>
            <CButton type="button" color="dark" variant="outline" id="searchMachine"
              @click="machineLookupVisible = !machineLookupVisible">{{ $t('search') }}</CButton>
          </CInputGroup>
        </CCol>
      </CRow>
    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="closeMachineSelection()">
        {{ $t('close') }}
      </CButton>
      <CButton color="primary" @click="confirmAction()">{{ $t('process') }}</CButton>
    </CModalFooter>    

    <CModal size="xl" alignment="center" :visible="machineLookupVisible" @close="() => { machineLookupVisible = false }">
      <CModalHeader>
        <CModalTitle>{{ $t('machines') }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <machines-lookup-mobile v-if="isMobile"
          @itemSelected="getMachine($event)" />
        <machines-lookup v-if="!isMobile"
          @itemSelected="getMachine($event)" />          
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="() => { machineLookupVisible = false }">
          {{ $t('close') }}
        </CButton>
      </CModalFooter>
    </CModal> 

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </CModal>
</template>

<script>
import MachineService from '../services/machines'
import MachinesLookup from './MachinesLookup.vue'
import MachinesLookupMobile from './mobile/MachinesLookupMobile.vue'

export default {
  name: 'LabReportSelectionModal',
  props: {
    cModalVisible: Boolean
  },
  data() {
    return {
      selectedMachine: '',
      machineLookupVisible: false,
      message: '',
      requestType: 0
    }
  },
  computed: {
    isMobile() {
      let mobile = localStorage.getItem('isMobile')
      return (mobile === 'true')
    }
  },
  methods: {
    closeMachineSelection() {
      this.message = ''
      this.$emit('closeMachineSelection')
    },
    initData() {
      this.message = ''
      this.selectedMachine = '',
      this.machineLookupVisible = false
    },
    getMachine(machineID) {
      this.selectedMachineID = machineID
      if (machineID != 0) {
        MachineService.getSingleMachine(machineID).then(
          (response) => {
            this.selectedMachine = response.data
            this.machineLookupVisible = false
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validMachineIDException')
      }
    },
    confirmAction() {
      if ((!this.selectedMachine.id) || (this.selectedMachine.id == 0)) {
        this.message = this.$t('validMachineSelectionException')
      } else {
        let selectedParameters = {
          labReportType: this.requestType,
          idMachine: this.selectedMachine.id
        }
        this.$emit('closeMachineSelection', selectedParameters)
      }
    }       
  },
  components: {
    MachinesLookup,
    MachinesLookupMobile
  }
}
</script>