import axios from 'axios'
import authHeader from './auth-header'
import formatQuery from './query-helper'

const API_URL = process.env.VUE_APP_SERVER_BASE_URL + 'characteristics'

class CharacteristicsService {
    getCharacteristics(queryParams) {
        return axios.get(API_URL + formatQuery(queryParams), { headers: authHeader() })
    }
    updateCharacteristic(characteristicID, characteristicParams) {
        return axios.patch(API_URL + '/' + characteristicID, characteristicParams, { headers: authHeader() })
    }
    createCharacteristic(characteristicParams) {
        return axios.post(API_URL, characteristicParams, { headers: authHeader() })
    }
    deleteCharacteristic(characteristicID) {
        return axios.delete(API_URL + '/' + characteristicID, {headers: authHeader() })
    }
    // Build other api calls here....   
}

export default new CharacteristicsService()