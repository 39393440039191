import axios from 'axios'
import authHeader from './auth-header'
import formatQuery from './query-helper'

const API_URL = process.env.VUE_APP_SERVER_BASE_URL + 'analysis-requests'

class AnalysisRequestService {
    getAnalysisRequests(queryParams) {
        return axios.get(API_URL + formatQuery(queryParams), { headers: authHeader() })
    }
    getSingleAnalysisRequest(requestID) {
        return axios.get(API_URL + '/' + requestID, { headers: authHeader() })
    }    
    updateAnalysisRequest(requestID, analysisParams) {
        return axios.patch(API_URL + '/' + requestID, analysisParams, { headers: authHeader() })
    }
    createAnalysisRequest(analysisParams) {
        return axios.post(API_URL, analysisParams, { headers: authHeader() })
    }
    deleteAnalysisRequest(requestID) {
        return axios.delete(API_URL + '/' + requestID, {headers: authHeader() })
    }
    // Build other api calls here....   
}

export default new AnalysisRequestService()