<template>
  <div>
    <div class="row">
      <div class="col-12 col-8-tablet push-2-tablet text-center">
        <CIcon size="xxl" icon="cil-settings"/>
        <h3 class="title">{{ $t('users') }}</h3>
      </div>
    </div>

    <h4>{{ $t('waitingForPermissionAssignment') }}</h4>

    <users-lookup v-if="isDataLoaded"
      :excludeUsers="assignedUsers"
      :appendButtons="appendButtons()"
      @itemSelected="managePermissions($event)"
      @editItem="manageUserAssignment($event)" />

    <h4>{{ $t('assignedPermissions') }}</h4>

    <users-lookup v-if="isDataLoaded"
      :excludeUsers="usersToAssign"
      :appendButtons="appendButtons()"
      @itemSelected="managePermissions($event)"
      @editItem="manageUserAssignment($event)" />

    <user-permissions
      :cModalVisible="permissionCardModal"
      :userID="selectedUserID"
      @closePermissionModal="refreshData()"/>

    <user-assignment-modal
      :cModalVisible="userAssignmentModal"
      :userID="selectedUserID"
      @closeUserAssignmentModal="onCloseUserAssignement($event)" />

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </div>
</template>

<script>
import UsersLookup from './UsersLookupMobile.vue'
import UserPermissionsService from '../../services/user-permissions'
import UserService from '../../services/users'
import UserPermissions from '../../components/UserPermissions.vue'
import UserAssignmentModal from '../../components/UserAssignmentModal.vue'

export default {
  name: 'UsersMobile',
  data() {
    return {
      dataLoaded: false,
      usersRetrieved: false,
      usersPermissionsRetrieved: false,
      userData: '',
      userPermissionsData: '',
      assignedUsers: [],
      usersToAssign: [],
      message: '',
      permissionCardModal: false,
      selectedUserID: 0,
      userAssignmentModal: false
    }
  },
  created() {
    this.getUsers()
    this.getUserPermissions()
  },
  computed: {
    isDataLoaded() {
      if (!this.dataLoaded) {
        if ((this.usersRetrieved) && (this.usersPermissionsRetrieved)) {
          this.fillUsersToSkip()
        }
      }
      return this.dataLoaded
    }
  },
  methods: {
    getUserPermissions() {
      UserPermissionsService.getUserPermissions({ '$limit': 5000 }).then(
        (response) => {
          this.userPermissionsData = response.data.data
          this.usersPermissionsRetrieved = true
        },
        (error)  => {
          this.message =           
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        }
      )
    },
    getUsers() {
      UserService.getUsers({ '$limit': 5000 }).then(
        (response) => {
          this.userData = response.data.data
          this.usersRetrieved = true
        },
        (error)  => {
          this.message =           
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }
      )
    },
    fillUsersToSkip() {
      this.assignedUsers = []
      this.usersToAssign = []
      this.userData.forEach(user => {
        if (this.userPermissionsData.findIndex(permission => permission.idUser == user.id) >= 0) {
          this.assignedUsers.push(user)
        } else {
          this.usersToAssign.push(user)
        }
      })
      this.dataLoaded = true
    },
    appendButtons() {
      let buttonsToAppend = []
      buttonsToAppend.push(this.appendPermissionButton())
      buttonsToAppend.push(this.appendEditButton())
      return buttonsToAppend
    },
    appendPermissionButton() {
      return (
        {
          key: 'permissions', 
          label: '', 
          _style: 'width:1%', 
          sorter: false, 
          filter: false
        }
      )
    },
    appendEditButton() {
      return (
        {
          key: 'edit', 
          label: '', 
          _style: 'width:1%', 
          sorter: false, 
          filter: false
        }
      )
    },
    managePermissions(userID) {
      if ((!userID) || (userID == 0)) {
        this.message = this.$t('unknownUserPermissionException')
      } else {
        this.selectedUserID = userID
        this.permissionCardModal = !this.permissionCardModal
      }
    },
    manageUserAssignment(userID) {
      if ((!userID) || (userID == 0)) {
        this.message = this.$t('unknownUserAssignmentException')
      } else {
        this.selectedUserID = userID
        this.userAssignmentModal = !this.userAssignmentModal
      }
    },
    refreshData() {
      this.permissionCardModal = false
      this.userAssignmentModal = false
      this.dataLoaded = false
      this.usersRetrieved = false
      this.usersPermissionsRetrieved = false
      this.getUsers()
      this.getUserPermissions()
    },
    onCloseUserAssignement(recalcData) {
      if (recalcData) {
        this.refreshData()
      } else {
        this.userAssignmentModal = false
      }
    }
  },
  components: {
    UsersLookup,
    UserPermissions,
    UserAssignmentModal
  }
}
</script>