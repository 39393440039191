<template>
  <div>
    <loading-data v-if="!dataLoaded"/>

    <small><general-table v-if="dataLoaded"
      :data="measurements"
      :columns="tableColumns"
      :rowsdetail="tableRowsDetails"
      :showItemsPerPage="false"
      :sorter="false"
      :columnFilter="false"        
      @itemSelected="$emit('itemSelected', $event)"/></small>

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </div>
</template>

<script>
import GeneralTable from '@/components/GeneralTable.vue'
import MeasurementService from '../../services/measurements'
import DateFormatter from '../../helpers/dateFormatter'
import LoadingData from '../LoadingData.vue'

export default {
  name: 'MeasurementsLookupMobile',
  props: {
    machineID: Number
  },
  data() {
    return {
      dataLoaded: false,
      message: '',
      content: [],
      searchQuery: '',
      tableColumns: [
        { key: 'measurementFields.id', _style: 'min-width: 50px' },
        { key: 'measurementFields.measured_By', _style: 'min-width: 150px' },
        { key: 'measurementFields.pH', _style: 'min-width: 50px' },
        { key: 'measurementFields.refrac_Reading', _style: 'min-width: 100px' },
        { key: 'measurementFields.eff_Concentration', _style: 'min-width: 100px' },
        { key: 'measurementFields.temperature', _style: 'min-width: 90px' },
        { key: 'measurementFields.appareance', _style: 'min-width: 170px' },
        { key: 'measurementFields.measured_At', _style: 'min-width: 140px' },
        {
          key: 'choose', 
          label: '', 
          _style: 'width:1%', 
          sorter: false, 
          filter: false
        }                
      ],
      tableRowsDetails: [
      ]
    }
  },
  created() {
    this.getMeasurementsGradually()
  },
  watch: {
    machineID: {
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          this.dataLoaded = false,
          this.getMeasurements()
        }
      }
    }
  },  
  computed: {
    measurements() {
      let processedData = []
      if (this.content.length > 0) {
        this.content.forEach((measurement) => {
          processedData.push({
            'measurementFields.id': measurement.id,
            'measurementFields.measured_By': measurement.measuredBy,
            'measurementFields.pH': measurement.pH,
            'measurementFields.refrac_Reading': measurement.refrac_Reading,
            'measurementFields.eff_Concentration': measurement.eff_Concentration,
            'measurementFields.temperature': measurement.temperature,
            'measurementFields.appareance': measurement.appearance,
            'measurementFields.measured_At': DateFormatter.formatDate(measurement.measured_At, true)
          })                    
        })
      }
      return processedData
    }
  },
  methods: {
    getMeasurementsGradually(skipParam) {
      if (!skipParam) {
        this.content = []
      }
      let queryParms = {}
      if ((this.machineID) && (this.machineID != 0)) {
        queryParms.idMachine = this.machineID
      }      
      queryParms.$limit = 50
      queryParms['$sort[measured_At]'] = -1
      if (skipParam) {
        queryParms['$skip'] = skipParam
      }
      MeasurementService.getMeasurements(queryParms).then(
        (response) => {
          if (this.content.length > 0) {
            this.content = this.content.concat(response.data.data)
          } else {
            this.content = response.data.data
          }
          this.dataLoaded = true
          if (response.data.total > response.data.limit + response.data.skip) {
            if (skipParam) {
              skipParam += response.data.limit
            } else {
              skipParam = response.data.limit
            }
            this.getMeasurementsGradually(skipParam)
          }
        },
        (error)  => {
          this.message =           
           (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }        
      )
    }
  },
  components: {
    GeneralTable,
    LoadingData
  }
}
</script>