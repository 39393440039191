class StringFormatter {
  pretifyName (name) {
    return name.replace(/[-_.]/g, ' ')
      .replace(/ +/g, ' ')
      //.replace(/([a-z0-9])([A-Z])/g, '$1 $2')
      .split('  ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join('  ')
  }
}

export default new StringFormatter()