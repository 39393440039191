<template>
  <CContainer fluid v-if="isDataLoaded">
    <CRow>
      <CCol :sm="6">
        <CCard>
          <CCardBody>
            <CCardTitle><strong>{{ getTitle }}</strong></CCardTitle>
            <CCardText>{{ $t('assignedParametersTitle') }}
                {{ $t('assignedParametersSubtitle') }}</CCardText>
            <CRow v-for="(assParameter, i) in assignedParameters" :key="assParameter.id" class="mb-3">
              <CCol>
                <CInputGroup class="mb-3">
                  <CFormInput type="text" id="assParameterName" readonly
                    :value="getTranslatedName(assParameter.characteristic_Name)" />
                  <CButton type="button" color="dark" variant="outline" id="editDetails" @click="toggleCollapse(assParameter.id)">
                    {{ editText }}
                    <CIcon icon="cil-pencil" size="sm" v-if="isMobile"/>
                  </CButton>     
                  <CButton type="button" color="danger" id="removeItem"
                    @click="removeAssignedParameter(assParameter.id, assParameter.idMachine)" >
                    <CIcon icon="cil-trash" size="lg" />
                  </CButton>                                  
                </CInputGroup>
                <CCollapse :visible="getCollapseStatus(assParameter.id)">
                  <CCard>
                    <CCardBody>
                      <CRow class="mb-3">
                        <CFormLabel for="unit" class="col-sm-4 col-form-label">{{ pretifyName('parameterFields.unit') }}</CFormLabel>
                        <CCol sm="8">
                          <CFormInput type="text" id="unit" v-model="assignedParameters[i].unit_of_measure" />
                        </CCol>
                      </CRow>
                      <CRow class="mb-3">
                        <CFormLabel for="tolerance" class="col-sm-4 col-form-label">{{ pretifyName('parameterFields.tolerance') }}</CFormLabel>
                        <CCol sm="8">
                          <CFormInput type="text" id="tolerance" v-model="assignedParameters[i].tolerance" />
                        </CCol>
                      </CRow> 
                      <CRow class="mb-3">
                        <CFormLabel for="calcFormula" class="col-sm-4 col-form-label">{{ pretifyName('parameterFields.calc_Formula') }}</CFormLabel>
                        <CCol sm="8">
                          <CInputGroup class="mb-3">
                            <CFormInput type="text" id="calcFormula" readonly :value="getFormulaDescription(assignedParameters[i].calc_formula)"/>
                            <CButton type="button" color="dark" variant="outline" id="editFormula"
                              @click="editFormula(i)">{{ $t('editFormula') }}</CButton>
                          </CInputGroup>                        
                        </CCol>
                      </CRow>                                                                    
                      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                        <CButton type="button" color="dark" shape="rounded-0" id="update"
                          @click="updateAssignedParameter(assParameter.id, assParameter.idMachine)">{{ $t('update') }}</CButton>
                      </div>
                    </CCardBody>
                  </CCard>                
                </CCollapse>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol :sm="6">
        <CCard>
          <CCardBody>
            <CCardTitle><strong>{{ $t('availableParameters') }}</strong></CCardTitle>
            <CCardText>{{ $t('availableParametersDesc') }}</CCardText>
            <div class="d-grid gap-2 col-6 mx-auto">
              <CButton v-for="parameter in availableParameters" :key="parameter.id" 
                color="secondary" variant="outline" shape="rounded-0"
                @click="assignParameter(parameter.id)">{{ getTranslatedName(parameter.name) }}</CButton>
            </div>
          </CCardBody>
        </CCard>
      </CCol>      
    </CRow>

    <parameter-formula-editor
      :cModalVisible="parameterFormulaModal"
      :currAssParameter="assignedParameters[assParameterIndex]"
      @closeParameterFormulaEditorModal="toggleEditFormulaModalVisibility($event)" />

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert>     
  </CContainer> 
</template>

<script>
import AssignedParametersService from '../services/machine-assigned-parameters'
import CharacteristicsService from '../services/characteristics'
import ParameterFormulaEditor from './ParameterFormulaEditor.vue'
import StringFormatter from '../helpers/stringFormatter'

export default {
  name: 'ParameterComponent',
  props: {
    parameterType: Number
  },
  data() {
    return {
      assignedParameters: '',
      availableParameters: '',
      allParameters: '',
      assignedParLoaded: false,
      allParLoaded: false,
      dataLoaded: false,
      collapseVisible: false,
      currParameterID: 0,
      message: '',
      parameterFormulaModal: false,
      assParameterIndex: 0
    }
  },
  created() {
    this.refreshComponent()
  },
  computed: {
    isDataLoaded() {
      if ((this.assignedParLoaded) && (this.allParLoaded)) {
        this.getAvailableParameters()
      }
      return this.dataLoaded
    },
    getTitle() {
      return ((this.parameterType == 0) ? this.$t('assignedLabReportParam') : this.$t('assignedWaterAnalysisParam'))
    },
    isMobile() {
      let mobile = localStorage.getItem('isMobile')
      return (mobile === 'true')
    },    
    editText() {
      if (this.isMobile) {
        return ''
      } else {
        return this.$t('editDetails')
      }
    }    
  },
  methods: {
    refreshComponent() {
      this.message = ''
      this.dataLoaded = false
      this.assignedParLoaded = false
      this.allParLoaded = false
      this.getAssignedParameters()
      this.getAllParameters()
    },
    getAssignedParameters() {
      if (this.$route.params.idMachine) {
        AssignedParametersService.getParameters({ 'idMachine': this.$route.params.idMachine, 'type': this.parameterType }).then(
          (response) => {
            this.assignedParameters = response.data.data
            this.assignedParLoaded = true
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validMachineIDException')
      }  
    },
    getAllParameters() {
      CharacteristicsService.getCharacteristics({ 'type': this.parameterType }).then(
        (response) => {
          this.allParameters = response.data.data
          this.allParLoaded = true
        },
        (error)  => {
          this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
        }
      )
    },    
    getAvailableParameters() {
      let calcParameters = []
      this.allParameters.forEach(parameter => {
        if (!this.assignedParameters.find(assPar => assPar.id == parameter.id)) {
          calcParameters.push(parameter)
        }
      })
      this.availableParameters = calcParameters
      this.dataLoaded = true
    },
    removeAssignedParameter(parameterID, machineID) {
      if ((machineID != 0) && (parameterID != 0)) {
        AssignedParametersService.deleteParameter({ 'id': parameterID, 'idMachine': machineID }).then(
          () => {
            this.refreshComponent()
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validMachineAndParameterException')
      }
    },
    assignParameter(parameterID) {
      if ((this.$route.params.idMachine) && (parameterID != 0)) {
        AssignedParametersService.assignParameter({ 'id': parameterID, 'idMachine': this.$route.params.idMachine }).then(
          () => {
            this.refreshComponent()
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validMachineAndParameterException')
      }    
    },
    updateAssignedParameter(parameterID, machineID) {
      if ((machineID != 0) && (parameterID != 0)) {
        const assignedParam = this.assignedParameters.find(param => param.id === parameterID)
        if (assignedParam) {
          AssignedParametersService.updateParameters({ 'id': parameterID, 'idMachine': machineID },
            {
              'unit_of_measure': assignedParam.unit_of_measure,
              'tolerance': assignedParam.tolerance,
              'init_value': assignedParam.init_value,
              'calc_formula': assignedParam.calc_formula }).then(
            () => {
              this.collapseVisible = false
              this.refreshComponent()
            },
            (error)  => {
              this.message =           
              (error.response &&
              error.response.data &&
              error.response.data.message) ||
              error.message ||
              error.toString()
            }
          )
        } else {
          this.message = this.$t('validMachineAndParameterException')
        }
      } else {
        this.message = this.$t('validMachineAndParameterException')
      }
    },
    toggleCollapse(parameterID) {
      if (this.currParameterID == 0) {
        this.currParameterID = parameterID
        this.collapseVisible = !this.collapseVisible
      } else if (this.currParameterID == parameterID) {
        this.collapseVisible = !this.collapseVisible
      } else {
        this.currParameterID = parameterID
        this.collapseVisible = true
      }
    },
    getCollapseStatus(parameterID) {
      if (!this.collapseVisible) {
        return false
      } else {
        return (this.currParameterID == parameterID)
      } 
    },
    editFormula(index) {
      this.assParameterIndex = index
      this.toggleEditFormulaModalVisibility(false)
    },
    toggleEditFormulaModalVisibility(refreshPage) {
      this.parameterFormulaModal = !this.parameterFormulaModal
      if (refreshPage) {
        this.refreshComponent()
      }
    },
    getFormulaDescription(inputFormula) {
      return (((inputFormula) && (inputFormula != '')) ? this.$t('calculatedField') : this.$t('noFormulaDefined') )
    },
    pretifyName(name) {
      return StringFormatter.pretifyName(this.$t(name))
    },
    getTranslatedName(parameter) {
      let result = ''
      if (parameter) {
        result = this.$t('characteristics.' + parameter)
      }
      return result
    }               
  },
  components: {
    ParameterFormulaEditor
  }
}
</script>