<template>
  <CContainer fluid>
    <loading-data v-if="!dataLoaded"/>

    <general-table v-if="dataLoaded"
      :data="products"
      :columns="tableColumns"
      :rowsdetail="tableRowsDetails"
      @itemSelected="$emit('itemSelected', $event)"/>

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </CContainer>
</template>

<script>
import GeneralTable from '@/components/GeneralTable.vue'
import ProductService from '../services/products'
import LoadingData from './LoadingData.vue'

export default {
  name: 'ProductsLookup',
  data() {
    return {
      dataLoaded: false,
      message: '',
      content: [],
      searchQuery: '',
      tableColumns: [
        'productFields.id',
        'productFields.name',
        'productFields.fluid_Supplier',
        'productFields.refractometer_Factor',
        'productFields.critical_pH',
        'productFields.product_Number',
        'status',      
      {
        key: 'choose', 
        label: '', 
        _style: 'width:1%', 
        sorter: false, 
        filter: false
      }
      ],
      tableRowsDetails: [
      ]
    }
  },
  created() {
    this.getProductsGradually()
  },
  computed: {
    products() {
      let processedData = []
      if (this.content.length > 0) {
        this.content.forEach((product) => {
          processedData.push({
            'productFields.id': product.id,
            'productFields.name': product.product,
            'productFields.fluid_Supplier': product.fluid_Supplier_Name,
            'productFields.refractometer_Factor': product.refactormeter_Factor,
            'productFields.critical_pH': product.critical_pH,
            'productFields.product_Number': product.product_Number,
            'status': product.status             
          })                    
        })
      }
      return processedData
    }
  },
  methods: {
    getProductsGradually(skipParam) {
      if (!skipParam) {
        this.content = []
      }      
      let paramQuery = {
        '$limit': 50
      }
      if (skipParam) {
        paramQuery['$skip'] = skipParam
      }
      ProductService.getProducts(paramQuery).then(
        (response) => {
          if (this.content.length > 0) {
            this.content = this.content.concat(response.data.data)
          } else {
            this.content = response.data.data
          }
          this.dataLoaded = true
          if (response.data.total > response.data.limit + response.data.skip) {
            if (skipParam) {
              skipParam += response.data.limit
            } else {
              skipParam = response.data.limit
            }
            this.getProductsGradually(skipParam)
          }
        },
        (error)  => {
          this.message =           
           (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }        
      )
    }                     
  },
  components: {
    GeneralTable,
    LoadingData
  }
}
</script>