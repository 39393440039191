<template>
  <CContainer fluid>
    <loading-data v-if="!dataLoaded"/>

    <general-table v-if="dataLoaded"
      :data="customers"
      :columns="tableColumns"
      :rowsdetail="tableRowsDetails"
      @itemSelected="$emit('itemSelected', $event)"/>

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </CContainer>
</template>

<script>
import GeneralTable from '@/components/GeneralTable.vue'
import LoadingData from './LoadingData.vue'
import PermissionManager from '../helpers/permissionManager'
import CustomerLightService from '../services/customers-light'

export default {
  name: 'CustomersLookup',
  data() {
    return {
      dataLoaded: false,
      message: '',
      content: [],
      searchQuery: '',
      tableColumns: [
        'customerFields.id',
        'customerFields.name',
        'customerFields.address',
        'customerFields.country',
        'customerFields.city',
        'customerFields.phone_Number',
        'customerFields.client_No',
        {
          key: 'choose', 
          label: '', 
          _style: 'width:1%', 
          sorter: false, 
          filter: false
        }
      ],
      tableRowsDetails: [
      ]
    }
  },
  created() {
    this.getCustomersGradually()
  },
  computed: {
    customers() {
      let processedData = []
      if (this.content.length > 0) {
        this.content.forEach((customer) => {
          processedData.push({
            'customerFields.id': customer.id,
            'customerFields.name': customer.company,
            'customerFields.address': customer.address,
            'customerFields.country': customer.country,
            'customerFields.city': customer.city,
            'customerFields.phone_Number': customer.phone_Number,
            'customerFields.client_No': customer.client_No               
          })                    
        })
      }
      return processedData
    }
  },
  methods: {
    getCustomersGradually(skipParam) {
      if (!skipParam) {
        this.content = []
      }               
      let paramQuery = {
        '$limit': 50
      }
      // Manage Permissions first
      if (!(PermissionManager.hasExtendedTechnicianPermissions())) {
        // 1. filter by distributor and agent evt.
        const userDistributor = PermissionManager.getDistributorFilteredVisibility()
        if (userDistributor) {
          paramQuery.idDistributor = userDistributor.idDistributor
        }
        const userAgent = PermissionManager.getAgentFilteredVisibility()
        if (userAgent) {
          paramQuery.idAgent = userAgent.idAgent
        }        
      }      
      if (skipParam) {
        paramQuery['$skip'] = skipParam
      }
      CustomerLightService.getCustomers(paramQuery).then(
        (response) => {
          if (this.content.length > 0) {
            this.content = this.content.concat(response.data.data)
          } else {
            this.content = response.data.data
          }
          this.dataLoaded = true
          if (response.data.total > response.data.limit + response.data.skip) {
            if (skipParam) {
              skipParam += response.data.limit
            } else {
              skipParam = response.data.limit
            }
            this.getCustomersGradually(skipParam)
          }
        },
        (error)  => {
          this.message =           
           (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }        
      )
    }   
  },
  components: {
    GeneralTable,
    LoadingData
  }
}
</script>