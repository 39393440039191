<template>
  <CAlert color="warning" v-if="customerSessionActive">
    {{ $t('customerSessionActiveDescription') }}<strong>{{ customerName }}</strong>
    <div class="d-grid gap-2 d-md-flex justify-content-md-end" v-if="isMobile">
      <CButton type="button" color="dark" shape="rounded-0" id="machinesOverview" v-if="isMobile"
        @click="openMachineOverview()">{{ $t('machinesOverview') }}
      </CButton>
    </div>
  </CAlert>
</template>

<script>
import CustomerService from '../services/customers'

export default {
  name: "CustomerSessionInfobox",
  data() {
    return {
      customerName: ''
    }
  },
  computed: {
    customerSessionActive() {
      let customerSessionStarted = localStorage.getItem('customerSessionStarted')
      this.getCustomerName()
      return ((customerSessionStarted) && (!(customerSessionStarted === 'false')))      
    },
    isMobile() {
      let mobile = localStorage.getItem('isMobile')
      return (mobile === 'true')
    }    
  },
  methods: {
    getCustomerName() {
      this.customerName = ''
      let customerID = localStorage.getItem('currentCustomer')
      if ((customerID) && (customerID != 0)) {
        CustomerService.getSingleCustomer(customerID).then(
          (response) => {
            this.customerName = response.data.company
          }
        )
      }
    },   
    openMachineOverview() {
      this.$router.push({ name: 'mMachinesOverview' })
    },        
  }
}
</script>