<template>
  <CContainer fluid>
    <CRow>
      <CCol xs="4">
        <h3>{{ $t('productTechnologyTitle') }}</h3>
      </CCol>
      <CCol xs="2">
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <CButton color="dark" shape="rounded-0" :disabled="!dataModified" @click="updateData()"><strong>{{ $t('saveChanges') }}</strong></CButton>
        </div>
      </CCol>
    </CRow>
    <br>

    <CCol xs="6" class="align-self-center">
      <CTable borderless v-if="isDataLoaded">
        <CTableBody>
          <CTableRow color="light" v-for="technology in technologiesData" :key="technology.sort_Index">
            <CTableHeaderCell :color="getLineColor(technology.line_Type)" scope="row" v-if="technology.line_Type == 0">{{ technology.value }}</CTableHeaderCell>
            <CTableDataCell v-if="technology.line_Type == 1">  {{ technology.value }}</CTableDataCell>
            <CTableDataCell :color="getLineColor(technology.line_Type)">
              <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <CButton size="sm" color="secondary" @click="moveLine(technology.sort_Index, -1)"><CIcon icon="cil-arrow-thick-top" size="sm"/></CButton>
                <CButton size="sm" color="secondary" @click="moveLine(technology.sort_Index, 1)"><CIcon icon="cil-arrow-thick-bottom" size="sm"/></CButton>
                <CButton size="sm" color="info" @click="createNewLine(technology.sort_Index)">{{ $t('createNewLine') }}</CButton>
                <CButton size="sm" color="danger" @click="deleteLine(technology.sort_Index)"><CIcon icon="cil-trash" size="sm"/></CButton>
              </div>
            </CTableDataCell>
          </CTableRow>
        </CTableBody>
      </CTable>
    </CCol>

    <new-product-technology-line
      :cModalVisible="newLineCreationVisible"
      :currIndex="selectedIndex"
      @closeTechnologyLineCreation="closeTechnologyLineCreation($event)" />
    
    <CToaster placement="bottom-end">
      <CToast color="dark" class="text-white" v-for="(toast, index) in toasts" :key="index">
        <CToastHeader closeButton>
        <span class="me-auto fw-bold">{{ toast.title }}</span>
        <small>{{ $t('now') }}</small>
        </CToastHeader>
        <CToastBody>
          {{ toast.content }}
        </CToastBody>  
      </CToast>
    </CToaster>

    <confirm-action
      :cModalVisible="popupConfirmMessage"
      :title="confirmTitle"
      :message="confirmMessage"
      :yesButton="$t('confirmButton')"
      :noButton="$t('cancelButton')"
      @confirm="confirmAction()" 
      @cancel="cancelAction()" />

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </CContainer>
</template>

<script>
import ProductTechnologiesService from '../services/product-technologies'
import NewProductTechnologyLine from './NewProductTechnologyLine.vue'
import ConfirmAction from './ConfirmAction.vue'

export default {
  name: 'ProductTechnologyEditor',
  data() {
    return {
      dataLoaded: false,
      message: '',
      technologiesData: [],
      dataModified: false,
      newLineCreationVisible: false,
      selectedIndex: 0,
      processedData: 0,
      toasts: [],
      popupConfirmMessage: false,
      confirmTitle: '',
      confirmMessage: ''
    }
  },
  computed: {
    isDataLoaded() {
      if (!this.dataLoaded) {
        this.getProductTechnologies()
      }
      return this.dataLoaded
    }
  },
  methods: {
    getProductTechnologies() {
      ProductTechnologiesService.getTechnologies({ '$sort[sort_Index]': 1 }).then(
        (response) => {
          this.technologiesData = response.data.data
          this.dataLoaded = true
        },
        (error) => {
          this.message =           
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }
      )
    },
    getLineColor(lineType) {
      return (lineType == 0) ? 'dark' : 'light'
    },
    moveLine(currIndex, steps) {
      let selectedTechnologyIndex = this.technologiesData.findIndex(technology => technology.sort_Index == currIndex)
      if (selectedTechnologyIndex >= 0) {
        let exchangeTechnologyIndex = this.technologiesData.findIndex(technology => technology.sort_Index == currIndex + steps)
        if (exchangeTechnologyIndex >= 0) {
          this.technologiesData[selectedTechnologyIndex].sort_Index = currIndex + steps
          this.technologiesData[exchangeTechnologyIndex].sort_Index = currIndex

          this.technologiesData.sort((a, b) => a.sort_Index - b.sort_Index) 
          this.dataModified = true         
        }
      }
    },
    deleteLine(currIndex) {
      let selectedTechnology = this.technologiesData.findIndex(technology => technology.sort_Index == currIndex)
      if (selectedTechnology >= 0) {
        this.technologiesData.splice(selectedTechnology, 1)
        this.dataModified = true
      }
    },
    createNewLine(currIndex) {
      if (!this.newLineCreationVisible) {
        this.selectedIndex = currIndex
      }
      this.newLineCreationVisible = !this.newLineCreationVisible
    },
    closeTechnologyLineCreation(newTechnologyLine) {
      if (newTechnologyLine) {
        if (newTechnologyLine.sort_Index >= 0) {
          let sortingIndex = newTechnologyLine.sort_Index
          let technologyLineToUpdate = this.technologiesData.findIndex(technology => technology.sort_Index == sortingIndex)
          while (technologyLineToUpdate >= 0) {
            sortingIndex++
            let newLineToUpdate = this.technologiesData.findIndex(technology => technology.sort_Index == sortingIndex)
            this.technologiesData[technologyLineToUpdate].sort_Index = sortingIndex
            technologyLineToUpdate = newLineToUpdate
          }
          this.technologiesData.push(newTechnologyLine)
          this.technologiesData.sort((a, b) => a.sort_Index - b.sort_Index)
          this.dataModified = true
        }
      }
      this.newLineCreationVisible = false
    },
    updateData() {
      // Ask for confirmation first...
      this.confirmTitle = this.$t('continueTitle')
      this.confirmMessage = this.$t('productTechnologyWarning')
      this.popupConfirmMessage = true
    },
    resetConfirmMessage() {
      this.confirmTitle = ''
      this.confirmMessage = '',
      this.popupConfirmMessage = false
    },
    cancelAction() {
      this.resetConfirmMessage()
    },
    confirmAction() {
      this.resetConfirmMessage()
      this.recreateValues()
    },
    recreateValues() {
      // Delete every Value and recreate them...
      //let continueProcessing = confirm('You are about to delete the Table and recreate all the values... Do you want to continue?')
      //if (continueProcessing) {
        // 1. Delete data
        ProductTechnologiesService.deleteAll().then(
          () => {
            // 2. Insert new Values...
            this.processedData = 0
            let lastInsertedID = 0
            let dataToProcess = this.technologiesData
            let newValuesToInsert = []
            dataToProcess.sort((a, b) => a.id - b.id)
            dataToProcess.forEach(technology => {
              if (technology.id == 0) {
                newValuesToInsert.push({
                  'value': technology.value,
                  'line_Type': technology.line_Type,
                  'sort_Index': technology.sort_Index
                })
              } else {
                ProductTechnologiesService.createTechnology(technology).then(
                  () => {
                    this.processedData ++
                    lastInsertedID = technology.id
                    if (this.processedData + newValuesToInsert.length == this.technologiesData.length) {
                      // Process new values...
                      if (newValuesToInsert.length > 0) {
                        newValuesToInsert.forEach(newLine => {
                          lastInsertedID ++
                          ProductTechnologiesService.createTechnology({ 'id': lastInsertedID, 'value': newLine.value, 'line_Type': Number(newLine.line_Type), 'sort_Index': newLine.sort_Index }).then(
                            () => {
                              this.processedData ++
                              if (this.processedData == this.technologiesData.length) {
                                this.createToast(this.$t('dataSuccesfullyUpdated'), this.$t('redirectingToHome'))
                                this.delay(2000).then(
                                  () => {
                                    this.$router.push('/') 
                                  }
                                )
                              }
                            },
                            (error) => {
                              this.message =           
                              (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                              error.message ||
                              error.toString()
                            }                  
                          )                          
                        })
                      } else {
                        this.createToast(this.$t('dataSuccesfullyUpdated'), this.$t('redirectingToHome'))
                        this.delay(2000).then(
                          () => {
                            this.$router.push('/') 
                          }
                        )                        
                      }
                    }
                  },
                  (error) => {
                    this.message =           
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString()
                  }                  
                )
              }
            })
          },
          (error) => {
            this.message =           
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      //}
    },
    createToast(title, content) {
      this.toasts.push({
        title: title,
        content: content
      })
    },
    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time))
    },    
  },
  components: {
    NewProductTechnologyLine,
    ConfirmAction
  }
}
</script>