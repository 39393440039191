<template>
  <CContainer fluid>
    <CRow class="mb-3">
      <CFormLabel for="file-input" class="col-sm-4 col-form-label">
        <strong>Click here to select a file that will be processed...</strong>
      </CFormLabel>
      <CCol sm="8">
        <input type="file" id="file-input" @change="onInputChange" />
      </CCol>
    </CRow>
    <CCard fluid>
      <CCardBody>
        <CCardTitle>Base Data Migration</CCardTitle>
        <CCardText>First download the data with Postman and than import it using the following buttons: </CCardText>
        <CButton color="info" variant="ghost" size="lg" @click="getCountries()">1. Countries</CButton>
        <CButton color="primary" variant="ghost" size="lg" @click="getDistributors()">2. Distributors</CButton>
        <CButton color="secondary" variant="ghost" size="lg" @click="getCustomers()">3. Customers</CButton>
        <CButton color="success" variant="ghost" size="lg" @click="getProducts()">4. Products</CButton>
        <CButton color="warning" variant="ghost" size="lg" @click="getMachines()">5. Machines</CButton>
        <CButton color="danger" variant="ghost" size="lg" @click="getLabReports()">6. Lab Reports</CButton>
        <CButton color="dark" variant="ghost" size="lg" @click="getMeasurements()">7. Measurements</CButton> 
        <CButton color="light" variant="ghost" size="lg" @click="getWaterAnalysis()">8. Water Analyses</CButton>                           
      </CCardBody>
    </CCard>

    <CToaster placement="top-end">
      <CToast color="dark" class="text-white" v-for="(toast, index) in toasts" :key="index">
        <CToastBody>
          {{ toast.content }}
        </CToastBody>  
      </CToast>
    </CToaster>
  </CContainer>
</template>

<script>
/* eslint-disable */
import CountryService from '../services/countries'
import DistributorService from '../services/distributors'
import CustomerService from '../services/customers'
import ProductService from '../services/products'
import DataMapper from '../helpers/migration/dataMapper'
import MachineService from '../services/machines'
import AssignedOperationsService from '../services/machine-assigned-operations'
import AssignedMaterialsService from '../services/machine-assigned-materials'
import AssignedFiltrationsService from '../services/machine-assigned-filtrations'
import AssignedParametersService from '../services/machine-assigned-parameters'
import MeasurementService from '../services/measurements'
import LabReportService from '../services/lab-reports'
import LabReportDetailService from '../services/lab-report-details'

export default {
  name: 'MigrationTool',
  data() {
    return {
      currFile: '',
      currJsonContent: '',
      contentLoaded: false,
      toasts: []
    }
  },
  watch: {
    contentLoaded: {
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          if (this.contentLoaded) {
            this.createToast(this.$t('Content successfully loaded... Click the button again to print it!'))
          }
        }
      }
    }
  },
  methods: {
    getCountries() {
      if (!this.contentLoaded) {
        this.readFileAndFillJsonObject()
      } else if (this.currJsonContent) {
        const noOfObjects = Object.keys(this.currJsonContent).length
        let counter = 0
        let errorString = ''
        this.currJsonContent.forEach(country => {
          CountryService.createCountry({
            'alpha2Code': country.alpha2Code,
            'name': country.name
          }).then(
            () => {
              counter ++
              if (counter == noOfObjects) {
                this.createToast('Countries succesfully filled! The following countries were not inserted: ', errorString)
                this.contentLoaded = false
              }
            },
            (error) => {
              counter ++
              console.log(error)
              errorString += country.name + '\r'
              if (counter == noOfObjects) {
                this.createToast('Countries succesfully filled! The following countries were not inserted: ', errorString)
                this.contentLoaded = false
              }              
            }
          )
        })
      }
    },
    getDistributors() {
      if (!this.contentLoaded) {
        this.readFileAndFillJsonObject()
      } else if (this.currJsonContent) {
        const noOfObjects = Object.keys(this.currJsonContent).length
        let counter = 0
        let errorString = ''
        this.currJsonContent.forEach(distributor => {
          // Maybe remove this part in the final Import (part to check the already inserted distributors)
          DistributorService.getDistributors({ 'company': distributor.name }).then(
            (response) => {
              const currDistributor = (response.data.data[0] ? response.data.data[0] : null)
              if (currDistributor) {
                DistributorService.updateDistributor(currDistributor.id, {
                  'idMigration': distributor.distributorId
                }).then(
                  () => {
                    counter ++
                    if (counter == noOfObjects) {
                      this.createToast('Distributors succesfully filled! The following Distributors were not inserted: ', errorString)
                      this.contentLoaded = false
                    }
                  },
                  (error) => {
                    counter ++
                    console.log(error)
                    errorString += distributor.name + '\r'
                    if (counter == noOfObjects) {
                      this.createToast('Distributors succesfully filled! The following distributors were not inserted: ', errorString)
                      this.contentLoaded = false
                    }              
                  }
                )
              } else {
                DistributorService.createDistributor({
                  'company': distributor.name,
                  'address': distributor.address,
                  'phone_Number': distributor.phoneNumber,
                  'contact_Person': distributor.contactPerson,
                  'disabled': (distributor.disabled ? true : false),
                  'zipCode': distributor.zipCode,
                  'city': distributor.city,
                  'country': distributor.country,
                  'client_No': distributor.clientNo,
                  'language': (distributor.locale == 'de' ? 1 : (distributor.locale == 'it' ? 2 : 0)),
                  'idMigration': distributor.distributorId
                }).then(
                  () => {
                    counter ++
                    if (counter == noOfObjects) {
                      this.createToast('Distributors succesfully filled! The following Distributors were not inserted: ', errorString)
                      this.contentLoaded = false
                    }
                  },
                  (error) => {
                    counter ++
                    console.log(error)
                    errorString += distributor.name + '\r'
                    if (counter == noOfObjects) {
                      this.createToast('Distributors succesfully filled! The following distributors were not inserted: ', errorString)
                      this.contentLoaded = false
                    }              
                  }
                )
              }
            },
            (error) => {
              alert(error)
              return
            }
          )
        })        
      }
    },
    getCustomers() {
      if (!this.contentLoaded) {
        this.readFileAndFillJsonObject()
      } else if (this.currJsonContent) {
        const noOfObjects = Object.keys(this.currJsonContent).length
        let counter = 0
        let errorString = ''
        this.currJsonContent.forEach(customer => {
          // Maybe remove this part in the final Import (part to check the already inserted distributors)
          CustomerService.getCustomers({ 'company': customer.name }).then(
            (response) => {
              const currCustomer = (response.data.data[0] ? response.data.data[0] : null)
              if (currCustomer) {
                CustomerService.updateCustomer(currCustomer.id, {
                  'idMigration': currCustomer.customerId
                }).then(
                  () => {
                    counter ++
                    if (counter == noOfObjects) {
                      this.createToast('Customers succesfully filled! The following Customers were not inserted: ', errorString)
                      this.contentLoaded = false
                    }
                  },
                  (error) => {
                    counter ++
                    console.log(error)
                    errorString += customer.name + '\r'
                    if (counter == noOfObjects) {
                      this.createToast('Customers succesfully filled! The following customers were not inserted: ', errorString)
                      this.contentLoaded = false
                    }              
                  }
                )
              } else {
                CustomerService.createCustomer({
                  'company': customer.name,
                  'address': customer.address,
                  'phone_Number': customer.phoneNumber,
                  'contact_Person': customer.contactPerson,
                  'disabled': (customer.disabled ? true : false),
                  'zipCode': customer.zipCode,
                  'city': customer.city,
                  'country': customer.country,
                  'client_No': customer.clientNo,
                  'language': (customer.locale == 'de' ? 1 : (customer.locale == 'it' ? 2 : 0)),
                  'idMigration': customer.customerId,
                  'idDistributorMigr': customer.distributorId
                }).then(
                  () => {
                    counter ++
                    if (counter == noOfObjects) {
                      this.createToast('Customers succesfully filled! The following customers were not inserted: ', errorString)
                      this.contentLoaded = false
                    }
                  },
                  (error) => {
                    counter ++
                    console.log(error)
                    errorString += customer.name + '\r'
                    if (counter == noOfObjects) {
                      this.createToast('Customers succesfully filled! The following customers were not inserted: ', errorString)
                      this.contentLoaded = false
                    }              
                  }
                )
              }
            },
            (error) => {
              counter ++
              console.log(error)
              errorString += customer.name + '\r'
              if (counter == noOfObjects) {
                this.createToast('Customers succesfully filled! The following customers were not inserted: ', errorString)
                this.contentLoaded = false
              } 
            }
          )
        })        
      }
    },
    getProducts() {
      if (!this.contentLoaded) {
        this.readFileAndFillJsonObject()
      } else if (this.currJsonContent) {
        const noOfObjects = Object.keys(this.currJsonContent).length
        let counter = 0
        let errorString = ''
        this.currJsonContent.forEach(product => {
          // Maybe remove this part in the final Import (part to check the already inserted distributors)
          ProductService.getProducts({ 'product': product.name }).then(
            (response) => {
              const currProduct = (response.data.data[0] ? response.data.data[0] : null)
              console.log('CurrProduct: ', currProduct)
              if (currProduct) {
                ProductService.updateProduct(currProduct.id, {
                  'idMigration': product.productId
                }).then(
                  () => {
                    counter ++
                    console.log('Updated Product!!!')
                    if (counter == noOfObjects) {
                      this.createToast('Products succesfully filled! The following products were not inserted: ', errorString)
                      this.contentLoaded = false
                    }
                  },
                  (error) => {
                    counter ++
                    console.log(error)
                    errorString += product.name + '\r'
                    if (counter == noOfObjects) {
                      this.createToast('Products succesfully filled! The following products were not inserted: ', errorString)
                      this.contentLoaded = false
                    }              
                  }
                )
              } else {
                ProductService.createProduct({
                  'product': product.name,
                  'technology': DataMapper.getTechnologyId((product.technology ? product.technology.technologyId : null)),
                  'refactormeter_Factor': product.refractometerFactor,
                  'critical_pH': product.criticalPH,
                  'status': (product.status == 'active' ? 0 : 1),
                  'product_Number': product.productNumber,
                  'idMigration': product.productId,
                  'idMigration_Fluid_Supplier': (product.fluidSupplier ? product.fluidSupplier.fluidSupplierId : null)
                }).then(
                  () => {
                    counter ++
                    console.log('Created Product!!')
                    if (counter == noOfObjects) {
                      this.createToast('Products succesfully filled! The following products were not inserted: ', errorString)
                      this.contentLoaded = false
                    }
                  },
                  (error) => {
                    counter ++
                    console.log(error)
                    errorString += product.name + '\r'
                    if (counter == noOfObjects) {
                      this.createToast('Products succesfully filled! The following products were not inserted: ', errorString)
                      this.contentLoaded = false
                    }              
                  }
                )
              }
            },
            (error) => {
              counter ++
              console.log(error)
              errorString += product.name + '\r'
              if (counter == noOfObjects) {
                this.createToast('Products succesfully filled! The following products were not inserted: ', errorString)
                this.contentLoaded = false
              } 
            }
          )
        })        
      }
    },
    getMachines() {
      // Note: name set as Name + MachineID for now...
      // Check also the migration fields in backend, could be that they need to be deleted previously
      if (!this.contentLoaded) {
        this.readFileAndFillJsonObject()
      } else if (this.currJsonContent) {
        const noOfObjects = Object.keys(this.currJsonContent).length
        let counter = 0
        let errorString = ''
        this.currJsonContent.forEach(machine => {
          // Maybe remove this part in the final Import (part to check the already inserted distributors)
          // const machineName = (machine.idNumber ? machine.name + ' ' + machine.idNumber : machine.name)
          MachineService.getMachines({ 'name': machine.name, 'id_Machine': machine.idNumber ? machine.idNumber : '' }).then(
            (response) => {
              const currMachine = (response.data.data[0] ? response.data.data[0] : null)
              if (currMachine) {
                MachineService.updateMachine(currMachine.id, {
                  'idMigration': machine.machineId
                }).then(
                  () => {
                    counter ++
                    if (counter == noOfObjects) {
                      this.createToast('Machines succesfully filled! The following machines were not inserted: ', errorString)
                      this.contentLoaded = false
                    }
                  },
                  (error) => {
                    counter ++
                    console.log(error)
                    errorString += machine.name + '\r'
                    if (counter == noOfObjects) {
                      this.createToast('Machines succesfully filled! The following machines were not inserted: ', errorString)
                      this.contentLoaded = false
                    }              
                  }
                )
              } else {
                MachineService.createMachine({
                  'name': machine.name,
                  'id_Machine': machine.idNumber,
                  'idDistributorMigr': (machine.customer ? machine.customer.distributorId : null),
                  'idCustomerMigr': (machine.customer ? machine.customer.customerId : null),
                  'idProductMigr': (machine.product ? machine.product.productId : null),
                  'status': (machine.status == 'active' ? 0 : 1),
                  'critical_pH': (machine.product ? machine.product.criticalPH : null),
                  'sump_Size': machine.tankSize,
                  'water': DataMapper.getWaterOptionValue((machine.water ? machine.water.waterId : null)),
                  'min_Eff_Concentration': machine.lowEffectiveConcentration,
                  'max_Eff_Concentration': machine.highEffectiveConcentration,
                  'last_Filled': machine.machineFilled,
                  'unknown_Date': (machine.machineFilled ? false : true),
                  'central_Plant': machine.centralPlant,
                  'idCentralMachineMigr': machine.centralMachineId,
                  'product_Content': machine.tankSize, // Init with the value of the tank
                  'idMigration': machine.machineId
                }).then(
                  (response) => {
                    counter ++
                    console.log('Created Machine!!')
                    // Create Additional data...
                    const currMachineID = response.data.id
                    if (machine.operations) {
                      machine.operations.forEach(operation => {
                        AssignedOperationsService.assignOperation({ 'id': operation.id, 'idMachine': currMachineID }).then(
                          () => { console.log('Operation Assigned: ', operation.id)}
                        )
                      })
                    }
                    if (machine.materials) {
                      machine.materials.forEach(material => {
                        AssignedMaterialsService.assignMaterial({
                          'id': material.id,
                          'idMachine': currMachineID,
                          'material_specification': material.materialSpecification ? material.materialSpecification : '',
                          'tool': material.tool ? material.tool : '',
                          'part': material.part ? material.part : ''
                        }).then(
                          () => { console.log('Material Assigned: ', material.id)}
                        )
                      })
                    }   
                    if (machine.filters) {
                      machine.filters.forEach(filter => {
                        AssignedFiltrationsService.assignFiltration({ 'id': filter.id, 'idMachine': currMachineID, 'comment': filter.description ? filter.description : '' }).then(
                          () => { console.log('Filtration Assigned: ', filter.id)}
                        )
                      })
                    }
                    // Manage Lab Report Parameters....  
                    if (machine.machineTolerance) {
                      // Manage all the cases...
                      let labReportParams = []
                      labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('concentration', machine.machineTolerance.concentration))
                      labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('trampOil', machine.machineTolerance.trampOil))
                      labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('effectiveConcentration', machine.machineTolerance.effectiveConcentration))
                      labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('titrationEmulsion', machine.machineTolerance.titrationEmulsion))
                      labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('productSpecificValue', machine.machineTolerance.productSpecificValue))
                      labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('refractometerReading', machine.machineTolerance.refractometerReading))
                      labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('correctionFactor', machine.machineTolerance.correctionFactor))
                      labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('phElectrometric', machine.machineTolerance.phElectrometric))
                      labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('cunductivity', machine.machineTolerance.cunductivity))
                      labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('bacterias', machine.machineTolerance.bacterias))
                      labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('finghiYeast', machine.machineTolerance.finghiYeast))
                      labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('concentrationAcidSplit', machine.machineTolerance.concentrationAcidSplit))
                      labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('totalHardnessEmulsion', machine.machineTolerance.totalHardnessEmulsion))
                      labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('nitrite', machine.machineTolerance.nitrite))
                      labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('nitrate', machine.machineTolerance.nitrate))
                      labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('depositOfWear', machine.machineTolerance.depositOfWear))
                      labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('corrosionDegree', machine.machineTolerance.corrosionDegree))
                      labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('productSpecificValueAlkalinity', machine.machineTolerance.productSpecificValueAlkalinity))
                      labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('productSpecificValueAcidSplit', machine.machineTolerance.productSpecificValueAcidSplit))
                      labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('chloride', machine.machineTolerance.chloride))
                      labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('sulfate', machine.machineTolerance.sulfate))
                      if (labReportParams.length > 0) {
                        labReportParams.forEach(parameter => {
                          AssignedParametersService.assignParameter({
                            'id': parameter.id,
                            'idMachine': currMachineID,
                            'tolerance': parameter.tolerance ? parameter.tolerance : ''
                          }).then(
                            () => { console.log('Parameter Assigned: ', parameter.id )}
                          )
                        })
                      }                 
                    }                                   
                    if (counter == noOfObjects) {
                      this.createToast('Machines succesfully filled! The following machines were not inserted: ', errorString)
                      this.contentLoaded = false
                    }
                  },
                  (error) => {
                    counter ++
                    console.log(error)
                    errorString += machine.name + '\r'
                    if (counter == noOfObjects) {
                      this.createToast('Machines succesfully filled! The following machines were not inserted: ', errorString)
                      this.contentLoaded = false
                    }              
                  }
                )
              }
            },
            (error) => {
              counter ++
              console.log(error)
              errorString += machine.name + '\r'
              if (counter == noOfObjects) {
                this.createToast('Machines succesfully filled! The following machine were not inserted: ', errorString)
                this.contentLoaded = false
              } 
            }
          )
        })        
      }
    },
    getMeasurements() {
      // Has to be run by last.....
      if (!this.contentLoaded) {
        this.readFileAndFillJsonObject()
      } else if (this.currJsonContent) {
        const noOfObjects = Object.keys(this.currJsonContent).length
        let counter = 0
        let errorString = ''
        this.currJsonContent.forEach(measurement => {
          MeasurementService.createMeasurement({
            'idMachineMigr': measurement.machineId,
            'idProductMigr': (measurement.product ? measurement.product.productId : null),
            'measuredBy': measurement.createdByUserFullName,
            'pH': measurement.reading,
            'refrac_Reading': measurement.refractometerReading,
            'eff_Concentration': measurement.effectiveConcentration,
            'appearance': measurement.apperance,
            'comment': (measurement.comment ? measurement.comment : '-'),
            'last_Filled': measurement.dateMachineFilled,
            'created': measurement.created,
            'refactormeter_Factor': measurement.refractometerFactor,
            'image_Count': measurement.imageCount,
            'idReportMigr': measurement.labReportId,
            'measured_At': measurement.measuredAt
          }).then(
            () => {
              counter ++
              console.log('Created Measurement!!')
              if (counter == noOfObjects) {
                this.createToast('Measurement succesfully filled! The following measurements were not inserted: ', errorString)
                this.contentLoaded = false
              }
            },
            (error) => {
              counter ++
              console.log(error)
              errorString += measurement.measurementId+ '\r'
              if (counter == noOfObjects) {
                this.createToast('Measurement succesfully filled! The following measurements were not inserted: ', errorString)
                this.contentLoaded = false
              }              
            }
          )
        })        
      }
    },
    getLabReports() {
      if (!this.contentLoaded) {
        this.readFileAndFillJsonObject()
      } else if (this.currJsonContent) {
        const noOfObjects = Object.keys(this.currJsonContent).length
        let counter = 0
        let errorString = ''
        this.currJsonContent.forEach(report => {
          LabReportService.createLabReport({
            'idMachineMigr': report.machineId,
            'type': 0,
            'date_Ordered': report.orderedAtDateTimeOffset,
            'status': (report.completedAtDateTimeOffset ? 2 : 1), // Suppose that all the lab reports will be closed by the time of the migration
            'completed_At': report.completedAtDateTimeOffset,
            'date_Of_Analysis': report.samplingDate,
            'reason_Of_Analysis': report.reasonOfAnalysis,
            'appearance': (report.report ? report.report.appearance : null),
            'deposit': (report.report ? report.report.deposits : null),
            'smell': (report.report ? report.report.smellTechnician : null),
            'comment': report.comment,
            'batch_Number': report.batchNumber,
            'cleannes_Of_Workstation': report.cleannessOfWorkstation,
            'floating_Oil_Phase': report.floatingOilPhase,
            'result_Indicator': DataMapper.getResultIndicator(report.sentimentStatus),
            'idMigration': report.id
          }).then(
            (response) => {
              counter ++
              console.log('Created Lab Report!!', response.data.id)
              // Insert Lab Report Details
              if (report.report) {
                let labReportParams = []
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('concentration', report.report.concentration, 0))
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('titrationEmulsion', report.report.titrationEmulsion, 0))
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('correctionFactor', report.report.correctionFactor, 0))
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('concentrationAcidSplit', report.report.concentrationAcidSplit, 0))
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('productSpecificValueAlkalinity', report.report.productSpecificValueAlkalinity, 0))
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('productSpecificValueAcidSplit', report.report.productSpecificValueAcidSplit, 0))                
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('trampOil', report.report.trampOil, 0))
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('effectiveConcentration', report.report.effectiveConcentration, 0))
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('productSpecificValue', report.report.productSpecificValue, 0))
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('refractometerReading', report.report.refractometerReading, 0))
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('phElectrometric', report.report.phElectrometric, 0))
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('cunductivity', report.report.cunductivity, 0))
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('nitrite', report.report.nitrite, 0))
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('nitrate', report.report.nitrate, 0))                
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('bacterias', report.report.bacterias, 0))
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('finghiYeast', report.report.finghiYeast, 0))
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('totalHardnessEmulsion', report.report.totalHardnessEmulsion, 0))
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('depositOfWear', report.report.depositOfWear, 0)) // Could have strange values...
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('corrosionDegree', report.report.corrosionDegree, 0))
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('chloride', report.report.chloride, 0))
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('sulfate', report.report.sulfate, 0)) 
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('mixedPhaseAcidSplit', report.report.mixedPhaseAcidSplit, 0)) 
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('mineralOilContent', report.report.mineralOilContent, 0)) 

                // Process in two steps: 1. insert parameter, 2. assign parameter to machine if not defined!!!
                if (labReportParams.length > 0) {
                  labReportParams.forEach(parameter => {
                    LabReportDetailService.createLabReportDetail({
                      'id': parameter.id,
                      'idLabReport': response.data.id,
                      'sample': String(parameter.tolerance)
                    }).then(
                      () => { console.log('Parameter assigned!')}
                    )
                    AssignedParametersService.assignParameter({
                      'id': parameter.id,
                      'idMachineMigr': report.machineId,
                    }).then(
                      () => { console.log('Parameter Assigned: ', parameter.id )},
                      (error) => { console.log('Parameter already assigned!!', error)}
                    )
                  })
                }               
              }
              if (counter == noOfObjects) {
                this.createToast('Lab Report succesfully filled! The following reports were not inserted: ', errorString)
                this.contentLoaded = false
              }
            },
            (error) => {
              counter ++
              console.log(error)
              errorString += report.reportId + '\r'
              if (counter == noOfObjects) {
                this.createToast('Lab Report succesfully filled! The following reports were not inserted: ', errorString)
                this.contentLoaded = false
              }              
            }
          )
        })        
      }
    },  
    getWaterAnalysis() {
      if (!this.contentLoaded) {
        this.readFileAndFillJsonObject()
      } else if (this.currJsonContent) {
        const noOfObjects = Object.keys(this.currJsonContent).length
        let counter = 0
        let errorString = ''
        this.currJsonContent.forEach(report => {
          LabReportService.createLabReport({
            'idCustomerMigr': report.customerId,
            'type': 1,
            'date_Ordered': report.orderedAt,
            'status': (report.completedAt ? 2 : 1),
            'completed_At': report.completedAt,
            'date_Of_Analysis': report.samplingDate,
            'reason_Of_Analysis': report.reasonOfAnalysis,
            'appearance': (report.analysis ? report.analysis.appearance : null),
            'deposit': (report.analysis ? report.analysis.deposits : null),
            'smell': (report.analysis ? report.analysis.smell : null),
            'comment': (report.analysis ? report.analysis.comment : null),
            'result_Indicator': DataMapper.getResultIndicator(report.sentimentStatus),
            'idMigration': report.id
          }).then(
            (response) => {
              counter ++
              console.log('Created Water Analysis!!', response.data.id)
              // Insert Lab Report Details
              if (report.analysis) {
                let labReportParams = []
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('waterHardness', report.analysis.waterHardness, 1))
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('phElectrometric', report.analysis.phElectrometric, 1))
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('conductivity', report.analysis.conductivity, 1))
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('nitrite', report.analysis.nitrite, 1))
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('nitrate', report.analysis.nitrate, 1))                
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('bacterias', report.analysis.bacterias, 1))
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('fungiYeast', report.analysis.fungiYeast, 1))
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('chloride', report.analysis.chloride, 1))
                labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('sulfate', report.analysis.sulfate, 1)) 
                //labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('mixedPhaseAcidSplit', report.report.mixedPhaseAcidSplit, 1)) 
                //labReportParams = labReportParams.concat(DataMapper.getMachineTolerances('mineralOilContent', report.report.mineralOilContent, 1))                
                // Process in two steps: 1. insert parameter, 2. assign parameter to machine if not defined!!!
                if (labReportParams.length > 0) {
                  labReportParams.forEach(parameter => {
                    LabReportDetailService.createLabReportDetail({
                      'id': parameter.id,
                      'idLabReport': response.data.id,
                      'sample': String(parameter.tolerance)
                    }).then(
                      () => { console.log('Parameter assigned!')}
                    )
                    AssignedParametersService.assignParameter({
                      'id': parameter.id,
                      'idMachineMigr': report.machineId,
                    }).then(
                      () => { console.log('Parameter Assigned: ', parameter.id )},
                      (error) => { console.log('Parameter already assigned!!', error)}
                    )
                  })
                }               
              }
              if (counter == noOfObjects) {
                this.createToast('Water Analysis succesfully filled! The following reports were not inserted: ', errorString)
                this.contentLoaded = false
              }
            },
            (error) => {
              counter ++
              console.log(error)
              errorString += report.reportId + '\r'
              if (counter == noOfObjects) {
                this.createToast('Water Analysis succesfully filled! The following reports were not inserted: ', errorString)
                this.contentLoaded = false
              }              
            }
          )
        })        
      }
    }, 
    onInputChange(e) {
      this.currFile = e.target.files[0]
      this.contentLoaded = false
    },
    async readFileAndFillJsonObject() {
      this.currJsonContent = ''
      this.contentLoaded = false
      if (this.currFile) {
        let fileContent = await this.currFile.text()
        this.currJsonContent = JSON.parse(fileContent)
        this.contentLoaded = true
      }
    },
    createToast(content) {
      this.toasts.push({
        content: content
      })
    },    
  }
}
</script>