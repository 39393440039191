<template>
  <div>
    <loading-data v-if="!dataLoaded"/>

    <small><general-table v-if="dataLoaded"
      :data="customers"
      :columns="tableColumns"
      :rowsdetail="tableRowsDetails"
      :showItemsPerPage="false"
      :sorter="false"
      :columnFilter="false"       
      @itemSelected="$emit('itemSelected', $event)"/></small>

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </div>
</template>

<script>
import GeneralTable from '@/components/GeneralTable.vue'
import LoadingData from '../LoadingData.vue'
import PermissionManager from '../../helpers/permissionManager'
import CustomerLightService from '../../services/customers-light'

export default {
  name: 'CustomersLookupMobile',
  data() {
    return {
      dataLoaded: false,
      message: '',
      content: [],
      searchQuery: '',
      tableColumns: [
        { key: 'customerFields.id', _style: 'min-width: 50px' },
        { key: 'customerFields.name', _style: 'min-width: 180px' },
        { key: 'customerFields.address', _style: 'min-width: 200px' },
        { key: 'customerFields.phone_Number', _style: 'min-width: 130px' },
        { key: 'customerFields.client_No', _style: 'min-width: 70px' },
        {
          key: 'choose', 
          label: '', 
          _style: 'width:1%', 
          sorter: false, 
          filter: false
        }
      ],
      tableRowsDetails: [
      ]
    }
  },
  created() {
    this.getCustomersGradually()
  },
  computed: {
    customers() {
      let processedData = []
      if (this.content.length > 0) {
        this.content.forEach((customer) => {
          processedData.push({
            'customerFields.id': customer.id,
            'customerFields.name': customer.company,
            'customerFields.address':  customer.address + ', ' + customer.city + ' (' + customer.country_Name + ')',
            'customerFields.phone_Number': customer.phone_Number,
            'customerFields.client_No': customer.client_No               
          })                    
        })
      }
      return processedData
    }
  },
  methods: {
    getCustomersGradually(skipParam) {
      if (!skipParam) {
        this.content = []
      }      
      let paramQuery = {
        '$limit': 50
      }
      // Manage Permissions first
      if (!(PermissionManager.hasExtendedTechnicianPermissions())) {
        // 1. filter by distributor and agent evt.
        const userDistributor = PermissionManager.getDistributorFilteredVisibility()
        if (userDistributor) {
          paramQuery.idDistributor = userDistributor.idDistributor
        }
        const userAgent = PermissionManager.getAgentFilteredVisibility()
        if (userAgent) {
          paramQuery.idAgent = userAgent.idAgent
        }        
      }       
      // Get active only from local storage
      let activeOnly = localStorage.getItem('showOnlyActiveRecords')
      if (activeOnly === 'true') {
        paramQuery.disabled = false
      }
      if (skipParam) {
        paramQuery['$skip'] = skipParam
      }
      CustomerLightService.getCustomers(paramQuery).then(
        (response) => {
          if (this.content.length > 0) {
            this.content = this.content.concat(response.data.data)
          } else {
            this.content = response.data.data
          }
          this.dataLoaded = true
          if (response.data.total > response.data.limit + response.data.skip) {
            if (skipParam) {
              skipParam += response.data.limit
            } else {
              skipParam = response.data.limit
            }
            this.getCustomersGradually(skipParam)
          }
        },
        (error)  => {
          this.message =           
           (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }        
      )
    }   
  },
  components: {
    GeneralTable,
    LoadingData
  }
}
</script>