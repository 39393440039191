<template>
  <div class="sidebar sidebar-fixed" :class="{ show: isVisible, hide: !isVisible}" @hide="warnMaster()">
    <CSidebarBrand>Strub A.G.</CSidebarBrand>
    <CSidebarNav v-if="checkAssignedUser()">
      <li class="nav-title">{{ $t('menu') }}</li>
      <CNavItem href="/m-machines">
        <CIcon  customClassName="nav-icon" icon="cil-speedometer"/>
        {{ $t('machines') }}
      </CNavItem>
      <CNavItem href="/m-products" v-if="distributorUser()">
        <CIcon  customClassName="nav-icon" icon="cil-puzzle"/>
        {{ $t('products') }}
      </CNavItem>      
      <CNavItem href="/m-machinesoverview" v-if="!distributorUser()">
        <CIcon  customClassName="nav-icon" icon="cil-bar-chart"/>
        {{ $t('machinesOverview') }}
      </CNavItem>
      <CNavItem href="/m-distributors" v-if="distributorUser()">
        <CIcon  customClassName="nav-icon" icon="cil-user"/> {{ $t('distributors') }}
      </CNavItem>
      <CNavItem href="/m-customers" v-if="distributorUser()">
        <CIcon  customClassName="nav-icon" icon="cil-user"/> {{ $t('customers') }}
      </CNavItem>    
      <CNavItem href="/m-administration" v-if="userWithAdminPermissions()">
        <CIcon  customClassName="nav-icon" icon="cil-settings"/>
        {{ $t('settings') }}
      </CNavItem>           
    </CSidebarNav>  
    <CSidebarToggler
      @click="warnMaster()"/>
  </div>
  <CBackdrop class="sidebar-backdrop d-none" :visible="isVisible" @click="warnMaster()" />
</template>

<script>
import PermissionManager from '../../helpers/permissionManager'

export default {
  name: 'SidebarMobile',
  props: {
    sidebarVisible: Boolean
  },
  data() {
    return {
      isVisible: false   
    }
  },
  watch: {
    sidebarVisible: {
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          this.isVisible = newValue
        }
      }
    }
  },    
  methods: {
    userWithAdminPermissions() {
      return PermissionManager.hasAdminPermissions()
    },
    distributorUser() {
      const userCustomer = PermissionManager.getCustomerFilteredVisibility()
      return userCustomer ? false : true      
    },
    checkAssignedUser() {
      return PermissionManager.userIsAssigned()
    },
    warnMaster() {
      this.$emit('hideSidebar')
    }
  }

}
</script>