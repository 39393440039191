<template>
  <CContainer fluid>
    <div class="row">
      <div class="col-12 col-8-tablet push-2-tablet text-center">
        <h3 v-if="dataLoaded" class="title">
          <CIcon icon="cil-arrow-circle-left" size="xxl" @click="backToMachines()"/>
           {{ $t('machine') }} {{ currMachine.id }}: {{ currMachine.name }}
        </h3>
      </div>
    </div>
    <br>
    <machine-components />
    <br>
    <CRow>
      <CCol :sm="6">
        <CCard>
          <CCardBody>
            <CCardTitle><strong>{{ $t('machineDetails') }}</strong></CCardTitle>
            <!--<CCardText>With supporting text below as a natural lead-in to additional content.</CCardText>-->
            <CRow class="mb-3">
              <CFormLabel for="machineName" class="col-sm-4 col-form-label">{{ pretifyName('machineFields.name') }}</CFormLabel>
              <CCol sm="8">
                <CFormInput type="text" id="machineName" v-model="currMachine.name" />
              </CCol>
            </CRow>
            <CRow class="mb-3">
              <CFormLabel for="machineID" class="col-sm-4 col-form-label">{{ pretifyName('machineFields.machine_ID') }}</CFormLabel>
              <CCol sm="8">
                <CFormInput type="text" id="machineID" v-model="currMachine.id_Machine" />
              </CCol>
            </CRow>
            <CRow class="mb-3">
              <CFormLabel for="distributor" class="col-sm-4 col-form-label">{{ pretifyName('machineFields.distributor') }}</CFormLabel>
              <CCol sm="8">
                <CInputGroup class="mb-3">
                  <CFormInput type="text" id="distributor" v-model="distributor" readonly
                    :value="currDistributor" />
                  <CButton type="button" color="dark" variant="outline" id="searchDistributor" :disabled="checkAgentPermissions"
                  @click="distLookupVisible = !distLookupVisible">{{ $t('search') }}</CButton>
                </CInputGroup>
              </CCol>
            </CRow>                         
            <CRow class="mb-3">
              <CFormLabel for="criticalPH" class="col-sm-4 col-form-label">{{ pretifyName('machineFields.critical_pH') }}</CFormLabel>
              <CCol sm="8">
                <CFormInput type="number" id="criticalPH" v-model="currMachine.critical_pH" :disabled="checkExtendedAgentPermissions" />
              </CCol>
            </CRow>     
            <CRow class="mb-3">
              <CFormLabel for="sumpSize" class="col-sm-4 col-form-label">{{ pretifyName('machineFields.sump_Size') }}</CFormLabel>
              <CCol sm="8">
                <CFormInput type="number" id="sumpSize" v-model="currMachine.sump_Size" />
              </CCol>
            </CRow>
            <CRow class="mb-3">
              <CFormLabel class="col-sm-6 col-form-label">{{ pretifyName('machineFields.min-max_Eff_Conc') }}</CFormLabel>
              <CCol sm="3">
                <CFormInput type="number" id="minEffConc" v-model="currMachine.min_Eff_Concentration" :disabled="checkCustomerPermissions" />
              </CCol>
              <CCol sm="3">
                <CFormInput type="number" id="maxEffConc" v-model="currMachine.max_Eff_Concentration" :disabled="checkCustomerPermissions" />
              </CCol>              
            </CRow>   
            <CRow class="mb-3">
              <CFormLabel for="water" class="col-sm-4 col-form-label">{{ pretifyName('machineFields.water') }}</CFormLabel>
              <CCol sm="8">
                <CFormSelect class="mb-3" aria-label="water select" id="water" v-model="currMachine.water_int">
                  <option value="0">{{ $t('waterOptions.soft') }}</option>
                  <option value="1">{{ $t('waterOptions.medium') }}</option>
                  <option value="2">{{ $t('waterOptions.hard') }}</option>
                  <option value="3">{{ $t('waterOptions.demineralised') }}</option>
                  <option value="4">{{ $t('waterOptions.ostmotic') }}</option>
                  <option value="5">{{ $t('waterOptions.process') }}</option>                  
                </CFormSelect>
              </CCol>
            </CRow>                                                          
            <CRow class="mb-3">
              <CFormLabel for="machineStatus" class="col-sm-4 col-form-label">{{ pretifyName('status') }}</CFormLabel>
              <CCol sm="8">
                <CFormSelect class="mb-3" aria-label="status select" id="machineStatus" v-model="currMachine.status_int">
                  <option value="0">{{ $t('statusOptions.active') }}</option>
                  <option value="1">{{ $t('statusOptions.inactive') }}</option>
                  <option value="2">{{ $t('statusOptions.under_Mantainance') }}</option>
                </CFormSelect>
              </CCol>
            </CRow>            
            <CButton type="button" color="dark" shape="rounded-0" id="updateMachine"
              :disabled="guestPermissions"
              @click="updateMachine()">
              {{ $t('updateMachine') }}</CButton>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol :sm="6">
        <CCard>
          <CCardBody>
            <CCardTitle><strong>{{ $t('analysisRequestedBy') }}</strong></CCardTitle>
            <CCardText>{{ currMachine.customer_Name }}</CCardText>
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
              <CButton type="button" color="dark" shape="rounded-0" id="changeCustomer" :disabled="checkAgentPermissions"
                @click="custLookupVisible = !custLookupVisible">{{ $t('change') }}</CButton>
            </div>
          </CCardBody>
        </CCard> 
        <br>      
        <CCard>
          <CCardBody>
            <CCardTitle><strong>{{ $t('machineContent') }}</strong></CCardTitle>
            <CRow class="mb-3">
              <CFormLabel for="product" class="col-sm-4 col-form-label">{{ pretifyName('machineFields.product') }}</CFormLabel>
              <CCol sm="8">
                <CFormInput type="text" id="product" v-model="product" readonly
                  :value="currMachine.product_Name" />
              </CCol>
            </CRow>
            <CRow class="mb-3">
              <CFormLabel for="productContent" class="col-sm-4 col-form-label">{{ pretifyName('machineFields.content') }}</CFormLabel>
              <CCol sm="8">
                <CFormInput type="number" id="productContent" v-model="productContent" readonly
                  :value="currMachine.product_Content" />
              </CCol>
            </CRow>
            <CRow class="mb-3">
              <CFormLabel for="lastFilled" class="col-sm-4 col-form-label">{{ pretifyName('machineFields.last_Filled') }}</CFormLabel>
              <CCol sm="8">
                <CFormInput type="text" id="lastFilled" v-model="lastFilled" readonly
                  :value="currMachine.last_Filled" />
              </CCol>
            </CRow>                        
            <div :class="formatButtonClass">
              <CButton type="button" color="dark" shape="rounded-0" id="checkMachine" :disabled="guestPermissions"
                @click="checkMachine()">{{ $t('updateContent') }}</CButton> 
              <CButton type="button" color="dark" shape="rounded-0" id="showHistory"
                @click="displayMachineHistory()">{{ $t('showMachineHistory') }}</CButton>                
              <CPopover placement="top" :trigger="specificBrowserTrigger">
                <template #content>
                  <div class="d-grid gap-2 justify-content-md-end">                
                    <CButton type="button" color="dark" shape="rounded-0" id="emptyMachine" :disabled="guestPermissions"
                      @click="askForEmptingConfirmation()">{{ $t('emptyMachine') }}</CButton>            
                    <CButton type="button" color="dark" shape="rounded-0" id="refillMachine" :disabled="guestPermissions"
                      @click="refillMachineClicked()">{{ $t('refillMachine') }}</CButton> 
                    <CButton type="button" color="dark" shape="rounded-0" id="changeProduct" :disabled="guestPermissions"
                      @click="changeProductVisible = !changeProductVisible">{{ $t('changeProduct') }}</CButton>
                  </div>
                </template>
                <template #toggler="{ on }">
                  <CButton type="button" color="dark" shape="rounded-0" id="advanced" v-on="on">
                    {{ $t('more') }}</CButton>     
                </template>
              </CPopover>
            </div>
          </CCardBody>
        </CCard>             
      </CCol>    
    </CRow>
    <br>
    <CRow>
      <CCollapse :visible="showMachineHistory" @show="$refs.machineEntries.getMachineEntriesGradually()">
        <CCard>
          <machine-entries-lookup
            ref="machineEntries"
            :machineID="this.$route.params.idMachine" />
        </CCard>
      </CCollapse>
    </CRow>

    <CModal size="xl" alignment="center" :visible="custLookupVisible" @close="() => { custLookupVisible = false }">
      <CModalHeader>
        <CModalTitle>{{ $t('customers') }}</CModalTitle>
      </CModalHeader>
      <CModalBody>      
        <customers-lookup
          @itemSelected="updateCustomer($event)" />
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="() => { custLookupVisible = false }">
          {{ $t('close') }}
        </CButton>
      </CModalFooter>
    </CModal>

    <CModal size="xl" alignment="center" :visible="distLookupVisible" @close="() => { distLookupVisible = false }">
      <CModalHeader>
        <CModalTitle>{{ $t('distributors') }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <distributors-lookup
          @itemSelected="getDistributorName($event)" />
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="() => { distLookupVisible = false }">
          {{ $t('close') }}
        </CButton>
      </CModalFooter>
    </CModal>

    <CModal size="xl" alignment="center" :visible="changeProductVisible" @close="() => { changeProductVisible = false }">
      <CModalHeader>
        <CModalTitle>{{ $t('products') }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <products-lookup
          @itemSelected="getProductName($event)" />
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="() => { changeProductVisible = false }">
          {{ $t('close') }}
        </CButton>
      </CModalFooter>
    </CModal>

    <CModal size="xl" alignment="center" :visible="refillMachineVisible" @close="() => { refillMachineVisible = false }">
      <CModalHeader>
        <CModalTitle>{{ getRefillPhaseTitle }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow class="mb-3">
          <CFormLabel for="newProduct" class="col-sm-2 col-form-label">{{ $t('product') }}</CFormLabel>
          <CCol sm="10">
            <CFormInput type="text" id="newProduct" v-model="newProduct" readonly
              :value="currProduct" />
          </CCol>
        </CRow>
        <CRow class="mb-3">
          <CFormLabel for="remainingQuantity" class="col-sm-2 col-form-label">
            <CLink v-c-tooltip="getRefillPhaseRemainingQuantityTooltip">{{ $t('remainingQuantity') }}</CLink>
          </CFormLabel>
          <CCol sm="10">
            <CFormInput type="number" id="remainingQuantity" v-model="remainingQuantity" />
          </CCol>
        </CRow>
        <CRow class="mb-3">
          <CFormLabel for="refillQuantity" class="col-sm-2 col-form-label">
            <CLink v-c-tooltip="getRefillPhaseRefillQuantityTooltip">
              {{ getRefillPhaseRefillQuantityTitle }}
            </CLink>
          </CFormLabel>
          <CCol sm="10">
            <CFormInput type="number" id="refillQuantity" v-model="refillQuantity" />
          </CCol>
        </CRow>                       
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="() => { refillMachineVisible = false }">
          {{ $t('close') }}
        </CButton>
        <CButton color="primary" @click="processMachineRefill(remainingQuantity, refillQuantity)">
          {{ $t('processChanges') }}
        </CButton>
      </CModalFooter>
    </CModal>    

    <machine-content-check-mobile
      :machineID="currMachine.id"
      :cModalVisible="machineContentCheckVisible"
      @closeMachineCheck="onCloseMachineCheck($event)" />

    <confirm-action
      :cModalVisible="popupConfirmMessage"
      :title="confirmTitle"
      :message="confirmMessage"
      :yesButton="$t('confirmButton')"
      :noButton="$t('cancelButton')"
      @confirm="confirmAction()" 
      @cancel="cancelAction()" />     

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert>     
  </CContainer>
</template>

<script>
import MachineService from '../../services/machines'
import DistributorService from '../../services/distributors'
import ProductService from '../../services/products'
import PerformMachineOperationsService from '../../services/perform-machine-operations'
import { MachineStatus, MachineWaterType } from '../../helpers/getEnumValues'
import MachineComponents from '../../components/MachineComponents.vue'
import CustomersLookup from './CustomersLookupMobile.vue'
import DistributorsLookup from './DistributorsLookupMobile.vue'
import ProductsLookup from './ProductsLookupMobile.vue'
import MachineEntriesLookup from './MachineEntriesLookupMobile.vue'
import DateFormatter from '../../helpers/dateFormatter'
import ConfirmAction from '../../components/ConfirmAction.vue'
import StringFormatter from '../../helpers/stringFormatter'
import PermissionManager from '../../helpers/permissionManager'
import MachineContentCheckMobile from './MachineContentCheckMobile.vue'

export default {
  name: 'EditMachineMobile',
  data() {
    return {
      currMachine: '',
      message: '',
      dataLoaded: false,
      custLookupVisible: false,
      distLookupVisible: false,
      currDistributor: '',
      refillMachineVisible: false,
      currProduct: '',
      currProductID: 0,
      changeProductVisible: false,
      currMachineRefillPhase: 0,
      showMachineHistory: false,
      popupConfirmMessage: false,
      confirmTitle: '',
      confirmMessage: '',
      machineContentCheckVisible: false       
    }
  },
  created() {
    this.getMachineValues()
  },
  computed: {
    getRefillPhaseTitle() {
      return ((this.currMachineRefillPhase == 0) ? this.$t('insertProductRefillQuantity') : this.$t('insertProductInitQuantity'))
    },
    getRefillPhaseRemainingQuantityTooltip() {
      return ((this.currMachineRefillPhase == 0) ? this.$t('insertMeasuredContainedQuantity') : this.$t('insertRemainingQuantity'))
    },
    getRefillPhaseRefillQuantityTooltip() {
      return ((this.currMachineRefillPhase == 0) ? this.$t('insertQuantityToRefill') : this.$t('insertQuantityToInit'))
    },
    getRefillPhaseRefillQuantityTitle() {
      return ((this.currMachineRefillPhase == 0) ? this.$t('refillQuantity') : this.$t('initQuantity'))
    },
    guestPermissions() {
      return !(PermissionManager.hasUserPermissions())
    },
    checkCustomerPermissions() {
      return (!(PermissionManager.hasAgentPermissions()) || (PermissionManager.getCustomerFilteredVisibility()))
    },
    checkAgentPermissions() {
      return (!(PermissionManager.hasExtendedAgentPermissions()) || (PermissionManager.getCustomerFilteredVisibility()))
    },    
    checkExtendedAgentPermissions() {
      return (!(PermissionManager.hasTechnicianPermissions()) || (PermissionManager.getCustomerFilteredVisibility()))
    },     
    formatButtonClass() {
      if ((screen.width <= 700) && (screen.width > 500)) {
        return 'd-grid gap-2 justify-content-md-end'
      } else {
        return 'd-grid gap-2 d-md-flex justify-content-md-end'
      }
    },
    specificBrowserTrigger() {
      let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      return (isSafari ? 'hover' : 'focus')
    }                    
  },
  methods: {
    getMachineValues() {
      if (this.$route.params.idMachine) {
        MachineService.getSingleMachine(this.$route.params.idMachine).then(
          (response) => {
            this.currMachine = response.data
            if (this.currMachine.last_Filled) {
              this.currMachine.last_Filled = DateFormatter.formatDate(this.currMachine.last_Filled, true)
            }
            this.currMachine.status_int = this.getMachineStatus(this.currMachine.status)
            this.currMachine.water_int = this.getMachineWater(this.currMachine.water)
            this.dataLoaded = true
            this.currDistributor = this.currMachine.distributor_Name
            this.currProduct = this.currMachine.product_Name
            this.currProductID = this.currMachine.idProduct
            this.setRouteInfoInLocalStorage()
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validMachineIDException')
      }
    },
    getMachineStatus(statusString) {
      if ((statusString) && (statusString != '')) {
        return MachineStatus.indexOf(statusString)
      } else {
        return 0
      }
    },
    getMachineWater(waterString) {
      if ((waterString) && (waterString != '')) {
        return MachineWaterType.indexOf(waterString)
      } else {
        return 0
      }
    },    
    updateMachine() {
      if ((this.$route.params.idMachine) && (this.currMachine.id == this.$route.params.idMachine)) {
        MachineService.updateMachines(this.$route.params.idMachine, {
          'name': this.currMachine.name,
          'id_Machine': this.currMachine.id_Machine,
          'idDistributor': this.currDistributor,
          'critical_pH': this.currMachine.critical_pH,
          'sump_Size': this.currMachine.sump_Size,
          'min_Eff_Concentration': this.currMachine.min_Eff_Concentration,
          'max_Eff_Concentration': this.currMachine.max_Eff_Concentration,
          'status': this.currMachine.status_int,
          'water': this.currMachine.water_int
        }).then(
          () => {
            this.getMachineValues()
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validMachineIDException')
      }
    },
    updateCustomer(customerID) {
      if ((this.$route.params.idMachine) && (customerID != 0)) {
        MachineService.updateMachines(this.$route.params.idMachine, {
          'idCustomer': customerID
        }).then(
          () => {
            this.custLookupVisible = false
            this.getMachineValues()
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validMachineIDException')
      }      
    },
    getDistributorName(distributorID) {
      if (distributorID != 0) {
        DistributorService.getSingleDistributor(distributorID).then(
          (response) => {
            this.distLookupVisible = false
            this.currDistributor = response.data.company
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validDistributorIDException')
      } 
    },
    getProductName(productID) {
      if (productID != 0) {
        ProductService.getSingleProduct(productID).then(
          (response) => {
            this.changeProductVisible = false
            this.currProduct = response.data.product
            this.currProductID = productID
            this.refillMachineVisible = true
            this.currMachineRefillPhase = 1 
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validProductIDException')
      }       
    },
    processMachineRefill(remainingQuantity, refillQuantity) {
      // Check Sump Size first:
      let quantityToCheck = (this.currMachineRefillPhase == 1 ? Number(refillQuantity) : Number(remainingQuantity) + Number(refillQuantity))
      if (Number(this.currMachine.sump_Size) < quantityToCheck) {
        this.message = this.$t('eccessiveProductQuantityWarning')
      } else {
        this.message = ''
        let operationParams = {
          'action': 'refillMachine',
          'phase': this.currMachineRefillPhase,
          'idMachine': this.currMachine.id,
          'remainingQuantity': remainingQuantity,
          'refillQuantity': refillQuantity
        }
        if (this.currMachineRefillPhase == 1) {
          operationParams.idProduct = this.currProductID
        }
        
        PerformMachineOperationsService.performOperation(operationParams).then(
          () => {
            this.refillMachineVisible = false
            this.getMachineValues()
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      }      
    },
    refillMachineClicked() {
      this.refillMachineVisible = !this.refillMachineVisible
      this.currMachineRefillPhase = 0
    },
    askForEmptingConfirmation() {
      this.confirmTitle = this.$t('continueTitle')
      this.confirmMessage = this.$t('emptyingMachineWarning')
      this.popupConfirmMessage = true
    },    
    emptyMachineClicked() {
      let operationParams = {
        'action': 'refillMachine',
        'phase': 2,
        'idMachine': this.currMachine.id,
        'remainingQuantity': 0,
        'refillQuantity': 0,
        'idProduct': 0
      }  
      this.performMachineOperation(operationParams)
    },
    onCloseMachineCheck(modifiedParameters) {
      if (modifiedParameters) {
        let operationParams = {
          'action': 'refillMachine',
          'phase': modifiedParameters.idProduct ? 1 : 0,
          'idMachine': this.currMachine.id,
          'remainingQuantity': '0',
          'refillQuantity': modifiedParameters.sump_Size,
          'executionDate': modifiedParameters.last_Filled
        }
        if (modifiedParameters.idProduct) {
          operationParams.idProduct = modifiedParameters.idProduct
        }
        this.performMachineOperation(operationParams)
      }
      this.machineContentCheckVisible = false
    },
    performMachineOperation(operationParams) {
      if (operationParams) {
        PerformMachineOperationsService.performOperation(operationParams).then(
          () => {
            this.getMachineValues()
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )    
      }  
    },   
    checkMachine() {
      this.machineContentCheckVisible = true
    },     
    displayMachineHistory() {
      this.showMachineHistory = !this.showMachineHistory
    },
    setRouteInfoInLocalStorage() {
      if (this.currMachine.id) {
        const params = {
          'idProduct': this.currMachine.idProduct,
          'idCustomer': this.currMachine.idCustomer,
          'idDistributor': this.currMachine.idDistributor,
          'measurementidMachine': this.currMachine.id
        }
        localStorage.setItem('routeInfo', JSON.stringify(params))
      }
    },
    confirmAction() {
      this.resetConfirmMessage()
      this.emptyMachineClicked()
    },
    cancelAction() {
      this.resetConfirmMessage()
    },
    resetConfirmMessage() {
      this.confirmTitle = ''
      this.confirmMessage = '',
      this.popupConfirmMessage = false
    },
    pretifyName(name) {
      return StringFormatter.pretifyName(this.$t(name))
    },
    backToMachines() {
      this.$router.go(-1)
    },                        
  },
  components: {
    MachineComponents,
    CustomersLookup,
    DistributorsLookup,
    ProductsLookup,
    MachineEntriesLookup,
    ConfirmAction,
    MachineContentCheckMobile
  }
}
</script>