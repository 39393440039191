import axios from 'axios'
import authHeader from './auth-header'
import formatQuery from './query-helper'

const API_URL = process.env.VUE_APP_SERVER_BASE_URL + 'customers'

class CustomerService {
    getCustomers(queryParams) {
        return axios.get(API_URL + formatQuery(queryParams), { headers: authHeader() })
    }
    getSingleCustomer(customerID) {
        return axios.get(API_URL + '/' + customerID, { headers: authHeader() })
    }       
    updateCustomer(customerID, customerParams) {
        return axios.patch(API_URL + '/' + customerID, customerParams, { headers: authHeader() })
    }
    createCustomer(customerParams) {
        return axios.post(API_URL, customerParams, { headers: authHeader() })
    }
    deleteCustomer(customerID) {
        return axios.delete(API_URL + '/' + customerID, {headers: authHeader() })
    }
    // Build other api calls here....   
}

export default new CustomerService()