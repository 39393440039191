import axios from 'axios'
import authHeader from './auth-header'
import formatQuery from './query-helper'

const API_URL = process.env.VUE_APP_SERVER_BASE_URL + 'machines-light'

class MachineLightService {
    getMachines(queryParams) {
        return axios.get(API_URL + formatQuery(queryParams), { headers: authHeader() })
    }
    getSingleMachine(machineID) {
        return axios.get(API_URL + '/' + machineID, { headers: authHeader() })
    }      
}

export default new MachineLightService()
