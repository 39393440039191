<template>
  <CChart
    type="line"
    :data="userDataset"
    :options="options"
    :plugins="[bgColor]"
  />
</template>

<script>
import { CChart } from '@coreui/vue-chartjs'

export default {
  name: 'ReportLineChart',
  props: {
      userDataset: Array
  },
  components: {
    CChart,
  },
  setup() {
    const bgColor = {
      id: 'bgColor',
      beforeDraw: (chart) => {
        const { ctx, width, height } = chart
        ctx.fillStyle = 'white'
        ctx.fillRect(0, 0, width, height)
        ctx.restore()
      }
    }

    const options = {
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            drawOnChartArea: false,
          },
          ticks: {
            maxTicksLimit: 10
          }
        },
        y: {
          ticks: {
            beginAtZero: false,
            maxTicksLimit: 10,
            stepSize: 0.5,
            max: 250,
          },
        },
      },
      elements: {
        point: {
          radius: 0,
          hitRadius: 10,
          hoverRadius: 4,
          hoverBorderWidth: 3,
        },
      },
    }

    return {
      options,
      bgColor
    }
  }
}
</script>
