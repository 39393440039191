<template>
  <div>
    <div class="wrapper d-flex flex-column min-vh-100 bg-light">
      <header-mobile />
      <div class="body flex-grow-1 px-3">
        <CContainer fluid>
          <router-view />
        </CContainer>
      </div>
      <footer-mobile />
    </div>
  </div>
</template>
<script>
import FooterMobile from '@/components/mobile/FooterMobile.vue'
import HeaderMobile from '@/components/mobile/HeaderMobile.vue'

export default {
  name: 'DefaultLayout',
  components: {
    FooterMobile,
    HeaderMobile
  },
}
</script>