<template>
  <CCard fluid v-if="isDataLoaded">
    <CCardBody>
      <CCardTitle>{{ $t('customer') }} </CCardTitle>
      <CCardText>{{ pretifyName('customerFields.id') }}: {{ currCustomer.id }} <br> {{ pretifyName('customerFields.name') }}: {{ currCustomer.company }}<br>
       {{ pretifyName('customerFields.address') }}: {{ currCustomer.address }}<br> {{ pretifyName('customerFields.city') }}: {{ currCustomer.city }}<br>
       {{ pretifyName('customerFields.phone_Number') }}: {{ currCustomer.phone_Number }}<br> {{ pretifyName('customerFields.contact_Person') }}: {{ currCustomer.contact_Person }}<br> 
       {{ pretifyName('customerFields.client_No') }}: {{ currCustomer.client_No }}</CCardText>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <CButton type="submit" color="dark" shape="rounded-0" class="mb-3"
          @click="() => { customerCardModal = !customerCardModal }">
          {{ $t('editCustomer') }}
        </CButton>
      </div>
    </CCardBody>

    <customer-card
      :cModalVisible="customerCardModal"
      operationType="0"
      :customerID="customerID"
      @closeCustomerCardModal="toggleCustCardModalVisibility($event)"/> 

  </CCard>
</template>

<script>
import CustomerService from '../services/customers'
import CustomerCard from '../components/CustomerCard.vue'
import StringFormatter from '../helpers/stringFormatter'

export default {
  name: 'CustomerInfobox',
  props: {
    customerID: Number
  },
  expose: ['dataLoaded'],
  data() {
    return {
      dataLoaded: false,
      currCustomer: '',
      lastCustomerID: 0,
      customerCardModal: false
    }
  },
  computed: {
    isDataLoaded() {
      if (this.customerID != this.lastCustomerID) {
        this.refreshData()
      }
      if (!this.dataLoaded) {
        this.getCustomerData()
      }
      return this.dataLoaded
    }
  },
  methods: {
    getCustomerData() {
      if (this.customerID != 0) {
        CustomerService.getSingleCustomer(this.customerID).then(
          (response) => {
            this.currCustomer = response.data
            this.lastCustomerID = this.currCustomer.id
            this.dataLoaded = true
          }
        )
      } else {
        this.dataLoaded = true
      }
    },
    refreshData() {
      this.dataLoaded = false
    },
    toggleCustCardModalVisibility(refreshPage) {
      this.customerCardModal = !this.customerCardModal
      if (refreshPage) {
        this.dataLoaded = false
      }
    },
    pretifyName(name) {
      return StringFormatter.pretifyName(this.$t(name))
    }            
  },
  components: {
    CustomerCard
  }
}
</script>