<template>
  <CModal size="xl" :visible="picturesModalVisible" @close="closeImageUploadComp()">
    <CModalHeader>
      <CModalTitle>{{ $t('uploadPictures') }}</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <div id="app">
        <DropZone class="drop-area" @files-dropped="addFiles" #default="{ dropZoneActive }">
          <label for="file-input">
            <span v-if="dropZoneActive">
              <span>{{ $t('dropPicture') }}</span>
              <span class="smaller">{{ $t('dropPicture2') }}</span>
            </span>
            <span v-else>
              <span>{{ $t('dragFiles') }} </span>
              <span class="smaller">
                {{ $t('or') }} <strong><em>{{ $t('clickHere') }}</em></strong> {{ $t('toSelectFiles') }}
              </span>
            </span><br>

            <input type="file" id="file-input" multiple @change="onInputChange" />
          </label>
          <ul class="image-list" v-show="files.length">
            <FilePreview v-for="file of files" :key="file.id" :file="file" tag="li" @remove="removeFile" />
          </ul>  
          <br><br>
          <!--<button @click.prevent="uploadFiles(files)"  class="upload-button">Upload</button>-->
          <div class="d-grid gap-2 d-md-flex justify-content-md-end">
            <CButton color="dark" @click.prevent="uploadFiles(files)"  class="upload-button">{{ $t('upload') }}</CButton>
          </div>
        </DropZone>
      </div>
    </CModalBody>
  </CModal>
</template>

<script setup>
import useFileList from '../compositions/file-list'
import DropZone from './DropZone.vue'
import FilePreview from './FilePreview.vue'
import createUploader from '../compositions/file-uploader'

const { uploadFiles } = createUploader()
const { files, addFiles, removeFile } = useFileList()

const emit = defineEmits(['closeImageUploadComp'])

function onInputChange(e) {
  addFiles(e.target.files)
  e.target.value = null
}

function getUploadedFiles() {
  let uploadedFiles = []
  files.value.forEach(file => {
    if (file.serverUrl) {
      uploadedFiles.push(file.serverUrl)
    }
  })
  return uploadedFiles  
}

function closeImageUploadComp() {
  emit('closeImageUploadComp', getUploadedFiles())
}
</script>

<script>
export default {
  name: 'UploadPicture',
  props: {
    picturesModalVisible: Boolean
  }
}
</script>

<style>
::-webkit-file-upload-button {
   display: none;
}
</style>