<template>
  <div>
    <div class="row">
      <div class="col-12 col-8-tablet push-2-tablet text-center">
        <CIcon size="xxl" icon="cil-bar-chart"/>
        <h3 class="title">
          <CIcon icon="cil-arrow-circle-left" size="xxl" @click="backToMachines()"/>
           {{ $t('measurements') }} {{ specificMachine }}
        </h3>
      </div>
    </div>

    <CContainer v-if="currProductID">
      <CRow class="align-items-center">
        <CCol>
          <CCard>
            <CCardBody>
              <CCardTitle>{{ $t('phValuesVariation') }}</CCardTitle>
              <general-line-chart v-if="dataLoaded"
                :userDataset="phValuesDataset" />
            </CCardBody>
          </CCard>
        </CCol>
        <CCol>
          <CCard>
            <CCardBody>
              <CCardTitle>{{ $t('effConcentrationVariation') }}</CCardTitle>
              <general-line-chart v-if="dataLoaded"
                :userDataset="effConcentrationsDataset" />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>  
    <br>
    <CRow v-if="currProductID">
      <CCol sm="6">
        <div class="d-grid gap-2 d-md-flex justify-content-md-start">
          <CButton type="button" color="danger" shape="rounded-0" id="newMeasurement" @click="newMeasurementFromMachine()">{{ $t('newMeasurement') }}</CButton><br>
        </div>    
      </CCol>
      <CCol sm="6">
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <CButton type="button" color="dark" shape="rounded-0" id="labReports" @click="goToLabReports(0)">{{ $t('labReports') }}</CButton>
          <CButton type="button" color="dark" shape="rounded-0" id="waterAnalysis" @click="goToLabReports(1)">{{ $t('waterAnalysis') }}</CButton>
        </div>
      </CCol>
    </CRow>          

    <loading-data v-if="!dataLoaded"/>

    <small><general-table v-if="dataLoaded"
      :data="measurements"
      :columns="tableColumns"
      :rowsdetail="tableRowsDetails"
      :showItemsPerPage="false"
      :sorter="false"
      :columnFilter="false"      
      :extraButtonName="$t('pictures')"
      :extraButtonName2="canRequestAnalysis"
      hideEditButtons
      @customAction="showPictures($event)"
      @customAction2="requestAnalysis($event)"
      @showComment="showComment($event)"/></small>

    <pictures-view
      :idMeasurement="selectedIDMeasure"
      :cModalVisible="picturesModalVisible"
      @closePictureModal="() => {picturesModalVisible = false}" />

    <new-lab-report-request-mobile
      :cModalVisible="labReportRequestVisible"
      :idMeasure="selectedIDMeasure"
      @closeAnalysisRequest="onCloseAnalysisRequest()" />      

    <CModal alignment="center" :visible="commentVisible" @close="() => { commentVisible = false }">
      <CModalHeader>
        <CModalTitle>{{ $t('comment') }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CFormTextarea id="measurementComment" rows="5" v-model="measurementComment" readonly />
      </CModalBody>
    </CModal>

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </div>
</template>

<script>
import GeneralTable from '@/components/GeneralTable.vue'
import GeneralLineChart from '@/components/GeneralLineChart.vue'
import MeasurementService from '../../services/measurements'
import MachineService from '../../services/machines'
import { getStyle } from '@coreui/utils/src'
import DateFormatter from '../../helpers/dateFormatter'
import PicturesView from '../../components/PicturesView.vue'
import StringFormatter from '../../helpers/stringFormatter'
import LoadingData from '@/components/LoadingData.vue'
import PermissionManager from '../../helpers/permissionManager'
import NewLabReportRequestMobile from './NewLabReportRequestMobile.vue'
import MeasurementLightService from '../../services/measurements-light'

export default {
  name: 'Measurements',
  data() {
    return {
      dataLoaded: false,
      message: '',
      content: [],
      searchQuery: '',
      tableColumns: [
        { key: 'measurementFields.id', _style: 'min-width: 50px' },
        { key: 'measurementFields.measured_By', _style: 'min-width: 150px' },
        { key: 'measurementFields.pH', _style: 'min-width: 50px' },
        { key: 'measurementFields.refrac_Reading', _style: 'min-width: 100px' },
        { key: 'measurementFields.eff_Concentration', _style: 'min-width: 100px' },
        { key: 'measurementFields.temperature', _style: 'min-width: 90px' },
        { key: 'measurementFields.appareance', _style: 'min-width: 170px' },
        { key: 'measurementFields.measured_At', _style: 'min-width: 140px' },
        {
          key: 'comment', 
          label: '', 
          _style: 'width:1%', 
          sorter: false, 
          filter: false
        },        
        {
          key: 'show_details', 
          label: '', 
          _style: 'width:1%', 
          sorter: false, 
          filter: false
        }
      ],
      tableRowsDetails: [
        'measurementFields.machine',
        'measurementFields.product',          
        'measurementFields.distributor',
        'measurementFields.customer',
        'measurementFields.water_Hardness',
        'measurementFields.last_Filled',
        'measurementFields.creation_Date',
        //'measurementFields.created_By',
        'measurementFields.refrac_Factor',
        'measurementFields.image_Count',
        'measurementFields.id_Report'
      ],
      machineNameLoaded: false,
      currMachineName: '',
      currMachinecriticalPH: 0,
      currMachineMinEffConc: 0,
      currMachineMaxEffConc: 0,
      showAllMeasurements: false,
      currProductID: 0,
      selectedIDMeasure: 0,
      picturesModalVisible: false,
      commentVisible: false,
      measurementComment: '',
      labReportRequestVisible: false,
      currMachine: ''
    }
  },
  computed: {
    specificMachine() {
      if (!this.machineNameLoaded) {
        this.getMachineData()
      } else {
        if (!this.dataLoaded) {
          this.getMeasurementsGradually()
        }
      }
      if (this.currMachineName != '') {
        return ' (' + this.currMachineName + ')'
      } else {
        return ''
      }
    },
    measurements() {
      let processedData = []
      if (this.content.length > 0) {
        this.content.forEach((measurement) => {
          processedData.push({
            'measurementFields.id': measurement.id,
            'measurementFields.machine': measurement.machine_Name ? measurement.machine_Name : this.currMachine.name,
            'measurementFields.product': measurement.product_Name ? measurement.product_Name : this.currMachine.product_Name,
            'measurementFields.measured_By': this.getMeasuredByName(measurement),
            'measurementFields.pH': measurement.pH,
            'measurementFields.refrac_Reading': measurement.refrac_Reading,
            'measurementFields.eff_Concentration': measurement.eff_Concentration,
            'measurementFields.temperature': measurement.temperature,
            'measurementFields.measured_At': DateFormatter.formatDate(measurement.measured_At, true),
            'measurementFields.distributor': measurement.distributor_Name ? measurement.distributor_Name : this.currMachine.distributor_Name,
            'measurementFields.customer': measurement.customer_Name ? measurement.customer_Name : this.currMachine.customer_Name,
            'measurementFields.water_Hardness': measurement.water_Hardness,
            'measurementFields.appareance': measurement.appearance,
            'measurementFields.comment': measurement.comment,
            'measurementFields.last_Filled': DateFormatter.formatDate(measurement.last_Filled, true),
            'measurementFields.creation_Date': DateFormatter.formatDate(measurement.created, true),
            'measurementFields.created_By': measurement.created_By_User,
            'measurementFields.refrac_Factor': measurement.refactormeter_Factor,
            'measurementFields.image_Count': measurement.image_Count,
            'measurementFields.id_Report': measurement.idReport                    
          })                    
        })
      }
      return processedData
    },
    phValuesDataset() {
      let phArray = []
      let phValueLabels= []
      this.measurements.forEach(measure => {
        phArray.unshift(measure['measurementFields.pH'])
        phValueLabels.unshift(DateFormatter.formateDateShort(measure['measurementFields.measured_At'], true))        
      })
      let dataset = []
      dataset.push({
        label: this.$t('phValues'),
        backgroundColor: context => this.getLineColor(context, this.currMachinecriticalPH),     
        borderColor: context => this.getLineColor(context, this.currMachinecriticalPH),
        segment: {
          borderColor: context => context.p1.parsed.y >= this.currMachinecriticalPH ? getStyle('--cui-success') : getStyle('--cui-danger')     
        },        
        pointHoverBackgroundColor: context => this.getLineColor(context, this.currMachinecriticalPH),
        borderWidth: 2, 
        data: phArray,        
      })
      if (this.currMachinecriticalPH != 0) {
        dataset.push({
          label: this.pretifyName('machineFields.critical_pH'),
          backgroundColor: getStyle('--cui-warning'),
          borderColor: getStyle('--cui-warning'),
          pointHoverBackgroundColor: getStyle('--cui-warning'),
          borderWidth: 2,
          data: this.getConstantArrayValue(phArray, this.currMachinecriticalPH)         
        })
      }
      return {
        labels: phValueLabels,
        datasets: dataset
      } 
    },
    effConcentrationsDataset() {
      let effArray = []
      let effConcLabels = []
      this.measurements.forEach(measure => {
        effArray.unshift(measure['measurementFields.eff_Concentration'])
        effConcLabels.unshift(DateFormatter.formateDateShort(measure['measurementFields.measured_At'], true)) 
      })
      let dataset = []
      dataset.push({
        label: this.$t('effConcentration'),
        backgroundColor: context => this.getLineColor(context, this.currMachineMinEffConc, this.currMachineMaxEffConc),     
        borderColor: context => this.getLineColor(context, this.currMachineMinEffConc, this.currMachineMaxEffConc),
        segment: {
          borderColor: context => (context.p1.parsed.y >= this.currMachineMinEffConc) && (context.p1.parsed.y <= this.currMachineMaxEffConc) ? getStyle('--cui-success') : getStyle('--cui-danger')     
        },        
        pointHoverBackgroundColor: context => this.getLineColor(context, this.currMachineMinEffConc, this.currMachineMaxEffConc),
        borderWidth: 2,
        data: effArray
      })
      if (this.currMachineMinEffConc != 0) {
        dataset.push({
          label: this.pretifyName('machineFields.min_Eff_Conc'),
          backgroundColor: 'transparent',
          borderColor: getStyle('--cui-warning'),
          pointHoverBackgroundColor: getStyle('--cui-warning'),
          borderWidth: 2,
          data: this.getConstantArrayValue(effArray, this.currMachineMinEffConc)         
        })
      }
      if (this.currMachineMaxEffConc != 0) {
        dataset.push({
          label: this.pretifyName('machineFields.max_Eff_Conc'),
          backgroundColor: 'transparent',
          borderColor: getStyle('--cui-warning'),
          pointHoverBackgroundColor: getStyle('--cui-warning'),
          borderWidth: 2,
          data: this.getConstantArrayValue(effArray, this.currMachineMaxEffConc)         
        })
      }          
      return {
        labels: effConcLabels,
        datasets: dataset
      }
    },
    canRequestAnalysis() {
      const agentUser = PermissionManager.getAgentFilteredVisibility()
      const customerUser = PermissionManager.getCustomerFilteredVisibility()
      if ((agentUser == null) && (customerUser == null)) {
        return null
      } else {
        return this.$t('requestAnalysis')
      }
    }
  },
  methods: {
    getMeasurementsGradually(skipParam) {
      if (!skipParam) {
        this.content = []
      }
      let queryParms = {}
      let routeParams = this.$route.params
      if ((routeParams.idMachine) && (routeParams.idMachine != 0)) {
          queryParms.idMachine = routeParams.idMachine
      }
      if ((routeParams.idMeasure) && (routeParams.idMeasure != 0)) {
        queryParms.id = routeParams.idMeasure
      }
      if (!this.showAllMeasurements) {
        if (this.currProductID != 0) {
          queryParms.idProduct = this.currProductID
        }
      }      
      queryParms.$limit = 50
      queryParms['$sort[measured_At]'] = -1
      if (skipParam) {
        queryParms['$skip'] = skipParam
      }
      if (this.showAllMeasurements) {      
        MeasurementService.getMeasurements(queryParms).then(
          (response) => {
            if (this.content.length > 0) {
              this.content = this.content.concat(response.data.data)
            } else {
              this.content = response.data.data
            }
            this.dataLoaded = true
            if (response.data.total > response.data.limit + response.data.skip) {
              if (skipParam) {
                skipParam += response.data.limit
              } else {
                skipParam = response.data.limit
              }
              this.getMeasurementsGradually(skipParam)
            }
          },
          (error)  => {
            this.message =           
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
          }        
        )
      } else {
        MeasurementLightService.getMeasurements(queryParms).then(
          (response) => {
            // Do not show more than 50 measurements... It gets too slow to render the graph...
            this.content = response.data.data
            this.dataLoaded = true           
          },
          (error)  => {
            this.message =           
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
          }        
        )        
      }      
    },    
    getMachineData() {
      let routeParams = this.$route.params
      if ((routeParams.idMachine) && (routeParams.idMachine != 0)) {          
        MachineService.getSingleMachine(routeParams.idMachine).then(
          (response) => {
            this.currMachine = response.data
            this.currMachineName = response.data.name
            this.currProductID = response.data.idProduct
            this.currMachinecriticalPH = response.data.critical_pH
            this.currMachineMinEffConc = response.data.min_Eff_Concentration
            this.currMachineMaxEffConc = response.data.max_Eff_Concentration
            this.machineNameLoaded = true
            this.setRouteInfoInLocalStorage(response.data)
          }
        )
      } else {
        this.machineNameLoaded = true
        this.currMachineName = ''
        this.showAllMeasurements = true
        this.getMeasurementsGradually()           
      }
    },
    goToLabReports(reportType) {
      const calledByOverview = this.$route.params.calledByOverview
      if (calledByOverview) {  
        this.$router.push({ name: 'mLabReports', params: { idMachine: this.$route.params.idMachine, currProductID: this.getCurrProductID(), labReportType: reportType, 'calledByOverview': true } })
      } else {       
        this.$router.push({ name: 'mLabReports', params: { idMachine: this.$route.params.idMachine, currProductID: this.getCurrProductID(), labReportType: reportType} })
      }
    },
    getConstantArrayValue(refArray, value) {
      let resultArray = []
      refArray.forEach(() => {
        resultArray.push(value)
      })
      return resultArray
    },
    setRouteInfoInLocalStorage(selectedMachine) {
      if (selectedMachine) {
        const params = {
          'idMachine': selectedMachine.id,
          'idProduct': selectedMachine.idProduct,
          'idCustomer': selectedMachine.idCustomer,
          'idDistributor': selectedMachine.idDistributor
        }
        localStorage.setItem('routeInfo', JSON.stringify(params))
      }
    },
    getCurrProductID() {
      return (this.showAllMeasurements ? 0 : this.currProductID)
    },
    getLineColor(context, lowerLimit, upperLimit) {
      const index = context.dataIndex
      const value = context.dataset.data[index]
      if (!lowerLimit) { lowerLimit = 0 }
      if (!upperLimit) { upperLimit = 9999}
      // Check if lower/upper Limits are numbers
      lowerLimit = Number(lowerLimit)
      if (isNaN(lowerLimit)) { lowerLimit = 0 }
      upperLimit = Number(upperLimit)
      if (isNaN(upperLimit)) { upperLimit = 0 }         
      return ((value >= lowerLimit) && (value <=upperLimit)) ? getStyle('--cui-success') : getStyle('--cui-danger')
    },
    showPictures(selectedMeasureID) {
      this.selectedIDMeasure = selectedMeasureID
      this.picturesModalVisible = true
    },
    pretifyName(name) {
      return StringFormatter.pretifyName(this.$t(name))
    },
    showComment(selectedMeasureID) {
      if ((selectedMeasureID) && (selectedMeasureID > 0)) {
        let measureIndex = this.content.findIndex(measurement => measurement.id == selectedMeasureID)
        if (measureIndex >= 0) {
          this.measurementComment = this.content[measureIndex].comment
          this.commentVisible = true
        }
      }
    },
    newMeasurementFromMachine() {
      let routeParams = this.$route.params
      if ((routeParams.idMachine) && (routeParams.idMachine != 0)) { 
        this.$router.push({ name: 'mMeasure', params: { 'machineID': routeParams.idMachine, 'measurementType': 0, 'calledByMeasurement': true } })
      } else {
        this.$router.push({ name: 'mMeasure', params: { 'measurementType': 3 } }) 
      }
    },
    requestAnalysis(measurementID) {
      if ((measurementID) && (measurementID != 0)) {
        this.selectedIDMeasure = measurementID
        this.labReportRequestVisible = true
      }
    },
    onCloseAnalysisRequest() {
      this.labReportRequestVisible = false
    },
    backToMachines() {
      this.$router.go(-1)
    }, 
    getMeasuredByName(fromMeasurement) {
      if (this.showAllMeasurements) {
        return fromMeasurement.measured_By_Name
      } else {
        return fromMeasurement.measuredBy_Name ? fromMeasurement.measuredBy_Name : fromMeasurement.measuredBy
      }
    }        
  },
  components: {
    GeneralTable,
    GeneralLineChart,
    PicturesView,
    LoadingData,
    NewLabReportRequestMobile
  }
}
</script>