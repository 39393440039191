<template>
  <div>
    <div class="row">
      <div class="col-12 col-8-tablet push-2-tablet text-center">
        <CIcon size="xxl" icon="cil-user"/>
        <h3 class="title">{{ $t('customers') }}</h3>
      </div>
    </div>

    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
      <CButton type="button" color="dark" shape="rounded-0" id="createCustomer" @click="createCustomer()">{{ $t('createNewCustomer') }}</CButton>
    </div>

    <loading-data v-if="!dataLoaded"/>

    <small><general-table v-if="dataLoaded"
      :data="customers"
      :columns="tableColumns"
      :rowsdetail="tableRowsDetails"
      :extraButtonName="$t('users')"
      machineLookupButton
      :showItemsPerPage="false"
      :sorter="false"
      :columnFilter="false"      
      @customAction="goToUsers($event)"
      @editItem="editCustomer($event)"
      @deleteItem="askForDeletionConfirmation($event)"
      @machineLookup="lookupMachines($event)"/></small>      

    <customer-card
      :cModalVisible="customerCardModal"
      :operationType="currOperationType"
      :customerID="selectedCustomerID"
      @closeCustomerCardModal="toggleCustCardModalVisibility($event)"/>         

    <CModal size="xl" alignment="center" :visible="userListVisible" @close="() => { userListVisible = false }">
      <CModalHeader>
        <CModalTitle>{{ $t('users') }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <users-lookup-mobile
          :companyType="1"
          :companyID="selectedCustomerID" />
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="() => { userListVisible = false }">
          {{ $t('close') }}
        </CButton>
      </CModalFooter>
    </CModal>  

    <confirm-action
      :cModalVisible="popupConfirmMessage"
      :title="confirmTitle"
      :message="confirmMessage"
      :yesButton="$t('confirmButton')"
      :noButton="$t('cancelButton')"
      @confirm="confirmAction()" 
      @cancel="cancelAction()" />        

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </div>
</template>

<script>
import GeneralTable from '@/components/GeneralTable.vue'
import CustomerService from '../../services/customers'
import UsersLookupMobile from './UsersLookupMobile.vue'
import CustomerCard from '../../components/CustomerCard.vue'
import DateFormatter from '../../helpers/dateFormatter'
import ConfirmAction from '../../components/ConfirmAction.vue'
import LoadingData from '../../components/LoadingData.vue'
import PermissionManager from '../../helpers/permissionManager'
import MachineService from '../../services/machines'

export default {
  name: 'CustomersMobile',
  data() {
    return {
      dataLoaded: false,
      message: '',
      content: [],
      searchQuery: '',
      tableColumns: [
        { key: 'customerFields.id', _style: 'min-width: 50px' },
        { key: 'customerFields.name', _style: 'min-width: 180px' },
        { key: 'customerFields.address', _style: 'min-width: 200px' },
        { key: 'customerFields.phone_Number', _style: 'min-width: 130px' },
        { key: 'customerFields.client_No', _style: 'min-width: 70px' },
        {
          key: 'show_details', 
          label: '', 
          _style: 'width:1%', 
          sorter: false, 
          filter: false
        }
      ],
      tableRowsDetails: [
        'disabled',
        'customerFields.contact_Person',
        'customerFields.distributor',
        'customerFields.zip',
        'customerFields.creation_Date',
        'customerFields.agent_Name'
      ],
      selectedCustomerID: 0,
      userListVisible: false,
      customerCardModal: false,
      currOperationType: 0,
      popupConfirmMessage: false,
      confirmTitle: '',
      confirmMessage: ''
    }
  },
  created() {
    this.getCustomersGradually()
  },
  computed: {
    customers() {
      let processedData = []
      if (this.content.length > 0) {
        this.content.forEach((customer) => {
          processedData.push({
            'customerFields.id': customer.id,
            'customerFields.name': customer.company,
            'customerFields.address': customer.address + ', ' + customer.city + ' (' + customer.country_Name + ')',
            'customerFields.phone_Number': customer.phone_Number,
            'customerFields.client_No': customer.client_No,
            'disabled': customer.disabled,
            'customerFields.contact_Person': customer.contact_Person,
            'customerFields.distributor': customer.distributor_Name,
            'customerFields.zip': customer.zipCode,
            'customerFields.creation_Date': DateFormatter.formatDate(customer.created, true),
            'customerFields.agent_Name': customer.agent_Name                  
          })                    
        })
      }
      return processedData
    }
  },
  methods: {
    getCustomersGradually(skipParam) {
      if (!skipParam) {
        this.content = []
      }      
      let paramQuery = {
        '$limit': 50
      }
      // Manage Permissions first
      if (!(PermissionManager.hasExtendedTechnicianPermissions())) {
        // 1. filter by distributor and agent evt.
        const userDistributor = PermissionManager.getDistributorFilteredVisibility()
        if (userDistributor) {
          paramQuery.idDistributor = userDistributor.idDistributor
        }
        const userAgent = PermissionManager.getAgentFilteredVisibility()
        if (userAgent) {
          paramQuery.idAgent = userAgent.idAgent
        }        
      }      
      // Get active only from local storage
      let activeOnly = localStorage.getItem('showOnlyActiveRecords')
      if (activeOnly === 'true') {
        paramQuery.disabled = false
      }      
      if (skipParam) {
        paramQuery['$skip'] = skipParam
      }
      CustomerService.getCustomers(paramQuery).then(
        (response) => {
          if (this.content.length > 0) {
            this.content = this.content.concat(response.data.data)
          } else {
            this.content = response.data.data
          }
          this.dataLoaded = true
          if (response.data.total > response.data.limit + response.data.skip) {
            if (skipParam) {
              skipParam += response.data.limit
            } else {
              skipParam = response.data.limit
            }
            this.getCustomersGradually(skipParam)
          }
        },
        (error)  => {
          this.message =           
           (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }        
      )
    },      
    editCustomer(customerID) {
      if ((!customerID) || (customerID == 0)) {
        this.message = this.$t('validCustomerException')
      } else {
        this.currOperationType = 0
        this.selectedCustomerID = customerID
        this.customerCardModal = !this.customerCardModal
      }
    },
    createCustomer() {
      this.currOperationType = 1
      this.selectedCustomerID = 0
      this.customerCardModal = !this.customerCardModal
    },
    askForDeletionConfirmation(customerID) {
      // Check if customer is associated to a specific machine
      MachineService.getMachines({'idCustomer': customerID}).then(
        (response) => {
          if (response.data.total > 0) {
            this.message = this.$t('associatedMachineException')
          } else {
            this.selectedCustomerID = customerID
            this.confirmTitle = this.$t('continueTitle')
            this.confirmMessage = this.$t('customerDeletionWarning', { cust: customerID })
            this.popupConfirmMessage = true
          }
        }
      )
    },
    deleteCustomer() {
      if ((!this.selectedCustomerID) || (this.selectedCustomerID == 0)) {
        this.message = this.$t('nonExistentCustomerException')
      } else {
        // let continueProcessing = confirm('You are about to delete the customer... Do you want to continue?')
        // if (continueProcessing) {
          CustomerService.deleteCustomer(this.selectedCustomerID).then(
            () => {
              this.dataLoaded = false
              this.getCustomersGradually()
            },
            (error)  => {
              this.message =           
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()
            }
          ) 
        //}
      }            
    },
    goToUsers(customerID) {
      if (this.userListVisible) {
        this.userListVisible = false
      } else {
        this.selectedCustomerID = customerID
        this.userListVisible = true
      }
    },
    toggleCustCardModalVisibility(refreshPage) {
      this.customerCardModal = !this.customerCardModal
      if (refreshPage) {
        this.dataLoaded = false
        this.getCustomersGradually()
      }
    },
    lookupMachines(customerID) {
      if (customerID) {
        this.$router.push({ name: 'mMachinesCustomer', params: { idCustomer: customerID } })
      }
    },
    confirmAction() {
      this.resetConfirmMessage()
      this.deleteCustomer()
    },
    cancelAction() {
      this.resetConfirmMessage()
    },
    resetConfirmMessage() {
      this.confirmTitle = ''
      this.confirmMessage = '',
      this.popupConfirmMessage = false
    }                 
  },
  components: {
    GeneralTable,
    UsersLookupMobile,
    CustomerCard,
    ConfirmAction,
    LoadingData
  }
}
</script>