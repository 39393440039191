// JS File used to define all the enum that can be used after in the other classes
class Enum {
    constructor (...keys) {
      keys.forEach((key, i) => {
        this[key] = i
      })
      Object.freeze(this)
    }
  
    * [Symbol.iterator] () {
      for (const key of Object.keys(this)) yield key
    }
  }
  
  // Start declaring Enum and arrays
  const MachineStatusEnum = new Enum(
    'Active',
    'Inactive',
    'Under Mantainance'
  )
  Object.freeze(MachineStatusEnum)
  
  const MachineStatus = [...MachineStatusEnum]
  
  const ProductStatusEnum = new Enum(
    'Active',
    'Inactive',
    'Under Mantainance'
  )
  Object.freeze(ProductStatusEnum)
  
  const ProductStatus = [...ProductStatusEnum]
  
  const ProductTechnologyEnum = new Enum(
    'Biobalanced metalworking fluids',
    'Biostable metalworking fluids',
    'Biostatic metalworking fluids',
    'Future resistant metalworking fluid',
    'Ohter metalworking fluid',
    'Neat cutting oil',
    'Grinding oil',
    'Honing oil',
    'Drawing oil',
    'Forming oil',
    'Rolling oil',
    'Stamping oil / fine blanking oil',
    'Lapping oil',
    'MQL (minimum quantity lubrication)',
    'EDM (electronic discharge machining)',
    'Deep hole drilling / thread cutting oil',
    'Quenching oil',
    'Hydraulic oil',
    'Industrial gear oil',
    'Slideway oil',
    'Spindle fluid',
    'Anti corrosion oil',
    'Grease and paste',
    'Spray',
    'Filter'
  )
  Object.freeze(ProductTechnologyEnum)
  
  const ProductTechnology = [...ProductTechnologyEnum]
  
  const ReportTypeEnum = new Enum(
    'Lab Report',
    'Water Analysis'
  )
  Object.freeze(ReportTypeEnum)
  
  const ReportType = [...ReportTypeEnum]
  
  const ReportStatusEnum = new Enum(
    'New',
    'Analysing',
    'Closed'
  )
  Object.freeze(ReportStatusEnum)
  
  const ReportStatus = [...ReportStatusEnum]
  
  const MachineEntryTypeEnum = new Enum(
    '',
    'Decrease',
    'Refill',
    'Product Change',
    'Emptying'
  )
  Object.freeze(MachineEntryTypeEnum)
  
  const MachineEntryType = [...MachineEntryTypeEnum]
  
  const MachineWaterTypeEnum = new Enum(
    'Soft (below 5° dH)',
    'Medium (5-15° dH)',
    'Hard (above 15° dH)',
    'Demineralised',
    'Ostmotic',
    'Process water'
  )
  Object.freeze(MachineWaterTypeEnum)
  
  const MachineWaterType = [...MachineWaterTypeEnum]

  const MachineRefillPhaseEnum = new Enum(
    'Refill',
    'Change'
  )
  Object.freeze(MachineRefillPhaseEnum)

  const MachineRefillPhase = [...MachineRefillPhaseEnum]

  const CompanyTypeEnum = new Enum(
    'Distributor',
    'Customer'
  )
  Object.freeze(CompanyTypeEnum)
  
  const CompanyType = [...CompanyTypeEnum]

  const RequestTypeEnum = new Enum(
    'Measurement',
    'Lab Report',
    'Water Analysis'
  )
  Object.freeze(RequestTypeEnum)
  
  const RequestType = [...RequestTypeEnum]
  
  const RequestStatusEnum = new Enum(
    'Open',
    'Under Development',
    'Closed'
  )
  Object.freeze(RequestStatusEnum)
  
  const RequestStatus = [...RequestStatusEnum]

  const LanguagesEnum = new Enum(
    'English',
    'German',
    'Italian',
    'French'
  )
  Object.freeze(LanguagesEnum)
  
  const Languages = [...LanguagesEnum]

  const UserRolesEnum = new Enum(
    'User',
    'Agent'
  )
  Object.freeze(UserRolesEnum)
  
  const UserRoles = [...UserRolesEnum]
  
  module.exports = { MachineStatus, ProductStatus, ProductTechnology, ReportType, ReportStatus, MachineEntryType, MachineWaterType, MachineRefillPhase, CompanyType, RequestType, RequestStatus, Languages, UserRoles }  