import axios from 'axios'
import authHeader from './auth-header'
import formatQuery from './query-helper'

const API_URL = process.env.VUE_APP_SERVER_BASE_URL + 'filtrations'

class FiltrationsService {
    getFiltrations(queryParams) {
        return axios.get(API_URL + formatQuery(queryParams), { headers: authHeader() })
    }
    updateFiltration(filtrationID, filtrationParams) {
        return axios.patch(API_URL + '/' + filtrationID, filtrationParams, { headers: authHeader() })
    }
    createFiltration(filtrationParams) {
        return axios.post(API_URL, filtrationParams, { headers: authHeader() })
    }
    deleteFiltration(filtrationID) {
        return axios.delete(API_URL + '/' + filtrationID, {headers: authHeader() })
    }
    // Build other api calls here....   
}

export default new FiltrationsService()