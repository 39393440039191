<template>
  <CContainer fluid v-if="isDataLoaded">
    <CRow>
      <CCol :sm="6">
        <CCard>
          <CCardBody>
            <CCardTitle><strong>{{ $t('assignedMaterials') }}</strong></CCardTitle>
            <CCardText>{{ $t('assignedMaterialsTitle') }}
                {{ $t('assignedMaterialsSubtitle') }}</CCardText>
            <CRow v-for="(assMaterial, i) in assignedMaterials" :key="assMaterial.id" class="mb-3">
              <CCol>
                <CInputGroup class="mb-3">
                  <CFormInput type="text" id="assMaterialName" readonly
                    :value="getTranslatedName(assMaterial.material_Name)" />
                  <CButton type="button" color="dark" variant="outline" id="editDetails" @click="toggleCollapse(assMaterial.id)">
                    {{ editText }}
                    <CIcon icon="cil-pencil" size="sm" v-if="isMobile"/>
                  </CButton>  
                  <CButton type="button" color="danger" id="removeItem"
                    @click="removeAssignedMaterial(assMaterial.id, assMaterial.idMachine)" >
                    <CIcon icon="cil-trash" size="lg" />
                  </CButton>                  
                </CInputGroup>
                <CCollapse :visible="getCollapseStatus(assMaterial.id)">
                  <CCard>
                    <CCardBody>
                      <CRow class="mb-3">
                        <CFormLabel for="materialSpecification" class="col-sm-4 col-form-label">{{ $t('materialFields.specification') }}</CFormLabel>
                        <CCol sm="8">
                          <CFormInput type="text" id="materialSpecification" v-model="assignedMaterials[i].material_specification" />
                        </CCol>
                      </CRow>
                      <CRow class="mb-3">
                        <CFormLabel for="tool" class="col-sm-4 col-form-label">{{ $t('materialFields.tool') }}</CFormLabel>
                        <CCol sm="8">
                          <CFormInput type="text" id="tool" v-model="assignedMaterials[i].tool" />
                        </CCol>
                      </CRow>
                      <CRow class="mb-3">
                        <CFormLabel for="part" class="col-sm-4 col-form-label">{{ $t('materialFields.part') }}</CFormLabel>
                        <CCol sm="8">
                          <CFormInput type="text" id="part" v-model="assignedMaterials[i].part" />
                        </CCol>
                      </CRow>                        
                      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                        <CButton type="button" color="dark" shape="rounded-0" id="update"
                          @click="updateAssignedMaterial(assMaterial.id, assMaterial.idMachine)">{{ $t('update') }}</CButton>
                      </div>
                    </CCardBody>
                  </CCard>                
                </CCollapse>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol :sm="6">
        <CCard>
          <CCardBody>
            <CCardTitle><strong>{{ $t('availableMaterials') }}</strong></CCardTitle>
            <CCardText>{{ $t('availableMaterialsDesc') }}</CCardText>
            <div class="d-grid gap-2 col-6 mx-auto">
              <CButton v-for="material in availableMaterials" :key="material.id" 
                color="secondary" variant="outline" shape="rounded-0"
                @click="assignMaterial(material.id)">{{ getTranslatedName(material.name) }}</CButton>
            </div>
          </CCardBody>
        </CCard>
      </CCol>      
    </CRow>

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert>     
  </CContainer> 
</template>

<script>
import AssignedMaterialsService from '../services/machine-assigned-materials'
import MaterialsService from '../services/materials'

export default {
  name: 'MaterialComponent',
  data() {
    return {
      assignedMaterials: '',
      availableMaterials: '',
      allMaterials: '',
      assignedMatLoaded: false,
      allMatLoaded: false,
      dataLoaded: false,
      collapseVisible: false,
      currMaterialID: 0,
      message: ''
    }
  },
  created() {
    this.refreshComponent()
  },
  computed: {
    isDataLoaded() {
      if ((this.assignedMatLoaded) && (this.allMatLoaded)) {
        this.getAvailableMaterials()
      }
      return this.dataLoaded
    },
    isMobile() {
      let mobile = localStorage.getItem('isMobile')
      return (mobile === 'true')
    },    
    editText() {
      if (this.isMobile) {
        return ''
      } else {
        return this.$t('editDetails')
      }
    }    
  },
  methods: {
    refreshComponent() {
      this.message = ''
      this.dataLoaded = false
      this.assignedMatLoaded = false
      this.allMatLoaded = false
      this.getAssignedMaterials()
      this.getAllMaterials()
    },
    getAssignedMaterials() {
      if (this.$route.params.idMachine) {
        AssignedMaterialsService.getMaterials({ 'idMachine': this.$route.params.idMachine }).then(
          (response) => {
            this.assignedMaterials = response.data.data
            this.assignedMatLoaded = true
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validMachineIDException')
      }  
    },
    getAllMaterials() {
      MaterialsService.getMaterials().then(
        (response) => {
          this.allMaterials = response.data.data
          this.allMatLoaded = true
        },
        (error)  => {
          this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
        }
      )
    },    
    getAvailableMaterials() {
      let calcMaterials = []
      this.allMaterials.forEach(material => {
        if (!this.assignedMaterials.find(assMat => assMat.id == material.id)) {
          calcMaterials.push(material)
        }
      })
      this.availableMaterials = calcMaterials
      this.dataLoaded = true
    },
    removeAssignedMaterial(materialID, machineID) {
      if ((machineID != 0) && (materialID != 0)) {
        AssignedMaterialsService.deleteMaterial({ 'id': materialID, 'idMachine': machineID }).then(
          () => {
            this.refreshComponent()
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validMachineAndMaterialException')
      }
    },
    assignMaterial(materialID) {
      if ((this.$route.params.idMachine) && (materialID != 0)) {
        AssignedMaterialsService.assignMaterial({ 'id': materialID, 'idMachine': this.$route.params.idMachine }).then(
          () => {
            this.refreshComponent()
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validMachineAndMaterialException')
      }    
    },
    updateAssignedMaterial(materialID, machineID) {
      if ((machineID != 0) && (materialID != 0)) {
        const assMaterial = this.assignedMaterials.find(material => material.id === materialID)
        if (assMaterial) {
          AssignedMaterialsService.updateMaterials({ 'id': materialID, 'idMachine': machineID },
            {
              'material_specification': assMaterial.material_specification ? assMaterial.material_specification : '',
              'tool': assMaterial.tool ? assMaterial.tool : '',
              'part': assMaterial.part ? assMaterial.part : ''
            }).then(
            () => {
              this.collapseVisible = false
              this.refreshComponent()
            },
            (error)  => {
              this.message =           
              (error.response &&
              error.response.data &&
              error.response.data.message) ||
              error.message ||
              error.toString()
            }
          )
        } else {
          this.message = this.$t('validMachineAndMaterialException')
        }          
      } else {
        this.message = this.$t('validMachineAndMaterialException')
      }
    },
    toggleCollapse(materialID) {
      if (this.currMaterialID == 0) {
        this.currMaterialID = materialID
        this.collapseVisible = !this.collapseVisible
      } else if (this.currMaterialID == materialID) {
        this.collapseVisible = !this.collapseVisible
      } else {
        this.currMaterialID = materialID
        this.collapseVisible = true
      }
    },
    getCollapseStatus(materialID) {
      if (!this.collapseVisible) {
        return false
      } else {
        return (this.currMaterialID == materialID)
      } 
    },
    getTranslatedName(material) {
      let result = ''
      if (material) {
        result = this.$t('materials.' + material)
      }
      return result
    }    
  }
}
</script>