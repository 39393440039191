<template>
  <div>
    <loading-data v-if="!dataLoaded"/>

    <small><general-table v-if="dataLoaded"
      :data="analysisRequests"
      :columns="tableColumns"
      :rowsdetail="tableRowsDetails"
      :showItemsPerPage="false"
      :sorter="false"
      :columnFilter="false"       
      @itemSelected="$emit('itemSelected', $event)"
      @showAttachment="getMeasurementID($event)"
      @showComment="$emit('showComment', $event)"
      @moveItem="$emit('moveItem', $event)"/></small>

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </div>
</template>

<script>
import GeneralTable from '@/components/GeneralTable.vue'
import AnalysisRequestService from '../../services/analysis-requests'
import DateFormatter from '../../helpers/dateFormatter'
import PermissionManager from '../../helpers/permissionManager'
import LoadingData from '../LoadingData.vue'
import UserService from '../../services/users'

export default {
  name: 'AnalysisRequestsLookupMobile',
  props: {
    machineID: Number,
    excludeClosed: Boolean,
    showForwardedRequests: Boolean,
    initialFilter: Object,
    moveRequestVisible: Boolean    
  },
  watch: {
    excludeClosed: {
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          this.dataLoaded = false,
          this.getAnalysisRequests()
        }
      }
    }
  },
  data() {
    return {
      dataLoaded: false,
      message: '',
      content: '',
      searchQuery: '',
      tableColumns: [
        { key: 'analysisRequestFields.id', _style: 'min-width: 50px' },
        { key: 'analysisRequest', _style: 'min-width: 250px' },
        { key: 'analysisRequestFields.handled_By', _style: 'min-width: 150px' },
        { key: this.showForwardedRequests ? 'forwardStatus' : 'status', _style: 'min-width: 50px' },
        {
          key: 'choose', 
          label: '', 
          _style: 'width:1%', 
          sorter: false, 
          filter: false
        },
        {
          key: 'attachment', 
          label: '', 
          _style: 'width:1%', 
          sorter: false, 
          filter: false
        },
        {
          key: 'comment', 
          label: '', 
          _style: 'width:1%', 
          sorter: false, 
          filter: false
        }        
      ],
      tableRowsDetails: [
      ],
      filteredUsers: []
    }
  },
  created() {
    this.getAnalysisRequests()
    if (this.moveRequestVisible) {
      this.tableColumns.push({
        key: 'move', 
        label: '', 
        _style: 'width:1%', 
        sorter: false, 
        filter: false        
      })
    }    
  },
  computed: {
    analysisRequests() {
      let processedData = []
      if (this.content.data) {
        this.content.data.forEach((request) => {
          let skipRequest = false
          if (this.filteredUsers.length > 0) {
            skipRequest = (
              this.filteredUsers.findIndex(item => item.id == request.requested_By_User) < 0)
          }
          let singleRequest = {
            'analysisRequestFields.id': request.id,
            'analysisRequestFields.handled_By': request.request_Handled_By_Name
          }
          if (this.showForwardedRequests) {
            singleRequest['analysisRequest'] = request.request_Type + ': ' + request.machine_Name + ' - ' + request.forwarded_By_Name + ' - ' + DateFormatter.formatDate(request.creation_Date, true),            
            singleRequest['forwardStatus'] = request.forward_Status
          } else {
            singleRequest['analysisRequest'] = request.request_Type + ': ' + request.machine_Name + ' - ' + request.requested_By_User_Name + 
                                                ' (' + request.customer_Name + ') - ' + DateFormatter.formatDate(request.creation_Date, true),            
            singleRequest['status'] = request.request_Status
          }
          if (!skipRequest) {                  
            processedData.push(singleRequest)
          }
        })
      }
      return processedData
    }
  },
  methods: {
    getAnalysisRequests() {
      let requestParams = this.initialFilter ? { ...this.initialFilter } : {}
      this.filteredUsers = []
      /*
      if ((requestParams.idCustomer) && (requestParams.idCustomer != 0)) {
        this.getAssociatedUsers(requestParams.idCustomer)
        delete requestParams.idCustomer
      }
      */
      this.dataLoaded = false
      if ((this.machineID) && (this.machineID != 0)) {
        requestParams.idMachine = this.machineID
      }
      if (this.excludeClosed) {
        requestParams.request_Status = '!2'
      }
      // Fix filter by permissions
      if (!((requestParams.idCustomer) && (requestParams.idCustomer != 0))) {
        const userCustomer = PermissionManager.getCustomerFilteredVisibility()
        if (userCustomer) {
          const userData = JSON.parse(localStorage.getItem('user'))
          requestParams.requested_By_User = userData.user.id
        } 
      }
      if (!this.showForwardedRequests) {
        const userDistributor = PermissionManager.getDistributorFilteredVisibility()
        if (userDistributor) {
          requestParams.idDistributor = userDistributor.idDistributor
        }   
      }           
      requestParams.$limit = 500
      requestParams['$sort[id]'] = -1
      AnalysisRequestService.getAnalysisRequests(requestParams).then(
        (response) => {
          this.content = response.data
          this.dataLoaded = true
        },
        (error)  => {
          this.message =           
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }
      )
    },
    getMeasurementID(selectedRequestID) {
      let selectedRequest = this.content.data.find(request => request.id == selectedRequestID)
      this.$emit('showAttachment', selectedRequest)
    },
    getAssociatedUsers(fromCustomerID) {
      let userParams = {
        'company_Type': 1,
        'idCompany': fromCustomerID
      }
      userParams.$limit = 5000
      UserService.getUsers(userParams).then(
        (response) => {
          this.filteredUsers = response.data.data
        },
        (error)  => {
          this.message =           
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }
      )
    }
  },
  components: {
    GeneralTable,
    LoadingData
  }
}
</script>