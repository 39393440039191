<template>
  <CWidgetStatsA class="mb-3" v-if="dataLoaded"
    :value="machine.name"
    :title="machine.product_Name + ' (ph: ' + machine.critical_pH + ')' + lastMeasuredDateString">
    <template #action>
      <CDropdown placement="bottom-end">
        <CDropdownToggle
          color="light"
          class="p-0 text-black"
          :caret="false"
        >
          <CIcon icon="cil-options" class="text-high-emphasis-inverse" />
        </CDropdownToggle>
        <CDropdownMenu>
          <CDropdownItem @click="editMachine()">{{ $t('edit') }}</CDropdownItem>
          <CDropdownItem @click="goToMeasurements()">{{ $t('measurements') }}</CDropdownItem>
          <CDropdownItem @click="newMeasurement()">{{ $t('newMeasurement') }}</CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    </template>
    <CRow class="mb-3">
      <CCol xs="auto">
      </CCol>
      <CCol xs="auto" class="me-auto" @click="toggleChartView()">
        <strong>{{ getChartDescription }}</strong>
      </CCol>
    </CRow>
    <template #chart>
      <CChart v-if="!showEffConcChart"
        type="line"
        class="mt-3 mx-3"
        style="height: 70px"
        :data="phValuesDataset"
        :options="phChartOptions"
      />
      <CChart v-if="showEffConcChart"
        type="line"
        class="mt-3 mx-3"
        style="height: 70px"
        :data="effConcentrationsDataset"
        :options="effConcChartOptions"
      />      
    </template>
  </CWidgetStatsA>
</template>

<script>
import MeasurementService from '../../services/measurements'
import { getStyle } from '@coreui/utils/src'
import StringFormatter from '../../helpers/stringFormatter'
import DateFormatter from '../../helpers/dateFormatter'
import { CChart } from '@coreui/vue-chartjs'

export default {
  name: 'MachineWidgetMobile',
  props: {
    machine: Object
  },
  data() {
    return {
      maxNoOfMeasurements: 8,
      measurements: [],
      message: '',
      dataLoaded: false,
      minpHValue: 2,
      maxpHValue: 12,
      showEffConcChart: false,
      minEffConcValue: 2,
      maxEffConcValue: 12
    }
  },
  created() {
    this.getLastMeasurements()
  },
  computed: {
    getChartDescription() {
      return this.showEffConcChart ? this.$t('effConcentrationVariation') : this.$t('phValuesVariation')
    },
    phValuesDataset() {
      let phArray = []
      let phValueLabels = []
      let lastMeasuredParameter = 0
      let lastMeasuredParameterSet = false
      this.measurements.forEach(measure => {
        if (!lastMeasuredParameterSet) {
          lastMeasuredParameter = measure['pH']
          lastMeasuredParameterSet = true
        }
        phArray.unshift(measure['pH'])
        phValueLabels.unshift(DateFormatter.formateDateShort(measure['measured_At'], true))        
      })
      this.setphParameters(phArray)
      let dataset = []
      dataset.push({
        label: this.$t('phValues'),
        backgroundColor: 'transparent',     
        borderColor: (lastMeasuredParameter >= Number(this.machine.critical_pH)) ? getStyle('--cui-success') : getStyle('--cui-danger'),      
        pointHoverBackgroundColor: (lastMeasuredParameter >= Number(this.machine.critical_pH)) ? getStyle('--cui-success') : getStyle('--cui-danger'),
        borderWidth: 2, 
        data: phArray,        
      })
      if (this.machine.critical_pH != 0) {
        dataset.push({
          label: this.pretifyName('machineFields.critical_pH'),
          backgroundColor: getStyle('--cui-warning'),
          borderColor: getStyle('--cui-warning'),
          pointHoverBackgroundColor: getStyle('--cui-warning'),
          borderWidth: 2,
          data: this.getConstantArrayValue(phArray, this.machine.critical_pH)         
        })
      }
      return {
        labels: phValueLabels,
        datasets: dataset
      } 
    },
    phChartOptions() {
      return this.getStandardChartOptions(
        Math.min(this.machine.critical_pH, this.minpHValue) - 1,
        Math.max(this.machine.critical_pH, this.maxpHValue) + 1)
    },
    effConcentrationsDataset() {
      let effArray = []
      let effConcLabels = []
      let lastMeasuredParameter = 0
      let lastMeasuredParameterSet = false      
      this.measurements.forEach(measure => {
        if (!lastMeasuredParameterSet) {
          lastMeasuredParameter = measure['eff_Concentration']
          lastMeasuredParameterSet = true
        }        
        effArray.unshift(measure['eff_Concentration'])
        effConcLabels.unshift(DateFormatter.formateDateShort(measure['measured_At'], true)) 
      })
      let dataset = []
      this.setEffConcParameters(effArray)
      dataset.push({
        label: this.$t('effConcentration'),
        backgroundColor: 'transparent',
        borderColor: this.getEffConcentrationStyle(lastMeasuredParameter, this.machine.min_Eff_Concentration, this.machine.max_Eff_Concentration),        
        pointHoverBackgroundColor: this.getEffConcentrationStyle(lastMeasuredParameter, this.machine.min_Eff_Concentration, this.machine.max_Eff_Concentration),
        borderWidth: 2,
        data: effArray
      })
      if (this.machine.min_Eff_Concentration != 0) {
        dataset.push({
          label: this.pretifyName('machineFields.min_Eff_Conc'),
          backgroundColor: getStyle('--cui-warning'),
          borderColor: getStyle('--cui-warning'),
          pointHoverBackgroundColor: getStyle('--cui-warning'),
          borderWidth: 2,
          data: this.getConstantArrayValue(effArray, this.machine.min_Eff_Concentration)         
        })
      }
      if (this.machine.max_Eff_Concentration != 0) {
        dataset.push({
          label: this.pretifyName('machineFields.max_Eff_Conc'),
          backgroundColor: getStyle('--cui-warning'),
          borderColor: getStyle('--cui-warning'),
          pointHoverBackgroundColor: getStyle('--cui-warning'),
          borderWidth: 2,
          data: this.getConstantArrayValue(effArray, this.machine.max_Eff_Concentration)         
        })
      }          
      return {
        labels: effConcLabels,
        datasets: dataset
      }    
    },
    effConcChartOptions() {
      return this.getStandardChartOptions(
        Math.min(this.machine.min_Eff_Concentration, this.minEffConcValue) - 1,
        Math.max(this.machine.max_Eff_Concentration, this.maxEffConcValue) + 1)
    }, 
    lastMeasuredDateString() {
      if (this.measurements.length > 0) {
        let lastDate = DateFormatter.formatDate(this.measurements[0].measured_At, true)
        if (lastDate) {
          return (' - ' + this.$t('lastMeasured') + ': ' + String(lastDate))
        } else {
          return ''
        }
      } else {
        return ''
      }
    }
  },
  methods: {
    getLastMeasurements() {
      this.measurements = []
      this.dataLoaded = false
      if (this.machine) {
        let queryParms = {
          'idMachine': this.machine.id,
          'idProduct': this.machine.idProduct
        }  
        queryParms.$limit = this.maxNoOfMeasurements
        queryParms['$sort[measured_At]'] = -1
        MeasurementService.getMeasurements(queryParms).then(
          (response) => {
            this.measurements = response.data.data
            this.dataLoaded = true
          },
          (error)  => {
            this.message =           
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
          }        
        )
      }
    },
    getConstantArrayValue(refArray, value) {
      let resultArray = []
      refArray.forEach(() => {
        resultArray.push(value)
      })
      return resultArray
    },    
    getStandardChartOptions(minValue, maxValue) {
      return {
        plugins: {
          legend: {
            display: false,
          },
        },
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              display: false,
            },
          },
          y: {
            min: minValue,
            max: maxValue,
            display: false,
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        },
        elements: {
          line: {
            borderWidth: 1,
            tension: 0.1,
          },
          point: {
            radius: 0.5,
            hitRadius: 10,
            hoverRadius: 4,
          },
        },        
      }
    },
    getLineColor(context, lowerLimit, upperLimit) {
      const index = context.dataIndex
      const value = context.dataset.data[index]
      if (!lowerLimit) { lowerLimit = 0 }
      if (!upperLimit) { upperLimit = 9999}
      // Check if lower/upper Limits are numbers
      lowerLimit = Number(lowerLimit)
      if (isNaN(lowerLimit)) { lowerLimit = 0 }
      upperLimit = Number(upperLimit)
      if (isNaN(upperLimit)) { upperLimit = 0 }         
      return ((value >= lowerLimit) && (value <=upperLimit)) ? getStyle('--cui-success') : getStyle('--cui-danger')
    },
    pretifyName(name) {
      return StringFormatter.pretifyName(this.$t(name))
    },
    setphParameters(phArray) {
      if (phArray.length > 0) {
        let minMaxSet = false
        phArray.forEach(value => {
          let numberValue = Number(value)
          if ((!isNaN(numberValue)) && (numberValue != 0)) {
            if (!minMaxSet) {
                this.minpHValue = numberValue
                this.maxpHValue = numberValue
                minMaxSet = true
            }
            if (this.minpHValue > numberValue) {
                this.minpHValue = numberValue
            }
            if (this.maxpHValue < numberValue) {
                this.maxpHValue = numberValue
            }   
          }     
        })
      }
    },
    setEffConcParameters(effArray) {
      if (effArray.length > 0) {
        let minMaxSet = false
        effArray.forEach(value => {
          let numberValue = Number(value)
          if ((!isNaN(numberValue)) && (numberValue != 0)) {
            if (!minMaxSet) {
                this.minEffConcValue = numberValue
                this.maxEffConcValue = numberValue
                minMaxSet = true
            }
            if (this.minEffConcValue > numberValue) {
                this.minEffConcValue = numberValue
            }
            if (this.maxEffConcValue < numberValue) {
                this.maxEffConcValue = numberValue
            }   
          }     
        })
      }
    },
    getEffConcentrationStyle(currValue, minValue, maxValue) {
      return ((currValue >= Number(minValue)) && (currValue <= Number(maxValue))) ? 
        getStyle('--cui-success') : getStyle('--cui-danger')
    },  
    goToMeasurements() {
      this.$router.push({ name: 'mMeasurementsMachine', params: { idMachine: this.machine.id, 'calledByOverview': true } })
    },
    editMachine() {
      this.$router.push({ name: 'mEditMachine', params: { idMachine: this.machine.id, 'calledByOverview': true } })
    },
    newMeasurement() {
      this.$router.push({ name: 'mMeasure', params: { 'machineID': this.machine.id, 'measurementType': 0 } })
    },
    toggleChartView() {
      this.showEffConcChart = !this.showEffConcChart
    }      
  },
  components: {
    CChart
  }
}
</script>