<template>
  <CCard fluid>
    <CCardBody>
      <CCardTitle>{{ $t('quickActions') }}</CCardTitle>
      <CCardSubtitle class="mb-2 text-muted">{{ $t('quickActionsSubtitle') }}</CCardSubtitle>
      <div class="d-grid gap-2 d-md-flex justify-content-md-start">
        <CButton type="button" color="dark" shape="rounded-0" id="newMeasurement"
          @click="openMeasurementCard()">{{ $t('newMeasurement') }}</CButton>
        <CButton type="button" color="dark" shape="rounded-0" id="requestAnalysis"
          @click="toggleAnalysisModalVisibility()">{{ $t('requestAnalysis') }}</CButton>     
        <CButton type="button" color="dark" shape="rounded-0" id="machinesOverview"
          @click="openMachineOverview()">{{ $t('machinesOverview') }}</CButton>                        
      </div>    
    </CCardBody>
    <analysis-request-mobile
      :cModalVisible="analysisRequestModal"
      @closeAnalysisRequest="refreshAnalysisLists()"/>      
  </CCard>
</template>

<script>
import AnalysisRequestMobile from './AnalysisRequestMobile.vue'

export default {
  name: 'CustomerQuickActionsMobile',
  data() {
    return {
      analysisRequestModal: false,
      machineSelectionModalVisible: false
    }
  },
  methods: {
    toggleAnalysisModalVisibility() {
      this.analysisRequestModal = !this.analysisRequestModal
    },
    openMeasurementCard() {
      this.$router.push({ name: 'mMeasure', params: { 'measurementType': 3 } })  
    },    
    isMobile() {
      let mobile = localStorage.getItem('isMobile')
      return (mobile === 'true')
    },
    openMachineOverview() {
      this.$router.push({ name: 'mMachinesOverview' })
    },
    refreshAnalysisLists() {
      this.analysisRequestModal = false
      this.$emit('refreshRequestsLists')
    }
  },
  components: {
    AnalysisRequestMobile
  }
}
</script>