import { jsPDF } from 'jspdf'
import dateFormatter from '../helpers/dateFormatter'
import reportParameterFormatter from '../helpers/reportParameterFormatter'
import StringFormatter from '../helpers/stringFormatter'
import i18n from '../i18n'
const { t } = i18n.global

class LabReportSummary {
  print(reportContent, machineParameterList, reportTitle, fileName) {
    this.generateReport(reportContent, machineParameterList, reportTitle, fileName, true)
  }

  attachReport(reportContent, machineParameterList, reportTitle, fileName) {
    return this.generateReport(reportContent, machineParameterList, reportTitle, fileName, false)
  }

  generateReport(reportContent, machineParameterList, reportTitle, fileName, printReport) {
    const doc = new jsPDF({compress: true})
    // Compressed to 246 MB

    // Define Report Layout
    doc.setFont('helvetica', 'bold')
    doc.setFontSize(16)
    doc.text(reportTitle, 10, 15)
    doc.setFontSize(11)
    doc.text( this.pretifyName('machineFields.machine_ID') + ': ' + this.preventNullValues(String(reportContent.idMachine)), 10, 20)

    var img = new Image()
    img.src = require('@/assets/Logo_Swiss_Tribology_transp_grey.png')
    doc.addImage(img, 'jpeg', 150, 7, 50, 13)    

    // Add Main Fields

    // Details Part
    doc.setDrawColor(211, 211, 211)
    doc.setFillColor(211, 211, 211)
    doc.rect(10, 26, 190, 5, 'F')
    doc.text( t('details'), 12, 30)

    doc.setFont('helvetica', 'normal')
    doc.setFontSize(10)
    doc.text( t('customer') + ': ', 10, 37)
    doc.text( t('machine') + ': ', 10, 42)
    doc.text( t('product') + ': ', 10, 47)
    doc.text( this.pretifyName('machineFields.content') + ': ', 10, 52)
    doc.text(this.preventNullValues(reportContent.customer_Name), 37, 37)
    doc.text(this.preventNullValues(reportContent.machine_External_ID ? reportContent.machine_Name + ' (' + reportContent.machine_External_ID + ')' : reportContent.machine_Name), 37, 42)
    doc.text(this.preventNullValues(reportContent.product_Name), 37, 47)
    doc.text(this.formatProductContent(reportContent), 37, 52)

    doc.text( this.pretifyName('labReportFields.batch_Number') + ': ', 130, 37)
    doc.text( this.pretifyName('labReportFields.date_Ordered') + ': ', 130, 42)
    doc.text( this.pretifyName('labReportFields.completed_At') + ': ', 130, 47)
    if (reportContent.type == 0) {
      doc.text(this.preventNullValues(reportContent.batch_Number), 165, 37)
    } else {
      doc.text(this.preventNullValues(String(reportContent.id)), 165, 37)
    }
    doc.text(reportContent.date_Ordered ? dateFormatter.formatDate(reportContent.date_Ordered, true) : '-', 165, 42)
    doc.text(reportContent.completed_At ? dateFormatter.formatDate(reportContent.completed_At, true) : '-', 165, 47)

    doc.text( this.pretifyName('labReportFields.reason_Of_Analysis') + ':  ' + this.preventNullValues(reportContent.reason_Of_Analysis), 10, 58)

    // Measurements part
    doc.setDrawColor(211, 211, 211)
    doc.setFillColor(211, 211, 211)
    doc.rect(10, 61, 190, 5, 'F')
    doc.setFont('helvetica', 'bold')
    doc.setFontSize(11)      
    doc.text( t('measurements'), 12, 65)

    // Print Smell/Appearance and other informations:
    let nextLine = 75
    doc.setFontSize(10)
    doc.setFont('helvetica', 'normal')
    if (reportContent.appearance) {
      doc.text( this.pretifyName('labReportFields.appearance_2') + ': ', 15, nextLine)
      doc.text(this.preventNullValues(reportContent.appearance), 85, nextLine)
      nextLine += 5
    }
    if (reportContent.deposit) {
      doc.text( this.pretifyName('labReportFields.deposit') + ': ', 15, nextLine)
      doc.text(this.preventNullValues(reportContent.deposit), 85, nextLine)
      nextLine += 5
    }
    if (reportContent.smell) {
      doc.text( this.pretifyName('labReportFields.smell') + ': ', 15, nextLine)
      doc.text(this.preventNullValues(reportContent.smell), 85, nextLine)
      nextLine += 5
    }
    if (reportContent.type == 0) {
      doc.setDrawColor(0, 0, 0)
      doc.text( t('analysisFloatingOilPhase'), 15, nextLine)
      doc.rect(85, nextLine - 2, 2, 2)
      if (reportContent.analysisFloatingOilPhase) {
        doc.line(85, nextLine - 2, 87, nextLine)
        doc.line(85, nextLine, 87, nextLine - 2)
      }
      nextLine += 5

      doc.text( t('labReportFloatingOilPhase'), 15, nextLine)
      doc.rect(85, nextLine - 2, 2, 2)
      if (reportContent.floating_Oil_Phase) {
        doc.line(85, nextLine - 2, 87, nextLine)
        doc.line(85, nextLine, 87, nextLine - 2)
      }    
      nextLine += 5
    }
    doc.setDrawColor(211, 211, 211)

    // Print Measurements Headers
    nextLine += 5
    doc.setFont('helvetica', 'bold')
    doc.text( t('parameter'), 15, nextLine)
    doc.text( t('tolerance'), 85, nextLine)
    doc.text( t('valueUnitOfMeasure'), 135, nextLine)
    
    // Lines
    doc.setFont('helvetica', 'normal')
    nextLine += 5

    // Measurements: first letter is an Uppercase letter!!!!!
    for (let parameter in reportContent) {
      let firstLetter = parameter.substring(0, 1)
      if ((firstLetter == firstLetter.toUpperCase()) || (parameter == 'pH electrometric (Lab Report)') || (parameter == 'pH electrometric')) {
        const machineParam = machineParameterList.find(detail => detail.characteristic_Name == parameter)
        if (machineParam) {
          let fullPathParameter = 'characteristics.' + parameter
          doc.text( t(fullPathParameter), 15, nextLine)          
          doc.text(machineParam.tolerance, 85, nextLine)
          let formattedText = reportParameterFormatter.splitSuperscriptText(reportContent[parameter])
          doc.text(formattedText.main, 135, nextLine)
          if (formattedText.superscript) {
            doc.setFontSize(6)
            doc.text(formattedText.superscript, 140, nextLine - 2)
            doc.setFontSize(10)     
            if (formattedText.remainingText) {
              doc.text(formattedText.remainingText, 141, nextLine)
            }       
          }
          nextLine += 5          
        }
      }
    }

    // Disclaimer
    nextLine += 1
    doc.setDrawColor(211, 211, 211)
    doc.setFillColor(211, 211, 211)
    doc.rect(10, nextLine, 190, 5, 'F')
    doc.setFont('helvetica', 'bold')
    doc.setFontSize(11)    
    nextLine += 4  
    doc.text( t('recommendations'), 12, nextLine)   

    nextLine += 10
    doc.setFont('helvetica', 'normal')
    doc.setFontSize(10)
    // Limit if needed = 110 chars
    let commentLines = doc.splitTextToSize(reportContent.comment, 180)
    doc.text(commentLines, 10, nextLine)
    nextLine += Math.floor(reportContent.comment.length / 110) * 5 + 5

    // Print circle indicator
    nextLine -= 5 // Skip last round
    if ((reportContent.result_Indicator) && (reportContent.result_Indicator > 0)) {
      doc.setLineWidth(0)    
      doc.setDrawColor(0)
      switch (reportContent.result_Indicator) {
        case 1:
          doc.setFillColor(0, 255, 0)
          break
        case 2:
          doc.setFillColor(255, 255, 0)
          break
        default:
          doc.setFillColor(255, 0, 0)
          break
      }    
      doc.circle(190, nextLine, 3, 'FD')
    }

    // Graphs - If needed
    // ORG: if (reportContent.type == 0) {
      nextLine += 6
      doc.setDrawColor(211, 211, 211)
      doc.setFillColor(211, 211, 211)
      doc.rect(10, nextLine, 190, 5, 'F')
      doc.setFont('helvetica', 'bold')
      doc.setFontSize(11)    
      nextLine += 4  
      doc.text( (reportContent.type == 0 ? t('phEffConcGraphs') : t('waterAnalysisGraphs')), 12, nextLine)
      nextLine += 5

      if (reportContent.isMobile) {
        nextLine += 5
        doc.setFont('helvetica', 'normal')
        doc.text( t('cannotRenderGraphs'), 10, nextLine)
      } else {
        if (reportContent.type == 0) {
          if (reportContent.phValuesChart) {
            doc.addImage(reportContent.phValuesChart, 'jpeg', 25, nextLine, 160, 25, undefined, 'FAST')
            nextLine += 30
          }
          if (reportContent.effConcChart) {
            doc.addImage(reportContent.effConcChart, 'jpeg', 25, nextLine, 160, 25, undefined, 'FAST') 
          }
        } else {
          let updateNextLine = false
          if (reportContent.waterpHChart) {
            doc.addImage(reportContent.waterpHChart, 'jpeg', 15, nextLine, 80, 25, undefined, 'FAST')
            updateNextLine = true
          }
          if (reportContent.waterHardnessChart) {
            doc.addImage(reportContent.waterHardnessChart, 'jpeg', 110, nextLine, 80, 25, undefined, 'FAST') 
            updateNextLine = true
          }
          if (updateNextLine) {
            nextLine += 30
          }
          if (reportContent.chlorideChart) {
            doc.addImage(reportContent.chlorideChart, 'jpeg', 15, nextLine, 80, 25, undefined, 'FAST') 
          }          
          if (reportContent.sulfateChart) {
            doc.addImage(reportContent.sulfateChart, 'jpeg', 110, nextLine, 80, 25, undefined, 'FAST')
          }
        }
      }
    // ORG: }
    
    // Footer
    doc.setFont('helvetica', 'normal')
    doc.setFontSize(10)
    doc.text('STRUB + Co.AG', 10, 285)
    doc.text('Swiss Tribology', 10, 290)
    doc.text('Mühlemattstrasse 5', 55, 285)
    doc.text('CH-6260 Reiden', 55, 290)
    doc.text('Phone +41 62 785 22 22', 100, 285)
    doc.text('Fax +41 62 785 22 33', 100, 290)
    doc.text('www.strub-lube.ch', 155, 285)
    doc.text('strub@strub-lube.ch', 155, 290)          

    // Export File
    if (printReport) {
      doc.save(fileName ? fileName + '.pdf' :
        reportContent.machine_Name + (reportContent.machine_External_ID ? '(' + reportContent.machine_External_ID + ')' : '') + '_report' + 
        reportContent.id + '.pdf')
    } else {
      doc.setProperties({ title: reportTitle + '_' + reportContent.id })
      return doc.output('datauristring', {filename: reportTitle + '_' + reportContent.id + '.pdf'})
    }   
  }  

  preventNullValues(parameter) {
    return (parameter? parameter : '-')
  }

  pretifyName(name) {
    return StringFormatter.pretifyName(t(name))
  }

  splitCommentIntoArray(comment) {
    let commentLines = []
    if (comment) {
      while (comment.length > 110) {
        commentLines.push(comment.substring(0, 110))
        comment = comment.substring(110)
      }
      if (comment.length > 0) {
        commentLines.push(comment)
      }
    }
    return commentLines
  }

  formatProductContent(reportContent) {
    let lastFilledString = reportContent.machine_last_Filled ?
      '(' + this.pretifyName('machineFields.last_Filled') + ' ' + dateFormatter.formatDate(reportContent.machine_last_Filled, true) + ')' : ''

    return this.preventNullValues(reportContent.machine_product_Content) + ' ' + lastFilledString
  }
}

export default new LabReportSummary()