<template>
  <CRow :xs="mobileGutter">
    <CCol :xs="auto">
      <CWidgetStatsF v-if="assFiltrLoaded" color="info" :title="$t('assignedFiltrations')" :value="assignedFiltrations.total" @click="performOperationsOnClick(0)">
        <template #icon>
          <CIcon icon="cil-eyedropper" size="xl"/>
        </template>
        <template #footer v-if="!isMobile()">
          <a class="nav-link" @click="editFiltrations()"> {{ $t('customize') }} </a>
        </template>
      </CWidgetStatsF>       
    </CCol>
    <CCol :xs="auto">
      <CWidgetStatsF v-if="assMatLoaded" color="success" :title="$t('assignedMaterials')" :value="assignedMaterials.total" @click="performOperationsOnClick(1)">
        <template #icon>
          <CIcon icon="cil-settings" size="xl"/>
        </template>
        <template #footer v-if="!isMobile()">
          <a class="nav-link" @click="editMaterials()"> {{ $t('customize') }} </a>
        </template>  
      </CWidgetStatsF>       
    </CCol>
    <CCol :xs="auto">
      <CWidgetStatsF v-if="assOpLoaded" color="warning" :title="$t('assignedOperations')" :value="assignedOperations.total" @click="performOperationsOnClick(2)">
        <template #icon>
          <CIcon icon="cil-note-add" size="xl"/>
        </template>
        <template #footer v-if="!isMobile()">
          <a class="nav-link" @click="editOperations()"> {{ $t('customize') }} </a>
        </template>  
      </CWidgetStatsF>       
    </CCol>
    <CCol :xs="auto">
      <CWidgetStatsF v-if="labRepLoaded" color="danger" :title="$t('labReportParam')" :value="labRepParameters.total" @click="performOperationsOnClick(3)">
        <template #icon>
          <CIcon icon="cil-factory" size="xl"/>
        </template>
        <template #footer v-if="!isMobile()">
          <a class="nav-link" @click="editLabReportParameters()"> {{ $t('customize') }} </a>
        </template>  
      </CWidgetStatsF>      
    </CCol>   
    <CCol :xs="auto">
      <CWidgetStatsF v-if="watAnalLoaded" color="info" :title="$t('waterAnalysisParam')" :value="watAnalParameters.total" @click="performOperationsOnClick(4)">
        <template #icon>
          <CIcon icon="cil-drop" size="xl"/>
        </template>
        <template #footer v-if="!isMobile()">
          <a class="nav-link" @click="editWaterAnalysisParameters()"> {{ $t('customize') }} </a>
        </template>  
      </CWidgetStatsF>
    </CCol>     
  </CRow> 
</template>

<script>
import AssignedFiltrationsService from '../services/machine-assigned-filtrations'
import AssignedMaterialsService from '../services/machine-assigned-materials'
import AssignedOperationsService from '../services/machine-assigned-operations'
import AssignedParametersService from '../services/machine-assigned-parameters'
import PermissionManager from '../helpers/permissionManager'

export default {
  name: 'MachineComponents',
  data() {
    return {
      assignedMaterials: '',
      assMatLoaded: false,
      assignedFiltrations: '',
      assFiltrLoaded: false,
      assignedOperations: '',
      assOpLoaded: false,
      labRepParameters: '',
      labRepLoaded: false,
      watAnalParameters: '',
      watAnalLoaded: false
    }
  },
  created() {
    this.refreshData()
  },
  computed: {
    mobileGutter() {
      if (this.isMobile()) {
        return { gutter: 0 }
      } else {
        return ''
      }
    }
  },
  methods: {
    getAssignedMaterials() {
      if (this.$route.params.idMachine) {
        AssignedMaterialsService.getMaterials({ 'idMachine': this.$route.params.idMachine }).then(
          (response) => {
            this.assignedMaterials = response.data
            this.assMatLoaded = true
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validMachineIDException')
      }    
    },
    getAssignedFiltrations() {
      if (this.$route.params.idMachine) {
        AssignedFiltrationsService.getFiltrations({ 'idMachine': this.$route.params.idMachine }).then(
          (response) => {
            this.assignedFiltrations = response.data
            this.assFiltrLoaded = true
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validMachineIDException')
      } 
    },
    getAssignedOperations() {
      if (this.$route.params.idMachine) {
        AssignedOperationsService.getOperations({ 'idMachine': this.$route.params.idMachine }).then(
          (response) => {
            this.assignedOperations = response.data
            this.assOpLoaded = true
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validMachineIDException')
      } 
    },
    getLabReportParameters() {
      if (this.$route.params.idMachine) {
        AssignedParametersService.getParameters(
          { 
            'idMachine': this.$route.params.idMachine,
            'type': 0
          }
          ).then(
          (response) => {
            this.labRepParameters = response.data
            this.labRepLoaded = true
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validMachineIDException')
      } 
    },
    getWaterAnalysisParameters() {
      if (this.$route.params.idMachine) {
        AssignedParametersService.getParameters(
          { 
            'idMachine': this.$route.params.idMachine,
            'type': 1
          }
          ).then(
          (response) => {
            this.watAnalParameters = response.data
            this.watAnalLoaded = true
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validMachineIDException')
      }  
    },
    refreshData() {
      this.getAssignedMaterials()
      this.getAssignedFiltrations()
      this.getAssignedOperations()
      this.getLabReportParameters()
      this.getWaterAnalysisParameters()
    },
    getValuePercentage(currentValue, maxValue) {
      return Math.round(currentValue * 100 / maxValue)
    },
    editFiltrations() {
      this.$router.push({ name: this.isMobile() ? 'mEditAttribute' : 'EditAttribute', params: { 'idMachine': this.$route.params.idMachine, 'which': 'filtrations' } })
    },
    editOperations() {
      this.$router.push({ name: this.isMobile() ? 'mEditAttribute' : 'EditAttribute', params: { 'idMachine': this.$route.params.idMachine, 'which': 'operations' } })
    },
    editMaterials() {
      this.$router.push({ name: this.isMobile() ? 'mEditAttribute' : 'EditAttribute', params: { 'idMachine': this.$route.params.idMachine, 'which': 'materials' } })
    },  
    editLabReportParameters() {
      if (this.checkUserPermissions()) {
        this.$router.push({ name: this.isMobile() ? 'mEditAttribute' : 'EditAttribute', params: { 'idMachine': this.$route.params.idMachine, 'which': 'labReports' } })
      }
    },
    editWaterAnalysisParameters() {
      if (this.checkUserPermissions()) {  
        this.$router.push({ name: this.isMobile() ? 'mEditAttribute' : 'EditAttribute', params: { 'idMachine': this.$route.params.idMachine, 'which': 'waterAnalysis' } })
      }
    },
    checkUserPermissions() {
      return ((PermissionManager.hasTechnicianPermissions()) && !(PermissionManager.getCustomerFilteredVisibility()))
    },
    isMobile() {
      let mobile = localStorage.getItem('isMobile')
      return (mobile === 'true')
    },
    performOperationsOnClick(operationNo) {
      if (this.isMobile()) {
        switch (operationNo) {
          case 0:
            this.editFiltrations()
            break
          case 1:
            this.editMaterials()
            break
          case 2:
            this.editOperations()
            break
          case 3:
            this.editLabReportParameters()
            break
          case 4:
            this.editWaterAnalysisParameters()
            break
          default:
            break
        }
      }
    }                        
  }
}
</script>