<template>
  <CContainer fluid>
    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
      <CTooltip :content="$t('displayFilters')" placement="top">
        <template #toggler="{ on }">
          <CIcon size="xxl" icon="cil-filter" @click="additionalFiltersVisible = !additionalFiltersVisible" v-on="on"/>
        </template>
      </CTooltip>
    </div>

    <machine-search-component 
      :filtersVisible="additionalFiltersVisible"
      @loadData="setAdditionalFilterAndSearch($event)"/>

    <loading-data v-if="!dataLoaded"/>

    <general-table v-if="dataLoaded"
      :data="machines"
      :columns="tableColumns"
      :rowsdetail="tableRowsDetails"
      @itemSelected="$emit('itemSelected', $event)"/>

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </CContainer>
</template>

<script>
import GeneralTable from '@/components/GeneralTable.vue'
import MachineService from '../services/machines'
import PermissionManager from '../helpers/permissionManager'
import LoadingData from './LoadingData.vue'
import MachineSearchComponent from '../components/MachineSearchComponent.vue'

export default {
  name: 'MachinesLookup',
  data() {
    return {
      dataLoaded: false,
      message: '',
      content: [],
      searchQuery: '',
      tableColumns: [
        'machineFields.id',
        'machineFields.name',
        'machineFields.machine_ID',
        'machineFields.distributor',
        'machineFields.customer',
        'machineFields.product',
        'machineFields.product_Content',
        'status',
      {
        key: 'choose', 
        label: '', 
        _style: 'width:1%', 
        sorter: false, 
        filter: false
      }
      ],
      tableRowsDetails: [
      ],
      additionalFilter: '',
      additionalFiltersVisible: false,
      retrievalStatus: 'stopped'         
    }
  },
  created() {
    this.getMachinesGradually()
  },
  computed: {
    machines() {
      let processedData = []
      if (this.content.length > 0) {
        this.content.forEach((machine) => {
          processedData.push({
            'machineFields.id': machine.id,
            'machineFields.name': machine.name,
            'machineFields.machine_ID': machine.id_Machine,
            'machineFields.distributor': machine.distributor_Name,
            'machineFields.customer': machine.customer_Name,
            'machineFields.product': machine.product_Name,
            'machineFields.product_Content': machine.product_Content,
            'status': machine.status,            
          })                    
        })
      }
      return processedData
    }
  },
  methods: {
    getMachinesGradually(skipParam) {
      this.retrievalStatus = 'retrieving'
      if (!skipParam) {
        this.content = []
      }      
      let queryParams = {}
      // Get activeSession Customer Filter
      let customerSessionStarted = localStorage.getItem('customerSessionStarted')
      let currentCustomer = localStorage.getItem('currentCustomer')
      if ((customerSessionStarted) && (!(customerSessionStarted === 'false')) &&
          (currentCustomer) && (currentCustomer != 0)) {
        queryParams.idCustomer = currentCustomer
      }       
      const userCustomer = PermissionManager.getCustomerFilteredVisibility()
      if (userCustomer) {
        queryParams.idCustomer = userCustomer.idCustomer
      }
      if (!(PermissionManager.hasExtendedTechnicianPermissions())) {
        const userDistributor = PermissionManager.getDistributorFilteredVisibility()
        if (userDistributor) {
          queryParams.idDistributor = userDistributor.idDistributor
        }       
      }       
      // Get Additional Filters
      if (this.additionalFilter) {
        queryParams = { ...queryParams, ...this.additionalFilter}
      }                         
      queryParams.$limit = 50
      if (skipParam) {
        queryParams['$skip'] = skipParam
      }
      MachineService.getMachines(queryParams).then(
        (response) => {
          if (this.retrievalStatus == 'restart') {
            this.retrievalStatus = 'stopped'
            this.content = ''
            this.dataLoaded = false
            this.getMachinesGradually()
          } else {          
            if (this.content.length > 0) {
              this.content = this.content.concat(response.data.data)
            } else {
              this.content = response.data.data
            }
            this.dataLoaded = true
            if (response.data.total > response.data.limit + response.data.skip) {
              if (skipParam) {
                skipParam += response.data.limit
              } else {
                skipParam = response.data.limit
              }
              this.getMachinesGradually(skipParam)
            } else {
              this.retrievalStatus = 'stopped'
            }            
          }
        },
        (error)  => {
          this.message =           
           (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()

          this.retrievalStatus = 'stopped'
        }        
      )
    },
    setAdditionalFilterAndSearch(searchFilter) {
      this.additionalFilter = (searchFilter ? searchFilter : '')
      this.dataLoaded = false
      this.content = []
      if (this.retrievalStatus == 'retrieving') {
        this.retrievalStatus = 'restart'
      } else {      
        this.getMachinesGradually()
      }
    }     
  },
  components: {
    GeneralTable,
    LoadingData,
    MachineSearchComponent
  }
}
</script>