<template>
  <CCardBody>
    <CDataTable
      :items="items"
      :fields="fields"
      :column-filter="columnFilter"
      table-filter
      :items-per-page-select="showItemsPerPage"
      :items-per-page="50"
      hover
      :sorter="sorter"
      pagination
      :striped="true"
      :border="true"
      :clickable-rows="clickable-rows"
    >
      <template #status="{item}">
        <td>
          <CBadge :color="getBadge(item.status)">
            {{item.status}}
          </CBadge>
        </td>
      </template>
      <template #forwardStatus="{item}">
        <td>
          <CBadge :color="getBadge(item.forwardStatus)">
            {{item.forwardStatus}}
          </CBadge>
        </td>
      </template>      
      <template #disabled="{item}">
        <td>
          <CBadge :color="getDisabledBadge(item.disabled)">
            {{item.disabled}}
          </CBadge>
        </td>
      </template>      
      <template #show_details="{item, index}">
        <td class="py-2">
          <CButton
            color="primary"
            variant="outline"
            square
            size="sm"
            @click="toggleDetails(item, index)"
          >
            {{Boolean(item._toggled) ? $t('hide') : $t('show') }}
          </CButton>
        </td>
      </template>
      <template #choose="{item}">
        <td class="py-2">
          <CButton
            color="primary"
            variant="outline"
            square
            size="sm"
            @click="$emit('itemSelected', getItemID(item))">
            {{ $t('choose') }}
          </CButton>
        </td>
      </template>
      <template #go_to_details="{item}">
        <td class="py-2">
          <CButton
            color="primary"
            variant="outline"
            square
            size="sm"
            @click="$emit('itemSelected', getItemID(item))">
            <CIcon icon="cil-menu" size="lg" /> {{ $t('details') }}
          </CButton>
        </td>
      </template>
      <template #permissions="{item}">
        <td class="py-2">
          <CButton
            color="primary"
            variant="outline"
            square
            size="sm"
            @click="$emit('itemSelected', getItemID(item))">
            {{ $t('permissions') }}
          </CButton>
        </td>
      </template>
      <template #edit="{item}">
        <td class="py-2">
          <CButton
            color="warning"
            variant="outline"
            square
            size="sm"
            @click="$emit('editItem', getItemID(item))">
            <CIcon icon="cil-pencil" size="xl" />
          </CButton>
        </td>
      </template>    
      <template #attachment="{item}">
        <td class="py-2">
          <CButton
            color="success"
            variant="outline"
            square
            size="sm"
            @click="$emit('showAttachment', getItemID(item))">
            <CIcon icon="cilPaperclip" size="xl" />
          </CButton>
        </td>
      </template>    
      <template #comment="{item}">
        <td class="py-2">
          <CButton
            color="dark"
            variant="outline"
            square
            size="sm"
            @click="$emit('showComment', getItemID(item))">
            <CIcon icon="cil-comment-square" size="xl" />
          </CButton>
        </td>
      </template>     
      <template #move="{item}">
        <td class="py-2">
          <CButton
            color="warning"
            variant="outline"
            square
            size="sm"
            @click="$emit('moveItem', getItemID(item))">
            <CIcon icon="cil-arrow-thick-top" size="xl" />
          </CButton>
        </td>
      </template>                        
      <template #details="{item}">
        <!--<CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">-->
        <CCollapse :visible="Boolean(item._toggled)" :duration="collapseDuration">
          <CCardBody>
            <!-- print details -->
            <CRow>
              <CCol>
                <div v-for="(row, index) in rows" :key="index">
                  <strong>{{ pretifyName(row) }}</strong>: {{ getPropertyValue(item, row) }}
                </div><br>
              </CCol>
              <CCol>
                <div class="d-grid gap-1 d-md-flex justify-content-md-end">
                  <CButton v-if="machineLookupButton" size="sm" color="warning" class="" @click="$emit('machineLookup', getItemID(item))">
                    {{ $t('machines') }}
                  </CButton>            
                  <CButton v-if="extraButtonName" size="sm" color="secondary" class="" @click="$emit('customAction', getItemID(item))">
                    {{ extraButtonName }}
                  </CButton>
                  <CButton v-if="extraButtonName2" size="sm" color="warning" class="" @click="$emit('customAction2', getItemID(item))">
                    {{ extraButtonName2 }}
                  </CButton>                  
                  <CButton v-if="!hideEditButtons" size="sm" color="info" class="" @click="$emit('editItem', getItemID(item))">
                    {{ $t('edit') }}
                  </CButton>
                  <CButton v-if="!hideEditButtons" size="sm" color="danger" class="ml-1" @click="$emit('deleteItem', getItemID(item))">
                    {{ $t('delete') }}
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCollapse>
      </template>
    </CDataTable>
  </CCardBody>
</template>

<script>
import CDataTable from './imported/table/CDataTable.vue'
import StringFormatter from '../helpers/stringFormatter'

export default {
  name: 'AdvancedTables',
  props: {
    data: Array,
    columns: Array,
    rowsdetail: Array,
    extraButtonName: String,
    extraButtonName2: String,
    hideEditButtons: Boolean,
    machineLookupButton: Boolean,
    clickableRows: Boolean,
    showItemsPerPage: {
      type: Boolean,
      default: true        
    },
    sorter: {
      type: Boolean,
      default: true        
    },
    columnFilter: {
      type: Boolean,
      default: true        
    }
  },
  data () {
    return {
      items: this.data.map((item, id) => { return {...item, id}}),
      fields: this.columns,
      details: [],
      rows: this.rowsdetail,
      collapseDuration: 0
    }
  },
  watch: {
    data (val, oldVal) {
      if (val && oldVal && this.objectsAreIdentical(val, oldVal)) {
        return
      }
      let toggledItem = this.items.findIndex(currValue => currValue['_toggled'])
      this.items = val.map((item, id) => { return {...item, id}})
      if (toggledItem >= 0) {
        this.items[toggledItem]['_toggled'] = true
      }
    },
  },
  methods: {
    getBadge (status) {
      switch (status) {
        case 'Active': return 'success'
        case 'Closed': return 'success'
        case 'Inactive': return 'secondary'
        case 'Under Mantainance': return 'warning'
        case 'Under Development': return 'warning'
        case 'Analysing': return 'warning'
        case 'Pending': return 'warning'
        case 'Banned': return 'danger'
        case 'Open': return 'danger'
        case 'New': return 'danger'
        default: 'primary'
      }
    },
    getDisabledBadge (disabled) {
      if (disabled) {
        return 'danger'
      } else {
        return 'success'
      }
    },    
    toggleDetails (item) {
      //this.$set(this.items[item.id], '_toggled', !item._toggled)
      // Untoggle everything first
      const currToggleStatus = item._toggled
      const toggledItems = this.items.filter(value => value['_toggled'])
      if (toggledItems) {
        toggledItems.forEach(toggledItem => {
          this.items[toggledItem.id]['_toggled'] = false
        })
      }
      this.items[item.id]['_toggled'] = !currToggleStatus
      this.collapseDuration = 300
      this.$nextTick(() => { this.collapseDuration = 0})
    },
    getPropertyValue (item, property) {
      let selectedItem = this.items[item.id]
      if (Object.prototype.hasOwnProperty.call(selectedItem, property)) {
        return selectedItem[property]
      }
      return
    },
    getItemID (item) {
      let selectedItem = this.items[item.id]
      // OLD: return selectedItem["iD"]
      return selectedItem[Object.keys(selectedItem)[0]]
    },
    pretifyName (name) {
      // Translate it first.. logic moved to the stringFormatter helper
      return StringFormatter.pretifyName(this.$t(name))
    },
    objectsAreIdentical (obj1, obj2) {
      return obj1.length === obj2.length && 
             JSON.stringify(obj1) === JSON.stringify(obj2)
    }       
  },
  components: {
    CDataTable
  }
}
</script>