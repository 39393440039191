import UploadService from '../services/upload'

export async function uploadFile(file) {
  file.status = 'loading'
  let response = await UploadService.uploadPictures(file.file).then(
    (response) => {
      // Set infos...
      file.serverUrl = response.data[0].url
      file.status = 'uploaded'
    },
    () => {
      file.status = 'error'
    }
  )

  return response
}

export function uploadFiles(files) {
  return Promise.all(files.map((file) => uploadFile(file)))
  
}

export default function createUploader() {
  return {
    uploadFile: function (file) {
      return uploadFile(file)
    },
    uploadFiles: function (files) {
      return uploadFiles(files)
    },
  }
}