<template>
  <CContainer fluid>
    <loading-data v-if="!dataLoaded"/>

    <general-table v-if="dataLoaded"
      :data="distributors"
      :columns="tableColumns"
      :rowsdetail="tableRowsDetails"
      @itemSelected="$emit('itemSelected', $event)"/>

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </CContainer>
</template>

<script>
import GeneralTable from '@/components/GeneralTable.vue'
import DistributorService from '../services/distributors'
import LoadingData from './LoadingData.vue'
import PermissionManager from '../helpers/permissionManager'

export default {
  name: 'DistributorsLookup',
  data() {
    return {
      dataLoaded: false,
      message: '',
      content: [],
      searchQuery: '',
      tableColumns: [
        'distributorFields.id',
        'distributorFields.name',
        'distributorFields.address',
        'distributorFields.country',
        'distributorFields.city',
        'distributorFields.phone_Number',
        'distributorFields.client_No',
        {
          key: 'choose', 
          label: '', 
          _style: 'width:1%', 
          sorter: false, 
          filter: false
        }
      ],
      tableRowsDetails: [
      ]
    }
  },
  created() {
    this.getDistributorsGradually()
  },
  computed: {
    distributors() {
      let processedData = []
      if (this.content.length > 0) {
        this.content.forEach((distributor) => {
          processedData.push({
            'distributorFields.id': distributor.id,
            'distributorFields.name': distributor.company,
            'distributorFields.address': distributor.address,
            'distributorFields.country': distributor.country,
            'distributorFields.city': distributor.city,
            'distributorFields.phone_Number': distributor.phone_Number,
            'distributorFields.client_No': distributor.client_No,              
          })                    
        })
      }
      return processedData
    }
  },
  methods: {
    getDistributorsGradually(skipParam) {
      if (!skipParam) {
        this.content = []
      }             
      let paramQuery = {
        '$limit': 50
      }
      // Manage Permissions first
      if (!(PermissionManager.hasExtendedTechnicianPermissions())) {
        const userDistributor = PermissionManager.getDistributorFilteredVisibility()
        if (userDistributor) {
          paramQuery.id = userDistributor.idDistributor
        }      
      }       
      if (skipParam) {
        paramQuery['$skip'] = skipParam
      }
      DistributorService.getDistributors(paramQuery).then(
        (response) => {
          if (this.content.length > 0) {
            this.content = this.content.concat(response.data.data)
          } else {
            this.content = response.data.data
          }
          this.dataLoaded = true
          if (response.data.total > response.data.limit + response.data.skip) {
            if (skipParam) {
              skipParam += response.data.limit
            } else {
              skipParam = response.data.limit
            }
            this.getDistributorsGradually(skipParam)
          }
        },
        (error)  => {
          this.message =           
           (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }        
      )
    }
  },
  components: {
    GeneralTable,
    LoadingData
  }
}
</script>