<template>
  <CCollapse :visible="filtersVisible">
    <CCard fluid color="secondary">
      <CCardHeader>
        <strong>{{ $t('machinesFilterSearch') }}</strong>
      </CCardHeader>
      <CCardBody>
        <!--<CCardTitle>Insert Filters to load a filtered list</CCardTitle>-->
        <CRow class="mb-3">
          <CCol sm="6">
            <CFormLabel for="machineName"><strong>{{ pretifyName('machineFields.name') }}</strong></CFormLabel>
            <CFormInput type="text" id="machineName" v-model="machineName"/>
          </CCol>
          <CCol sm="3">
            <CFormLabel for="machineID"><strong>{{ pretifyName('machineFields.machine_ID') }}</strong></CFormLabel>
            <CFormInput type="text" id="machineID" v-model="machineID"/>
          </CCol> 
          <CCol sm="3">
            <CFormLabel for="idMachine"><strong>{{ pretifyName('machineFields.id') }}</strong></CFormLabel>
            <CFormInput type="text" id="idMachine" v-model="idMachine"/>
          </CCol>                
        </CRow>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <CButton color="danger"
            @click="clearFilters()">
            <CIcon icon="cil-filter-x" size="xl" />
          </CButton>
          <CButton color="dark"
            @click="buildFilter()">
            {{ $t('search') }}
          </CButton>
        </div>                     
      </CCardBody> 
    </CCard>
  </CCollapse>
</template>

<script>
import StringFormatter from '../helpers/stringFormatter'

export default {
  name: 'MachineSearchComponent',
  props: {
    filtersVisible: Boolean
  },
  data() {
    return {
      machineName: '',
      machineID: '',
      idMachine: ''
    }
  },
  methods: {
    buildFilter() {
      let filterParams = {}
      if (this.machineName) {
        filterParams.name = '%' + this.machineName + '%'
      }
      if (this.machineID) {
        filterParams.id_Machine = '%' + this.machineID + '%'
      }
      if (this.idMachine) {
        filterParams.id = this.idMachine
      }
      this.$emit('loadData', filterParams)
    },
    clearFilters() {
      this.machineName = ''
      this.machineID = ''
      this.idMachine = ''
      this.$emit('loadData')
    },
    pretifyName (name) {
      // Translate it first.. logic moved to the stringFormatter helper
      return StringFormatter.pretifyName(this.$t(name))
    }    
  }
}
</script>