<template>
  <CModal backdrop="static" size="lg" :visible="cModalVisible" @close="$emit('cancel')">
    <CModalBody>
      <CAlert color="warning" v-bind:visible="true">
        <CAlertHeading>{{ title }}</CAlertHeading>
        <p>{{ message }}</p>
        <hr />
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <CButton type="button" color="danger" shape="rounded-0" id="emptyMachine"
            @click="$emit('cancel')">{{ noButton }}</CButton>            
          <CButton type="button" color="secondary" shape="rounded-0" id="confirmAction"
            @click="$emit('confirm')">{{ yesButton }}</CButton>
        </div>
      </CAlert>
    </CModalBody>
  </CModal>
</template>

<script>
export default {
  name: 'ConfirmAction',
  props: {
    cModalVisible: Boolean,
    title: String,
    message: String,
    yesButton: String,
    noButton: String
  }
}
</script>