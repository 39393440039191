<template>
  <CCard fluid>
    <CCardBody>
      <CCardTitle>{{ analysisRequestTitle }}</CCardTitle>
      <CCardSubtitle class="mb-2 text-muted">{{ analysisRequestDescription }}</CCardSubtitle> 
      <CCardBody>
        <analysis-requests-lookup
          :excludeClosed="excludeClosedAnalysis"
          :showForwardedRequests="showForwardedRequests"
          :initialFilter="returnAnalysisRequestFilter"
          :moveRequestVisible="((this.analysisRequestType) && (this.analysisRequestType == 2))"
          @itemSelected="processRequest($event)"
          @showAttachment="viewAttachments($event)"
          @showComment="showComment($event)"
          @moveItem="moveItem($event)"/>
          
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <CButton type="button" color="dark" shape="rounded-0" id="viewAll"
            @click="() => { excludeClosedAnalysis = !excludeClosedAnalysis }">{{ $t('viewAll') }}</CButton>
        </div>            
      </CCardBody>
      <!--<CCardText>In this card all the incoming request directed to the customer will be displayed... Request Table has to be defined in the Backend as well....</CCardText>-->
    </CCardBody>

    <CModal size="xl" :visible="handleRequestVisible" @close="() => { handleRequestVisible = !handleRequestVisible }">
      <CModalHeader>
        <CModalTitle>{{ $t('handleAnalysisRequest') }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CCardText>{{ $t('handleAnalysisRequestDetails') }}</CCardText>
        <CRow class="mb-3">
          <CFormLabel for="requestType" class="col-sm-2 col-form-label">{{ $t('requestType') }}</CFormLabel>
          <CCol sm="10">
            <CFormSelect class="mb-3" aria-label="request type select" id="requestType" v-model="requestType" :value="getRequestType()">
              <option value="0">{{ $t('requestTypeOpt0') }}</option>
              <option value="1">{{ $t('requestTypeOpt1') }}</option>
              <option value="2">{{ $t('requestTypeOpt2') }}</option>
            </CFormSelect>
          </CCol>
        </CRow>          
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="() => { handleRequestVisible = !handleRequestVisible }">
          {{ $t('close') }}
        </CButton>
        <CButton color="dark" @click="handleRequest(requestType)">{{ $t('handleRequest') }}</CButton>
        <CButton color="danger" @click="removeRequest()">{{ $t('closeRequest') }}</CButton>
      </CModalFooter>
    </CModal>

    <CToaster placement="top-end">
      <CToast color="dark" class="text-white" v-for="(toast, index) in toasts" :key="index">
        <CToastHeader closeButton>
        <span class="me-auto fw-bold">{{ toast.title }}</span>
        <small>{{ $t('now') }}</small>
        </CToastHeader>
        <CToastBody>
          {{ toast.content }}
        </CToastBody>  
      </CToast>
    </CToaster>

    <CModal alignment="center" :visible="commentVisible" @close="() => { commentVisible = false }">
      <CModalHeader>
        <CModalTitle>{{ $t('comment') }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CFormTextarea id="commentToDisplay" rows="5" :value="selectedRequest.comment" readonly /><br>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="() => { commentVisible = false }">
          {{ $t('close') }}
        </CButton>        
      </CModalFooter>
    </CModal> 

    <CModal backdrop="static" size="lg" :visible="replyVisible" @close="() => { replyVisible = false}">
      <CModalBody>
        <CAlert color="warning" v-bind:visible="true">
          <CAlertHeading>{{ $t('closeForwardedRequestTitle') }}</CAlertHeading>
          <p>{{ $t('closeForwardedRequestDescription') }}</p>
          <CFormTextarea id="commentToDisplay" rows="5" v-model="selectedRequest.comment" /><br>
          <hr />
          <div class="d-grid gap-2 d-md-flex justify-content-md-end">
            <CButton type="button" color="danger" shape="rounded-0" id="emptyMachine"
              @click="() => { replyVisible = false}">{{ $t('close') }}</CButton>            
            <CButton type="button" color="secondary" shape="rounded-0" id="confirmAction"
              @click="sendReplyAndClose()">{{ $t('replyAndClose') }}</CButton>
          </div>
        </CAlert>
      </CModalBody>
    </CModal>       

  </CCard>
</template>

<script>
import AnalysisRequestsLookup from './AnalysisRequestsLookup.vue'
import AnalysisRequestService from '../services/analysis-requests'
import { RequestType, RequestStatus } from '../helpers/getEnumValues'
import PermissionManager from '../helpers/permissionManager'

export default {
  name: 'DistributoAnalysisRequest',
  props: {
    analysisRequestType: Number
  },
  data() {
    return {
      selectedRequest: '',
      handleRequestVisible: false,
      selectedRequestID: 0,
      excludeClosedAnalysis: ((this.analysisRequestType) && (this.analysisRequestType == 2)) ? false : true,
      toasts: [],
      commentVisible: false,
      replyVisible: false
    }
  },
  computed: {
    returnAnalysisRequestFilter() {
      let currDistributor = PermissionManager.getDistributorFilteredVisibility()
      const currAnalysisRequestType = this.analysisRequestType ? this.analysisRequestType : 0
      let analysisRequestFilter = {}
      let customerSessionStarted = localStorage.getItem('customerSessionStarted')
      let currentCustomer = localStorage.getItem('currentCustomer')
      if ((customerSessionStarted) && (!(customerSessionStarted === 'false')) &&
          (currentCustomer) && (currentCustomer != 0)) {
        analysisRequestFilter.idCustomer = currentCustomer
      }      
      switch (currAnalysisRequestType) {
        case 1: // Analysis Forwarded to me
          analysisRequestFilter.active_Forward_Process = 1
          analysisRequestFilter.idDistributor = currDistributor.idDistributor ? currDistributor.idDistributor : 0
          break
        case 2: // Analysis Forwarded by me
          analysisRequestFilter.active_Forward_Process = 1
          analysisRequestFilter.forwarded_By = currDistributor.idDistributor ? currDistributor.idDistributor : 0
          break      
        default:
          analysisRequestFilter.active_Forward_Process = 0
          break
      }
      return analysisRequestFilter
    },
    analysisRequestTitle() {
      const currAnalysisRequestType = this.analysisRequestType ? this.analysisRequestType : 0
      switch (currAnalysisRequestType) {
        case 1: // Analysis Forwarded to me
          return this.$t('analysisForwardedToMe')
        case 2: // Analysis Forwarded by me
          return this.$t('analysisForwardedByMe')      
        default:
          return this.$t('analysisRequests')
      }
    },
    analysisRequestDescription() {
      const currAnalysisRequestType = this.analysisRequestType ? this.analysisRequestType : 0
      switch (currAnalysisRequestType) {
        case 1: // Analysis Forwarded to me
          return this.$t('analysisForwardedToMeDescription')
        case 2: // Analysis Forwarded by me
          return this.$t('analysisForwardedByMeDescription')       
        default:
          return this.$t('analysisToPerform')
      }      
    },
    showForwardedRequests() {
      const currAnalysisRequestType = this.analysisRequestType ? this.analysisRequestType : 0
      switch (currAnalysisRequestType) {
        case 1: // Analysis Forwarded to me
          return true
        case 2: // Analysis Forwarded by me
          return true     
        default:
          return false
      }      
    }
  },
  methods: {
    processRequest(requestID) {
      if (requestID) {
        // 1. Get Analysis Request to process it later...
        this.selectedRequestID = requestID
        AnalysisRequestService.getSingleAnalysisRequest(requestID).then(
          (response) => {
            this.selectedRequest = response.data
            let currDistributor = PermissionManager.getDistributorFilteredVisibility()
            if ((currDistributor.idDistributor) && (this.selectedRequest.forwarded_By == currDistributor.idDistributor) &&
              !(this.getForwardStatus(this.selectedRequest.forward_Status) == 2)) {
              this.createToast(this.$t('forwardedRequest'), this.$t('forwardedRequestDetails'))
            } else {
              if ((this.selectedRequest.idMeasurement) && (this.selectedRequest.idMeasurement != 0)) {
                switch(this.selectedRequest.request_Type) {
                  case 'Measurement':
                    // To Update....
                    this.$router.push({ name: 'MeasurementsMachine', params: { idMachine: this.selectedRequest.idMachine } })
                    break
                  case 'Lab Report':
                    this.$router.push({ name: 'LabReportDetails', params: { 'idMachine': this.selectedRequest.idMachine, 'labReportType': 0, 'idLabReport': this.selectedRequest.idMeasurement }})  
                    break                
                  case 'Water Analysis':
                    this.$router.push({ name: 'LabReportDetails', params: { 'idMachine': this.selectedRequest.idMachine, 'labReportType': 1, 'idLabReport': this.selectedRequest.idMeasurement }})  
                    break                  
                  default:
                    break
                }
              } else {
                if (this.getRequestStatus() == 2) {
                  this.commentVisible = true
                } else {
                  if (PermissionManager.hasTechnicianPermissions()) {
                    this.handleRequestVisible = true
                  }
                }
              }
            }
          }
        )        
      }
    },
    getRequestType() {
      if ((this.selectedRequest.request_Type) && (this.selectedRequest.request_Type != '')) {
        return RequestType.indexOf(this.selectedRequest.request_Type)
      }
    },
    getRequestStatus() {
      if ((this.selectedRequest.request_Status) && (this.selectedRequest.request_Status != '')) {
        return RequestStatus.indexOf(this.selectedRequest.request_Status)
      }
    },    
    handleRequest(requestType) {
      if (!requestType) {
        requestType = this.getRequestType()
      }
      this.handleRequestVisible = false
      this.$router.push({ name: 'Measure', params: { requestID: this.selectedRequestID, measurementType: requestType } })
    },
    viewAttachments(chosenRequest) {
      if ((chosenRequest.attached_idMeasure) && (chosenRequest.attached_idMeasure != 0)) {
        this.$router.push({ name: 'MeasurementsMachine', params: { 'idMachine': chosenRequest.idMachine, 'idMeasure': chosenRequest.attached_idMeasure } })
      } else {
        this.createToast(this.$t('noAttachmentProvided'), this.$t('noAttachmentProvidedDetails'))
      }
    },
    createToast(title, content) {
      this.toasts.push({
        title: title,
        content: content
      })
    },
    getForwardStatus(statusString) {
      if ((statusString) && (statusString != '')) {
        return RequestStatus.indexOf(statusString)
      }
    },
    showComment(requestID) {
      if (requestID) {
        this.selectedRequestID = requestID
        AnalysisRequestService.getSingleAnalysisRequest(requestID).then(
          (response) => {
            this.selectedRequest = response.data
            this.commentVisible = true
          }
        )        
      }      
    },
    moveItem(requestID) {
      if (requestID) {
        AnalysisRequestService.getSingleAnalysisRequest(requestID).then(
          (response) => {       
            this.selectedRequestID = requestID 
            this.selectedRequest = response.data
            let currDistributor = PermissionManager.getDistributorFilteredVisibility()
            if ((currDistributor.idDistributor) && (this.selectedRequest.forwarded_By == currDistributor.idDistributor) &&
              !(this.getForwardStatus(this.selectedRequest.forward_Status) == 2)) {
              this.createToast(this.$t('forwardedRequest'), this.$t('forwardedRequestDetails'))
            } else {
              // Ask for a sending confirmation and close the status directly 
              this.replyVisible = true
            }
          }
        )      
      }
    },
    sendReplyAndClose() {
      if ((this.selectedRequest.id) && (this.selectedRequest.id != 0)) {
        const currDistributor = PermissionManager.getDistributorFilteredVisibility()
        const queryParams = {
          'idDistributor': currDistributor.idDistributor ? currDistributor.idDistributor : 0,
          'active_Forward_Process': 0,
          'comment': this.selectedRequest.comment,  
          'request_Status': 2        
        }
        AnalysisRequestService.updateAnalysisRequest(this.selectedRequest.id, queryParams).then(
          () => {
            this.replyVisible = false
            this.$emit('refreshRequestsLists')
          }
        )
      } 
    },
    removeRequest() {
      if ((this.selectedRequest.id) && (this.selectedRequest.id != 0)) {
        const currDistributor = PermissionManager.getDistributorFilteredVisibility()
        if (PermissionManager.hasTechnicianPermissions() && (currDistributor.idDistributor)) {
          const queryParams = {
            'notify_User': false,  
            'request_Status': 2        
          }
          AnalysisRequestService.updateAnalysisRequest(this.selectedRequest.id, queryParams).then(
            () => {
              this.handleRequestVisible = false
              this.$emit('refreshRequestsLists')
            }
          )
        }
      }
    }  
  },
  components: {
    AnalysisRequestsLookup
  }
}
</script>