import axios from 'axios'
import authHeader from './auth-header'
import formatQuery from './query-helper'

const API_URL = process.env.VUE_APP_SERVER_BASE_URL + 'machine-assigned-operations'

class AssignedOperationsService {
    getOperations(queryParams) {
        return axios.get(API_URL + formatQuery(queryParams), { headers: authHeader() })
    }
    updateOperations(compositeKeyParams, operationParams) {
        return axios.patch(API_URL + formatQuery(compositeKeyParams), operationParams, { headers: authHeader() })
    }
    assignOperation(operationParams) {
        return axios.post(API_URL, operationParams, { headers: authHeader() })
    }
    deleteOperation(compositeKeyParams) {
        return axios.delete(API_URL + formatQuery(compositeKeyParams), {headers: authHeader() })
    }
    // Build other api calls here....   
}

export default new AssignedOperationsService()