<template>
  <div>
    <div class="d-flex w-100 justify-content-between">
      <h4 class="mb-1"><strong>{{ machine.name }}{{ machineIDString }}</strong></h4>
    </div>
    <p class="mb-1"><italic>{{ machine.product_Name }}</italic><br v-if="isVerticalMobileMode()">
      <strong>{{ formatDateString(this.machine.last_Filled, spacingCharacter() + pretifyName('machineFields.last_Filled')) }}</strong>
    </p>
    <p class="mb-1" v-if="machine.last_Measurement">
      <span :style="lastMeasuredStyle">{{ formatDateString(machine.last_Measurement.measured_At, $t('lastMeasured')) }}</span>      
       - {{ pretifyName('measurementFields.pH') }}: 
        <CAvatar :color="phColorIndicator" size="sm" />
       - {{ pretifyName('measurementFields.eff_Concentration') }}: 
        <CAvatar :color="effConcColorIndicator" size="sm" />
    </p>
    <p class="mb-1" v-else>
      <span style="color: #9f9fa1">{{ $t('noMeasurementsException') }}</span> <CAvatar color="light" size="sm"/>
    </p>
    <CCard :class="'border-dark'" v-if="analysisRequests.length > 0">
      <CCardBody>
        <CCardTitle><strong>{{ $t('analysisRequests') }}</strong></CCardTitle>
        <CListGroup>   
          <CListGroupItem v-for="(request, index) in analysisRequests" :key="index" :color="getstatusColor(request.request_Status)" component="button" @click="processRequest(request.id)">
            {{ request.request_Type }}: {{ dateStringFormat(request.creation_Date) }} - {{ request.request_Status }}          
          </CListGroupItem>
        </CListGroup>      
      </CCardBody>
    </CCard>

    <CToaster placement="top-end">
      <CToast color="dark" class="text-white" v-for="(toast, index) in toasts" :key="index">
        <CToastHeader closeButton>
        <span class="me-auto fw-bold">{{ toast.title }}</span>
        <small>{{ $t('now') }}</small>
        </CToastHeader>
        <CToastBody>
          {{ toast.content }}
        </CToastBody>  
      </CToast>
    </CToaster>

  </div>
</template>

<script>
import StringFormatter from '../helpers/stringFormatter'
import DateFormatter from '../helpers/dateFormatter'
import AnalysisRequestService from '../services/analysis-requests'
import PermissionManager from '../helpers/permissionManager'
import { RequestStatus } from '../helpers/getEnumValues'

export default {
  name: 'MachineListItemMobile',
  props: {
    machine: Object
  },
  data() {
    return {
      message: '',
      analysisRequests: [],
      toasts: []
    }
  },
  created() {
    this.getAnalysisRequests()
  },
  computed: {
    phColorIndicator() {
      if ((!this.machine.last_Measurement) ||
            (!this.machine.critical_pH)) {
        return "secondary"
      } else {
        return (this.machine.last_Measurement.pH >= Number(this.machine.critical_pH)) ? "success" : "danger" 
      }
    },
    effConcColorIndicator() {
      if ((!this.machine.last_Measurement) ||
            (!this.machine.min_Eff_Concentration) ||
            (!this.machine.max_Eff_Concentration) ||
            ((this.machine.min_Eff_Concentration == 0) && (this.machine.max_Eff_Concentration == 0))) {
        return "secondary"
      } else {
        return ((this.machine.last_Measurement.eff_Concentration >= Number(this.machine.min_Eff_Concentration)) &&
            (this.machine.last_Measurement.eff_Concentration <= Number(this.machine.max_Eff_Concentration))) ? "success" : "danger"     
      }
    },
    machineIDString() {
      if (this.machine.id_Machine) {
        return ' (' + this.machine.id_Machine + ')'
      } else {
        return ''
      }
    },
    lastMeasuredStyle() {
      if (this.machine.last_Measurement) {
        if (DateFormatter.formatDate(this.machine.last_Measurement.measured_At, true) == DateFormatter.formatDate(new Date(), true)) {        
          return 'color: #3a8cc7;font-weight:bold'           
        }
      }

      return ''
    }        
  },
  methods: {
    pretifyName(name) {
      return StringFormatter.pretifyName(this.$t(name))
    },
    formatDateString(fromDate, caption) {
      if (fromDate) {
        let formattedDate = DateFormatter.formatDate(fromDate, true)
        if (formattedDate) {
          return (caption + ': ' + String(formattedDate))
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    isVerticalMobileMode() {
      return (screen.width <= 500)
    },
    spacingCharacter() {
      return (this.isVerticalMobileMode() ? '' : ' - ')
    },
    getAnalysisRequests() {
      let requestParams = { 
        'idMachine': this.machine.id,
        'request_Status': '!2'
      }       
      requestParams.$limit = 500
      requestParams['$sort[id]'] = -1     
      AnalysisRequestService.getAnalysisRequests(requestParams).then(
        (response) => {
          this.analysisRequests = response.data.data
        },
        (error)  => {
          this.message =           
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }
      )
    },
    dateStringFormat(refDate) {
      return DateFormatter.formatDate(refDate, true)
    },
    getstatusColor(status) {
      switch (status) {
        case 'Closed': return 'success'
        case 'Under Development': return 'warning'
        case 'Open': return 'danger'
        default: 'primary'
      }      
    },
    processRequest(requestID) {
      this.$emit('suspendParentBehaviour', true)
      let currDistributor = PermissionManager.getDistributorFilteredVisibility()
      if ((requestID) && (currDistributor.idDistributor)) {
        // 1. Get Analysis Request to process it later...
        let currIndex = this.analysisRequests.findIndex(request => request.id == requestID)
        if (currIndex >= 0) {
          if ((this.analysisRequests[currIndex].forwarded_By == currDistributor.idDistributor) &&
            !(this.getStatus(this.analysisRequests[currIndex].forward_Status) == 2)) {
            this.createToast(this.$t('forwardedRequest'), this.$t('forwardedRequestDetails'))
          } else {
            if ((this.analysisRequests[currIndex].idMeasurement) && (this.analysisRequests[currIndex].idMeasurement != 0)) {
              switch(this.analysisRequests[currIndex].request_Type) {
                case 'Measurement':
                  this.$router.push({ name: 'MeasurementsMachine', params: { idMachine: this.analysisRequests[currIndex].idMachine } })
                  break
                case 'Lab Report':
                  this.$router.push({ name: 'LabReportDetails', params: { 'idMachine': this.analysisRequests[currIndex].idMachine, 'labReportType': 0, 'idLabReport': this.analysisRequests[currIndex].idMeasurement }})  
                  break                
                case 'Water Analysis':
                  this.$router.push({ name: 'LabReportDetails', params: { 'idMachine': this.analysisRequests[currIndex].idMachine, 'labReportType': 1, 'idLabReport': this.analysisRequests[currIndex].idMeasurement }})  
                  break                  
                default:
                  break
              }
            } else {
              if (PermissionManager.hasTechnicianPermissions()) {
                this.$emit('handleAnalysisRequest', this.analysisRequests[currIndex])
              }
            }
          }
        }
      }
    },
    getStatus(statusString) {
      if ((statusString) && (statusString != '')) {
        return RequestStatus.indexOf(statusString)
      }
    },
    createToast(title, content) {
      this.toasts.push({
        title: title,
        content: content
      })
    }                              
  }  
}
</script>