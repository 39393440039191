<template>
  <CCard fluid v-if="isDataLoaded">
    <CCardBody>
      <CCardTitle>{{ $t('machine') }} </CCardTitle>
      <CCardText>{{ pretifyName('machineFields.id') }}: {{ currMachine.id }} <br> {{ pretifyName('machineFields.name') }}: {{ currMachine.name }}<br>
       {{ pretifyName('machineFields.machine_ID') }}: {{ currMachine.id_Machine }}<br>
       {{ pretifyName('status') }}: {{ currMachine.status }}<br> {{ pretifyName('machineFields.sump_Size') }}: {{ currMachine.sump_Size }}<br>
       {{ pretifyName('machineFields.product_Content') }}: {{ currMachine.product_Content }}<br> {{ pretifyName('machineFields.critical_pH') }}: {{ currMachine.critical_pH }}<br> 
       {{ pretifyName('machineFields.min-max_Eff_Conc') }}: {{ currMachine.min_Eff_Concentration }} - {{ currMachine.max_Eff_Concentration }}<br>
       {{ pretifyName('machineFields.last_Filled') }}: {{ currMachine.last_Filled}}</CCardText>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <CButton type="submit" color="dark" shape="rounded-0" class="mb-3"
          @click="goToMachine()">
          {{ $t('goToMachine') }}
        </CButton>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import MachineService from '../services/machines'
import DateFormatter from '../helpers/dateFormatter'
import StringFormatter from '../helpers/stringFormatter'

export default {
  name: 'MachineInfobox',
  props: {
    machineID: Number
  },
  expose: ['dataLoaded'],
  data() {
    return {
      dataLoaded: false,
      currMachine: '',
      lastMachineID: 0
    }
  },
  computed: {
    isDataLoaded() {
      if (this.machineID != this.lastMachineID) {
        this.refreshData()
      }
      if (!this.dataLoaded) {
        this.getMachineData()
      }
      return this.dataLoaded
    }
  },
  methods: {
    getMachineData() {
      if (this.machineID != 0) {
        MachineService.getSingleMachine(this.machineID).then(
          (response) => {
            this.currMachine = response.data
            if (this.currMachine.last_Filled) {
              this.currMachine.last_Filled = DateFormatter.formatDate(this.currMachine.last_Filled, true)
            }
            this.lastMachineID = this.currMachine.id
            this.dataLoaded = true
          }
        )
      } else {
        this.dataLoaded = true
      }
    },
    goToMachine() {
      let isMobile = localStorage.getItem('isMobile')
      this.$router.replace({ name: (isMobile === 'true') ? 'mEditMachine' : 'EditMachine', params: { idMachine: this.machineID }})
    },
    refreshData() {
      this.dataLoaded = false
    },
    pretifyName(name) {
      return StringFormatter.pretifyName(this.$t(name))
    }    
  }
}
</script>