<template>
  <CModal backdrop="static" size="xl" :visible="cModalVisible" @show="reloadData()" @close="closeUserAssignement()">
    <CModalHeader>
      <CModalTitle>{{ $t('userAssignment') }}</CModalTitle>
    </CModalHeader>
    <CModalBody v-if="dataLoaded">
      <CRow class="mb-3">
        <CFormLabel for="companyType" class="col-sm-2 col-form-label">{{ $t('linkToCompany') }}</CFormLabel>
        <CCol sm="10">
          <CFormSelect class="mb-3" aria-label="company type select" id="companyType" v-model="currUser.company_Type_Int">
            <option value="0">{{ $t('distributor') }}</option>
            <option value="1">{{ $t('customer') }}</option>
          </CFormSelect>
        </CCol>
      </CRow>
      <CRow class="mb-3">
        <CFormLabel for="companyName" class="col-sm-2 col-form-label">{{ pretifyName('customerFields.name') }}</CFormLabel>
        <CCol sm="10">
          <CInputGroup class="mb-3">
            <CFormInput type="text" id="companyName" v-model="companyName" readonly
              :value="currCompany.company" />
            <CButton type="button" color="dark" variant="outline" id="searchCompany"
              @click="setModalVisibility()">{{ $t('search') }}</CButton>
          </CInputGroup>
        </CCol>
      </CRow>       
      <CRow class="mb-3">
        <CFormLabel for="userRole" class="col-sm-2 col-form-label">{{ pretifyName('userFields.role') }}</CFormLabel>
        <CCol sm="10">
          <CFormSelect class="mb-3" aria-label="role select" id="userRole" v-model="currUser.role_Int">
            <option value="0">{{ $t('userRolesOptions.user') }}</option>
            <option value="1">{{ $t('userRolesOptions.agent') }}</option>
          </CFormSelect>
        </CCol>
      </CRow>       
    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="closeUserAssignement()">
        {{ $t('close') }}
      </CButton>
      <CButton color="primary"
        @click="updateUser()">
        {{ $t('updateUserData') }}
      </CButton>
    </CModalFooter>

    <CModal size="xl" alignment="center" :visible="distLookupVisible" @close="() => { distLookupVisible = false }">
      <CModalHeader>
        <CModalTitle>{{ $t('distributors') }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <distributors-lookup-mobile v-if="isMobile"
          @itemSelected="getDistributor($event)" />
        <distributors-lookup v-if="!isMobile"
          @itemSelected="getDistributor($event)" />          
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="() => { distLookupVisible = false }">
          {{ $t('close') }}
        </CButton>
      </CModalFooter>
    </CModal> 

    <CModal size="xl" alignment="center" :visible="custLookupVisible" @close="() => { custLookupVisible = false }">
      <CModalHeader>
        <CModalTitle>{{ $t('customers') }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <customers-lookup-mobile v-if="isMobile"
          @itemSelected="getCustomer($event)" />
        <customers-lookup v-if="!isMobile"
          @itemSelected="getCustomer($event)" />          
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="() => { custLookupVisible = false }">
          {{ $t('close') }}
        </CButton>
      </CModalFooter>
    </CModal>     

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </CModal>
</template>

<script>
import UserService from '../services/users'
import DistributorService from '../services/distributors'
import CustomerService from '../services/customers'
import DistributorsLookup from './DistributorsLookup.vue'
import CustomersLookup from './CustomersLookup.vue'
import DistributorsLookupMobile from './mobile/DistributorsLookupMobile.vue'
import CustomersLookupMobile from './mobile/CustomersLookupMobile.vue'
import { CompanyType, UserRoles } from '../helpers/getEnumValues'
import StringFormatter from '../helpers/stringFormatter'

export default {
  name: 'CustomerCard',
  props: {
    cModalVisible: Boolean,
    userID: Number
  },
  data() {
    return {
      message: '',
      currUser: '',
      dataLoaded: false,
      currCompany: '',
      distLookupVisible: false,
      custLookupVisible: false  
    }
  },
  computed: {
    isMobile() {
      let mobile = localStorage.getItem('isMobile')
      return (mobile === 'true')
    }
  },
  methods: {
    getUser() {
      this.currUser = {}
      if ((this.userID) && (this.userID != 0)) {
        UserService.getSpecificUser(this.userID).then(
          (response) => {
            this.currUser = response.data
            this.currUser.company_Type_Int = this.getCompanyType(this.currUser.company_Type)
            this.currCompany = {}
            this.currCompany.id = this.currUser.idCompany
            this.currCompany.company = this.currUser.company_Name
            this.currUser.role_Int = this.getUserRole(this.currUser.role)
            this.dataLoaded = true
          },
          (error) => {
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()          
          }
        )
      } else {
        this.message = this.$t('validUserIDException')
      }
    },
    getCompanyType(companyTypeString) {
      if ((companyTypeString) && (companyTypeString != '')) {
        return CompanyType.indexOf(companyTypeString)
      }
    },
    getUserRole(userRoleString) {
      if ((userRoleString) && (userRoleString != '')) {
        return UserRoles.indexOf(userRoleString)
      } else {
        return 0
      }
    },    
    getDistributor(distributorID) {
      if ((distributorID) && (distributorID != 0)) {
        DistributorService.getSingleDistributor(distributorID).then(
          (response) => {
            this.currCompany = response.data
            this.distLookupVisible = false
          },
          (error)  => {
            this.message =           
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validDistributorIDException')
      }
    },
    getCustomer(customerID) {
      if ((customerID) && (customerID != 0)) {
        CustomerService.getSingleCustomer(customerID).then(
          (response) => {
            this.currCompany = response.data
            this.custLookupVisible = false
          },
          (error)  => {
            this.message =           
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validCustomerIDException')
      }
    },
    setModalVisibility() {
      if ((this.distLookupVisible) || (this.custLookupVisible)) {
        this.distLookupVisible = false
        this.custLookupVisible = false
      } else {
        if ((!this.currUser.company_Type_Int) || (this.currUser.company_Type_Int == 0)) {
          this.distLookupVisible = true
        } else {
          this.custLookupVisible = true
        }
      }
    },    
    updateUser() {
      if (!this.currUser.company_Type_Int) {
        this.currUser.company_Type_Int = 0
      }
      UserService.updateSpecificUser(this.userID, { 
        "company_Type": this.currUser.company_Type_Int,
        "idCompany": this.currCompany.id,
        "role": this.currUser.role_Int
      }).then(
        () => {
          this.message = ''
          this.dataLoaded = false
          this.$emit('closeUserAssignmentModal', true)
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        }
      )
    },
    closeUserAssignement() {
      this.message = ''
      this.dataLoaded = false
      this.$emit('closeUserAssignmentModal', false)
    },
    reloadData() {
      this.dataLoaded = false
      this.getUser()
    }            ,
    pretifyName(name) {
      return StringFormatter.pretifyName(this.$t(name))
    }    
  },
  components: {
    DistributorsLookup,
    CustomersLookup,
    DistributorsLookupMobile,
    CustomersLookupMobile
  }
}
</script>