import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_SERVER_BASE_URL + 'perform-machine-operations'

class PerformMachineOperationsService {
    performOperation(operationParams) {
        return axios.post(API_URL, operationParams, { headers: authHeader() })
    }
    // Build other api calls here....   
}

export default new PerformMachineOperationsService()