import axios from 'axios'
import authHeader from './auth-header'
import formatQuery from './query-helper'

const API_URL = process.env.VUE_APP_SERVER_BASE_URL + 'lab-reports'

class LabReportService {
    getLabReports(queryParams) {
        return axios.get(API_URL + formatQuery(queryParams), { headers: authHeader() })
    }
    getSingleLabReport(labReportID) {
        return axios.get(API_URL + '/' + labReportID, { headers: authHeader() })
    }     
    updateLabReport(labReportID, labReportParams) {
        return axios.patch(API_URL + '/' + labReportID, labReportParams, { headers: authHeader() })
    }
    createLabReport(labReportParams) {
        return axios.post(API_URL, labReportParams, { headers: authHeader() })
    }
    deleteLabReport(labReportID) {
        return axios.delete(API_URL + '/' + labReportID, {headers: authHeader() })
    }
    // Build other api calls here....   
}

export default new LabReportService()