<template>
  <CCard fluid>
    <CCardBody>
      <CCardTitle>{{ $t('quickActions') }}</CCardTitle>
      <CCardSubtitle class="mb-2 text-muted">{{ $t('quickActionsSubtitle') }}</CCardSubtitle>
      <div class="d-grid gap-2 d-md-flex justify-content-md-start">
        <CButton type="button" color="dark" shape="rounded-0" id="newMeasurement"
          @click="toggleMachineSelectionVisibility()">{{ $t('newMeasurement') }}</CButton>
        <CButton type="button" color="dark" shape="rounded-0" id="requestAnalysis"
          @click="toggleAnalysisModalVisibility()">{{ $t('requestAnalysis') }}</CButton>                  
        <CButton type="button" color="dark" shape="rounded-0" id="machinesOverview"
          @click="openMachineOverview()">{{ $t('machinesOverview') }}</CButton>           
      </div>    
    </CCardBody>
    <analysis-request
      :cModalVisible="analysisRequestModal"
      @closeAnalysisRequest="refreshAnalysisLists()"/>    

    <machine-selection-modal
      :cModalVisible="machineSelectionModalVisible"
      @closeMachineSelection="onCloseMachineSelection($event)"/>   
  </CCard>
</template>

<script>
import AnalysisRequest from './AnalysisRequest.vue'
import MachineSelectionModal from './MachineSelectionModal.vue'

export default {
  name: 'CustomerQuickActions',
  data() {
    return {
      analysisRequestModal: false,
      machineSelectionModalVisible: false
    }
  },
  methods: {
    toggleAnalysisModalVisibility() {
      this.analysisRequestModal = !this.analysisRequestModal
    },
    onCloseMachineSelection(machineID) {
      this.toggleMachineSelectionVisibility()
      if ((machineID) && (machineID != 0)) {
        if (this.isMobile()) {
          this.$router.push({ name: 'mMeasure', params: { 'machineID': machineID, 'measurementType': 0 } })
        } else {
          this.$router.push({ name: 'Measure', params: { 'machineID': machineID, 'measurementType': 0 } })
        }
      }
    },
    toggleMachineSelectionVisibility() {
      this.machineSelectionModalVisible = !this.machineSelectionModalVisible
    },
    isMobile() {
      let mobile = localStorage.getItem('isMobile')
      return (mobile === 'true')
    },
    refreshAnalysisLists() {
      this.analysisRequestModal = false
      this.$emit('refreshRequestsLists')
    },
    openMachineOverview() {
      this.$router.push({ name: 'MachinesOverview' })
    },         
  },
  components: {
    AnalysisRequest,
    MachineSelectionModal
  }
}
</script>