<template>
  <CSidebar
    position="fixed"
    :unfoldable="allowFolding"
    :visible="true">
    <CSidebarBrand>Strub A.G</CSidebarBrand>
    <CSidebarNav v-if="checkAssignedUser()">
      <li class="nav-title">{{ $t('menu') }}</li>
      <CNavItem href="/machines">
        <CIcon  customClassName="nav-icon" icon="cil-speedometer"/>
        {{ $t('machines') }}
      </CNavItem>
      <CNavItem href="/products" v-if="distributorUser()">
        <CIcon  customClassName="nav-icon" icon="cil-puzzle"/>
        {{ $t('products') }}
      </CNavItem>
      <CNavItem href="/distributors" v-if="distributorUser()">
        <CIcon  customClassName="nav-icon" icon="cil-user"/> {{ $t('distributors') }}
      </CNavItem>
      <CNavItem href="/customers" v-if="distributorUser()">
        <CIcon  customClassName="nav-icon" icon="cil-user"/> {{ $t('customers') }}
      </CNavItem>
      <CNavItem href="/lastmeasurements" v-if="distributorUser()">
        <CIcon  customClassName="nav-icon" icon="cil-bar-chart"/>
        {{ $t('measurements') }}
      </CNavItem>      
      <CNavItem href="/administration" v-if="userWithAdminPermissions()">
        <CIcon  customClassName="nav-icon" icon="cil-settings"/>
        {{ $t('settings') }}
      </CNavItem>     
    </CSidebarNav>
    <CSidebarToggler @click="commuteFoldingState"/>
  </CSidebar>
</template>

<script>
import PermissionManager from '../helpers/permissionManager'

export default {
  name: 'Sidebar',
  data() {
    return {
      allowFolding: true
    }
  },
  methods: {
    commuteFoldingState() {
      this.allowFolding = !this.allowFolding
    },
    userWithAdminPermissions() {
      return PermissionManager.hasAdminPermissions()
    },
    distributorUser() {
      const userCustomer = PermissionManager.getCustomerFilteredVisibility()
      return userCustomer ? false : true      
    },
    checkAssignedUser() {
      return PermissionManager.userIsAssigned()
    }
  }

}
</script>