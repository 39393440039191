<template>
  <CContainer fluid>
    <div class="row">
      <div class="col-12 col-8-tablet push-2-tablet text-center">
        <h3 v-if="dataLoaded" class="title">
          <CIcon icon="cil-arrow-circle-left" size="xxl" @click="backToEditMachine()"/>
            {{ $t('machine') }} {{ currMachine.id }}: {{ currMachine.name }}
        </h3>
      </div>
    </div>
    <br>  
    <filtration-component v-if="filtrationCompVisible"/>
    <operation-component v-if="operationCompVisible"/>
    <material-component v-if="materialCompVisible"/>
    <parameter-component v-if="labReportCompVisible" parameterType="0" />
    <parameter-component v-if="waterAnalysisCompVisible" parameterType="1" />

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert>     
  </CContainer>
</template>

<script>
import MachineService from '../services/machines'
import FiltrationComponent from '../components/FiltrationComponent.vue'
import OperationComponent from '../components/OperationComponent.vue'
import MaterialComponent from '../components/MaterialComponent.vue'
import ParameterComponent from '../components/ParameterComponent.vue'

export default {
  name: 'EditMachineAttribute',
  data() {
    return {
      currMachine: '',
      message: '',
      dataLoaded: false,
      filtrationCompVisible: false,
      operationCompVisible: false,
      materialCompVisible: false,
      labReportCompVisible: false,
      waterAnalysisCompVisible: false
    }
  },
  created() {
    this.getMachineValues()
    this.getComponentsVisibility()
  },
  methods: {
    getMachineValues() {
      if (this.$route.params.idMachine) {
        MachineService.getSingleMachine(this.$route.params.idMachine).then(
          (response) => {
            this.currMachine = response.data
            this.dataLoaded = true
            this.setRouteInfoInLocalStorage()
          },
          (error)  => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      } else {
        this.message = this.$t('validMachineIDException')
      }
    },
    getComponentsVisibility() {
      this.filtrationCompVisible = this.$route.params.which == 'filtrations'
      this.operationCompVisible = this.$route.params.which == 'operations'
      this.materialCompVisible = this.$route.params.which == 'materials'
      this.labReportCompVisible = this.$route.params.which == 'labReports'
      this.waterAnalysisCompVisible = this.$route.params.which == 'waterAnalysis'
    },
    backToEditMachine() {
      this.$router.go(-1)
    },
    setRouteInfoInLocalStorage() {
      if (this.currMachine.id) {
        const params = {
          'idMachine': this.currMachine.id,
          'idProduct': this.currMachine.idProduct,
          'idCustomer': this.currMachine.idCustomer,
          'idDistributor': this.currMachine.idDistributor,
          'measurementidMachine': this.currMachine.id
        }
        localStorage.setItem('routeInfo', JSON.stringify(params))
      }
    },
    isMobile() {
      let mobile = localStorage.getItem('isMobile')
      return (mobile === 'true')
    }    
  },
  components: {
    FiltrationComponent,
    OperationComponent,
    MaterialComponent,
    ParameterComponent
  }
}
</script>