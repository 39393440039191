<template>
  <div>
    <div class="d-flex w-100 justify-content-between">
      <h4 class="mb-1"><strong>{{ machine.name }}{{ machineIDString }}</strong></h4>
    </div>
    <p class="mb-1"><italic>{{ machine.product_Name }}</italic><br v-if="isVerticalMobileMode()">
      <strong>{{ formatDateString(this.machine.last_Filled, spacingCharacter() + pretifyName('machineFields.last_Filled')) }}</strong>
    </p>
    <p class="mb-1" v-if="machine.last_Measurement">
      <span :style="lastMeasuredStyle">{{ formatDateString(machine.last_Measurement.measured_At, $t('lastMeasured')) }}</span>
       - {{ pretifyName('measurementFields.pH') }}: 
        <CAvatar :color="phColorIndicator" size="sm" />
       - {{ pretifyName('measurementFields.eff_Concentration') }}: 
        <CAvatar :color="effConcColorIndicator" size="sm" />
    </p>
    <p class="mb-1" v-else>
      <span style="color: #9f9fa1">{{ $t('noMeasurementsException') }}</span> <CAvatar color="light" size="sm"/>
    </p>    
  </div>
</template>

<script>
import StringFormatter from '../../helpers/stringFormatter'
import DateFormatter from '../../helpers/dateFormatter'

export default {
  name: 'MachineListItemMobile',
  props: {
    machine: Object
  },
  data() {
    return {
      message: '',
      dataLoaded: false
    }
  },
  computed: {
    phColorIndicator() {
      if ((!this.machine.last_Measurement) ||
            (!this.machine.critical_pH)) {
        return "secondary"
      } else {
        return (this.machine.last_Measurement.pH >= Number(this.machine.critical_pH)) ? "success" : "danger" 
      }
    },
    effConcColorIndicator() {
      if ((!this.machine.last_Measurement) ||
            (!this.machine.min_Eff_Concentration) ||
            (!this.machine.max_Eff_Concentration) ||
            ((this.machine.min_Eff_Concentration == 0) && (this.machine.max_Eff_Concentration == 0))) {
        return "secondary"
      } else {
        return ((this.machine.last_Measurement.eff_Concentration >= Number(this.machine.min_Eff_Concentration)) &&
            (this.machine.last_Measurement.eff_Concentration <= Number(this.machine.max_Eff_Concentration))) ? "success" : "danger" 
      }
    },
    machineIDString() {
      if (this.machine.id_Machine) {
        return ' (' + this.machine.id_Machine + ')'
      } else {
        return ''
      }
    },
    lastMeasuredStyle() {
      if (this.machine.last_Measurement) {
        if (DateFormatter.formatDate(this.machine.last_Measurement.measured_At, true) == DateFormatter.formatDate(new Date(), true)) {
          return 'color: #3a8cc7;font-weight:bold' 
        }
      }

      return ''
    }        
  },
  methods: {
    pretifyName(name) {
      return StringFormatter.pretifyName(this.$t(name))
    },
    formatDateString(fromDate, caption) {
      if (fromDate) {
        let formattedDate = DateFormatter.formatDate(fromDate, true)
        if (formattedDate) {
          return (caption + ': ' + String(formattedDate))
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    isVerticalMobileMode() {
      return (screen.width <= 500)
    },
    spacingCharacter() {
      return (this.isVerticalMobileMode() ? '' : ' - ')
    }            
  }  
}
</script>