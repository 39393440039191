<template>
  <CModal backdrop="static" size="xl" :visible="cModalVisible" @show="resetFilters()" @close="closeCustomerSelection()">
    <CModalHeader>
      <CModalTitle>{{ $t('customerSelectionTitle') }}</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <small><CListGroup v-if="customersLoaded">
        <CListGroupItem>
          <CFormInput
            type="text"
            id="customerFilter"
            :placeholder="$t('filterPlaceholder')"
            v-model="customersFilterState" />  
        </CListGroupItem>
        <CListGroupItem v-for="customer in filteredCustomers" :key="customer.id" component="button" @click="confirmAction(customer.id)">
          <div class="d-flex w-100 justify-content-between">
            <h6 class="mb-1"><strong>{{ customer.id }} - {{ customer.company }}</strong></h6>
          </div>
          <p class="mb-1"><italic>{{ customer.address }}</italic></p>
          <p class="mb-1">{{ customer.zipCode }}, {{ customer.city }} ({{ customer.country }})</p>
        </CListGroupItem>
      </CListGroup></small> 
    </CModalBody>   

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </CModal>
</template>

<script>
import CustomerLightService from '../../services/customers-light'
import PermissionManager from '../../helpers/permissionManager'

export default {
  name: 'CustomerSelectionModalMobile',
  props: {
    cModalVisible: Boolean
  },
  data() {
    return {
      message: '',
      customerData: [],
      customersLoaded: false,
      customersFilterState: '',
      customerColumns: [        
        'id',
        'company',
        'address',
        'country',
        'city',
        'zipCode'    
      ]  
    }
  }, 
  computed: {
    filteredCustomers() {
      if (this.customersLoaded) {
        let processedData = [...this.customerData]
        if (this.customersFilterState) {
          const filter = this.customersFilterState.toLowerCase()
          const hasFilter = (item) => String(item).toLowerCase().includes(filter)
          processedData = processedData.filter(item => {
            return this.customerColumns.filter(key => hasFilter(item[key])).length
          })          
        }
        return processedData
      } else {
        return []
      }
    }
  },  
  created() {
    this.initData()
    this.getCustomersGradually()
  },
  methods: {
    initData() {
      this.message = ''
      this.customersLoaded = false
      this.customersFilterState = ''
      this.customerData = []
    },
    resetFilters() {
      this.message = ''
      this.customersFilterState = ''      
    },
    getCustomersGradually(skipParam) {
      if (!skipParam) {
        this.customerData = []
      }      
      let paramQuery = {
        '$limit': 50
      }
      // Manage Permissions first
      if (!(PermissionManager.hasExtendedTechnicianPermissions())) {
        // 1. filter by distributor and agent evt.
        const userDistributor = PermissionManager.getDistributorFilteredVisibility()
        if (userDistributor) {
          paramQuery.idDistributor = userDistributor.idDistributor
        }
        const userAgent = PermissionManager.getAgentFilteredVisibility()
        if (userAgent) {
          paramQuery.idAgent = userAgent.idAgent
        }        
      }       
      // Get active only from local storage
      let activeOnly = localStorage.getItem('showOnlyActiveRecords')
      if (activeOnly === 'true') {
        paramQuery.disabled = false
      }
      if (skipParam) {
        paramQuery['$skip'] = skipParam
      }
      CustomerLightService.getCustomers(paramQuery).then(
        (response) => {
          if (this.customerData.length > 0) {
            this.customerData = this.customerData.concat(response.data.data)
          } else {
            this.customerData = response.data.data
          }
          this.customersLoaded = true
          if (response.data.total > response.data.limit + response.data.skip) {
            if (skipParam) {
              skipParam += response.data.limit
            } else {
              skipParam = response.data.limit
            }
            this.getCustomersGradually(skipParam)
          }
        },
        (error)  => {
          this.message =           
           (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }        
      )
    },   
    confirmAction(selectedCustomerID) {
      if ((!selectedCustomerID) || (selectedCustomerID == 0)) {
        this.message = this.$t('validCustomerIDException')
      } else {
        this.$emit('confirmCustomerSelection', selectedCustomerID)
      }
    },
    closeCustomerSelection() {
      this.$emit('cancelCustomerSelection')
    }         
  }
}
</script>