<template>
  <CCard fluid>
    <CCardBody>
      <CCardTitle>{{ $t('adminCenterTitle') }}</CCardTitle>
      <CRow>
        <CCol :xs="auto">
          <CWidgetStatsF color="info" :title="$t('userPermissions')">
            <template #icon>
              <CIcon icon="cil-people" size="xl"/>
            </template>
            <template #footer>
              <a class="nav-link" @click="editUsers()"> {{ $t('edit') }} </a>
            </template>  
          </CWidgetStatsF>       
        </CCol>
        <CCol :xs="auto">
          <CWidgetStatsF color="primary" :title="$t('customerAgentAssignment')">
            <template #icon>
              <CIcon icon="cil-user-plus" size="xl"/>
            </template>
            <template #footer>
              <a class="nav-link" @click="editCustomerAgentAssignment()"> {{ $t('edit') }} </a>
            </template>  
          </CWidgetStatsF>       
        </CCol>        
        <CCol :xs="auto">
          <CWidgetStatsF color="warning" :title="$t('productTechnologies')">
            <template #icon>
              <CIcon icon="cil-eyedropper" size="xl"/>
            </template>
            <template #footer>
              <a class="nav-link" @click="editTechnologies()"> {{ $t('edit') }} </a>
            </template>  
          </CWidgetStatsF>       
        </CCol>        
      </CRow> 
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: 'AdminSetupQuickActions',
  methods: {
    editUsers() {
      this.$router.push('/users')
    },
    editTechnologies() {
      this.$router.push('/technologies')
    },
    editCustomerAgentAssignment() {
      this.$router.push('/agents')
    }
  }
}
</script>