export default {
  "quickActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni rapide"])},
  "quickActionsSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le azioni rapide possono variare in relazione al ruolo dell'utente"])},
  "newMeasurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova misurazione"])},
  "requestAnalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta Analisi"])},
  "uselessButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulsante inutile"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento in corso..."])},
  "loadingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento dati"])},
  "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricato"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovere"])},
  "adminCenterTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centro di amministrazione"])},
  "userPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizzazioni utente"])},
  "productTechnologies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tecnologie prodotti"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificare"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizzazione"])},
  "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processare"])},
  "analysisRequestTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta di analisi"])},
  "analysisRequestSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare il tipo di richiesta e la macchina in cui eseguire l'analisi"])},
  "requestType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di richiesta"])},
  "requestTypeOpt0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misurazione (generale)"])},
  "requestTypeOpt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporto di laboratorio"])},
  "requestTypeOpt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi delle acque"])},
  "selectMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona Macchina"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricerca..."])},
  "measurementAttachmentDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allega misurazione per specificare il motivo per cui è necessaria l'analisi"])},
  "measurementAttachmentMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allega misurazione"])},
  "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare nuovo"])},
  "linkExisting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collegare esistente"])},
  "notificationFlag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvisami quando la richiesta viene gestita"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudere"])},
  "createRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea richiesta"])},
  "selectMeasurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona Misura"])},
  "noMeasurementLinkedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna misura attualmente collegata..."])},
  "measurementLinkedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misura correttamente collegata"])},
  "measurementConfirmTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifica Misura mancante"])},
  "measurementConfirmMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stai per inviare una richiesta di analisi senza misura... Continua?"])},
  "noOfMeasurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr. di misure"])},
  "viewMeasurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza misurazioni"])},
  "machineSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezione della macchina"])},
  "goToMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai alla macchina"])},
  "customize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["personalizzare"])},
  "assignedFiltrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrazioni assegnate"])},
  "assignedMaterials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materiali assegnati"])},
  "assignedOperations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operazioni assegnate"])},
  "assignedLabReportParam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametri del rapporto di laboratorio assegnati"])},
  "assignedWaterAnalysisParam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametri di analisi dell'acqua assegnati"])},
  "assignedPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizzazioni assegnate"])},
  "labReportParam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporti laboratorio"])},
  "waterAnalysisParam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi acque"])},
  "editProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica prodotto"])},
  "editDistributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica distributore"])},
  "editCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica cliente"])},
  "distributorLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posizione del distributore"])},
  "customerLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posizione del cliente"])},
  "receivedMeasurementAsAttachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevuta la misura come allegato"])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro:"])},
  "filterPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["digitare testo da ricercare..."])},
  "itemsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementi per pagina:"])},
  "pageContentInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sul contenuto della pagina (Ricerche)"])},
  "toggleInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle Informazioni sulla pagina"])},
  "verifyTokenMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grazie per aver verificato il tuo account. Vai alla pagina di accesso per continuare"])},
  "verifyingAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica dell'account... per favore attendi..."])},
  "unableToVerifyAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile verificare l'account. Si prega di contattare l'assistenza."])},
  "openAnalysisRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richieste di analisi aperte"])},
  "openAnalysisDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi in entrata richieste ai distributori, fare clic sul pulsante Visualizza tutto per visualizzare anche quelle completate"])},
  "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adesso"])},
  "distributorOverwiev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panoramica del distributore"])},
  "analysisToPerform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi in entrata da eseguire richieste dai Clienti"])},
  "analysisForwardedToMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richieste inoltrate da gestire"])},
  "analysisForwardedByMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richieste inoltrate"])},
  "analysisForwardedToMeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi da gestire, inoltrate da altri distributori"])},
  "analysisForwardedByMeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi inoltrate ad un altro distributore (definito nella scheda distributore)"])},
  "handleAnalysisRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestire la richiesta di analisi"])},
  "handleAnalysisRequestDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna analisi è stata ancora creata per gestire questa richiesta... Per procedere con la creazione, confermare/modificare l'analisi da eseguire"])},
  "handleRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestire la richiesta"])},
  "assignedFiltrationsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo elenco contiene tutti i filtri già assegnati..."])},
  "assignedFiltrationsSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è possibile vedere/modificare il commento relativo all'incarico cliccando sul pulsante e rimuovere l'incarico cliccando sulla 'X'"])},
  "availableFiltrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri disponibili"])},
  "availableFiltrationsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le restanti filtrazioni disponibili sono elencate qui"])},
  "assignedMaterialsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa lista contiene tutti i Materiali già assegnati..."])},
  "assignedMaterialsSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è possibile modificare i campi relativi all'incarico cliccando sul pulsante 'Aggiorna' e rimuovere l'incarico cliccando sulla 'X'"])},
  "availableMaterials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materiali disponibili"])},
  "availableMaterialsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i restanti materiali disponibili sono elencati qui"])},
  "assignedOperationsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo elenco contiene tutte le Operazioni già assegnate..."])},
  "assignedOperationsSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è possibile rimuovere l'incarico cliccando sulla 'X'"])},
  "availableOperations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operazioni disponibili"])},
  "availableOperationsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le restanti operazioni disponibili sono elencate qui"])},
  "assignedParametersTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa lista contiene tutti i parametri già assegnati..."])},
  "assignedParametersSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è possibile modificare i campi relativi all'incarico cliccando sul pulsante 'Aggiorna' e rimuovere l'incarico cliccando sulla 'X'"])},
  "availableParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametri disponibili"])},
  "availableParametersDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i restanti parametri disponibili sono elencati qui"])},
  "assignedPermissionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo elenco contiene tutte le autorizzazioni già assegnate..."])},
  "assignedPermissionsSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è possibile rimuovere l'incarico cliccando sulla 'X'"])},
  "availablePermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizzazioni disponibili"])},
  "availablePermissionsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le restanti autorizzazioni disponibili sono elencate qui"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
  "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo e-mail"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "newLabReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo rapporto di laboratorio"])},
  "newWaterAnalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova analisi dell'acqua"])},
  "inputMeasuredValues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immettere i valori misurati"])},
  "confirmMeasurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma la misurazione"])},
  "technologyLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linea tecnologica"])},
  "technologyLineDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il tipo di riga da creare (intestazione o riga valore)"])},
  "lineType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modello di linea"])},
  "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intestazione"])},
  "line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linea"])},
  "lineValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore di riga"])},
  "calculatedField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo calcolato..."])},
  "noFormulaDefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna formula definita"])},
  "formulaEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor di formule"])},
  "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula"])},
  "formulaEditorInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In questo editor è possibile digitare la Formula desiderata... Le operazioni disponibili sono quelle standard, quindi (+, -, *, /) È anche possibile raggruppare le operazioni utilizzando le parentesi () Utilizzare gli identificatori dei parametri per specificare un parametro (A-Z)"])},
  "formulaEditorAvailableParamDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo elenco contiene tutti i parametri disponibili, consultarli tramite identificatore (A-Z)"])},
  "productTechnologyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definisci il layout tecnologico"])},
  "confirmUserMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlla la tua e-mail per confermare l'utente"])},
  "uploadPictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica immagini"])},
  "dropPicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lasciali qui"])},
  "dropPicture2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per aggiungerli"])},
  "dragFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trascina qui i tuoi file"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o"])},
  "clickHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clicca qui"])},
  "toSelectFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per selezionare i file"])},
  "userAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnazione utente"])},
  "linkToCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collegamento all'azienda"])},
  "updateUserData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna dati utente"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benvenuto"])},
  "createNewCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea nuovo cliente"])},
  "createNewDistributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea un nuovo distributore"])},
  "createNewProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea nuovo prodotto"])},
  "createNewMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea nuova macchina"])},
  "finalizeReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizzare il rapporto"])},
  "mainData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati principali"])},
  "inputParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametri (Ingresso)"])},
  "inputParameterDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci i parametri principali e poi clicca su Calcola..."])},
  "calculatedParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametri (calcolati)"])},
  "calculatedParametersDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valori calcolati... Modificabili, ma anche calcolabili"])},
  "updateReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna rapporto"])},
  "updateReportBaseData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna dati principali"])},
  "machineDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli macchina"])},
  "updateMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna macchina"])},
  "machineContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenuto della macchina"])},
  "emptyMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svuota macchina"])},
  "refillMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricarica macchina"])},
  "changeProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia prodotto"])},
  "showMachineHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra cronologia macchina"])},
  "analysisRequestedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi richiesta da"])},
  "remainingQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità rimanente"])},
  "refillQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità di ricarica"])},
  "initQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità iniziale"])},
  "insertProductRefillQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci la quantità di ricarica del prodotto"])},
  "insertProductInitQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci la quantità iniziale del prodotto"])},
  "insertMeasuredContainedQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserire la quantità contenuta misurata prima della ricarica"])},
  "insertRemainingQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserire la quantità rimanente del Prodotto precedente che verrà svuotato"])},
  "insertQuantityToRefill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci la quantità da Ricaricare"])},
  "insertQuantityToInit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci la Quantità Iniziale del nuovo prodotto"])},
  "downloadReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica Rapporto"])},
  "downloadRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica richiesta"])},
  "labReportInfoboxInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I valori in questo elenco contengono dati relativi alla macchina corrente... I valori del singolo Report potrebbero differire, aprire un singolo report per avere un focus più specifico"])},
  "phValuesVariation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variazione dei valori di PH"])},
  "effConcentrationVariation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eff. Variazione di concentrazione"])},
  "valuesFilteredByProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I valori sono filtrati dal Prodotto attualmente nella Macchina"])},
  "phValues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valori PH"])},
  "effConcentration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eff. Concentrazione"])},
  "waitingForPermissionAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In attesa di assegnazione delle autorizzazioni..."])},
  "activeCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clienti attivi"])},
  "activeProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodotti attivi"])},
  "activeMachines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macchine attive"])},
  "currWorkingMachines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macchine attualmente in funzione"])},
  "activeCustomersMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clienti"])},
  "activeProductsMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodotti"])},
  "activeMachinesMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macchine"])},
  "currWorkingMachinesMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macch. Att."])},
  "sample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campione"])},
  "parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametro"])},
  "tolerance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tolleranze"])},
  "valueUnitOfMeasure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore (unità di misura)"])},
  "recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raccomandazioni per il trattamento"])},
  "phEffConcGraphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafici raffiguranti pH e concentrazione effettiva"])},
  "waterAnalysisGraphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafici (pH, durezza, cloruro, solfato)"])},
  "resultIndicatorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risultato visivo del report richiesto"])},
  "customerAgentAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnazione Clienti Agenti"])},
  "updateAssignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna assegnazioni"])},
  "removeAssigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi assegnati selezionati"])},
  "unassignedCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clienti non assegnati"])},
  "unassignedCustomersDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In questa lista sono visualizzati tutti i clienti non assegnati"])},
  "agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenti"])},
  "agentsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gli agenti disponibili sono visualizzati qui..."])},
  "assignedCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clienti assegnati"])},
  "assignedCustomersDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In questa lista sono visualizzati tutti i clienti assegnati (all'agente)"])},
  "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non assegnato"])},
  "lastLabReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo rapporto creato"])},
  "adminMobileDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nella versione mobile, l'unica impostazione accessibile è l'assegnazione utenti. Per le altre impostazioni, consultare la version desktop!"])},
  "globalSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni generali"])},
  "onlyActiveRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra solo record attivi"])},
  "cannotRenderGraphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I grafici non possono essere renderizzati in versione mobile."])},
  "displayFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra filtri avanzati"])},
  "machinesFilterSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricerca rapida macchine"])},
  "machinesOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panoramica macchine"])},
  "machinesOverviewDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare il cliente desiderato per visualizzarne le macchine"])},
  "customerSelectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SELEZIONE CLIENTE"])},
  "measuredMachinesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MACCHINE MISURATE"])},
  "availableMachinesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MACCHINE DISPONIBILI"])},
  "switchToDesktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passa a Versione Desktop"])},
  "switchToMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passa a Versione Mobile"])},
  "customerSessionActiveDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una sessione cliente è attualmente attiva: "])},
  "startCustomerSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvia sessione cliente"])},
  "stopCustomerSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ferma sessione cliente"])},
  "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generale"])},
  "specifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifiche"])},
  "lastMeasured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultima misurazione"])},
  "forgotThePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password dimenticata?"])},
  "sendPasswordResetTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripristino password"])},
  "sendPasswordResetDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserire il proprio indirizzo email per ricevere il link di ripristino"])},
  "sendPasswordResetButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia Link"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova password"])},
  "newPasswordCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma password"])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripristina password"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaggio"])},
  "agentSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia agente"])},
  "sendLabReportEmailToCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia rapport di laboratorio via E-Mail"])},
  "customerEmailSelectionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un destinatario della lista proposta. In caso di selezione vuota, l'agente responsabile sarà il destinatario delle E-Mail"])},
  "analysisFloatingOilPhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fase oleosa nella vasca di emulsione"])},
  "labReportFloatingOilPhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fase oleosa nel campione di emulsione"])},
  "__MENUS__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menus"])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iscriviti"])},
  "__BUTTONS__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buttons"])},
  "pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immagini"])},
  "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascondere"])},
  "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espandere"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
  "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegliere"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare"])},
  "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permessi"])},
  "showComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra commento..."])},
  "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvare le modifiche"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiornare"])},
  "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta"])},
  "createLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea linea"])},
  "editFormula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica formula"])},
  "editDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica i dettagli"])},
  "updateFormula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna formula"])},
  "createNewLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea una nuova linea"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento"])},
  "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcolare"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificare..."])},
  "processChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifiche al processo"])},
  "viewAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra tutto"])},
  "reopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riapri"])},
  "replyAndClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rispondi e chiudi"])},
  "forwardRequestBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inoltra richiesta"])},
  "confirmAndNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma e nuovo"])},
  "updateContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna contenuto"])},
  "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanzate..."])},
  "viewCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richieste cliente"])},
  "confirmAndRequestLabReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedi rapporto laboratorio"])},
  "closeRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi richiesta"])},
  "__TABLE_NAMES__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table Names"])},
  "machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macchina"])},
  "machines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macchine"])},
  "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodotto"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodotti"])},
  "distributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributore"])},
  "distributors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributori"])},
  "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente"])},
  "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clienti"])},
  "labReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relazione di laboratorio"])},
  "labReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporti di laboratorio"])},
  "waterAnalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi dell'acqua"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti"])},
  "measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misurazione"])},
  "measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misure"])},
  "analysisRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta di analisi"])},
  "analysisRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richieste di analisi"])},
  "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persone"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])},
  "__TABLE_FIELDS__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table Fields"])},
  "machineFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nome"])},
    "machine_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID macchina"])},
    "distributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["distributore"])},
    "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cliente"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodotto"])},
    "product_Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contenuto prodotto"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stato"])},
    "critical_pH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pH critico"])},
    "sump_Size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensione della coppa"])},
    "water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["acqua"])},
    "min_Eff_Conc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min. Eff. Concentrazione"])},
    "max_Eff_Conc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. Eff. Concentrazione"])},
    "last_Filled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ultimo riempito"])},
    "unknown_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data sconosciuta"])},
    "creation_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data di creazione"])},
    "created_By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["creato da"])},
    "central_Plant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pianta centrale"])},
    "central_Machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macchina centrale"])},
    "min-max_Eff_Conc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min/max eff. Concentrazione"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contenuto (LT)"])}
  },
  "productFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nome"])},
    "fluid_Supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fornitore di fluidi"])},
    "refractometer_Factor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fattore rifrattometrico"])},
    "critical_pH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pH critico"])},
    "technology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tecnologia"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stato"])},
    "product_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numero del prodotto"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["creato il"])},
    "created_By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["creato da"])}
  },
  "customerFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nome"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indirizzo"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nazione"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["città"])},
    "phone_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["telefono n."])},
    "client_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAV Nr."])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabilitato"])},
    "contact_Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["referente"])},
    "distributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["distributore"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAP"])},
    "creation_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data di creazione"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lingua"])},
    "agent_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome agente"])}
  },
  "distributorFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nome"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indirizzo"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nazione"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["città"])},
    "phone_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["telefono nr."])},
    "client_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAV Nr."])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabilitato"])},
    "contact_Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["referente"])},
    "active_Machines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macchine attive"])},
    "inactive_Machines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macchine inattive"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAP"])},
    "creation_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data di creazione"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lingua"])},
    "analysis_Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email notifica analisi"])},
    "handle_Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestisci rapporti"])},
    "forward_To_Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inoltra richieste a"])}
  },
  "measurementFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["macchina"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodotto"])},
    "measured_By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mis. da"])},
    "pH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pH"])},
    "refrac_Reading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lettura refr."])},
    "eff_Concentration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conc. Eff."])},
    "measured_At": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mis. il"])},
    "distributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["distributore"])},
    "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cliente"])},
    "appareance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apparenza"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commento"])},
    "last_Filled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ultimo riempimento"])},
    "creation_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data di creazione"])},
    "created_By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["creato da"])},
    "refrac_Factor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fattore refrattometro"])},
    "image_Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numero immagini"])},
    "id_Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD rapporto"])},
    "temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["temperatura"])},
    "water_Hardness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["durezza acqua"])}
  },
  "labReportFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "sample_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["campione nr."])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodotto"])},
    "requested_By_Customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["richiesto dal cliente"])},
    "date_Ordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ordinato il"])},
    "reason_Of_Analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["motivo dell'analisi"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stato"])},
    "batch_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lotto nr."])},
    "date_Of_Analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data analisi"])},
    "completed_At": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["completato il"])},
    "appearance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aspetto"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deposito"])},
    "smell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["odore"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commento"])},
    "cleanness_Of_Workstation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pulizia della postazione di lavoro"])},
    "floating_Oil_Phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fase olio galleggiante"])},
    "measured_By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["misurato da"])},
    "appearance_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aspetto"])}
  },
  "characteristics": {
    "Water hardness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durezza dell'acqua"])},
    "pH electrometric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pH elettrometrico"])},
    "Conductivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conducibilità mS/cm"])},
    "Nitrite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nitrito"])},
    "Nitrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nitrato"])},
    "Chloride": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloruro"])},
    "Sulfate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solfato"])},
    "Bacteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["batteri"])},
    "Funghi / yeast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funghi/lievito"])},
    "Tramp Oil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olio residuo"])},
    "Effective Concentration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eff. Concentrazione"])},
    "Refractometer Reading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rif. Lettura"])},
    "pH electrometric (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pH elettrometrico"])},
    "Conductivity (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conducibilità "])},
    "Nitrite (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nitrito"])},
    "Nitrate (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nitrato"])},
    "Chloride (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloruro"])},
    "Sulfate (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solfato"])},
    "Bacteria (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batteri"])},
    "Funghi / yeast (Lab Report)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funghi / lievito"])},
    "Total hardness emulsion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emulsione a durezza totale"])},
    "Deposit of wear 5 um": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposito usura 5 um"])},
    "Corrosion degree DIN 51360": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grado di corrosione DIN 51360"])},
    "Product Specific Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore specifico del prodotto"])},
    "Concentration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concentrazione"])},
    "Mixed Phase acid split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scissione acida in fase mista"])},
    "Mineral Oil Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenuto di olio minerale"])},
    "Titration Emulsion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emulsione di titolazione"])},
    "Correction Factor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fattore di correzione"])},
    "Concentration acid split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concentrazione scissione acida"])},
    "Product specific value alkalinity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore di alcalinità specifico del prodotto"])},
    "Product specific value acid split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Split acido del valore specifico del prodotto"])},
    "Density": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Densità"])},
    "Corrosion test on steel plate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test corrosione su lamiera d'acciaio"])}
  },
  "filtrations": {
    "Band filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro a banda"])},
    "Centrifuge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrifuga"])},
    "Chip conveyor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convogliatore di truccioli"])},
    "Magnet filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro magnetico"])},
    "No filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senza filtro"])},
    "Other filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altri filtri"])},
    "Paper filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro di carta"])},
    "Skimmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scrematrice"])}
  },
  "materials": {
    "Aluminium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alluminio"])},
    "Brass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottone"])},
    "Bronze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bronzo"])},
    "Cast Iron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghisa"])},
    "Copper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rame"])},
    "Duplex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplex"])},
    "Hastelloy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hastelloy"])},
    "Inconel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inconel"])},
    "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
    "Stainless Steel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciaio inossidabile"])},
    "Steel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciaio"])},
    "Super duplex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super duplex"])},
    "Titanium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titanio"])}
  },
  "materialFields": {
    "specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifiche"])},
    "tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attrezzo"])},
    "part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parte"])}
  },
  "operations": {
    "Broaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broaching"])},
    "Deep Hole Drilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deep hole drilling"])},
    "Drilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drilling"])},
    "Forming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formaing"])},
    "Grinding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grinding"])},
    "Milling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milling"])},
    "Reaming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reaming"])},
    "Sawing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sawing"])},
    "Tapping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapping"])},
    "Thread cutting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thread cutting"])},
    "Turning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turning"])},
    "Neat Cutting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neat Cutting"])},
    "Hobbing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hobbing"])},
    "Honing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honing"])},
    "Drawing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drawing"])},
    "Forming2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forming 2"])},
    "Rolling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolling"])},
    "Stamping Fine Blanking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stamping Fine Blanking"])},
    "Lapping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lapping"])},
    "Roughing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roughing"])},
    "Electronig Discharge Machining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronig Discharge Machining"])},
    "Deep Hole Drilling Thread Cutting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deep Hole Drilling Thread Cutting"])},
    "Quenching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quenching"])}
  },
  "machineEntryFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "entry_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di movimento"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quantità"])},
    "idProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numero identificativo del prodotto"])},
    "product_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nome del prodotto"])},
    "execution_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data di esecuzione"])},
    "machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["macchina"])}
  },
  "analysisRequestFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "request_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di richiesta"])},
    "requested_By_User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["richiesto dall'utente"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stato"])},
    "product_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nome del prodotto"])},
    "machine_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nome della macchina"])},
    "handled_By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gestito da"])},
    "creation_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data di creazione"])},
    "forwarder_By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inoltrato da"])},
    "forward_Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato inoltro"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commento"])}
  },
  "userFields": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iD"])},
    "first_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nome"])},
    "last_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cognome"])},
    "phone_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numero di telefono"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail"])},
    "linked_To": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["collegato a"])},
    "company_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome della ditta"])},
    "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["autorizzazione"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lingua"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ruolo"])}
  },
  "parameterFields": {
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unità"])},
    "tolerance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tolleranza"])},
    "init_Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valore iniziale"])},
    "calc_Formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["formula calcolo"])}
  },
  "statusOptions": {
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inattivo"])},
    "under_Mantainance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Manutenzione"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo"])},
    "analysing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In fase di analisi"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiuso"])}
  },
  "languageOptions": {
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglese"])},
    "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tedesco"])},
    "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italiano"])},
    "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francese"])}
  },
  "userRolesOptions": {
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente"])},
    "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agente"])}
  },
  "waterOptions": {
    "soft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soft (sotto i 5°dH)"])},
    "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media (5-15°dH)"])},
    "hard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dura (sopra i 15°dH)"])},
    "demineralised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demineralizzata"])},
    "ostmotic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osmotica"])},
    "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acqua di processo"])}
  },
  "__TABLE_COMMON_FIELDS__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table Common Fields"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
  "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabilitato"])},
  "forwardStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato inoltro"])},
  "__EXCEPTIONS__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceptions"])},
  "validMachineException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È necessario scegliere una macchina valida per creare la richiesta"])},
  "validMachineIDException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È necessario fornire un ID macchina valido per continuare!"])},
  "validMachineSelectionException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona prima una macchina valida!"])},
  "validProductException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per continuare è necessario scegliere un prodotto valido"])},
  "validProductIDException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È necessario fornire un ID prodotto valido per continuare!"])},
  "validProductSelectionException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona prima un prodotto valido!"])},
  "validDistributorException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per continuare è necessario scegliere un distributore valido"])},
  "validDistributorIDException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È necessario fornire un ID distributore valido per continuare!"])},
  "validDistributorSelectionException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona prima un distributore valido!"])},
  "validCustomerException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per continuare è necessario scegliere un cliente valido"])},
  "validCustomerIDException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per continuare è necessario fornire un ID cliente valido!"])},
  "validCustomerSelectionException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona prima un cliente valido!"])},
  "noInfosAvailableException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna informazione disponibile per questa pagina..."])},
  "validMachineAndFiltrationException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per eseguire questa funzione è necessario fornire un ID macchina e un ID di filtrazione validi!"])},
  "validMachineAndMaterialException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per eseguire questa funzione è necessario fornire un ID macchina e un ID materiale validi!"])},
  "validMachineAndOperationException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per eseguire questa funzione è necessario fornire un ID macchina e un ID operazione validi!"])},
  "validMachineAndParameterException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per eseguire questa funzione è necessario fornire un ID macchina e un ID parametro validi!"])},
  "missingParameterException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile valutare le formule causa parametro mancante..."])},
  "validUserIDException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID utente non specificato!! Impossibile caricare la pagina richiesta..."])},
  "validUserAndPermissionException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per eseguire questa funzione è necessario fornire un ID utente e un ID di autorizzazione validi!"])},
  "nonExistentCustomerException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile eliminare un cliente inesistente!!"])},
  "nonExistentDistributorException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile eliminare un distributore inesistente!!"])},
  "nonExistentMachineException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile eliminare una macchina inesistente!!"])},
  "nonExistentProductException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile eliminare un prodotto inesistente!!"])},
  "labReportLoadException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile caricare il rapporto di laboratorio"])},
  "unknownMachineLabReportException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è possibile raccogliere rapporti di laboratorio senza conoscere la Macchina"])},
  "unknownReportTypeException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile raccogliere il parametro del rapporto di laboratorio perché il tipo di rapporto di laboratorio è mancante!!"])},
  "undefinedReportException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile passare a un rapporto non definito"])},
  "missingParametersException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non abbastanza parametri specificati!"])},
  "unknownUserPermissionException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile accedere al permesso di un utente inesistente!!"])},
  "unknownUserAssignmentsException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile accedere alle assegnazioni di un utente inesistente!!"])},
  "emptyCommentException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile finalizzare il report senza un commento... Inserire il commento per proseguire!"])},
  "validAnalysisRequestIDException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È necessario fornire un ID richiesta valido per continuare!"])},
  "limitedPermissionsException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per precedere con quest'azione è richiesto un livello di permessi superiore."])},
  "emptyForwardingRequestException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile inoltrare una richiesta vuota"])},
  "emptyRequestReplyException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile rispondere ad una richiesta vuota o senza un messaggio di testo!"])},
  "associatedMachineException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile eliminare il record selezionato, poiché è associato almento ad una macchina... Rimuovere prima i riferimenti!!"])},
  "alreadyAssignedLabReportException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un rapporto di laboratorio è già stato creato per questa misurazione!!"])},
  "emptyEmailException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo Mail non può essere vuoto!"])},
  "emptyPasswordException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password non può essere vuota!"])},
  "checkPasswordMismatchException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password di conferma non corrisponde alla nuova password inserita!"])},
  "changePasswordException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore interno, impossibile procedere con il cambio password..."])},
  "noMeasurementsException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna misurazione effettuata per questa macchina..."])},
  "__TOASTS__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toasts"])},
  "requestStatusNotCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo stato della richiesta non è completato!!"])},
  "cannotAccessDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile accedere ai dettagli della richiesta..."])},
  "noAttachmentProvided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun allegato fornito!"])},
  "noAttachmentProvidedDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La richiesta non è stata soddisfatta o è stata richiesta una misurazione"])},
  "dataSuccesfullyUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati aggiornati con successo"])},
  "redirectingToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reindirizzamento alla home page..."])},
  "reportSuccesfullyUpdated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Segnala con successo ", _interpolate(_named("rep")), ". Reindirizzamento......"])},
  "reportBaseDataSuccesfullyUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati principali rapporto aggiornati correttamente!"])},
  "reportClosedMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Impossibile modificare ", _interpolate(_named("rep")), " perché lo stato è già chiuso!!!"])},
  "reportOpenMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("rep")), " non è chiuso, è possibile modificare il rapporto senza alcuna operazione aggiuntiva!!"])},
  "redirectingToList": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Reindirizzamento a ", _interpolate(_named("list")), "..."])},
  "measurementSuccesfullyCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misurazione Creata con successo!!"])},
  "labReportSuccesfullyRequested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporto di laboratorio Richiesto con successo"])},
  "waterAnalysisSuccesfullyRequested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi dell'acqua richiesta con successo"])},
  "forwardedRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'analisi richiesta è stata inoltrata"])},
  "forwardedRequestDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sarà nuovamente disponibile quando il flusso di inoltro sarà terminato..."])},
  "__CONFIRMATION__": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
  "confirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])},
  "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
  "sendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia e-mail"])},
  "doNotSendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non inviare e-mail"])},
  "continueTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi continuare?"])},
  "productTechnologyWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stai per eliminare la tabella e ricreare tutti i valori..."])},
  "customerDeletionWarning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Stai per eliminare un cliente (", _interpolate(_named("cust")), ")…"])},
  "distributorDeletionWarning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Stai per eliminare un distributore (", _interpolate(_named("dist")), ")…"])},
  "machineDeletionWarning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Stai per eliminare una macchina (", _interpolate(_named("machine")), ")..."])},
  "productDeletionWarning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Stai per eliminare un prodotto (", _interpolate(_named("prod")), ")..."])},
  "sendEmailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviare un'e-mail al cliente richiedente?"])},
  "sendEmailDetails": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A questo Report è collegata un'analisi della richiesta... Vuoi inviare all'Utente ", _interpolate(_named("usr")), " una notifica via E-Mail?"])},
  "emptyingMachineWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stai per svuotare la macchina..."])},
  "customerAgentAssigmentWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verranno effettuate le seguenti modifiche: "])},
  "eccessiveProductQuantityWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La quantità specificata supera il massimo consentito dalla macchina"])},
  "closeForwardedRequestTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi la richiesta rispondendo al cliente"])},
  "closeForwardedRequestDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci il messaggio da inviare al cliente e clicca sul pulsante rispondi per chiudere la richiesta..."])},
  "resetEmailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il link di ripristino è stato inviato alla mail specificata."])},
  "passwordSuccesfullyUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password aggiornata correttamente!"])},
  "labReportDeletionWarning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Stai per eliminare un rapporto di laboratorio (", _interpolate(_named("labReport")), "), Vuoi proseguire?"])}
}