<template>
  <CContainer fluid>
    <div class="row">
      <div class="col-12 col-8-tablet push-2-tablet text-center">
        <CIcon size="xxl" icon="cil-image-broken"/>
        <h1 class="title">404</h1>
        <h4>The requested page was not found</h4>
      </div>
    </div>    
  </CContainer>
</template>

<script>
export default {
  name: 'NotFoundComponent'
}
</script>