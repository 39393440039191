<template>
  <div class="about" >
    <div v-if="successful">
        <h1>{{ $t('verifyTokenMessage') }}</h1>
    </div>
    <div v-else>
        <h1>{{ $t('verifyingAccount') }}</h1>
    </div>
    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
        <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
        <div>
            {{ message }}
        </div>
    </CAlert> 
  </div>
</template>

<script>
export default {
  name: "Verify",
  data() {
      return {
        message: '',
        successful: false
      }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    },
  },
  mounted() {
    this.verifyAccount()
  },
  methods: {
    verifyAccount() {
      let token = this.$route.query.token
      this.loading = true;
      this.$store.dispatch("auth/confirmUser", token).then(
        () => {
          this.successful = true
        },
        () => {
          this.message = this.$t('unableToVerifyAccount')
          this.successful = false
        }
      )
    },
  },
}
</script>