<template>
  <CCard fluid>
    <CCardBody>
      <CCardTitle>{{ $t('distributorOverwiev') }}</CCardTitle>
      <CRow>
        <CCol :xs="auto">
          <CWidgetStatsC v-if="activeCustomersLoaded" class="mb-3" :value="activeCustomers.total" inverse color="primary" :title="$t('activeCustomers')">
            <template #icon><CIcon icon="cil-people" height="36"/></template>
          </CWidgetStatsC>      
        </CCol>
        <CCol :xs="auto">
          <CWidgetStatsC v-if="activeProductsLoaded" class="mb-3" :value="activeProducts.total" inverse color="info" :title="$t('activeProducts')">
            <template #icon><CIcon icon="cil-puzzle" height="36"/></template>
          </CWidgetStatsC>       
        </CCol>
        <CCol :xs="auto">
          <CWidgetStatsC v-if="activeMachinesLoaded" class="mb-3" :value="activeMachines.total" inverse color="warning" :title="$t('activeMachines')">
            <template #icon><CIcon icon="cil-speedometer" height="36"/></template>
          </CWidgetStatsC>        
        </CCol>
        <CCol :xs="auto">
          <CWidgetStatsC v-if="workingMachinesLoaded" class="mb-3" :value="workingMachines.total" inverse color="danger" :title="$t('currWorkingMachines')">
            <template #icon><CIcon icon="cil-factory" height="36"/></template>
          </CWidgetStatsC>        
        </CCol>
      </CRow> 
    </CCardBody>
  </CCard>
</template>

<script>
import ProductLightService from '../services/products-light'
import CustomerLightService from '../services/customers-light'
import MachineLightService from '../services/machines-light'

export default {
  name: 'DistributorAdminWidget',
  data() {
    return {
      activeProductsLoaded: false,
      activeProducts: '',
      activeCustomersLoaded: false,
      activeCustomers: '',
      activeMachinesLoaded: false,
      activeMachines: '',
      workingMachinesLoaded: false,
      workingMachines: ''
    }
  },
  props: {
    distributorID: Number
  },
  created() {
    this.refreshData()
  },
  methods: {
    getActiveProducts() {
      let productParams = {}
      if (this.distributorID != 0) {
        productParams.idFluid_Supplier = this.distributorID
      }
      productParams.status = 0
      ProductLightService.getProducts(productParams).then(
        (response) => {
          this.activeProducts = response.data
          this.activeProductsLoaded = true          
        }
      )
    },
    getActiveCustomers() {
      let customersParams = {}
      if (this.distributorID != 0) {
        customersParams.idDistributor = this.distributorID
      }
      customersParams.disabled = 0
      CustomerLightService.getCustomers(customersParams).then(
        (response) => {
          this.activeCustomers = response.data
          this.activeCustomersLoaded = true          
        }
      )      
    },
    getActiveMachines() {
      let machinesParams = {}
      if (this.distributorID != 0) {
        machinesParams.idDistributor = this.distributorID
      }
      machinesParams.status = 0
      MachineLightService.getMachines(machinesParams).then(
        (response) => {
          this.activeMachines = response.data
          this.activeMachinesLoaded = true          
        }
      )       
    },
    getWorkingMachines() {
      let machinesParams = {}
      if (this.distributorID != 0) {
        machinesParams.idDistributor = this.distributorID
      }
      machinesParams.status = 0
      machinesParams.product_Content = '>0'
      MachineLightService.getMachines(machinesParams).then(
        (response) => {
          this.workingMachines = response.data
          this.workingMachinesLoaded = true          
        }
      )      
    },        
    refreshData() {
      this.getActiveProducts()
      this.getActiveCustomers()
      this.getActiveMachines()
      this.getWorkingMachines()
    }
  }
}
</script>