<template>
  <CContainer fluid>
    <div class="row">
      <div class="col-12 col-8-tablet push-2-tablet text-center">
        <CIcon size="xxl" :icon="labReportIcon"/>
        <h3 class="title">
          <CIcon icon="cil-arrow-circle-left" size="xxl" @click="backToLabReport()"/>
           {{ labReportTitle }}
        </h3>
      </div>
    </div>

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert>

    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
      <CButton type="button" color="dark" shape="rounded-0" variant="outline" id="showComment"
        @click="toggleCommentVisibility(currLabReport.comment, currLabReport.result_Indicator)">{{ $t('showComment') }}</CButton>    
      <CButton type="button" color="dark" shape="rounded-0" id="createCustomer" @click="closeLabReport()">{{ $t('finalizeReport') }}</CButton>
      <CButton type="button" color="warning" shape="rounded-0" id="copyParameters" @click="toggleReportSelectionVisibility()">
        <CIcon icon="cil-copy" size="xxl"/></CButton>
    </div><br>

    <CRow>
      <CCol xl="6">
        <CCard>
          <CCardBody>
            <CCardTitle>{{ $t('mainData') }}</CCardTitle>
            <CRow class="mb-3">
              <CCol sm="6">
                <CFormLabel for="machineName"><strong>{{ $t('machine') }}</strong></CFormLabel>
                <CFormInput type="text" id="machineName" v-model="currLabReport.machine_Name" readonly/>
              </CCol>
              <CCol>
                <CFormLabel for="customerName"><strong>{{ $t('customer') }}</strong></CFormLabel>
                <CFormInput type="text" id="customerName" v-model="currLabReport.customer_Name" readonly/>
              </CCol>        
            </CRow>
            <CRow class="mb-3">
              <CCol sm="6">
                <CFormLabel for="samplingDate"><strong>{{ pretifyName('labReportFields.date_Of_Analysis') }}</strong></CFormLabel>
                <CFormInput type="text" id="samplingDate" v-model="samplingDate" :value="analysisDateFormatted" readonly />
              </CCol>
              <CCol v-if="currLabReport.type == 0">
                <CFormLabel for="batchNumber"><strong>{{ pretifyName('labReportFields.batch_Number') }}</strong></CFormLabel>
                <CFormInput type="text" id="batchNumber" v-model="currLabReport.batch_Number" />
              </CCol>       
            </CRow>
            <CRow class="mb-3">
              <CCol sm="12">
                <CFormLabel for="sampleNumber"><strong>{{ pretifyName('labReportFields.sample_Number') }}</strong></CFormLabel>
                <CFormInput type="text" id="sampleNumber" v-model="currLabReport.sample_Number" />   
              </CCol>
            </CRow>            
            <CRow v-if="currLabReport.type == 0">
              <CCol sm="12">
                <CFormLabel for="reasonOfAnalysis"><strong>{{ pretifyName('labReportFields.reason_Of_Analysis') }}</strong></CFormLabel>
                <CFormTextarea id="reasonOfAnalysis" rows="5" v-model="currLabReport.reason_Of_Analysis" />   
              </CCol>
            </CRow>      
            <CRow v-if="currLabReport.type == 0">
              <CCol sm="6">
                <CFormLabel for="smell"><strong>{{ pretifyName('labReportFields.smell') }}</strong></CFormLabel>
                <CFormTextarea id="smell" rows="5" v-model="currLabReport.smell" />   
              </CCol>
              <CCol>
                <CFormLabel for="cleannessOfWorkstation"><strong>{{ pretifyName('labReportFields.cleanness_Of_Workstation') }}</strong></CFormLabel>
                <CFormTextarea id="cleannessOfWorkstation" rows="5" v-model="currLabReport.cleannes_Of_Workstation" />   
              </CCol>       
            </CRow>  
            <CRow v-if="currLabReport.type == 1">
              <CCol sm="6">
                <CFormLabel for="reasonOfAnalysis"><strong>{{ pretifyName('labReportFields.reason_Of_Analysis') }}</strong></CFormLabel>
                <CFormTextarea id="reasonOfAnalysis" rows="5" v-model="currLabReport.reason_Of_Analysis" />     
              </CCol>
              <CCol>
                <CFormLabel for="smell"><strong>{{ pretifyName('labReportFields.smell') }}</strong></CFormLabel>
                <CFormTextarea id="smell" rows="5" v-model="currLabReport.smell" />
              </CCol>       
            </CRow>            
            <CRow>
              <CCol sm="6">
                <CFormLabel for="appearance"><strong>{{ pretifyName('labReportFields.appearance_2') }}</strong></CFormLabel>
                <CFormTextarea id="appearance" rows="5" v-model="currLabReport.appearance" />   
              </CCol>
              <CCol>
                <CFormLabel for="deposit"><strong>{{ pretifyName('labReportFields.deposit') }}</strong></CFormLabel>
                <CFormTextarea id="deposit" rows="5" v-model="currLabReport.deposit" />   
              </CCol>       
            </CRow>            
            <CRow v-if="currLabReport.type == 0">
              <CCol sm="6">
                <CFormCheck type="checkbox" id="floatingOilPhase" :label="pretifyName('labReportFields.floating_Oil_Phase')"
                  v-model="currLabReport.floating_Oil_Phase" />
              </CCol>      
            </CRow>
            <CRow>
              <CFormLabel for="labReportStatus"><strong>{{ pretifyName('labReportFields.status') }}</strong></CFormLabel>
              <CCol sm="10">
                <CFormSelect class="mb-3" aria-label="status select" id="labReportStatus" v-model="labReportStatus" :value="getLabReportStatus(currLabReport.status)" disabled >
                  <option value="0">{{ $t('statusOptions.new') }}</option>
                  <option value="1">{{ $t('statusOptions.analysing') }}</option>
                  <option value="2">{{ $t('statusOptions.closed') }}</option>
                </CFormSelect>
              </CCol>             
            </CRow>
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
              <CButton type="button" color="dark" shape="rounded-0" id="updateLabReport" @click="updateLabReportBaseData()">{{ $t('updateReportBaseData') }}</CButton> 
            </div>             
          </CCardBody>
        </CCard>
        <br>
      </CCol>
      <CCol xl="6">
        <CCard>
          <CCardBody>
            <CCardTitle>{{ $t('inputParameters') }}</CCardTitle>
            <CRow v-for="(inputParam, i) in machineInputParameters" :key="inputParam.id">
              <CFormLabel class="col-sm-6 col-form-label"><strong>{{ getTranslatedValue(inputParam.characteristic_Name) }}</strong></CFormLabel>
              <CCol sm="6">
                <CInputGroup>
                  <CInputGroupText class="input-group-prepend col-3"><strong>{{ getParamTolerance(inputParam.tolerance) }}</strong></CInputGroupText>
                  <CFormSelect aria-label="parameter select" id="parameter" v-model="inputParams[i]"
                    v-if="getParametersOptionsValues(inputParam)">
                    <option v-for="(param, index) in inputParam.customValues" :key="index"
                      :value="index">{{ param }}</option>
                  </CFormSelect>
                  <CFormInput type="number" id="param" v-model="inputParams[i]" v-else/>
                  <CInputGroupText class="input-group-append col-3"><strong>{{ getParamUnitOfMeasure(inputParam.unit_of_measure) }}</strong></CInputGroupText>
                </CInputGroup>
              </CCol>                            
            </CRow>
            <CCardText>{{ $t('inputParameterDesc') }}</CCardText>
            <div class="d-grid gap-2 d-md-flex justify-content-md-end" v-if="machineCalcParametersFound">
              <CButton type="button" color="dark" shape="rounded-0" id="calcValues" @click="calculateValues()">{{ $t('calculate') }}</CButton> 
            </div>       
            <div class="d-grid gap-2 d-md-flex justify-content-md-end" v-if="!machineCalcParametersFound">
              <CButton type="button" color="dark" shape="rounded-0" id="calcValues" @click="updateLabReport()">{{ $t('updateReport') }}</CButton> 
            </div>                
          </CCardBody>
        </CCard>
        <br>
        <CCard v-if="machineCalcParametersFound">
          <CCardBody>
            <CCardTitle>{{ $t('calculatedParameters') }}</CCardTitle>
            <CRow v-for="(calcParam, i) in machineCalcParameters" :key="calcParam.id">
              <CFormLabel class="col-sm-6 col-form-label"><strong>{{ getTranslatedValue(calcParam.characteristic_Name) }}</strong></CFormLabel>
              <CCol sm="6">
                <CInputGroup>
                  <CInputGroupText class="input-group-prepend col-3"><strong>{{ getParamTolerance(calcParam.tolerance) }}</strong></CInputGroupText>
                  <CFormInput type="number" id="param" v-model="calculatedParams[i]" />
                  <CInputGroupText class="input-group-append col-3"><strong>{{ getParamUnitOfMeasure(calcParam.unit_of_measure) }}</strong></CInputGroupText>
                </CInputGroup>
              </CCol>               
            </CRow>            
            <CCardText>{{ $t('calculatedParametersDesc') }}</CCardText>
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
              <CButton type="button" color="dark" shape="rounded-0" id="calcValues" @click="updateLabReport()">{{ $t('updateReport') }}</CButton> 
            </div>           
          </CCardBody>
        </CCard>  
      </CCol>
    </CRow>          

    <CToaster placement="top-end">
      <CToast color="dark" class="text-white" v-for="(toast, index) in toasts" :key="index">
        <CToastBody>
          {{ toast.content }}
        </CToastBody>  
      </CToast>
    </CToaster>

    <confirm-action
      :cModalVisible="popupConfirmMessage"
      :title="confirmTitle"
      :message="confirmMessage"
      :yesButton="$t('sendEmail')"
      :noButton="$t('doNotSendEmail')"
      @confirm="confirmAction()" 
      @cancel="cancelAction()" />      
     
    <CModal alignment="center" :visible="commentVisible" @close="() => { commentVisible = false }">
      <CModalHeader>
        <CModalTitle>{{ $t('comment') }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CFormTextarea id="labReportComment" rows="5" v-model="labReportComment" /><br>
        <CRow>
          <CCol sm="2">
            <CDropdown light variant="nav-item">
              <CDropdownToggle color="secondary"><CBadge class="border border-light p-3" :color="resultIndicatorColor" shape="rounded-circle">
                <span class="visually-hidden">0</span></CBadge>
              </CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem @click="updateResultIndicator(1)"><CBadge class="border border-light p-3" color="success" shape="rounded-circle">
                  <span class="visually-hidden">1</span></CBadge></CDropdownItem>
                <CDropdownItem @click="updateResultIndicator(2)"><CBadge class="border border-light p-3" color="warning" shape="rounded-circle">
                  <span class="visually-hidden">2</span></CBadge></CDropdownItem>
                <CDropdownItem @click="updateResultIndicator(3)"><CBadge class="border border-light p-3" color="danger" shape="rounded-circle">
                  <span class="visually-hidden">3</span></CBadge></CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </CCol>
          <CCol sm="10">
            <CFormLabel><strong>{{ $t('resultIndicatorText') }}</strong></CFormLabel>
          </CCol>          
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="() => { commentVisible = false }">
          {{ $t('close') }}
        </CButton>
        <CButton color="primary" v-if="!viewAllItems"
          @click="updateCommentOnLabReport()">
          {{ $t('update') }}
        </CButton>        
      </CModalFooter>
    </CModal>

    <CModal size="xl" alignment="center" :visible="reportSelectionVisible" @close="toggleReportSelectionVisibility()">
      <CModalHeader>
        <CModalTitle>{{ $t('labReports') }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <lab-report-details-lookup
          :currMachineID="currLabReport.idMachine"
          :currReportID="currLabReport.id"
          :currProductID="currLabReport.idProduct"
          :labReportType="currLabReport.type"
          @selectReport="copyReportParams($event)"
        />
      </CModalBody>
    </CModal>    

  </CContainer>
</template>

<script>
import LabReportService from '../services/lab-reports'
import AssignedParametersService from '../services/machine-assigned-parameters'
import MeasurementService from '../services/measurements'
import { ReportStatus } from '../helpers/getEnumValues'
import LabReportDetailService from '../services/lab-report-details'
import FormulaParser from '../helpers/formulaParser'
import AnalysisRequestService from '../services/analysis-requests'
import DateFormatter from '../helpers/dateFormatter'
import ConfirmAction from '../components/ConfirmAction.vue'
import StringFormatter from '../helpers/stringFormatter'
import LabReportDetailsLookup from '../components/LabReportDetailsLookup.vue'
import PermissionManager from '../helpers/permissionManager'
import PerformMachineOperationsService from '../services/perform-machine-operations'
import LabReportSummary from '../reports/LabReportSummary'
import ParameterFormatter from '../helpers/reportParameterFormatter'
import LanguageParser from '../helpers/languageParser'
import UserService from '../services/users'

export default {
  name: 'EditLabReport',
  data() {
    return {
      labReportDataLoaded: false,
      dataLoaded: false,
      message: '',
      currLabReport: '',
      machineInputParameters: '',
      machineCalcParameters: '',
      machineCalcParametersFound: false,
      inputParams: [],
      calculatedParams: [],
      toasts: [],
      preExistingReportDetails: [],
      updatedParams: [],
      updateComplete: false,
      popupConfirmMessage: false,
      confirmTitle: '',
      confirmMessage: '',
      refRequestAnalysis: 0,
      commentVisible: false,
      labReportComment: '',  
      resultIndicator: 0,
      reportSelectionVisible: false,
      isForwardedRequest: false,
      customerUserData: '',
      analysisFloatingOilPhase: false                 
    }
  },
  computed: {
    labReportTitle() {
      if (!this.labReportDataLoaded) {
        this.getLabReportData()
      } else if (!this.dataLoaded) {
        this.getMachineParameters()
      }
      return (this.currLabReport.type == 1) ? this.$t('waterAnalysis') : this.$t('labReport')
    },
    labReportIcon() {
      return ((this.currLabReport.type == 1) ? 'cil-drop' : 'cil-factory')
    },
    analysisDateFormatted() {
      return (DateFormatter.formatDate(this.currLabReport.date_Of_Analysis))
    },
    resultIndicatorColor() {
      switch(this.resultIndicator) {
        case 1:
          return 'success'
        case 2:
          return 'warning'
        case 3:
          return 'danger'
        default:
          return 'light'
      }
    }    
  },
  watch: {
    updateComplete: {
      handler(newValue, oldValue) {
        if ((newValue != oldValue) && (this.updateComplete)) {
          //this.closeFormSuccessfull('Updated!!!')
          this.createToast(this.$t('reportSuccesfullyUpdated', {rep: 'Updated!!!'}))
        }
      }
    }
  },
  created() {
    this.getLabReportData()
  },
  methods: {
    getLabReportData() {
      if ((this.$route.params.idLabReport) && (this.$route.params.idLabReport != 0)) {
        LabReportService.getSingleLabReport(this.$route.params.idLabReport).then(
          (response) => {
            this.currLabReport = response.data
            this.labReportDataLoaded = true
            this.setRouteInfoInLocalStorage()
          },
          (error) => {
            this.message =           
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()              
          }
        )
      } else {
        this.message = this.$t('labReportLoadException')
      }
    },
    getMachineParameters() {
      if ((this.currLabReport.id) && (this.currLabReport.id != 0)) {
        let queryParams = {
          'idMachine': this.currLabReport.idMachine,
          'type': this.currLabReport.type
        } 
        AssignedParametersService.getParameters(queryParams).then(
          (response) => {
            let machineParameterList = response.data.data
            this.assignCustomValues(machineParameterList)
            this.splitMachineParameters(machineParameterList)
            this.dataLoaded = true
            this.preloadInputValues(this.currLabReport.id)
          },
          (error)  => {
            this.message =           
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()
          }
        ) 
      }       
    },
    splitMachineParameters(machineParameterList) {
      if (machineParameterList) {
        // Init Arrays
        this.machineInputParameters = []
        this.machineCalcParameters = []
        // Process Data
        machineParameterList.forEach(parameter => {
          if ((parameter.calc_formula) && (parameter.calc_formula != '')) {
            this.machineCalcParametersFound = true
            this.machineCalcParameters.push(parameter)
          } else {
            this.machineInputParameters.push(parameter)
          }
        })
      }
    },
    preloadInputValues(fromlabReportID) {
      if (this.dataLoaded) {
        LabReportDetailService.getLabReportDetails({ 'idLabReport': fromlabReportID }).then(
          (response) => {
            const reportDetails = response.data.data
            this.preExistingReportDetails = reportDetails
            // Load Input Params
            this.inputParams = []
            this.calculatedParams = []
            this.machineInputParameters.forEach(parameter => {
              const reportIndex = reportDetails.findIndex(detail => detail.id == parameter.id)
              if (reportIndex >= 0) {
                this.inputParams.push(ParameterFormatter.getParameterOptionValue(parameter.id, Number(reportDetails[reportIndex].sample), true))
              } else {
                this.inputParams.push(ParameterFormatter.getOptionParameterDefaultValue(parameter.id))
              }
            })

            // Load Calc Params
            this.machineCalcParameters.forEach(parameter => {
              const reportIndex = reportDetails.findIndex(detail => detail.id == parameter.id)
              if (reportIndex >= 0) {
                this.calculatedParams.push(Number(reportDetails[reportIndex].sample))
              } else {
                this.calculatedParams.push(null)
              }
            })            
          }
        )
      }
    },
    backToLabReport() {
      this.$router.go(-1)
    },
    setRouteInfoInLocalStorage() {
      if (this.currLabReport) {
        const params = {
          'idMachine': this.currLabReport.idMachine,
          'idProduct': this.currLabReport.idProduct,
          'idCustomer': this.currLabReport.idCustomer,
          'newLabReport': this.currLabReport
        }
        if ((this.currLabReport.idAnalysisRequest) && (this.currLabReport.idAnalysisRequest != 0)) {
          params.idRequest = this.currLabReport.idAnalysisRequest
        }        
        localStorage.setItem('routeInfo', JSON.stringify(params))
      }
    },
    getLabReportStatus(statusString) {
      if ((statusString) && (statusString != '')) {
        return ReportStatus.indexOf(statusString)
      }
    },
    getParamUnitOfMeasure(unit) {
      if (!unit) {
        return ''
      }
      return ('(' + unit + ')')
    },
    getParamTolerance(tolerance) {
      if (!tolerance) {
        return this.emptyPadEndString('', 15)
      }
      return this.emptyPadEndString('[' + tolerance + ']', 15)
    },
    emptyPadEndString(inputString, length) {
      return (inputString.padEnd(length))
    },    
    updateLabReportBaseData() {
      let queryParams = {
        'reason_Of_Analysis': this.currLabReport.reason_Of_Analysis,
        'smell': this.currLabReport.smell,
        'batch_Number': this.currLabReport.batch_Number,
        'cleannes_Of_Workstation': this.currLabReport.cleannes_Of_Workstation,
        'floating_Oil_Phase': this.currLabReport.floating_Oil_Phase,
        'appearance': this.currLabReport.appearance,
        'deposit': this.currLabReport.deposit,
        'sample_Number': this.currLabReport.sample_Number
      }
      LabReportService.updateLabReport(this.currLabReport.id, queryParams).then(
        () => {
          this.createToast(this.$t('reportBaseDataSuccesfullyUpdated'))
        },
        (error) => {
          this.message = (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
        }
      )      
    },
    calculateValues() {
      if (!(this.machineCalcParameters.length === 0)) {
        // Clear Calculated Params
        this.calculatedParams = []
        this.machineCalcParameters.forEach(calcParameter => {
          let formula = calcParameter.calc_formula
          // First Replace characteristic IDs with values
          while (formula.indexOf('{') >= 0) {
            const startPos = formula.indexOf('{')
            const endPos = formula.indexOf('}')
            const currParameterID = Number(formula.substring(startPos + 1, endPos))
            const currInputPosition = this.machineInputParameters.findIndex(inputParam => inputParam.id == currParameterID)
            let newValue = 0
            if (currInputPosition >= 0) {
              newValue = this.inputParams[currInputPosition]
            }
            const stringToReplace = '{' + currParameterID + '}'
            formula = formula.replace(stringToReplace, newValue)
          }
          // Then Parse result
          this.calculatedParams.push(FormulaParser.parse(formula))
        })
      }
    },
    updateLabReport() {
      // Update also base data
      this.updateLabReportBaseData()
      if (!(this.preExistingReportDetails.length === 0)) {
        LabReportDetailService.deleteLabReportDetail({ 'idLabReport': this.currLabReport.id }).then(
          () => { 
            this.preExistingReportDetails = []
            this.updateLabReport2()
          },
          (error) => {
            this.message =           
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()
            this.updateLabReport2()
            this.message = ''
          }
        )
      } else {
        this.updateLabReport2()
      }
    },
    updateLabReport2() {
      if (!(this.machineInputParameters.length === 0)) {
        this.createLabReportDetails(this.machineInputParameters, this.inputParams)
      }
      if (!(this.machineCalcParameters.length === 0)) {
        this.createLabReportDetails(this.machineCalcParameters, this.calculatedParams)
      }
    },
    createLabReportDetails(parametersArray, paramsValue) {
      let i = 0
      parametersArray.forEach(parameter => {
        if (paramsValue[i]) {
          // Collect Query Parameters
          let queryParam = {
            'id': parameter.id,
            'idLabReport': this.currLabReport.id,
            'unit': parameter.unit_of_measure,
            'tolerance': parameter.tolerance,
            'sample': String(ParameterFormatter.getParameterDecimalValue(parameter.id, Number(paramsValue[i])))
          }
          i++
          LabReportDetailService.createLabReportDetail(queryParam).then(
            () => {
              this.updatedParams.push(parameter.id)
              this.updateComplete = (this.machineInputParameters.length + this.machineCalcParameters.length === this.updatedParams.length)                 
            },
            (error) => {
              this.message =           
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString()
              return
            }
          )
        } else {
          this.updatedParams.push(parameter.id)
          this.preExistingReportDetails.push(parameter)
          this.updateComplete = (this.machineInputParameters.length + this.machineCalcParameters.length === this.updatedParams.length)     
          i++      
        }
      })
    },
    closeLabReport() {
      // Check that a comment has been inserted
      if ((!this.currLabReport.comment) || (this.currLabReport.comment == '')) {
        this.message = this.$t('emptyCommentException')
      } else {
        // 1. Set Status to Closed
        LabReportService.updateLabReport(this.currLabReport.id, { 'status': 2 }).then(
          () => {
            // 2. Create a new measurement/delete existing evt... Managed in backend
            MeasurementService.deleteMultiMeasurements({ 'idReport': this.currLabReport.id }).then(
              () => { },
              (error) => {
                this.message =           
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString()
              }
            )
            MeasurementService.createMeasurement({ 'idReport': this.currLabReport.id }).then(
              () => { },
              (error) => {
                this.message =           
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString()
              }            
            )
            // 3. Update Analysis Request if linked to one...
            AnalysisRequestService.getAnalysisRequests({ 'request_Type': Number(this.currLabReport.type) + 1, 'idMeasurement': this.currLabReport.id }).then(
              (response) => {
                if (response.data.data[0]) {
                  // Ask for confirmation if sending email or not...
                  let currDistributor = PermissionManager.getDistributorFilteredVisibility()
                  this.isForwardedRequest = ((response.data.data[0].forwarded_By) &&
                                            (response.data.data[0].forwarded_By != 0) &&
                                            (response.data.data[0].idDistributor == currDistributor.idDistributor))
                  this.analysisFloatingOilPhase = response.data.data[0].floating_Oil_Phase
                  if (response.data.data[0].keep_User_Notified) {
                    this.storeCustomerData(response.data.data[0].requested_By_User)
                    this.askBeforeUpdatingAnalysis(response.data.data[0])
                  } else {
                    this.closeAnalysisRequest(response.data.data[0].id, false)
                  }
                } else {
                  this.closeFormSuccessfull()
                }
              },
              (error) => {
                this.message =           
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString()
              }
            )
          },
          (error) => {
            this.message =           
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()
          } 
        )
      }
    },   
    closeAnalysisRequest(requestID, sendEmailToUser) {
      if ((requestID) && (requestID != 0)) {
        // Ask for confirmation first....
        const userData = JSON.parse(localStorage.getItem('user'))
        const queryParams = {
          'request_Type': Number(this.currLabReport.type) + 1,
          'request_Handled_By': userData.user.id,
          'idMeasurement': this.currLabReport.id,
          'notify_User': false
        }
        // Manage forwarded requests
        if (this.isForwardedRequest) {
          queryParams['forward_Status'] = 2
        } else {
          queryParams['request_Status'] = 2
        }

        AnalysisRequestService.updateAnalysisRequest(requestID, queryParams).then(
          () => { 
            if (sendEmailToUser) {
              const orgLanguage = LanguageParser.getStoredLanguage()
              if (this.customerUserData) {
                if (orgLanguage != LanguageParser.getLanguage(this.customerUserData.language)) {
                  LanguageParser.setLanguage(LanguageParser.getLanguage(this.customerUserData.language))
                  this.$i18n.locale = LanguageParser.getStoredLanguage()
                }
              }
              // Perform operation
              let operationParams = {
                'action': 'sendLabReport',
                'analysisRequest': requestID,
                'attachment': this.getLabReportAttachment()
              } 
              if (orgLanguage) {
                LanguageParser.setLanguage(orgLanguage)
                this.$i18n.locale = LanguageParser.getStoredLanguage()
              }          
              PerformMachineOperationsService.performOperation(operationParams).then(
                () => {
                  this.closeFormSuccessfull('closed!!')
                },
                (error)  => {
                  this.message =           
                  (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                  error.message ||
                  error.toString()
                }
              )              
            } else {
              this.closeFormSuccessfull('closed!!')
            }
          },
          (error) => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      }
    },
    askBeforeUpdatingAnalysis(requestAnalysis) {
      this.refRequestAnalysis = requestAnalysis.id
      this.confirmTitle = this.$t('sendEmailTitle')
      this.confirmMessage = this.$t('sendEmailDetails', {usr: requestAnalysis.requested_By_User_Name})
      this.popupConfirmMessage = true
    },
    confirmAction() {
      this.resetConfirmMessage()
      this.closeAnalysisRequest(this.refRequestAnalysis, true)
    },
    cancelAction() {
      this.resetConfirmMessage()
      this.closeAnalysisRequest(this.refRequestAnalysis, false)
    },
    resetConfirmMessage() {
      this.confirmTitle = ''
      this.confirmMessage = '',
      this.popupConfirmMessage = false
    },     
    createToast(content) {
      this.toasts.push({
        content: content
      })
    },    
    closeFormSuccessfull(operationType) {
      this.createToast(this.$t('reportSuccesfullyUpdated', {rep: operationType}))
      this.delay(2000).then(
        () => this.backToLabReport()
      )
    },
    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time))
    },
    pretifyName(name) {
      return StringFormatter.pretifyName(this.$t(name))
    },
    getTranslatedValue(characteristicName) {
      let charFullName = 'characteristics.' + characteristicName
      return this.$t(charFullName)
    },
    toggleCommentVisibility(currComment, currIndicator) {
      if (this.commentVisible) {
        this.labReportComment = ''
        this.resultIndicator = 0
      } else {
        this.labReportComment = currComment
        this.resultIndicator = currIndicator
      }
      this.commentVisible = !this.commentVisible
    },  
    updateCommentOnLabReport() {
      if ((this.currLabReport.id) && (this.currLabReport.id != 0)) {
        LabReportService.updateLabReport(this.currLabReport.id, { 'comment': this.labReportComment, 'result_Indicator': this.resultIndicator }).then(
          () => {
            // Refresh data
            this.commentVisible = false
            this.currLabReport.comment = this.labReportComment
            this.currLabReport.result_Indicator = this.resultIndicator
          },
          (error) => {
            this.message =           
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()
          } 
        )        
      }
    },
    updateResultIndicator(value) {
      this.resultIndicator = value
    },
    isMobile() {
      let mobile = localStorage.getItem('isMobile')
      return (mobile === 'true')
    },
    toggleReportSelectionVisibility() {
      this.reportSelectionVisible = !this.reportSelectionVisible
    },
    copyReportParams(fromReportID) {
      this.toggleReportSelectionVisibility()
      if ((!fromReportID) || (fromReportID == this.currLabReport.id)) {
        return
      }
      this.preloadInputValues(fromReportID)
    },
    getLabReportAttachment() {
      let reportTarget = JSON.parse(JSON.stringify(this.getUnifiedLabReport()))
      reportTarget.analysisFloatingOilPhase = this.analysisFloatingOilPhase
      if (this.$route.params.phValuesChart) {
        reportTarget.phValuesChart = this.$route.params.phValuesChart
      }
      if (this.$route.params.effConcChart) {
        reportTarget.effConcChart = this.$route.params.effConcChart
      }  
      if (this.$route.params.waterpHChart) {
        reportTarget.waterpHChart = this.$route.params.waterpHChart
      }
      if (this.$route.params.sulfateChart) {
        reportTarget.sulfateChart = this.$route.params.sulfateChart
      }     
      if (this.$route.params.chlorideChart) {
        reportTarget.chlorideChart = this.$route.params.chlorideChart
      }
      if (this.$route.params.waterHardnessChart) {
        reportTarget.waterHardnessChart = this.$route.params.waterHardnessChart
      }           
      let filteredParameterList = this.getFullParameterList().filter(parameter => parameter.hide_In_Reports == false)
      return LabReportSummary.attachReport(reportTarget, filteredParameterList, this.labReportTitle, '')
    },
    getFullParameterList() {
      let machineParamenterList = [...this.machineInputParameters, ...this.machineCalcParameters]
      return machineParamenterList     
    },
    getUnifiedLabReport() {
      let completeLabReport = { ...this.currLabReport }
      completeLabReport.completed_At = new Date()
      // Add Input Parameters
      let i = 0
      this.machineInputParameters.forEach(parameter => {
        completeLabReport[parameter.characteristic_Name] = this.inputParams[i] ? String(ParameterFormatter.getParameterOptionValueFromIndex(parameter.id, Number(this.inputParams[i]))) : ''
        i++
      })
      // Add calculated Parameters
      i = 0
      this.machineCalcParameters.forEach(parameter => {
        completeLabReport[parameter.characteristic_Name] = this.calculatedParams[i] ? String(ParameterFormatter.getParameterOptionValueFromIndex(parameter.id, Number(this.calculatedParams[i]))) : ''
        i++
      })     

      return completeLabReport
    },
    getParametersOptionsValues(refParameter) {
      return (refParameter.customValues.length > 0)
    },
    assignCustomValues(machineParameters) {
      if (machineParameters.length > 0) {
        machineParameters.forEach(parameter => {
          ParameterFormatter.getParameterValueSelection(parameter.id, parameter)
        })
      }
    },
    storeCustomerData(customerUserID) {
      this.customerUserData = []
      if (customerUserID) {
        UserService.getSpecificUser(customerUserID).then(
          (response) => {
            this.customerUserData = response.data
          }
        )
      }
    }                              
  },
  components: {
    ConfirmAction,
    LabReportDetailsLookup
  }
}
</script>