<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="sidebarVisible = !sidebarVisible" v-on="on">
        <CIcon icon="cil-menu" size="xl" />
      </CHeaderToggler>
      <img
        style="float:center;margin:10px 10px"
        src="@/assets/Logo_Swiss_Tribology_transp_grey.png"
        alt="Strub Logo"
        width="190"          
      />   
      <CHeaderToggler class="ps-1" @click="visibleEnd = !visibleEnd" v-on="on">
        <CIcon icon="cil-info" size="xl" />
      </CHeaderToggler>          
    </CContainer>
    <CHeaderDivider />
    <CHeaderNav>
      <CNavItem>
        <router-link to="/m-dashboard" class="nav-link"> {{ $t('home') }} </router-link>
      </CNavItem>
      <CNavItem v-if="!currentUser">
        <router-link to="/m-register" class="nav-link"> {{ $t('signup') }} </router-link>
      </CNavItem>
      <CNavItem v-if="!currentUser">
        <router-link to="/m-login" class="nav-link"> {{ $t('login') }} </router-link>
      </CNavItem>
      <CNavItem v-if="currentUser">
        <router-link to="/m-profile" class="nav-link">{{ $t('user') }} {{ currentUser.user.first_Name }}</router-link>
      </CNavItem>
      <CNavItem v-if="currentUser">
        <a class="nav-link" @click.prevent="logOut"> {{ $t('logout') }} </a>
      </CNavItem>          
    </CHeaderNav>
    <CNavbarNav class="justify-content-md-end">
      <CDropdown light variant="nav-item" v-if="dataLoaded">
        <CDropdownToggle color="secondary"><CIcon :icon="currFlag" size="xl"/></CDropdownToggle>
        <CDropdownMenu>
          <CDropdownItem @click="changeLanguage('en')"><CIcon icon="cifGb" size="xl" /></CDropdownItem>
          <CDropdownItem @click="changeLanguage('de')"><CIcon icon="cifDe" size="xl" /></CDropdownItem>
          <CDropdownItem @click="changeLanguage('it')"><CIcon icon="cifIt" size="xl" /></CDropdownItem>
          <CDropdownItem @click="changeLanguage('fr')"><CIcon icon="cifFr" size="xl" /></CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    </CNavbarNav>      
  </CHeader>

  <COffcanvas placement="end" :visible="visibleEnd" @hide="() => { visibleEnd = !visibleEnd }" @show="retrieveRouteInfo()">
    <COffcanvasHeader>
      <COffcanvasTitle>{{ $t('pageContentInfo') }}</COffcanvasTitle>
      <CCloseButton class="text-reset" @click="() => { visibleEnd = false }"/>     
    </COffcanvasHeader>
    <COffcanvasBody :key="offCanvasBodyKey">
      <customer-session-infobox :key="customerSessionKey" />
      <div v-if="noInfoAvailableVisible">
        {{ $t('noInfosAvailableException') }}
      </div>
      <div v-if="descriptiveTextVisible">
        {{ descriptiveText }} <br>
      </div>
      <analysis-request-infobox v-if="requestInfoboxVisible"
        :requestID="requestID "/>
      <prev-lab-report-infobox v-if="prevLabReportInfoboxVisible"
        :newLabReport="newLabReport" />
      <measurement-infobox v-if="measurementInfoboxVisible"
        :machineID="measurementMachineID"
        :productID="productID" />
      <machine-infobox v-if="machineInfoboxVisible" 
        :machineID="machineID" />
      <product-infobox v-if="productInfoboxVisible"
        :productID="productID" />
      <customer-infobox v-if="customerInfoboxVisible"
        :customerID="customerID" />   
      <distributor-infobox v-if="distributorInfoboxVisible"
        :distributorID="distributorID" />              
    </COffcanvasBody>    
    <COffcanvasFooter>
      <CCard>
        <CCardBody>
          <CCardTitle>{{ $t('globalSettings') }}</CCardTitle>
          <CListGroupItem :color="agentSelectionColor" v-if="isUserAgent">
            <div class="d-flex w-100 justify-content-between">
              <h6 class="mb-1"><strong>{{ $t('agentSelection') }}</strong></h6>
            </div>
            <CFormSelect class="mb-3" aria-label="agent select" id="agent" v-model="selectedAgentID">
              <option v-for="(agent, index) in availableAgents" :key="index"
                :value="agent.id">{{ agent.first_Name }} {{ agent.last_Name }}</option>
            </CFormSelect>
          </CListGroupItem>
          <CFormSwitch
            :label="$t('onlyActiveRecords')"
            id="showOnlyActiveRecords"
            :checked="showOnlyActive"
            v-model="showOnlyActive" />
          <CFormSwitch v-if="switchToDesktopVisible"
            :label="$t('switchToDesktop')"
            id="switchToDesktop"
            :checked="switchToDesktop"
            v-model="switchToDesktop" />            
        </CCardBody>
      </CCard>
    </COffcanvasFooter>
  </COffcanvas>

  <sidebar-mobile
    :sidebarVisible="sidebarVisible"
    @hideSidebar="updateSidebarVisibility()" />

</template>

<script>
import MachineInfobox from '../MachineInfobox.vue'
import ProductInfobox from '../ProductInfobox.vue'
import CustomerInfobox from '../CustomerInfobox.vue'
import DistributorInfobox from '../DistributorInfobox.vue'
import MeasurementInfobox from '../MeasurementInfobox.vue'
import AnalysisRequestInfobox from '../AnalysisRequestInfobox.vue'
import PrevLabReportInfobox from '../PrevLabReportInfobox.vue'
import LanguageParser from '../../helpers/languageParser'
import SidebarMobile from './SidebarMobile.vue'
import CustomerSessionInfobox from '../CustomerSessionInfobox.vue'
import PermissionManager from '../../helpers/permissionManager'
import UserService from '../../services/users'

export default {
  data() {
    return { 
      visibleEnd: false,
      machineInfoboxVisible: false,
      machineID: 0,
      productInfoboxVisible: false,
      productID: 0,      
      customerInfoboxVisible: false,
      customerID: 0,
      distributorInfoboxVisible: false,
      distributorID: 0,
      measurementInfoboxVisible: false,
      measurementMachineID: 0, 
      requestInfoboxVisible: false,
      requestID: 0,
      prevLabReportInfoboxVisible: false,      
      newLabReport: '',      
      descriptiveTextVisible: false,           
      descriptiveText: '',            
      noInfoAvailableVisible: true,
      currFlag: 'cifGb',
      reloadFlag: false,
      dataLoaded: false,
      sidebarVisible: false,
      showOnlyActive: false,
      switchToDesktop: false,
      customerSessionKey: 1,
      availableAgents: [],
      selectedAgentID: 0,
      offCanvasBodyKey: 1      
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user
    },
    switchToDesktopVisible() {
      return (screen.width > 760)
    },
    isUserAgent() {
      return ((PermissionManager.getAgentFilteredVisibility(true) != null) && (PermissionManager.hasExtendedAgentPermissions()))
    },
    agentSelectionColor() {
      const currAgent = PermissionManager.getAgentFilteredVisibility(true)
      const storedAgent = PermissionManager.getAgentFilteredVisibility()
      if (!currAgent) {
        return ''
      }
      if (storedAgent) {
        return (storedAgent.idAgent == currAgent.idAgent) ? '' : 'warning'
      } else {
        return ''
      }
    }
  },
  watch: {
    dataLoaded: {
      handler(newValue, oldValue) {
        if ((newValue != oldValue) && (!newValue)) {
          this.updateCurrentFlag()
        }
      },
      flush: 'post'
    },
    showOnlyActive: {
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateActiveRecordGlobal()
        }
      },
      flush: 'post'
    },
    switchToDesktop: {
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateGlobalView()
        }
      },
      flush: 'post'
    },
    selectedAgentID: {
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          const currAgent = PermissionManager.getAgentFilteredVisibility(true)
          const storedAgent = PermissionManager.getAgentFilteredVisibility()
          if ((!storedAgent) || (storedAgent.idAgent == 0)) {
            if ((this.selectedAgentID != 0) && (this.selectedAgentID != currAgent.idAgent)) {
              localStorage.setItem('differentAgentSelected', this.selectedAgentID)
              this.$router.push('/')
            }
          } else if (storedAgent.idAgent != this.selectedAgentID) {
            if (this.selectedAgentID == currAgent.idAgent) {
              localStorage.removeItem('differentAgentSelected')
            } else {
              localStorage.setItem('differentAgentSelected', this.selectedAgentID)
            }
            this.$router.push('/')
          }
        }
      }
    }         
  },  
  created() {
    this.updateCurrentFlag()
    this.setOnlyActive()
    this.retrieveDistributorAgents()
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout')
      this.$router.push('/m-login')
    },
    retrieveRouteInfo() {
      this.clearInfobox()
      const routeInfo = JSON.parse(localStorage.getItem('routeInfo'))
      if (routeInfo) {
        if ((routeInfo.idMachine) && (routeInfo.idMachine != 0)) {
          this.machineID = routeInfo.idMachine
          this.machineInfoboxVisible = true
        } else {
          this.machineID = 0
          this.machineInfoboxVisible = false
        }
        if ((routeInfo.idProduct) && (routeInfo.idProduct != 0)) {
          this.productID = routeInfo.idProduct
          this.productInfoboxVisible = true
        } else {
          this.productID = 0
          this.productInfoboxVisible = false
        }
        if ((routeInfo.idCustomer) && (routeInfo.idCustomer != 0)) {
          this.customerID = routeInfo.idCustomer
          this.customerInfoboxVisible = true
        } else {
          this.customerID = 0
          this.customerInfoboxVisible = false
        }
        if ((routeInfo.idDistributor) && (routeInfo.idDistributor != 0)) {
          this.distributorID = routeInfo.idDistributor
          this.distributorInfoboxVisible = true
        } else {
          this.distributorID = 0
          this.distributorInfoboxVisible = false
        }         
        if ((routeInfo.measurementidMachine) && (routeInfo.measurementidMachine != 0)) {
          this.measurementMachineID = routeInfo.measurementidMachine
          this.measurementInfoboxVisible = true
        } else {
          this.measurementMachineID = 0
          this.measurementInfoboxVisible = false
        }      
        if ((routeInfo.idRequest) && (routeInfo.idRequest != 0)) {
          this.requestID = routeInfo.idRequest
          this.requestInfoboxVisible = true
        } else {
          this.requestID = 0
          this.requestInfoboxVisible = false
        }      
        if ((routeInfo.newLabReport) && (routeInfo.newLabReport != '')) {
          this.newLabReport = routeInfo.newLabReport
          this.prevLabReportInfoboxVisible = true
        } else {
          this.newLabReport = ''
          this.prevLabReportInfoboxVisible = false
        }                
        if ((routeInfo.descriptiveText) && (routeInfo.descriptiveText != '')) {
          this.descriptiveText = routeInfo.descriptiveText
          this.descriptiveTextVisible = true
        } else {
          this.descriptiveText = ''
          this.descriptiveTextVisible = false
        }                       
        this.noInfoAvailableVisible = false
      }
      this.customerSessionKey += 1
      this.offCanvasBodyKey += 1
    },
    clearInfobox() {
      this.machineID = 0
      this.machineInfoboxVisible = false
      this.productID = 0
      this.productInfoboxVisible = false 
      this.customerID = 0
      this.customerInfoboxVisible = false     
      this.distributorID = 0
      this.distributorInfoboxVisible = false  
      this.measurementMachineID = 0
      this.measurementInfoboxVisible = false          
      this.descriptiveText = 0
      this.requestInfoboxVisible = false
      this.requestID = 0
      this.prevLabReportInfoboxVisible = false
      this.newLabReport = ''
      this.descriptiveTextVisible = false           
      this.noInfoAvailableVisible = true
    },
    changeLanguage(newLanguage) {
      LanguageParser.setLanguage(newLanguage)
      this.reloadFlag = true
      this.dataLoaded = false
    },
    updateCurrentFlag() {
      this.$i18n.locale = LanguageParser.getStoredLanguage()
      let tempFlag = 'cifGb'
      switch (this.$i18n.locale) {
        case 'en':
          tempFlag = 'cifGb'
          break
        case 'de':
          tempFlag = 'cifDe'
          break
        case 'it':
          tempFlag = 'cifIt'
          break
        case 'fr':
          tempFlag = 'cifFr'
          break          
        default:
          break
      }
      this.currFlag = tempFlag
      this.dataLoaded = true
    },
    reRenderFlag() {
      this.reloadFlag = false
    },
    updateSidebarVisibility() {
      this.sidebarVisible = false
    },
    updateActiveRecordGlobal() {
      localStorage.setItem('showOnlyActiveRecords', this.showOnlyActive)
    },
    setOnlyActive() {
      this.showOnlyActive = false
      let onlyActive = localStorage.getItem('showOnlyActiveRecords')
      if (onlyActive === 'true') {
        this.showOnlyActive = true
      }
    },
    updateGlobalView() {
      if (this.switchToDesktop) {
        localStorage.setItem('isMobile', false)
        this.$router.push('/')
      }
    },
    retrieveDistributorAgents() {
      const userAgent = PermissionManager.getAgentFilteredVisibility()
      if (userAgent) {
        this.selectedAgentID = userAgent.idAgent
      }
      this.availableAgents = []
      const userDistributor = PermissionManager.getDistributorFilteredVisibility()
      if (userDistributor) {
        if (userAgent) {
          let userParams = {
            'company_Type': 0,
            'idCompany': userDistributor.idDistributor,
            'role': 1
          }
          userParams.$limit = 5000          
          UserService.getUsers(userParams).then(
            (response) => {
              this.availableAgents = response.data.data
            },
            (error)  => {
              this.message =           
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()
            }
          )
        }
      }      
    }     
  },
  components: {
    MachineInfobox,
    ProductInfobox,
    CustomerInfobox,
    DistributorInfobox,
    MeasurementInfobox,
    AnalysisRequestInfobox,
    PrevLabReportInfobox,
    SidebarMobile,
    CustomerSessionInfobox
  }
}
</script>