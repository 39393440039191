import axios from 'axios'
import authHeader from './auth-header'
import formatQuery from './query-helper'

const API_URL = process.env.VUE_APP_SERVER_BASE_URL + 'operations'

class OperationsService {
    getOperations(queryParams) {
        return axios.get(API_URL + formatQuery(queryParams), { headers: authHeader() })
    }
    updateOperation(operationID, operationParams) {
        return axios.patch(API_URL + '/' + operationID, operationParams, { headers: authHeader() })
    }
    createOperation(operationParams) {
        return axios.post(API_URL, operationParams, { headers: authHeader() })
    }
    deleteOperation(operationID) {
        return axios.delete(API_URL + '/' + operationID, {headers: authHeader() })
    }
    // Build other api calls here....   
}

export default new OperationsService()