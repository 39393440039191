import { createRouter, createWebHistory } from 'vue-router'
import Login from '../components/Login.vue'
//import Profile from '../components/Profile.vue'
import DefaultLayout from '../layouts/DefaultLayout.vue'
import UserCard from '../components/UserCard.vue'
import Register from '../components/Register.vue'
import Verify from '../components/ConfirmToken.vue'
import Products from '../views/Products.vue'
import Machines from '../views/Machines.vue'
import Distributors from '../views/Distributors.vue'
import Customers from '../views/Customers.vue'
import Measurements from '../views/Measurements.vue'
import EditMachine from '../views/EditMachine.vue'
import EditMachineAttribute from '../views/EditMachineAttribute.vue'
import Dashboard from '../components/Dashboard.vue'
import LabReports from '../views/LabReports.vue'
import labReportDetails from '../views/LabReportDetails.vue'
import MeasurementCard from '../views/MeasurementCard.vue'
import EditLabReport from '../views/EditLabReport.vue'
import Users from '../views/Users.vue'
import ProductTechnologyEditor from '../components/ProductTechnologyEditor.vue'
import AdminMain from '../views/AdminMain.vue'
import CustomerAgentAssignment from '../views/CustomerAgentAssignment.vue'
import MigrationTool from '../views/MigrationTool.vue'
import About from '../views/About.vue'
import NotFoundComponent from '../components/NotFoundComponent.vue'
import InitLayout from '../layouts/InitLayout.vue'
import DefaultLayoutMobile from '../layouts/DefaultLayoutMobile.vue'
import LoginMobile from '../components/mobile/LoginMobile.vue'
import DashboardMobile from '../components/mobile/DashboardMobile.vue'
import MachinesMobile from '../components/mobile/MachinesMobile.vue'
import CustomersMobile from '../components/mobile/CustomersMobile.vue'
import DistributorsMobile from '../components/mobile/DistributorsMobile.vue'
import ProductsMobile from '../components/mobile/ProductsMobile.vue'
import MeasurementsMobile from '../components/mobile/MeasurementsMobile.vue'
import EditMachineMobile from '../components/mobile/EditMachineMobile.vue'
import LabReportsMobile from '../components/mobile/LabReportsMobile.vue'
import AdminMainMobile from '../components/mobile/AdminMainMobile.vue'
import UsersMobile from '../components/mobile/UsersMobile.vue'
import RegisterMobile from '../components/mobile/RegisterMobile.vue'
import MachinesStatusOverviewMobile from '../components/mobile/MachinesStatusOverviewMobile.vue'
import ResetPassword from '../components/ResetPassword.vue'
import MachinesStatusOverview from '../components/MachinesStatusOverview.vue'

const routes = [
  {
    path: '/',
    name: 'Init',
    component: InitLayout
  },
  {
    path: '/main',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: '/about',
        name: 'About',
        component: About
      },
      {
        path: '/login',
        name: 'Login',
        component: Login
      },
      {
        path: '/profile',
        name: 'Profile',
        component: UserCard
      },
      {
        path: '/register',
        name: 'Register',
        component: Register
      },
      {
        path: '/verify',
        name: 'Verify',
        component: Verify
      },
      {
        path: '/products',
        name: 'Products',
        component: Products
      },
      {
        path: '/machines/:idMachine?',
        name: 'Machines',
        component: Machines
      },
      {
        path: '/machines/product/:idProduct',
        name: 'MachinesProduct',
        component: Machines
      },
      {
        path: '/machines/distributor/:idDistributor',
        name: 'MachinesDistributor',
        component: Machines
      },
      {
        path: '/machines/customer/:idCustomer',
        name: 'MachinesCustomer',
        component: Machines
      },                  
      {
        path: '/distributors',
        name: 'Distributors',
        component: Distributors
      },
      {
        path: '/customers',
        name: 'Customers',
        component: Customers
      },
      {
        path: '/lastmeasurements',
        name: 'Measurements',
        component: Measurements
      },      
      {
        path: '/measurements/:idMeasure?/machine/:idMachine',
        name: 'MeasurementsMachine',
        component: Measurements
      },
      {
        path: '/editmachine/:idMachine',
        name: 'EditMachine',
        component: EditMachine
      },
      {
        path: '/editattribute/machine/:idMachine/:which',
        name: 'EditAttribute',
        component: EditMachineAttribute
      },
      {
        path: '/labreports/machine/:idMachine/type/:labReportType',
        name: 'LabReports',
        component: LabReports
      },
      {
        path: '/labreportdetails/machine/:idMachine/type/:labReportType/:idLabReport?',
        name: 'LabReportDetails',
        component: labReportDetails
      },
      {
        path: '/measure',
        name: 'Measure',
        component: MeasurementCard
      },
      {
        path: '/editlabreport/:idLabReport',
        name: 'EditLabReport',
        component: EditLabReport
      },
      {
        path: '/users',
        name:'Users',
        component: Users
      },
      {
        path: '/technologies',
        name: 'Technologies',
        component: ProductTechnologyEditor
      },
      {
        path: '/administration',
        name: 'Administration',
        component: AdminMain
      },
      {
        path: '/agents',
        name: 'CustomerAgents',
        component: CustomerAgentAssignment
      },
      {
        path: '/migrationtool',
        name: 'MigrationTool',
        component: MigrationTool
      },
      {
        path: '/machinesoverview',
        name: 'MachinesOverview',
        component: MachinesStatusOverview
      },      
      {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword
      }      
    ]
  },
  {
    path: '/mainmobile',
    name: 'Main',
    component: DefaultLayoutMobile,
    redirect: '/m-dashboard',
    children: [
      {
        path: '/m-dashboard',
        name: 'mDashboard',
        component: DashboardMobile
      },
      {
        path: '/m-about',
        name: 'mAbout',
        component: About
      },
      {
        path: '/m-login',
        name: 'mLogin',
        component: LoginMobile
      },
      {
        path: '/m-profile',
        name: 'mProfile',
        component: UserCard
      },
      {
        path: '/m-register',
        name: 'mRegister',
        component: RegisterMobile
      },
      {
        path: '/m-verify',
        name: 'mVerify',
        component: Verify
      },
      {
        path: '/m-products',
        name: 'mProducts',
        component: ProductsMobile
      },
      {
        path: '/m-machines/:idMachine?',
        name: 'mMachines',
        component: MachinesMobile
      },
      {
        path: '/m-machines/product/:idProduct',
        name: 'mMachinesProduct',
        component: MachinesMobile
      },
      {
        path: '/m-machines/distributor/:idDistributor',
        name: 'mMachinesDistributor',
        component: MachinesMobile
      },
      {
        path: '/m-machines/customer/:idCustomer',
        name: 'mMachinesCustomer',
        component: MachinesMobile
      },      
      {
        path: '/m-distributors',
        name: 'mDistributors',
        component: DistributorsMobile
      },
      {
        path: '/m-customers',
        name: 'mCustomers',
        component: CustomersMobile
      },
      {
        path: '/m-lastmeasurements',
        name: 'mMeasurements',
        component: MeasurementsMobile
      },
      {
        path: '/m-measurements/:idMeasure?/machine/:idMachine',
        name: 'mMeasurementsMachine',
        component: MeasurementsMobile
      },      
      {
        path: '/m-editmachine/:idMachine',
        name: 'mEditMachine',
        component: EditMachineMobile
      },
      {
        path: '/m-editattribute/machine/:idMachine/:which',
        name: 'mEditAttribute',
        component: EditMachineAttribute
      },
      {
        path: '/m-labreports/machine/:idMachine/type/:labReportType',
        name: 'mLabReports',
        component: LabReportsMobile
      },
      {
        path: '/m-labreportdetails/machine/:idMachine/type/:labReportType/:idLabReport?',
        name: 'mLabReportDetails',
        component: labReportDetails
      },
      {
        path: '/m-measure',
        name: 'mMeasure',
        component: MeasurementCard
      },
      {
        path: '/m-editlabreport/:idLabReport',
        name: 'mEditLabReport',
        component: EditLabReport
      },
      {
        path: '/m-users',
        name:'mUsers',
        component: UsersMobile
      },
      {
        path: '/m-technologies',
        name: 'mTechnologies',
        component: ProductTechnologyEditor
      },
      {
        path: '/m-administration',
        name: 'mAdministration',
        component: AdminMainMobile
      },
      {
        path: '/m-agents',
        name: 'mCustomerAgents',
        component: CustomerAgentAssignment
      },
      {
        path: '/m-migrationtool',
        name: 'mMigrationTool',
        component: MigrationTool
      },
      {
        path: '/m-machinesoverview',
        name: 'mMachinesOverview',
        component: MachinesStatusOverviewMobile
      },
      {
        path: '/m-reset-password',
        name: 'mResetPassword',
        component: ResetPassword
      }         
    ]
  },  
  {
    path: '/:pathMatch(.*)',
    name: '404',
    component: NotFoundComponent
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.afterEach(() => {
  localStorage.removeItem('routeInfo')
})

export default router
