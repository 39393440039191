<template>
  <CModal size="xl" :visible="cModalVisible" @show="initData()" @close="closeTechnologyLineCreation()">
    <CModalHeader>
      <CModalTitle>{{ $t('technologyLine') }}</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <CCardText>{{ $t('technologyLineDesc') }}</CCardText>
      <CRow class="mb-3">
        <CFormLabel for="lineType" class="col-sm-2 col-form-label">{{ $t('lineType') }}</CFormLabel>
        <CCol sm="10">
          <CFormSelect class="mb-3" aria-label="status select" id="lineType" v-model="lineType">
            <option value="0">{{ $t('header') }}</option>
            <option value="1">{{ $t('line') }}</option>
          </CFormSelect>
        </CCol>
      </CRow>      
      <CRow class="mb-3">
        <CFormLabel for="lineValue" class="col-sm-2 col-form-label">{{ $t('lineValue') }}</CFormLabel>
        <CCol sm="10">
          <CFormInput type="text" id="lineValue" v-model="lineValue" />
        </CCol>
      </CRow>    
    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="closeTechnologyLineCreation()">
        {{ $t('close') }}
      </CButton>
      <CButton color="primary" @click="createTechnologyLine()">{{ $t('createLine') }}</CButton>
    </CModalFooter>    

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </CModal>
</template>

<script>
export default {
  name: 'NewProductTechnologyLine',
  props: {
    cModalVisible: Boolean,
    currIndex: Number
  },
  data() {
    return {
      message: '',
      lineType: 0,
      lineValue: ''
    }
  },
  methods: {
    createTechnologyLine() {
      if (this.lineValue != '') {
        let technologyLine = {
          'id': 0,
          'value': this.lineValue,
          'line_Type': this.lineType,
          'sort_Index': this.currIndex + 1    
        }
        this.$emit('closeTechnologyLineCreation', technologyLine)
      } else {
        this.closeTechnologyLineCreation()
      }
    },
    closeTechnologyLineCreation() {
      this.message = ''
      this.$emit('closeTechnologyLineCreation')
    },
    initData() {
      this.lineType = 0
      this.lineValue = ''
      this.message = ''
    }     
  }
}
</script>