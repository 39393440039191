import moment from 'moment'

class DateFormatter {
  formatDate(refDate, emptyValueifNull) {
    return (refDate ? moment(String(refDate)).format('LL') : (emptyValueifNull ? null : moment().format('LL')))
  }
  formateDateShort(refDate, emptyValueifNull) {
    return (refDate ? moment(String(refDate)).format('MMM DD') : (emptyValueifNull ? null : moment().format('MMM DD')))
  }
  formatDateNumbers(refDate, emptyValueifNull) {
    return (refDate ? moment(String(refDate)).format('YYYY-MM-DD') : (emptyValueifNull ? null : moment().format('YYYY-MM-DD')))
  }
}

export default new DateFormatter()