<template>
  <CContainer fluid>
    <div class="row">
      <div class="col-12 col-8-tablet push-2-tablet text-center">
        <CIcon size="xxl" :icon="labReportIcon"/>
        <h3 class="title">
          <CIcon icon="cil-arrow-circle-left" size="xxl" @click="backToLabReports()"/>
           {{ labReportTitle }} {{ specificMachine }}
        </h3>
      </div>
    </div>         

    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
      <CButton type="button" color="dark" shape="rounded-0" id="viewAll" @click="toggleView()">{{ $t('viewAll') }}</CButton>
      <CButton v-if="!viewAllItems" type="button" color="dark" shape="rounded-0" id="editLabReport" :disabled="checkUserPermissions" @click="editLabReport()">{{ $t('edit') }}</CButton>
      <CButton v-if="reopenLabReportAvailable" type="button" color="warning" shape="rounded-0" id="reopenLabReport" @click="checkNotificationBeforeReopening()">{{ $t('reopen') }}</CButton>
      <CButton v-if="reopenLabReportAvailable" type="button" color="danger" shape="rounded-0" id="deleteReport" @click="askForDeletionConfirmation()">{{ $t('delete') }}</CButton>
    </div>

    <br>
    <CCard fluid>
      <CTable bordered responsive v-if="isDataLoaded">
        <CTableBody>
          <CTableRow v-for="(column, index) in labReportColumns" :key="index">
            <CTableHeaderCell scope="row">{{column.columnName}}{{column.tolerance}}</CTableHeaderCell>
            <CTableDataCell v-for="(reportValue, index2) in labReportDetailsArray" :key="index2" :color="getCellColor(reportValue.id)">
              {{reportValue[column.name]}}
            </CTableDataCell>
          </CTableRow> 
          <CTableRow>
            <CTableHeaderCell scope="row">{{ $t('comment') }}</CTableHeaderCell>
            <CTableDataCell v-for="(reportValue, index) in labReportDetailsArray" :key="index">
              <CButton type="button" color="dark" variant="outline" id="showComment"
                @click="toggleCommentVisibility(reportValue['comment'], reportValue['result_Indicator'])">{{ $t('showComment') }}</CButton>  
            </CTableDataCell>
          </CTableRow> 
          <CTableRow>
            <CTableHeaderCell scope="row"></CTableHeaderCell>
            <CTableDataCell v-for="(reportValue, index) in labReportDetailsArray" :key="index">
              <CButton type="button" color="dark" variant="outline" id="downloadReport"
                @click="updateReportValuesAndDownload(reportValue)">
                <CIcon size="lg" icon="cil-cloud-download"/>
                {{ $t('downloadReport') }}
                </CButton>  
            </CTableDataCell>
          </CTableRow>                           
        </CTableBody>
      </CTable>
    </CCard>

    <CToaster placement="top-end">
      <CToast color="dark" class="text-white" v-for="(toast, index) in toasts" :key="index">
        <CToastBody>
          {{ toast.content }}
        </CToastBody>  
      </CToast>
    </CToaster>

    <CModal alignment="center" :visible="commentVisible" @close="() => { commentVisible = false }">
      <CModalHeader>
        <CModalTitle>{{ $t('comment') }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CFormTextarea id="labReportComment" rows="5" v-model="labReportComment" :readonly="viewAllItems" /><br>
        <CRow>
          <CCol sm="2">
            <CDropdown light variant="nav-item">
              <CDropdownToggle color="secondary"><CBadge class="border border-light p-3" :color="resultIndicatorColor" shape="rounded-circle">
                <span class="visually-hidden">0</span></CBadge>
              </CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem @click="updateResultIndicator(1)"><CBadge class="border border-light p-3" color="success" shape="rounded-circle">
                  <span class="visually-hidden">1</span></CBadge></CDropdownItem>
                <CDropdownItem @click="updateResultIndicator(2)"><CBadge class="border border-light p-3" color="warning" shape="rounded-circle">
                  <span class="visually-hidden">2</span></CBadge></CDropdownItem>
                <CDropdownItem @click="updateResultIndicator(3)"><CBadge class="border border-light p-3" color="danger" shape="rounded-circle">
                  <span class="visually-hidden">3</span></CBadge></CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </CCol>
          <CCol sm="10">
            <CFormLabel><strong>{{ $t('resultIndicatorText') }}</strong></CFormLabel>
          </CCol>          
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="() => { commentVisible = false }">
          {{ $t('close') }}
        </CButton>
        <CButton color="primary" v-if="updateCommentVisible"
          @click="updateCommentOnLabReport()">
          {{ $t('update') }}
        </CButton>        
      </CModalFooter>
    </CModal>   

    <confirm-action
      :cModalVisible="popupConfirmMessage"
      :title="confirmTitle"
      :message="confirmMessage"
      :yesButton="$t('confirmButton')"
      :noButton="$t('cancelButton')"
      @confirm="confirmAction()" 
      @cancel="cancelAction()" />      

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert>

    <!-- Split report values based on Report Type: Different graphs for Water Analysis... !-->
    <report-line-chart id="phValuesChart" v-if="renderLabReportGraphs"
      :userDataset="phValuesDataset" />
    <report-line-chart id="effConcChart" v-if="renderLabReportGraphs"
      :userDataset="effConcentrationsDataset" />  
    <CRow class="align-items-center" v-if="renderWaterAnalysisGraphs">
      <CCol>
        <report-line-chart id="waterpHChart" v-if="renderWaterAnalysisGraphs"
          :userDataset="waterpHDataset" />
      </CCol>
      <CCol>
        <report-line-chart id="sulfateChart" v-if="renderWaterAnalysisGraphs"
          :userDataset="sulfateDataset" />
      </CCol>
    </CRow>
    <CRow class="align-items-center" v-if="renderWaterAnalysisGraphs">
      <CCol>    
        <report-line-chart id="chlorideChart" v-if="renderWaterAnalysisGraphs"
          :userDataset="chlorideDataset" />
      </CCol>
      <CCol>
        <report-line-chart id="waterHardnessChart" v-if="renderWaterAnalysisGraphs"
          :userDataset="waterHardnessDataset" />
      </CCol>
    </CRow>                       
  </CContainer>
</template>

<script>
import LabReportService from '../services/lab-reports'
import LabReportDetailService from '../services/lab-report-details'
import AssignedParametersService from '../services/machine-assigned-parameters'
import MachineService from '../services/machines'
import LabReportSummary from '../reports/LabReportSummary'
import { ReportStatus } from '../helpers/getEnumValues'
import DateFormatter from '../helpers/dateFormatter'
import PermissionManager from '../helpers/permissionManager'
import AnalysisRequestService from '../services/analysis-requests'
import StringFormatter from '../helpers/stringFormatter'
import ReportLineChart from '../components/ReportLineChart.vue'
import { getStyle } from '@coreui/utils/src'
import MeasurementService from '../services/measurements'
import ParameterFormatter from '../helpers/reportParameterFormatter'
import ConfirmAction from '../components/ConfirmAction.vue'

export default {
  name: 'LabReportDetails',
  data() {
    return {
      machineParameterList: '',
      machineParameterLoaded: false,
      labReportColumns: '',
      labReportArray: '',
      message: '',
      dataLoaded: false,
      labReportsArrayFilled: false,
      labReportDetailsArray: '',
      commentVisible: false,
      labReportComment: '',
      machineNameLoaded: false,
      currMachineName: '',
      viewAllItems: false,
      toasts: [],
      resultIndicator: 0,
      measurements: '',
      measurementsLoaded: false,
      currMachineMinEffConc: 0,
      currMachineMaxEffConc: 0,
      currMachinecriticalPH: 0,
      popupConfirmMessage: false,
      confirmTitle: '',
      confirmMessage: '',
      waterpHValues: [],
      sulfateValues: [],
      chlorideValues: [],
      waterHardnessValues: [],
      lastWaterParameterRetrieved: false      
    }
  },
  created() {
    this.getMachineParameters()
    this.getLabReports(this.$route.params.idLabReport)
    this.getMeasurements()
    this.getWaterAnalysisParameters()
  },
  computed: {
    specificMachine() {
      if (!this.machineNameLoaded) {
        this.getMachineData()
      }
      if (this.currMachineName != '') {
        return ' (' + this.currMachineName + ')'
      } else {
        return ''
      }
    },
    labReportTitle() {
      return ((this.$route.params.labReportType == 1) ? this.$t('waterAnalysis') : this.$t('labReport'))
    }, 
    labReportIcon() {
      return ((this.$route.params.labReportType == 1) ? 'cil-drop' : 'cil-factory')
    },         
    isDataLoaded() {
      if ((this.labReportsArrayFilled) && (this.machineParameterLoaded) && (!this.dataLoaded)) {
        this.getLabReportDetails()
      }
      return this.dataLoaded
    },
    reopenLabReportAvailable() {
      return ((!this.viewAllItems) && (PermissionManager.hasExtendedTechnicianPermissions()))
    },
    checkUserPermissions() {
      return (!(PermissionManager.hasTechnicianPermissions()) || (PermissionManager.getCustomerFilteredVisibility()))
    },
    resultIndicatorColor() {
      switch(this.resultIndicator) {
        case 1:
          return 'success'
        case 2:
          return 'warning'
        case 3:
          return 'danger'
        default:
          return 'light'
      }
    },
    phValuesDataset() {
      let phArray = []
      let phValueLabels= []
      let dataset = []
      if (this.measurementsLoaded) {
        this.measurements.forEach(measure => {
          phArray.unshift(measure.pH)
          phValueLabels.unshift(DateFormatter.formateDateShort(measure.measured_At, true))        
        })
        dataset.push({
          label: this.$t('phValues'),
          backgroundColor: getStyle('--cui-info'),     
          borderColor: getStyle('--cui-info'),
          segment: {
            borderColor: getStyle('--cui-info')     
          },        
          pointHoverBackgroundColor: getStyle('--cui-info'),
          borderWidth: 2, 
          data: phArray,        
        })
        if (this.currMachinecriticalPH != 0) {
          dataset.push({
            label: this.pretifyName('machineFields.critical_pH') + this.getValueDescription(this.currMachinecriticalPH),
            backgroundColor: getStyle('--cui-danger'),
            borderColor: getStyle('--cui-danger'),
            pointHoverBackgroundColor: getStyle('--cui-danger'),
            borderWidth: 2,
            data: this.getConstantArrayValue(phArray, this.currMachinecriticalPH)         
          })
        }
      }
      return {
        labels: phValueLabels,
        datasets: dataset
      } 
    },
    effConcentrationsDataset() {
      let effArray = []
      let effConcLabels = []
      let dataset = []
      if (this.measurementsLoaded) {
        this.measurements.forEach(measure => {
          effArray.unshift(measure.eff_Concentration)
          effConcLabels.unshift(DateFormatter.formateDateShort(measure.measured_At, true)) 
        })
        dataset.push({
          label: this.$t('effConcentration'),
          backgroundColor: getStyle('--cui-info'),     
          borderColor: getStyle('--cui-info'),
          segment: {
            borderColor: getStyle('--cui-info')     
          },        
          pointHoverBackgroundColor: getStyle('--cui-info'),
          borderWidth: 2,
          data: effArray
        })
        if (this.currMachineMinEffConc != 0) {
          dataset.push({
            label: this.pretifyName('machineFields.min_Eff_Conc') + this.getValueDescription(this.currMachineMinEffConc),
            backgroundColor: getStyle('--cui-danger'),
            borderColor: getStyle('--cui-danger'),
            pointHoverBackgroundColor: getStyle('--cui-danger'),
            borderWidth: 2,
            data: this.getConstantArrayValue(effArray, this.currMachineMinEffConc)         
          })
        }
        if (this.currMachineMaxEffConc != 0) {
          dataset.push({
            label: this.pretifyName('machineFields.max_Eff_Conc') + this.getValueDescription(this.currMachineMaxEffConc),
            backgroundColor: getStyle('--cui-danger'),
            borderColor: getStyle('--cui-danger'),
            pointHoverBackgroundColor: getStyle('--cui-danger'),
            borderWidth: 2,
            data: this.getConstantArrayValue(effArray, this.currMachineMaxEffConc)         
          })
        } 
      }         
      return {
        labels: effConcLabels,
        datasets: dataset
      }
    },
    waterpHDataset() {
      let dataset = []
      let dataArray = []
      let dataLabels = []
      if (this.lastWaterParameterRetrieved) {
        this.waterpHValues.forEach(parameter => {
          dataArray.push(parameter.value)
          dataLabels.push(DateFormatter.formateDateShort(parameter.date, true))
        })
        dataset.push({
          label: this.$t('characteristics.pH electrometric'),
          backgroundColor: getStyle('--cui-warning'),     
          borderColor: getStyle('--cui-warning'),
          segment: {
            borderColor: getStyle('--cui-warning')     
          },        
          pointHoverBackgroundColor: getStyle('--cui-warning'),
          borderWidth: 2,
          data: dataArray
        }) 
      }         
      return {
        labels: dataLabels,
        datasets: dataset
      }
    },   
    sulfateDataset() {
      let dataset = []
      let dataArray = []
      let dataLabels = []
      if (this.lastWaterParameterRetrieved) {
        this.sulfateValues.forEach(parameter => {
          dataArray.push(parameter.value)
          dataLabels.push(DateFormatter.formateDateShort(parameter.date, true))
        })
        dataset.push({
          label: this.$t('characteristics.Sulfate'),
          backgroundColor: getStyle('--cui-success'),     
          borderColor: getStyle('--cui-success'),
          segment: {
            borderColor: getStyle('--cui-success')     
          },        
          pointHoverBackgroundColor: getStyle('--cui-success'),
          borderWidth: 2,
          data: dataArray
        }) 
      }         
      return {
        labels: dataLabels,
        datasets: dataset
      }
    },
    chlorideDataset() {
      let dataset = []
      let dataArray = []
      let dataLabels = []
      if (this.lastWaterParameterRetrieved) {
        this.chlorideValues.forEach(parameter => {
          dataArray.push(parameter.value)
          dataLabels.push(DateFormatter.formateDateShort(parameter.date, true))
        })
        dataset.push({
          label: this.$t('characteristics.Chloride'),
          backgroundColor: getStyle('--cui-danger'),     
          borderColor: getStyle('--cui-danger'),
          segment: {
            borderColor: getStyle('--cui-danger')     
          },        
          pointHoverBackgroundColor: getStyle('--cui-danger'),
          borderWidth: 2,
          data: dataArray
        }) 
      }         
      return {
        labels: dataLabels,
        datasets: dataset
      }
    },
    waterHardnessDataset() {
      let dataset = []
      let dataArray = []
      let dataLabels = []
      if (this.lastWaterParameterRetrieved) {
        this.waterHardnessValues.forEach(parameter => {
          dataArray.push(parameter.value)
          dataLabels.push(DateFormatter.formateDateShort(parameter.date, true))
        })
        dataset.push({
          label: this.$t('characteristics.Water hardness'),
          backgroundColor: getStyle('--cui-info'),     
          borderColor: getStyle('--cui-info'),
          segment: {
            borderColor: getStyle('--cui-info')     
          },        
          pointHoverBackgroundColor: getStyle('--cui-info'),
          borderWidth: 2,
          data: dataArray
        }) 
      }         
      return {
        labels: dataLabels,
        datasets: dataset
      }
    },         
    updateCommentVisible() {
      if (this.viewAllItems) {
        return false
      }
      return !this.checkUserPermissions
    },
    isMobile() {
      let mobile = localStorage.getItem('isMobile')
      return (mobile === 'true')
    },
    renderLabReportGraphs() {
      return (!(this.isMobile) && (this.$route.params.labReportType == 0))
    },
    renderWaterAnalysisGraphs() {
      return (!(this.isMobile) && (this.$route.params.labReportType == 1))
    }           
  },
  methods: {
    getMachineParameters() {
      let routeParams = this.$route.params
      let queryParams = {}
      if ((routeParams.idMachine) && (routeParams.idMachine != 0)) {
        queryParams.idMachine = routeParams.idMachine
      } else {
        this.message = this.$t('unknownMachineLabReportException')
        return
      }
      if (routeParams.labReportType) {
        queryParams.type = routeParams.labReportType
      } else {
        this.message = this.$t('unknownReportTypeException')
        return
      }  
      AssignedParametersService.getParameters(queryParams).then(
        (response) => {
          this.machineParameterList = response.data.data
          this.getColumnsArray()
          this.machineParameterLoaded = true
        },
        (error)  => {
          this.message =           
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        }
      )        
    },
    getLabReports(reportID) {
      // Empty Array first...
      this.labReportArray = []
      this.labReportDetailsArray = []
      this.labReportsArrayFilled = false
      this.dataLoaded = false
      if ((reportID) && (reportID != 0)) {
        LabReportService.getSingleLabReport(reportID).then(
          (response) => {
            this.labReportArray.push(response.data)
            this.labReportsArrayFilled = true
            this.setRouteInfoInLocalStorage(response.data)
          },
          (error) => {
            this.message =           
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()              
          }
        )
      } else {
        this.removeRouteInfoFromLocalStorage()
        let routeParams = this.$route.params
        let queryParams = {}
        if ((routeParams.idMachine) && (routeParams.idMachine != 0)) {
          queryParams.idMachine = routeParams.idMachine
        } else {
          this.message = this.$t('unknownMachineLabReportException')
          return
        }
        if ((routeParams.currProductID) && (routeParams.currProductID != 0)) {
          queryParams.idProduct = routeParams.currProductID
        }        
        if (routeParams.labReportType) {
          queryParams.type = routeParams.labReportType
        }
        if (this.checkUserPermissions) {
          queryParams.status = 2
        }
        queryParams.$limit = 50
        queryParams['$sort[id]'] = -1
        LabReportService.getLabReports(queryParams).then(
          (response) => {
            this.labReportArray = response.data.data
            this.labReportsArrayFilled = true
          },
          (error) => {
            this.message =           
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()
          }
        )
      }
    },
    getLabReportDetails() {
      this.labReportArray.forEach(element => {
        let labReport = element
        // Format Date Fields
        if (labReport.date_Ordered) {
          labReport.date_Ordered = DateFormatter.formatDate(labReport.date_Ordered, true)
        }
        if (labReport.date_Of_Analysis) {
          labReport.date_Of_Analysis = DateFormatter.formatDate(labReport.date_Of_Analysis, true)
        }
        if (labReport.completed_At) {
          labReport.completed_At = DateFormatter.formatDate(labReport.completed_At, true)
        }                
        LabReportDetailService.getLabReportDetails({ 'idLabReport': labReport.id }).then(
          (response) => {
            const reportDetails = response.data.data
            this.machineParameterList.forEach(parameter => {
              const reportParam = reportDetails.find(detail => detail.id == parameter.id)
              if (reportParam) {
                labReport[parameter.characteristic_Name] =
                  ((reportParam.unit != '') ? 
                    (ParameterFormatter.getParameterOptionValue(parameter.id, Number(reportParam.sample)) + ' (' + reportParam.unit + ')') : 
                    (ParameterFormatter.getParameterOptionValue(parameter.id, Number(reportParam.sample))))
              } else {
                labReport[parameter.characteristic_Name] = ''
              }
            })
            this.labReportDetailsArray.push(labReport)
            if (this.labReportArray.length == this.labReportDetailsArray.length) {
              if (this.labReportDetailsArray.length > 1) {
                this.labReportDetailsArray.sort((a, b) => {return b.id - a.id})
              }
              this.dataLoaded = true
            }
          }
        )
      })
    },
    getMachineData() {
      let routeParams = this.$route.params
      if ((routeParams.idMachine) && (routeParams.idMachine != 0)) {          
        MachineService.getSingleMachine(routeParams.idMachine).then(
          (response) => {
            this.currMachineName = response.data.name
            this.currMachineMinEffConc = response.data.min_Eff_Concentration
            this.currMachineMaxEffConc = response.data.max_Eff_Concentration
            this.currMachinecriticalPH = response.data.critical_pH
            this.machineNameLoaded = true
          }
        )
      } else {
        this.machineNameLoaded = true
        this.currMachineName = ''            
      }
    },
    getColumnsArray() {
      this.labReportColumns = [
        { 'name': 'status', 'columnName': this.$t('status'), 'tolerance': ''},
        { 'name': 'product_Name', 'columnName': this.pretifyName('labReportFields.product'), 'tolerance': ''},
        { 'name': 'sample_Number', 'columnName': this.pretifyName('labReportFields.sample_Number'), 'tolerance': ''},
        { 'name': 'batch_Number', 'columnName': this.pretifyName('labReportFields.batch_Number'), 'tolerance': ''},
        { 'name': 'reason_Of_Analysis', 'columnName': this.pretifyName('labReportFields.reason_Of_Analysis'), 'tolerance': ''},
        { 'name': 'date_Ordered', 'columnName': this.pretifyName('labReportFields.date_Ordered'), 'tolerance': ''},
        { 'name': 'date_Of_Analysis', 'columnName': this.pretifyName('labReportFields.date_Of_Analysis'), 'tolerance': ''},
        { 'name': 'completed_At', 'columnName': this.pretifyName('labReportFields.completed_At'), 'tolerance': ''},
        { 'name': 'appearance', 'columnName': this.pretifyName('labReportFields.appearance_2'), 'tolerance': ''},
        { 'name': 'deposit', 'columnName': this.pretifyName('labReportFields.deposit'), 'tolerance': ''},
        { 'name': 'smell', 'columnName': this.pretifyName('labReportFields.smell'), 'tolerance': ''}
      ]
      this.machineParameterList.forEach(parameter => {
        let characteristicTag = 'characteristics.' + parameter.characteristic_Name
        this.labReportColumns.push({ 'name': parameter.characteristic_Name, 'columnName': this.$t(characteristicTag), 'tolerance': (parameter.tolerance ? ' - (' + parameter.tolerance + ')' : '') })
      })
    },
    getMeasurements() {
      let routeParams = this.$route.params     
      if ((routeParams.idMachine) && (routeParams.idMachine != 0)) {
        let queryParms = {}
        queryParms.idMachine = routeParams.idMachine
        if ((routeParams.currProductID) && (routeParams.currProductID != 0)) {
          queryParms.idProduct = routeParams.currProductID
        }
        queryParms.$limit = 500
        queryParms['$sort[measured_At]'] = -1
        MeasurementService.getMeasurements(queryParms).then(
          (response) => {
            this.measurements = response.data.data
            this.measurementsLoaded = true
          },
          (error)  => {
            this.message =           
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()
          }
        )        
      } 
    },
    getWaterAnalysisParameters() {
      this.waterpHValues = []
      this.sulfateValues = []
      this.chlorideValues = []
      this.waterHardnessValues = [] 
      this.lastWaterParameterRetrieved = false
      let routeParams = this.$route.params  
      if (routeParams.labReportType == 1) {   
        if ((routeParams.idMachine) && (routeParams.idMachine != 0)) {
          let queryParms = {}
          queryParms.idMachine = routeParams.idMachine
          if ((routeParams.currProductID) && (routeParams.currProductID != 0)) {
            queryParms.idProduct = routeParams.currProductID
          }
          queryParms.type = 1
          queryParms.$limit = 500
          queryParms['$sort[date_Of_Analysis]'] = 1
          LabReportService.getLabReports(queryParms).then(
            (response) => {
              const waterAnalysisReports = response.data.data
              const totalNoOfElements = waterAnalysisReports.length
              let processedReports = 0
              waterAnalysisReports.forEach(waterAnalysisReport => {
                LabReportDetailService.getLabReportDetails({ 'idLabReport': waterAnalysisReport.id }).then(
                  (response) => {
                    const reportDetails = response.data.data
                    reportDetails.forEach(detail => {
                      switch (detail.id) {
                        case 1:
                          this.waterHardnessValues.push({ 
                            date: waterAnalysisReport.date_Of_Analysis,
                            value: detail.sample
                          })
                          break
                        case 2:
                          this.waterpHValues.push({ 
                            date: waterAnalysisReport.date_Of_Analysis,
                            value: detail.sample
                          })
                          break
                        case 6:
                          this.chlorideValues.push({ 
                            date: waterAnalysisReport.date_Of_Analysis,
                            value: detail.sample
                          })
                          break
                        case 7:
                          this.sulfateValues.push({ 
                            date: waterAnalysisReport.date_Of_Analysis,
                            value: detail.sample
                          })
                          break
                        default:
                          break                                                                              
                      }
                    })
                    processedReports += 1
                    if (processedReports >= totalNoOfElements) {
                      this.lastWaterParameterRetrieved = true
                      this.sortWaterAnalysisParameters()
                    }
                  },
                  () => {
                    // Increase variable also in case of errors...
                    processedReports += 1
                    if (processedReports >= totalNoOfElements) {
                      this.lastWaterParameterRetrieved = true
                      this.sortWaterAnalysisParameters()
                    }                    
                  }
                )
              })
            },
            (error)  => {
              this.message =           
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString()
            }            
          )
        }
      }
    },
    sortWaterAnalysisParameters() {
      this.waterpHValues.sort((a, b) => {return new Date(a.date) - new Date(b.date)})
      this.sulfateValues.sort((a, b) => {return new Date(a.date) - new Date(b.date)})
      this.chlorideValues.sort((a, b) => {return new Date(a.date) - new Date(b.date)})
      this.waterHardnessValues.sort((a, b) => {return new Date(a.date) - new Date(b.date)})
    },    
    toggleCommentVisibility(currComment, currIndicator) {
      if (this.commentVisible) {
        this.labReportComment = ''
        this.resultIndicator = 0
      } else {
        this.labReportComment = currComment
        this.resultIndicator = currIndicator
      }
      this.commentVisible = !this.commentVisible
    },
    backToLabReports() {
      this.$router.go(-1)
    },
    toggleView() {
      this.viewAllItems = !this.viewAllItems
      if (this.viewAllItems) {
        this.getLabReports()
      } else {
        this.getLabReports(this.$route.params.idLabReport)
      }
    },
    updateReportValuesAndDownload(reportValue, fileName = '') {
      reportValue.analysisFloatingOilPhase = false
      AnalysisRequestService.getAnalysisRequests({ 'request_Type': Number(reportValue.type) + 1, 'idMeasurement': reportValue.id }).then(
        (response) => {
          if (response.data.data[0]) {
            reportValue.analysisFloatingOilPhase = response.data.data[0].floating_Oil_Phase
          }
          this.downloadReport(reportValue, fileName)
        },
        () => {
          this.downloadReport(reportValue, fileName)
        }
      )    
    },
    downloadReport(reportValue, fileName = '') {
      let reportTarget = JSON.parse(JSON.stringify(reportValue))
      // ORG: if (this.$route.params.labReportType == 0) {
        if (this.isMobile) {
          reportTarget.isMobile = true
        } else {
          if (this.$route.params.labReportType == 0) {
            const phValuesChart = document.getElementById('phValuesChart')
            reportTarget.phValuesChart = phValuesChart.toDataURL('image/jpeg', 1.0)
            const effConcChart = document.getElementById('effConcChart')
            reportTarget.effConcChart = effConcChart.toDataURL('image/jpeg', 1.0)
          } else {  
            const waterpHChart = document.getElementById('waterpHChart')
            reportTarget.waterpHChart = waterpHChart.toDataURL('image/jpeg', 1.0)
            const sulfateChart = document.getElementById('sulfateChart')
            reportTarget.sulfateChart = sulfateChart.toDataURL('image/jpeg', 1.0)  
            const chlorideChart = document.getElementById('chlorideChart')
            reportTarget.chlorideChart = chlorideChart.toDataURL('image/jpeg', 1.0)
            const waterHardnessChart = document.getElementById('waterHardnessChart')
            reportTarget.waterHardnessChart = waterHardnessChart.toDataURL('image/jpeg', 1.0)              
          }
        }
      // ORG: }    
      // Pass filtered parameter list:
      let filteredParameterList = this.machineParameterList.filter(parameter => parameter.hide_In_Reports == false)
      LabReportSummary.print(reportTarget, filteredParameterList, this.labReportTitle, fileName)
    },
    setRouteInfoInLocalStorage(currReport) {
      if (currReport) {
        const params = {
          'idMachine': currReport.idMachine,
          'idProduct': currReport.idProduct,
          'idCustomer': currReport.idCustomer,
        }
        if ((currReport.idAnalysisRequest) && (currReport.idAnalysisRequest != 0)) {
          params.idRequest = currReport.idAnalysisRequest
        }
        localStorage.setItem('routeInfo', JSON.stringify(params))
      }
    },
    removeRouteInfoFromLocalStorage() {
      localStorage.removeItem('routeInfo')
    },
    getLabReportStatus(statusString) {
      if ((statusString) && (statusString != '')) {
        return ReportStatus.indexOf(statusString)
      }
    }, 
    editLabReport() {
      if (this.getLabReportStatus(this.labReportArray[0].status) == 2) {
        this.createToast(this.$t('reportClosedMessage', {rep: this.labReportTitle}))
      } else {
        if (this.isMobile) {
          this.$router.push({ name: 'mEditLabReport', params: this.getEditParameters() })
        } else {
          this.$router.push({ name: 'EditLabReport', params: this.getEditParameters() })
        }
      }
    },
    checkNotificationBeforeReopening() {
      AnalysisRequestService.getAnalysisRequests({ 'request_Type': Number(this.$route.params.labReportType) + 1, 'idMeasurement': this.labReportArray[0].id }).then(
        (response) => {
          if (response.data.data[0]) {
            // Ask for confirmation if sending email or not...
            if (response.data.data[0].keep_User_Notified) {
              // Update request first...
              this.updateAnalysisRequestAndReopenReport(response.data.data[0].id)
            } else {
              this.reopenLabReport()
            }
          } else {
            this.reopenLabReport()
          }
        },
        (error) => {
          this.message =           
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        }
      )     
    },
    updateAnalysisRequestAndReopenReport(requestID) {
      if ((requestID) && (requestID != 0)) {
        const queryParams = {
          'notify_User': false
        }
        AnalysisRequestService.updateAnalysisRequest(requestID, queryParams).then(
          () => { 
            this.reopenLabReport()
          },
          (error) => {
            this.message =           
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
          }
        )
      }
    },
    reopenLabReport() {
      if (!(this.getLabReportStatus(this.labReportArray[0].status) == 2)) {
        this.createToast(this.$t('reportOpenMessage', {rep: this.labReportTitle}))
      } else {
        LabReportService.updateLabReport(this.$route.params.idLabReport, { 'status': 1 }).then(
          () => {
            // Refresh data
            this.getLabReports(this.$route.params.idLabReport)
          },
          (error) => {
            this.message =           
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()
          } 
        )        
      }
    },
    createToast(content) {
      this.toasts.push({
        content: content
      })
    },
    getCellColor(reportID) {
      if ((this.viewAllItems) && (reportID) && (this.$route.params.idLabReport) && (this.$route.params.idLabReport == reportID)) {
        return 'info'
      }
      return 'default'
    },
    updateCommentOnLabReport() {
      if ((this.$route.params.idLabReport) && (this.$route.params.idLabReport != 0)) {
        LabReportService.updateLabReport(this.$route.params.idLabReport, { 'comment': this.labReportComment, 'result_Indicator': this.resultIndicator }).then(
          () => {
            // Refresh data
            this.commentVisible = false
            this.getLabReports(this.$route.params.idLabReport)
          },
          (error) => {
            this.message =           
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()
          } 
        )        
      }
    },
    pretifyName(name) {
      return StringFormatter.pretifyName(this.$t(name))
    },
    updateResultIndicator(value) {
      this.resultIndicator = value
    },
    getConstantArrayValue(refArray, value) {
      let resultArray = []
      refArray.forEach(() => {
        resultArray.push(value)
      })
      return resultArray
    }, 
    getLineColor(context, lowerLimit, upperLimit) {
      const index = context.dataIndex
      const value = context.dataset.data[index]
      if (!lowerLimit) { lowerLimit = 0 }
      if (!upperLimit) { upperLimit = 9999}
      return ((value >= lowerLimit) && (value <=upperLimit)) ? getStyle('--cui-success') : getStyle('--cui-danger')
    },
    getValueDescription(inputValue) {
      return (inputValue ? ' (' + inputValue + ')' : '')
    },
    getEditParameters() {
      // Get graphs if available
      let editParams = {
        'idLabReport': this.$route.params.idLabReport
      }
      if (!this.isMobile) {
        if (this.$route.params.labReportType == 0) {
          const phValuesChart = document.getElementById('phValuesChart')
          editParams.phValuesChart = phValuesChart.toDataURL('image/jpeg', 1.0)
          const effConcChart = document.getElementById('effConcChart')
          editParams.effConcChart = effConcChart.toDataURL('image/jpeg', 1.0)    
        } else {
          const waterpHChart = document.getElementById('waterpHChart')
          editParams.waterpHChart = waterpHChart.toDataURL('image/jpeg', 1.0)
          const sulfateChart = document.getElementById('sulfateChart')
          editParams.sulfateChart = sulfateChart.toDataURL('image/jpeg', 1.0)  
          const chlorideChart = document.getElementById('chlorideChart')
          editParams.chlorideChart = chlorideChart.toDataURL('image/jpeg', 1.0)
          const waterHardnessChart = document.getElementById('waterHardnessChart')
          editParams.waterHardnessChart = waterHardnessChart.toDataURL('image/jpeg', 1.0)                 
        }   
      }
      const calledByOverview = this.$route.params.calledByOverview
      if (calledByOverview) {
        editParams.calledByOverview = true
      }
      return editParams
    },
    askForDeletionConfirmation() {
      // Check if customer is associated to a specific machine
      if (this.getLabReportStatus(this.labReportArray[0].status) == 2) {
        this.createToast(this.$t('reportClosedMessage', {rep: this.labReportTitle}))
      } else {
        this.confirmTitle = this.$t('continueTitle')
        this.confirmMessage = this.$t('labReportDeletionWarning', { labReport: this.labReportArray[0].id })
        this.popupConfirmMessage = true
      }     
    },  
    confirmAction() {
      this.resetConfirmMessage()
      this.deleteReport()
    },
    cancelAction() {
      this.resetConfirmMessage()
    },
    resetConfirmMessage() {
      this.confirmTitle = ''
      this.confirmMessage = '',
      this.popupConfirmMessage = false
    },
    deleteReport() {
      // TD: perform deletion
      // 1. Remove link with analysis request
      AnalysisRequestService.getAnalysisRequests({ 'request_Type': Number(this.$route.params.labReportType) + 1, 'idMeasurement': this.labReportArray[0].id }).then(
        (response) => {
          if (response.data.data[0]) {
            const queryParams = {
              'idMeasurement': 0,
              'request_Status': 0,
              'notify_User': false
            }
            AnalysisRequestService.updateAnalysisRequest(response.data.data[0].id, queryParams)            
          }
        } 
      )     

      // 2. Delete Lab Report Details
      LabReportDetailService.getLabReportDetails({ 'idLabReport': this.labReportArray[0].id }).then(
        (response) => {
          if (response.data.total > 0) {
            // 2.1 Delete lab report details before
            LabReportDetailService.deleteLabReportDetail({ 'idLabReport': this.labReportArray[0].id }).then(
              () => { 
                // 3. Delete Lab Report
                LabReportService.deleteLabReport(this.labReportArray[0].id).then(
                  () => {       
                    // 4. Return to report list
                    this.backToLabReports()
                  },
                  (error) => {
                    this.message =           
                      (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                      error.message ||
                      error.toString()
                  }
                )
              },
              (error) => {
                this.message =           
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString()
              }
            )
          } else {
            // 3. Delete Lab Report
            LabReportService.deleteLabReport(this.labReportArray[0].id).then(
              () => {       
                // 4. Return to report list
                this.backToLabReports()
              },
              (error) => {
                this.message =           
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString()
              }
            )
          }
        }
      )
    }                                       
  },
  components: {
    ReportLineChart,
    ConfirmAction
  }
}
</script>

<style scoped>
.chart-wrapper {
  visibility: hidden;
}
</style>