<template>
  <CCard fluid v-if="isDataLoaded">
    <CCardBody>
      <CCardTitle>{{ $t('distributor') }} </CCardTitle>
      <CCardText>{{ pretifyName('distributorFields.id') }}: {{ currDistributor.id }} <br> {{ pretifyName('distributorFields.name') }}: {{ currDistributor.company }}<br>
       {{ pretifyName('distributorFields.address') }}: {{ currDistributor.address }}<br> {{ pretifyName('distributorFields.city') }}: {{ currDistributor.city }}<br>
       {{ pretifyName('distributorFields.phone_Number') }}: {{ currDistributor.phone_Number }}<br> {{ pretifyName('distributorFields.contact_Person') }}: {{ currDistributor.contact_Person }}<br> 
       {{ pretifyName('distributorFields.client_No') }}: {{ currDistributor.client_No }}</CCardText>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <CButton type="submit" color="dark" shape="rounded-0" class="mb-3"
          @click="() => { distributorCardModal = !distributorCardModal }">
          {{ $t('editDistributor') }}
        </CButton>
      </div>
    </CCardBody>

    <distributor-card
      :cModalVisible="distributorCardModal"
      operationType="0"
      :distributorID="distributorID"
      @closeDistributorCardModal="toggleDistrCardModalVisibility($event)"/> 

  </CCard>
</template>

<script>
import DistributorService from '../services/distributors'
import DistributorCard from '../components/DistributorCard.vue'
import StringFormatter from '../helpers/stringFormatter'

export default {
  name: 'DistributorInfobox',
  props: {
    distributorID: Number
  },
  expose: ['dataLoaded'],
  data() {
    return {
      dataLoaded: false,
      currDistributor: '',
      lastDistributorID: 0,
      distributorCardModal: false
    }
  },
  computed: {
    isDataLoaded() {
      if (this.distributorID != this.lastDistributorID) {
        this.refreshData()
      }
      if (!this.dataLoaded) {
        this.getDistributorData()
      }
      return this.dataLoaded
    }
  },
  methods: {
    getDistributorData() {
      if (this.distributorID != 0) {
        DistributorService.getSingleDistributor(this.distributorID).then(
          (response) => {
            this.currDistributor = response.data
            this.lastDistributorID = this.currDistributor.id
            this.dataLoaded = true
          }
        )
      } else {
        this.dataLoaded = true
      }
    },
    refreshData() {
      this.dataLoaded = false
    },
    toggleDistrCardModalVisibility(refreshPage) {
      this.distributorCardModal = !this.distributorCardModal
      if (refreshPage) {
        this.dataLoaded = false
      }
    },
    pretifyName(name) {
      return StringFormatter.pretifyName(this.$t(name))
    }          
  },
  components: {
    DistributorCard
  }
}
</script>