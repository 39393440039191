<template>
  <div>
    <loading-data v-if="!dataLoaded"/>

    <small><general-table v-if="dataLoaded"
      :data="machineEntries"
      :columns="tableColumns"
      :rowsdetail="tableRowsDetails"
      :showItemsPerPage="false"
      :sorter="false"
      :columnFilter="false"/></small>

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </div>
</template>

<script>
import GeneralTable from '@/components/GeneralTable.vue'
import MachineEntriesService from '../../services/machine-entries'
import DateFormatter from '../../helpers/dateFormatter'
import LoadingData from '../LoadingData.vue'

export default {
  name: 'MachineEntriesLookupMobile',
  props: {
    machineID: Number
  },
  data() {
    return {
      dataLoaded: false,
      message: '',
      content: [],
      searchQuery: '',
      tableColumns: [
        { key: 'machineEntryFields.id', _style: 'min-width: 50px' },
        { key: 'machineEntryFields.entry_Type', _style: 'min-width: 100px' },
        { key: 'machineEntryFields.quantity', _style: 'min-width: 100px' },
        { key: 'machineEntryFields.product_Name', _style: 'min-width: 150px' },
        { key: 'machineEntryFields.execution_Date', _style: 'min-width: 150px' },
      ],
      tableRowsDetails: [
      ]
    }
  },
  created() {
    this.getMachineEntriesGradually()
  },
  computed: {
    machineEntries() {
      let processedData = []
      if (this.content.length > 0) {
        this.content.forEach((entry) => {
          processedData.push({
            'machineEntryFields.id': entry.id,
            'machineEntryFields.entry_Type': entry.entry_type,
            'machineEntryFields.quantity': entry.quantity,
            'machineEntryFields.product_Name': entry.product_Name,
            'machineEntryFields.execution_Date': DateFormatter.formatDate(entry.execution_date, true),       
          })                    
        })
      }
      return processedData
    }
  },
  methods: {
    getMachineEntriesGradually(skipParam) {
      if (!skipParam) {
        this.content = []
      }          
      let paramQuery = {
        '$limit': 50
      }
      if ((this.machineID) && (this.machineID != 0)) {
        paramQuery.idMachine = this.machineID
      }
      paramQuery['$sort[id]'] = -1        
      if (skipParam) {
        paramQuery['$skip'] = skipParam
      }
      MachineEntriesService.getMachineEntries(paramQuery).then(
        (response) => {
          if (this.content.length > 0) {
            this.content = this.content.concat(response.data.data)
          } else {
            this.content = response.data.data
          }
          this.dataLoaded = true
          if (response.data.total > response.data.limit + response.data.skip) {
            if (skipParam) {
              skipParam += response.data.limit
            } else {
              skipParam = response.data.limit
            }
            this.getMachineEntriesGradually(skipParam)
          }
        },
        (error)  => {
          this.message =           
           (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }        
      )
    }
  },
  components: {
    GeneralTable,
    LoadingData
  }
}
</script>