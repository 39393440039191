<template>
  <CCard fluid>
    <CCardBody>
      <CCardTitle>{{ $t('quickActions') }}</CCardTitle>
      <CCardSubtitle class="mb-2 text-muted">{{ $t('quickActionsSubtitle') }}</CCardSubtitle>
      <CAlert color="warning" v-if="customerSessionActive">
        {{ $t('customerSessionActiveDescription') }}<strong>{{ customerName }}</strong>
      </CAlert>      
      <div class="d-grid gap-2 d-md-flex justify-content-md-start">
        <CButton type="button" color="dark" shape="rounded-0" id="customerSession"
          @click="startStopCustomerSession()">{{ customerSessionTitle }}</CButton>      
        <CButton type="button" color="dark" shape="rounded-0" id="newMeasurement"
          @click="toggleMachineSelectionVisibility()">{{ $t('newMeasurement') }}</CButton>
        <CButton type="button" color="dark" shape="rounded-0" id="emptyMachine" v-if="!isAgentSession"
          @click="toggleLabReportModalVisibility()">{{ $t('newLabReport') }}</CButton>                  
        <CButton type="button" color="dark" shape="rounded-0" id="emptyMachine" v-if="isAgentSession"
          @click="toggleAnalysisRequestModalVisibility()">{{ $t('requestAnalysis') }}</CButton>          
        <CButton type="button" color="dark" shape="rounded-0" id="machinesOverview"
          @click="openMachineOverview()">{{ $t('machinesOverview') }}</CButton>           
      </div>    
    </CCardBody>
    <lab-report-selection-modal
      :cModalVisible="newLabReportModalVisible"
      @closeMachineSelection="onCloseNewLabReportModal($event)"/>   

    <machine-selection-modal
      :cModalVisible="machineSelectionModalVisible"
      @closeMachineSelection="onCloseMachineSelection($event)"/> 

    <analysis-request
      :cModalVisible="analysisRequestModal"
      @closeAnalysisRequest="toggleAnalysisRequestModalVisibility()"/>  

    <CModal size="xl" alignment="center" :visible="customerSelectionVisible" @close="onCancelCustomerSelection()">
      <CModalHeader>
        <CModalTitle>{{ $t('customers') }}</CModalTitle>
      </CModalHeader>
      <CModalBody>      
        <customers-lookup
          @itemSelected="onConfirmCustomerSelection($event)" />
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="onCancelCustomerSelection()">
          {{ $t('close') }}
        </CButton>
      </CModalFooter>
    </CModal>        
  </CCard>
</template>

<script>
import LabReportSelectionModal from './LabReportSelectionModal.vue'
import MachineSelectionModal from './MachineSelectionModal.vue'
import CustomerService from '../services/customers'
import CustomersLookup from './CustomersLookup.vue'
import AnalysisRequest from './AnalysisRequest.vue'
import PermissionManager from '../helpers/permissionManager'

export default {
  name: 'DistributorQuickActions',
  data() {
    return {
      machineSelectionModalVisible: false,
      newLabReportModalVisible: false,
      customerSelectionVisible: false,
      customerName: '',
      analysisRequestModal: false      
    }
  },
  computed: {
    customerSessionActive() {
      let customerSessionStarted = localStorage.getItem('customerSessionStarted')
      return ((customerSessionStarted) && (!(customerSessionStarted === 'false')))      
    },
    customerSessionTitle() {
      if (this.customerSessionActive) {
        return this.$t('stopCustomerSession')
      } else {
        return this.$t('startCustomerSession')
      }      
    },
    isAgentSession() {
      return (PermissionManager.getAgentFilteredVisibility() != null)
    }    
  },
  created() {
    this.getCustomerName()
  },  
  methods: {
    toggleLabReportModalVisibility() {
      this.newLabReportModalVisible = !this.newLabReportModalVisible
    },
    onCloseNewLabReportModal(selectionParameters) {
      this.toggleLabReportModalVisibility()
      if (this.isMobile()) {
        this.$router.push({ name: 'mMeasure', params: { 'machineID': selectionParameters.idMachine, 'measurementType': Number(selectionParameters.labReportType) + 1 } })
      } else {
        this.$router.push({ name: 'Measure', params: { 'machineID': selectionParameters.idMachine, 'measurementType': Number(selectionParameters.labReportType) + 1 } })
      }
    },    
    onCloseMachineSelection(machineID) {
      this.toggleMachineSelectionVisibility()
      if ((machineID) && (machineID != 0)) {
        if (this.isMobile()) {
          this.$router.push({ name: 'mMeasure', params: { 'machineID': machineID, 'measurementType': 0 } })
        } else {
          this.$router.push({ name: 'Measure', params: { 'machineID': machineID, 'measurementType': 0 } })
        }
      }
    },
    toggleMachineSelectionVisibility() {
      this.machineSelectionModalVisible = !this.machineSelectionModalVisible
    },
    isMobile() {
      let mobile = localStorage.getItem('isMobile')
      return (mobile === 'true')
    },
    startStopCustomerSession() {
      if (!this.customerSessionActive) {
        this.customerSelectionVisible = true
      } else {
        localStorage.setItem('customerSessionStarted', false)
        this.$emit('refreshRequestsLists')
      }      
    },
    onConfirmCustomerSelection(selectedCustomerID) {
      if ((selectedCustomerID) && (selectedCustomerID != 0)) {
        localStorage.setItem('customerSessionStarted', true)
        localStorage.setItem('currentCustomer', selectedCustomerID)
        this.customerSelectionVisible = false
        this.getCustomerName()
        this.$emit('refreshRequestsLists')
      }
    },
    onCancelCustomerSelection() {
      this.customerSelectionVisible = false
      this.$emit('refreshRequestsLists')
    },
    getCustomerName() {
      this.customerName = ''
      let customerID = localStorage.getItem('currentCustomer')
      if ((customerID) && (customerID != 0)) {
        CustomerService.getSingleCustomer(customerID).then(
          (response) => {
            this.customerName = response.data.company
          }
        )
      }
    },
    toggleAnalysisRequestModalVisibility() {
      this.analysisRequestModal = !this.analysisRequestModal
    },
    openMachineOverview() {
      this.$router.push({ name: 'MachinesOverview' })
    },             
  },
  components: {
    MachineSelectionModal,
    LabReportSelectionModal,
    CustomersLookup,
    AnalysisRequest
  }
}
</script>