export default function formatQuery(queryParam) {
    let resultingString = ''
    if ((!queryParam) || (queryParam == ''))
        return resultingString
    for (let parameter in queryParam) {
        if (resultingString == '') {
            resultingString += '?'
        } else {
            resultingString += '&'
        }
        resultingString += parameter;
        let parameterValue = queryParam[parameter]
        const paramOperator = parameterValue[0]
        if (paramOperator == '<') {
            resultingString += '[$lt]'
            parameterValue = parameterValue.substr(1, parameterValue.length)
        } else if (paramOperator == '>') {
            resultingString += '[$gt]'
            parameterValue = parameterValue.substr(1, parameterValue.length)
        } else if (paramOperator == '!') {
            resultingString += '[$ne]'
            parameterValue = parameterValue.substr(1, parameterValue.length)
        } else if (paramOperator == '%') {
            resultingString += '[$like]'
        }
        resultingString += '=' + parameterValue
    }
    return resultingString
}