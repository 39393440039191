<template>
  <CContainer fluid>
    <div class="row">
      <div class="col-12 col-8-tablet push-2-tablet text-center">
        <CIcon size="xxl" :icon="labReportIcon"/>
        <h3 class="title">
          <CIcon icon="cil-arrow-circle-left" size="xxl" @click="backToMeasurements()"/>
           {{ labReportTitle }} {{ specificMachine }}
        </h3>
      </div>
    </div>         

    <general-table v-if="dataLoaded"
      :data="labReports"
      :columns="tableColumns"
      :rowsdetail="tableRowsDetails"
      @itemSelected="showItemDetails($event)"/>

    <CAlert v-if="message" color="danger" class="d-flex align-items-center">
      <CIcon icon="cil-warning" class="flex-shrink-0 me-2" width="24" height="24" />
      <div>
        {{ message }}
      </div>
    </CAlert> 
  </CContainer>
</template>

<script>
import GeneralTable from '../components/GeneralTable.vue'
import MachineService from '../services/machines'
import DateFormatter from '../helpers/dateFormatter'
import PermissionManager from '../helpers/permissionManager'
import LabReportLightService from '../services/lab-reports'

export default {
  name: 'LabReports',
  data() {
    return {
      dataLoaded: false,
      message: '',
      content: '',
      searchQuery: '',
      tableColumns: [
        'labReportFields.id',
        'labReportFields.sample_Number',
        'labReportFields.batch_Number',
        'labReportFields.product',
        'labReportFields.requested_By_Customer',
        'labReportFields.date_Ordered',
        'labReportFields.reason_Of_Analysis',
        'status',
        'labReportFields.measured_By',
        {
          key: 'go_to_details', 
          label: '', 
          _style: 'width:1%', 
          sorter: false, 
          filter: false
        }
      ],
      tableRowsDetails: [],
      machineNameLoaded: false,
      currMachineName: '',
      currMachine: ''
    }
  },
  created() {
    this.getLabReports()
  },
  computed: {
    specificMachine() {
      if (!this.machineNameLoaded) {
        this.getMachineData()
      }
      if (this.currMachineName != '') {
        return ' (' + this.currMachineName + ')'
      } else {
        return ''
      }
    },
    labReportTitle() {
      return ((this.$route.params.labReportType == 1) ? 'Water Analysis' : 'Lab Report')
    },
    labReportIcon() {
      return ((this.$route.params.labReportType == 1) ? 'cil-drop' : 'cil-factory')
    },
    labReports() {
      let processedData = []
      if (this.content.data) {
        this.content.data.forEach((labReport) => {
          processedData.push({
            'labReportFields.id': labReport.id,
            'labReportFields.sample_Number': labReport.sample_Number,
            'labReportFields.batch_Number': labReport.batch_Number,
            'labReportFields.product': this.currMachine.product_Name,
            'labReportFields.requested_By_Customer': this.currMachine.customer_Name,
            'labReportFields.date_Ordered': DateFormatter.formatDate(labReport.date_Ordered, true),
            'labReportFields.reason_Of_Analysis': labReport.reason_Of_Analysis,
            'status': labReport.status,
            'labReportFields.measured_By': labReport.measuredBy_Name ? labReport.measuredBy_Name : labReport.measured_By
          })                    
        })
      }
      return processedData
    }
  },  
  methods: {
    getLabReports() {
      let routeParams = this.$route.params
      let queryParams = {}
      if ((routeParams.idMachine) && (routeParams.idMachine != 0)) {
        queryParams.idMachine = routeParams.idMachine
      }
      if ((routeParams.currProductID) && (routeParams.currProductID != 0)) {
        queryParams.idProduct = routeParams.currProductID
      }      
      if (routeParams.labReportType) {
        queryParams.type = routeParams.labReportType
      }
      if (this.showOnlyCompletedReports()) {
        queryParams.status = 2
      }      
      queryParams.$limit = 5000
      queryParams['$sort[date_Ordered]'] = -1
      LabReportLightService.getLabReports(queryParams).then(
        (response) => {
          this.content = response.data
          this.dataLoaded = true
        },
        (error) => {
          this.message =           
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        }
      )
    },
    getMachineData() {
      let routeParams = this.$route.params
      if ((routeParams.idMachine) && (routeParams.idMachine != 0)) {          
        MachineService.getSingleMachine(routeParams.idMachine).then(
          (response) => {
            this.currMachine = response.data
            this.currMachineName = response.data.name
            this.machineNameLoaded = true
            this.setRouteInfoInLocalStorage(response.data)
          }
        )
      } else {
        this.machineNameLoaded = true
        this.currMachineName = ''            
      }
    },
    showItemDetails(reportID) {
      if ((!reportID) || (reportID == 0)) {
        this.message = this.$t('undefinedReportException')
      } else {
        let routeParams = this.$route.params
        let queryParams = {}
        if ((routeParams.idMachine) && (routeParams.idMachine != 0)) {
            queryParams.idMachine = routeParams.idMachine
        }
        if ((routeParams.currProductID) && (routeParams.currProductID != 0)) {
            queryParams.currProductID = routeParams.currProductID
        }        
        if (routeParams.labReportType) {
            queryParams.labReportType = routeParams.labReportType
        }
        queryParams.idLabReport = reportID
        const calledByOverview = this.$route.params.calledByOverview
        if (calledByOverview) {
          queryParams.calledByOverview = true
        }     
        this.$router.push({ name: 'LabReportDetails', params: queryParams })
      }   
    },
    backToMeasurements() {
      this.$router.go(-1)
    },
    setRouteInfoInLocalStorage(currMachine) {
      if (currMachine) {
        const params = {
          'descriptiveText': this.$t('labReportInfoboxInfo'),
          'idMachine': currMachine.id,
          'idProduct': currMachine.idProduct,
          'idCustomer': currMachine.idCustomer,
          'idDistributor': currMachine.idDistributor
        }
        localStorage.setItem('routeInfo', JSON.stringify(params))
      }
    },
    showOnlyCompletedReports() {
      return (!(PermissionManager.hasTechnicianPermissions()) || (PermissionManager.getCustomerFilteredVisibility()))
    },          
  },
  components: {
    GeneralTable
  }
}
</script>